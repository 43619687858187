import loadingAnimation from '../../../asssets/puzzle-animation.gif';

const PuzzleLoader = () => {
  return (
    <img 
    className='puzzle-loader-gif'
    src={loadingAnimation} alt='gif-checkmark'/>
  )
}

export default PuzzleLoader