import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useTranslate from '../../../hooks/useTranslate';
import { FormStepperContainer, FormSubmitContainer, PanelDescriptor, SaveButton } from '../../UI/StyledComponents/FormsStyledComponents';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InlineContainer } from '../../UI/StyledComponents/GlobalStyledComponents';
import { deleteEmployer } from '../../../store/employerSlice';
import DeleteResourceComponent from '../../SharedComponents/DeleteResourceComponent';
import { useSelector } from 'react-redux';
import { getMyProviderDetails } from '../../../store/providerSlice';
import { useEffect, useState } from 'react';
import useDetectResize from '../../../hooks/useDetectResize';
import { AsyncThunk } from '@reduxjs/toolkit';


interface StepperProps {
  steps: string[];
  children: any, 
  disableFormSubmitOrg?: any,
  goToPanel: (step:number) => any,
  isEdit: boolean,
  getValues: any,
  deleteThisRecordId: string,
  hasDeleteAccess?: boolean,
  deleteThisRecordFunc?: AsyncThunk<any, any, any>,
  tags: number[],
  isMobileTabletWidth: boolean,
  stackStepsTitle: boolean,
  isFormType: 'employer' | 'job' | 'individual';
  setDisplayCompletedMsg?: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: any;
}

const StepperWidget = (props: StepperProps) => {
  const {
    steps,
    children,
    disableFormSubmitOrg,
    goToPanel,
    isEdit,
    getValues,
    deleteThisRecordId,
    deleteThisRecordFunc,
    hasDeleteAccess,
    tags,
    isMobileTabletWidth,
    stackStepsTitle,
    isFormType,
    setDisplayCompletedMsg,
    activeStep,
    setActiveStep,
    handleSubmit
  } = props;

  const t = useTranslate();
  const warnUserMsg = t('deleteAllJobWarningMsg');
  const myDetails = useSelector(getMyProviderDetails);
  
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({})


  const {isLaptop, isDesktop, isTablet } = useDetectResize();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    //watch fields in rhf to determine if completed.
    // return boolean.
    return Object.keys(completed).length;
  };

  const  notifyUserRequiredFieldsCompleted = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    //handleComplete();
    const newActiveStep = +activeStep === (steps.length-1) ? activeStep : (+activeStep + 1);
    console.log(`Go FORWARD: Current Panel: ${activeStep}. Next Panel: ${newActiveStep}`);
    goToPanel(newActiveStep);
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    let step = +activeStep === 0 ? 0 : (+activeStep - 1)
    console.log(`Go BACK: Current Panel: ${+activeStep}. Reverse to Panel: ${+step}`);
    goToPanel(step);
    setActiveStep((prevActiveStep: number) => prevActiveStep = step);
  };

  const handleStep = (step: number) => () => {
    goToPanel(step);
    setActiveStep(step);
  };

  // const handleComplete = () => {
    
  //   console.log(getValues());
  //   const input = getValues()!;
  //   const newCompleted = completed;
  //   //If all required fields are finished mark complete
  //   console.log(input);
  //   if(input !== undefined){
  //     if(isFormType === 'employer'){
  //       if( input.address !== '' && input.industry !== 'default' && input.orgName != '' && input.latitude != 0 && input.longitude !==0 ){
  //         newCompleted[0] = true;
  //         setCompleted(newCompleted);
  //       }
        
  //       if( input.pocFirstName !== ''  && input.pocPhone.length > 0 ){
  //         newCompleted[1] = true;
  //         setCompleted(newCompleted);
  //       }
        
  //       if( tags.length > 0  ){
  //         newCompleted[2] = true;
  //         setCompleted(newCompleted);
  //       }
  //       if(newCompleted[0] && newCompleted[1] && newCompleted[2] ){
  
  //       }else{
  
  //       }
  //     }
  //     else if( isFormType === 'job'){
  //       if( input?.address! !== '' && input?.title! !== ''){
  //         newCompleted[0] = true;
  //         setCompleted(newCompleted);
  //       }
        
  //       if(tags?.length! > 0 && input?.minRate! !== '' && input?.weeklyHrs! > 0){
  //         newCompleted[1] = true;
  //         setCompleted(newCompleted);
  //       }
        
  //       if(input!.daysAvailable!.length > 0 && input!.idealStartDate! !== 'N/A'  && input!.notes!.length > 0 ){
  //         newCompleted[2] = true;
  //         setCompleted(newCompleted);
  //       }
  //       if(newCompleted[0] && newCompleted[1] && showOnlyOnce){
  //         setDisplayCompletedMsg && setDisplayCompletedMsg( currState => currState =true);
  //         setShowOnlyOnce(false);
  
  //       }else{
  //         setDisplayCompletedMsg && setDisplayCompletedMsg( currState => currState =false);
  //       }
  //     }
  //     else if( isFormType === 'individual'){
  //       if( input.address !== '' && input.title !== ''){
  //         newCompleted[0] = true;
  //         setCompleted(newCompleted);
  //       }
        
  //       if(tags.length > 0 && input.minRate !== '' && input.weeklyHrs > 0){
  //         newCompleted[1] = true;
  //         setCompleted(newCompleted);
  //       }
        
  //       if(input.daysAvailable.length > 0 && input.idealStartDate !== 'N/A'  && input.notes.length > 0 ){
  //         newCompleted[2] = true;
  //         setCompleted(newCompleted);
  //       }
  //       if(newCompleted[0] && newCompleted[1] && showOnlyOnce){
  //         setDisplayCompletedMsg && setDisplayCompletedMsg( currState => currState =true);
  //         setShowOnlyOnce(false);
  //       }else{
  //         setDisplayCompletedMsg && setDisplayCompletedMsg( currState => currState =false);
  //       }
  //     }
  //   }
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };


  return (
  <Box sx={{ width: 1 }}>
    <FormStepperContainer 
      border={isMobileTabletWidth ? 'none' : '2px solid #D9E1E7'}
      gridTemplateColumns= '100%'
    >
      <PanelDescriptor textAlign={'center'} width='100%'>
        <Typography 
          variant='h3'
          width='100%'
          marginBottom='5px'
        >
          { steps[activeStep]}
        </Typography>
      </PanelDescriptor>

      <Stepper
        sx={{
          borderRadius: '10px', 
          padding: isMobileTabletWidth ? '0' : '5px !important', 
          width: '100%',
          maxWidth: '800px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }} 
        nonLinear 
        alternativeLabel={stackStepsTitle}
        activeStep={+activeStep}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton 
              color="inherit" 
              onClick={handleStep(index)}
            >
              { (isTablet || isLaptop || isDesktop) ? label : null}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </FormStepperContainer>
    <React.Fragment>
      <>
        {children}
      </>
      <FormSubmitContainer>
        <Box
          sx={{width: '33%', marginRight: 'auto'}}
        >
          {activeStep !== 0 &&
            <Button
              color="inherit"
              onClick={handleBack}
              sx={{ mr: 1 }}
              variant='contained'
            >
              Back
            </Button>
          }
            <Box sx={{ flex: '1 1 auto' }} />

        </Box>
        { (hasDeleteAccess && deleteThisRecordFunc)
          && 
          <Box sx={{width: '33%', marginLeft: 'auto', marginRight: 'auto'}}>
            <DeleteResourceComponent 
              recordId={deleteThisRecordId!}
              warnUserMsg={warnUserMsg} 
              deleteAsyncThunkFunc={deleteThisRecordFunc!} 
              subject={isFormType}
              customStyle={{ backgroundColor:'#d32f2f !important', color:'white'}}         
            />
          </Box>
          }
          <Box
            sx={{width:'33%', marginLeft: 'auto', display: 'contents'}}
          >
           { (activeStep !== steps.length -1) &&  <Button onClick={() => {handleNext()}} sx={{ mr: 1 }}>
              Next
            </Button>}
              {( 
                <SaveButton 
                  onClick={handleSubmit}
                  variant="contained"
                >
                {(isEdit ? t('submitSettingsBtn').toUpperCase() + ' ' : t('submitSaveBtn').toUpperCase() + ' ' ) +  (isFormType === 'employer' ? t('employerBtnText').toUpperCase() : isFormType === 'job' ? t('jobBtnText').toUpperCase() : t('individualBtnText').toUpperCase() )}
                </SaveButton>
              )}

          </Box>
      </FormSubmitContainer>
    </React.Fragment>
  </Box>
);
}

export default StepperWidget