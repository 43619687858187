import Button from '@mui/material/Button';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import Icon from '../../asssets/icons';

import ConfirmDeletionComponent from './ConfirmDeletionComponent';
import AltSuccessComponent from '../UI/Popup/AltSuccessComponent';
import Modal from '../UI/Popup/BackdropComponent';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useTranslate from '../../hooks/useTranslate';
import { DeleteOverlay } from '../UI/StyledComponents/NotificationModalStyledComponents';
import useDetectResize from '../../hooks/useDetectResize';

interface DeleteProp {
    recordId: string,
    warnUserMsg: string,
    deleteAsyncThunkFunc: AsyncThunk<any,any,any>,
    subject: string,
    customStyle?: any
}

const DeleteResourceComponent = (props: DeleteProp) => {
  const {recordId, warnUserMsg, subject, deleteAsyncThunkFunc, customStyle} = props;
  const [displayModal, setDisplayModal ] = useState<boolean>(false);
  const [deleteSuccessful, setDeleteSuccessful] = useState<boolean>(false);
  const {windowDimensions} = useDetectResize();
  const t = useTranslate();
 
  useEffect(()=>{
    deleteSuccessful && setDisplayModal(false);
    const hideConfirmationDelay = setTimeout(()=>{
      setDeleteSuccessful(false);
    }, 1600);

    return () => {
      clearTimeout(hideConfirmationDelay);
    }

  }, [deleteSuccessful])
  
  return (
    <>
      <Button
        variant='contained'
        sx={customStyle || {}}
        onClick={() => {
            setDisplayModal(true);
            }
        }
      >
        {<DeleteOutlineOutlinedIcon sx={{color:'white'}} />}{ ' ' + t('delete')}
      </Button>
    {
      displayModal 
      && 
      <Modal 
        className='del-res-ovrly' 
        onHide={ () =>{
          setDisplayModal(false)
        }} 
      >
        <DeleteOverlay
          onClick={(e)=>{e.stopPropagation()}}
          top = {windowDimensions.h}
          right = {windowDimensions.w}
        >
          <ConfirmDeletionComponent 
            className='open-jobs-popup' 
            closeModal={() => {
              setDisplayModal(false)
            }}
            icon={Icon.AlertGif} 
            confirmDeletion={setDeleteSuccessful}
            recordId={recordId!}
            asyncThunkFunc={deleteAsyncThunkFunc}
            messageWarning={warnUserMsg}
            subject={subject}
          />          
        </DeleteOverlay>
      </Modal>
    }
    {
      deleteSuccessful
      &&
      <Modal
        className='confirm-del-modal'
        onHide={() => {}}
      >
        <DeleteOverlay
          onClick={(e)=>{e.stopPropagation()}}
          top = {windowDimensions.h}
          right = {windowDimensions.w}
        >
          <AltSuccessComponent message='Delete successful' animation={Icon.SwooshGif}/>
        </DeleteOverlay>
      </Modal>
    }
    </>
  )
}

export default DeleteResourceComponent