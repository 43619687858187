import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from ".";
import { JobFormData } from "../components/JobComponents/JobCreateComponent";
import { Job, JobMetrics } from "../components/typescript/types";
import { getSortedRankedKeyValuePair } from "../helpers/helperFunctions";

export interface JobSlice {
  allJobs:Job[],
  groupedJobsByTagCount: Job[],
  IJM: JobMetrics[],
  status: string,
  error: string | null,
  myOrgId: string,
}

export const fetchJobs = createAsyncThunk('jobs/fetchJobs', async (params: {orgId:string, token: string}) => {
  //currently orgId is null, when we toggle between public & private jobs, we want to pass the orgId to fetch org specific.
  return axios
  .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/job/${params.orgId}`, {
    headers:{
      'Authorization': `Bearer ${params.token}` 
    }
  })
  .then((response) => {
    console.info('Response job: ', response);
    return response.data;  
  })
  .catch((err) => {
    console.log(err);
  });
});

export const postJob = createAsyncThunk('jobs/postJob', async (params:{jobObject: JobFormData, token: string}) => {
  return axios
  .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/job/create`, params.jobObject, {
    headers:{
      'Authorization': `Bearer ${params.token}` 
    }
  })
  .then(response => {
    console.info("Job POST res: ", response.data);
    return response.data; //don't change to return just an object, will return undefined in extra reducers section.
  })
  .catch((err) => {
    return err.message;
  });
});

export const updateJob = createAsyncThunk('jobs/updateJob', async(params: {updatedJob: JobFormData, token: string}) => {
  return axios
  .put(`${process.env.REACT_APP_SERVER_URL}/api/v1/job/update`, params.updatedJob, {
    headers:{
      'Authorization': `Bearer ${params.token}` 
    }
  })
  .then((response) => {
    console.log("JOB PUT");
    return response.data;   
  })
  .catch(err => {
    return err.message;
  })
});

export const deleteJobRecord = createAsyncThunk('jobs/deleteRecord', async (params: { deletePayload: { creator:string, lookupId: string }, token:string}) => {
  console.info('Reducer delete job', params.deletePayload);

  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/job/delete`,
    data: params.deletePayload,
    headers: {'Authorization': `Bearer ${params.token}`}
  }).then((res) => {
    return res.data;
  }).catch(err => {
    return err.message;
  });
});

export const deleteJobFile = createAsyncThunk('jobs/deleteFile', async(params: {jobId:string, fileRef:string, provId:string, isMulti:boolean, token: string}) => {
  return axios
  .put(`${process.env.REACT_APP_SERVER_URL}/api/v1/job/update/file`, {
    id: params.jobId,
    fileRef: params.fileRef,
    provId: params.provId,
    isMulti: params.isMulti
  }, 
  {
    headers: {
      'Authorization': `Bearer ${params.token}`
    }
  })
  .then((response) => {
    return response.data;
  })
  .catch(err => {
    return err.message;
  })
})

const initialState: JobSlice = {
  allJobs: [],
  groupedJobsByTagCount: [],
  IJM: [],
  status:'idle',
  error: null,
  myOrgId: '',
};


const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobStatus: (state: JobSlice, action: PayloadAction<string>) => {
      console.log('updated job status!');
      return {
        ...state,
        status: action.payload
      }
    },
    setJobList: (state: JobSlice, action: PayloadAction<Job[]>) => {
      state.allJobs = [...action.payload];
    },
    setJobMetrics: (state: JobSlice, action: PayloadAction<JobMetrics[]>) => {
      console.info('Reducer Updating IJM, action.payload: ', action.payload);
      state.IJM = [...action.payload]
    },
    setJobsReportByTagCount: (state: JobSlice, action: {payload:{totalCount:number, organizationOrGlobal:string}}) => {
      const totalCount = action.payload.totalCount;
      const userOrgId = action.payload.organizationOrGlobal;
      const results = state.allJobs.filter((job:Job) => {
        if(userOrgId.startsWith('all jobs')){
          return totalCount < 3 ? job.tags.length === action.payload.totalCount : job.tags.length >=3; 
        }else{
          return totalCount < 3 ? (job.tags.length === action.payload.totalCount && job.providerOrgId === userOrgId ) : (job.tags.length >=3 && job.providerOrgId === userOrgId); 
        }
      });
      state.groupedJobsByTagCount = [...results]
    },
    socketJobPayload: (state: JobSlice, action: PayloadAction<{data: {job: Job[], metrics: JobMetrics[], isNew:boolean}}>) => {
      console.info( 'Incoming socket data! ',  action);
      const {job: newJob, metrics, isNew } = action.payload.data;
     
      //check if job exists in array, if it does, replace, if not push into array.
      storeJob();
      //filter all metrics based off of organization_id then see if they exist in store replace or add.
      storeMetrics();
      
      function storeJob(){
        const jobIndex = state.allJobs.findIndex((job: Job) => job.id === newJob[0].id);

        if(jobIndex === -1){ //cannot find, so it's a new job.
          void state.allJobs.push(newJob[0]);
        }else{
          state.allJobs[jobIndex] = newJob[0];
          state.allJobs = [...state.allJobs];
        }
      }
      
      function storeMetrics(){
        let myNewMetrics = metrics.filter(metric => metric.ijmIndividualOrgId === state.myOrgId);
        // console.info(myNewMetrics);
        if(isNew){
            myNewMetrics.forEach(metric => {
            void state.IJM.push(metric);
          });
        }else{
          myNewMetrics.forEach(newMetric => {
            let index = state.IJM.findIndex(currMetric => currMetric.ijmIndividual === newMetric.ijmIndividual && currMetric.ijmJob === newMetric.ijmJob);
            state.IJM[index] = newMetric;
          });
          state.IJM = [...state.IJM]
        }
      }
    },
    socketJobMetrics: (state: JobSlice, action: PayloadAction<{ijMetrics: JobMetrics[], isNew:boolean}>) => {
      const {ijMetrics, isNew } = action.payload;

      storeMetrics();
      
      function storeMetrics(){
        // console.log(ijMetrics);
        let myNewMetrics = ijMetrics.filter(metric => metric.ijmIndividualOrgId === state.myOrgId);

        if(isNew && myNewMetrics.length > 0){
           myNewMetrics.forEach(metric => {
            void state.IJM.push(metric);
           });
        }else if (!isNew && myNewMetrics.length > 0){
          myNewMetrics.forEach(newMetric => {
            let index = state.IJM.findIndex(currMetric => currMetric.ijmIndividual === newMetric.ijmIndividual && currMetric.ijmJob === newMetric.ijmJob);
            state.IJM[index] = {...newMetric};
          });
          state.IJM = [...state.IJM]
        }
      }
    },
    socketJobAttachmentUpdate: (state: JobSlice, action: PayloadAction<{data: {job: Job[] }}>) => {
      const updatedJob = action.payload.data.job[0];
      let index = state.allJobs.findIndex(storedJob => storedJob.id === updatedJob.id);
      state.allJobs[index] = updatedJob;
      state.allJobs = [...state.allJobs];
    }
  },
  extraReducers(builder) {
    builder
    .addCase(fetchJobs.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(fetchJobs.fulfilled, (state, action) => {
      state.status = 'succeeded';
      console.info('JobMetrics Payload: ', action.payload.data);

      const jobMetrics = action.payload.data.metrics.map((metric: JobMetrics) => {
        return metric;
      });

      state.IJM = jobMetrics;
      state.myOrgId= action.payload.data.myOrgId;
      //console.log('Set my orgId to: ', action.payload.data.myOrgId);

    })
    .addCase(fetchJobs.rejected, (state, action) => {
      state.status = 'error';
      state.error = action.error.message!;
    })
    .addCase(postJob.fulfilled, (state,action) => {
      // state.status = 'posted';
      // console.info('postJob xReducer payload:', action.payload.data);
      // state.IJM = [...action.payload.data.metrics];
      // state.allJobs = [...state.allJobs];
    })
    .addCase(updateJob.fulfilled, (state, action) => {
      // state.status = 'updated'
      // console.info('updateJob xReducer payload: ', action.payload.data);
      // const updateIndex = state.allJobs.findIndex(job => job.id === action.payload.data.job[0].id);
      // state.allJobs[updateIndex] = action.payload.data.job[0];
      // state.allJobs = [...state.allJobs];
      // state.IJM = [...action.payload.data.metrics];
    })
    .addCase(deleteJobFile.fulfilled, (state, action) => {
      console.log(action.payload);
      const updateIndex = state.allJobs.findIndex(job => job.id === action.payload.jobId);
      console.log(updateIndex);
      if(action.payload.isMulti === false){
        state.allJobs[updateIndex].jobDescFile = 'Empty';
        state.allJobs = [...state.allJobs];
      }else{
        state.allJobs[updateIndex].additionalFiles = ['Empty'];
        state.allJobs = [...state.allJobs];
      }
    })
    .addCase(deleteJobRecord.fulfilled, (state, action) => {
      console.info('job deleted from system', action.payload.data);
    });
  },
});

export const {
  setJobMetrics,
  socketJobPayload,
  socketJobMetrics,
  socketJobAttachmentUpdate,
  setJobStatus,
  setJobList,
  setJobsReportByTagCount,
} = jobSlice.actions;
export const getAllJobs = (state: RootState) => state.jobs.allJobs;
export const getIJMData = (state: RootState) => state.jobs.IJM;
export const getJobStatus = (state: RootState) => state.jobs.status;
export const getJobError = (state: RootState) => state.jobs.error;

export const getJobDistribution = (state: RootState) => state.jobs.groupedJobsByTagCount;

export const selectJobById = ( state: RootState, jobId:string ) => {
  return state.jobs.allJobs.find( (job) => job.id === jobId)
}

export const getEmployerSpecificJobs = ( state: RootState, employerId:string) => {
  return state.jobs.allJobs.filter( (job) => job.employerId === employerId);
}

export const getJobCount = ( state: RootState) => {
  return state.jobs.allJobs.reduce((openCount:number, job: Job) => {return job.isJobActive ?  openCount +1 : openCount}, 0)
}


export const getFlatArrayOfJobTags = (state: RootState, filterQuery: string, usersOrgId: string) => {
  //get an array of all skill/interest keywords as a flat map array
  let selectedStatus = filterQuery.startsWith('open') ? true : false;
  const rawTagList =  state.jobs.allJobs
    .filter((job:Job) => {
        if(!usersOrgId.startsWith('show all jobs')){
          return filterQuery === 'all' ? (true && job.providerOrgId === usersOrgId) : (job.isJobActive === selectedStatus && job.providerOrgId === usersOrgId)
        }else{
          return filterQuery === 'all' ? true : job.isJobActive === selectedStatus  
        }
      }
    )
    .map((job: Job) => {
    return job.tags;
    })
    .flat();
  //create k:v pair
  
  let results = getSortedRankedKeyValuePair(rawTagList);

  return results
}

export const getArrayOfJobTags = (state: RootState, filterQuery: string, usersOrgId: string) => {
  let selectedStatus = filterQuery.startsWith('all') ? (true || false) : filterQuery.startsWith('open') ? true  : false;
  return state.jobs.allJobs
    .filter((job:Job) => {
        if(!usersOrgId.startsWith('show all jobs')){
          return filterQuery === 'all' ? (true && job.providerOrgId === usersOrgId) : (job.isJobActive === selectedStatus && job.providerOrgId === usersOrgId)
        }else{
          return filterQuery === 'all' ? true : job.isJobActive === selectedStatus  
        }
      }
    )
    .map((job: Job) => {
    let sortedResults = [...job.tags].sort((a:number, b:number) => {return +a > +b ? 1 : -1})
    return sortedResults;
  });
}
export default jobSlice.reducer;