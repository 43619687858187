import { Box, Typography } from "@mui/material"
import { FilledHoursBadge, StatusBadge } from "../UI/StyledComponents/GlobalStyledComponents"
import useTranslate from "../../hooks/useTranslate"

export const HrsBadge = (props: {numerator: number, denominator: number}) => {
  return(
    <FilledHoursBadge
      className={`hrs-bdge`}
    >
      <Typography
        variant='caption'
        color='white'
        className='hrs-bdge'
      >
        {props.numerator +'/' + props.denominator + ' hrs'}
      </Typography>
    </FilledHoursBadge>
  )
}
export const JobHrsBadge = (props: { totalHrs: number}) => {
  return(
    <FilledHoursBadge
      className={`hrs-bdge`}
      minWidth='85px !important'
      textAlign='center'
    >
      <Typography
        variant='caption'
        color='white'
        className='hrs-bdge'
      >
        {props.totalHrs + ' hrs avail'}
      </Typography>
    </FilledHoursBadge>
  )
}


export const IndStatusBadge = (props: {isOpen: boolean}) => {
  const t = useTranslate();
  return(
    <StatusBadge 
      backgroundColor= {`${props.isOpen! ?  '#3d7652' : '#eb393d'}`}
    >
      <Typography variant='caption' >
        { props.isOpen! ?  t('open') : t('closed')}
      </Typography>
    </StatusBadge>

  )
}