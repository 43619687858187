import React, { createContext, ReactNode, useEffect, useState } from 'react'
import { enTrans, frTrans } from './translationText';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

export const TranslationContext = React.createContext<any | null>(null);

const TranslationProvider = ({children}:{children: ReactNode}) => {
    const [locale, setLocale] = useState<string>(() =>{ return localStorage.getItem('language')  || 'en'});
    
    useEffect(()=>{
        setLocale((currState: string) => {
            return currState = localStorage.getItem('language') || 'en';
        });
        
        localStorage.getItem('language') === 'fr' && dayjs.locale('fr');
    }, [])

    return(
    <TranslationContext.Provider value={ locale === 'en' ? enTrans : frTrans}>
        {children}
    </TranslationContext.Provider>
)};

export default TranslationProvider;