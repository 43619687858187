import { styled } from "@mui/material/styles";
import { Box, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { SystemProps, Theme } from "@mui/system";

export const WidgetContainer = styled(Box)(({theme}) => ({
  fontFamily: 'LexendDeca',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.5',
  textTransform: 'uppercase',
  letterSpacing: 'normal',
  textAlign: 'left',
  
  padding: '16px 12px',
  borderRadius: '16px',
  justifyContent: 'space-between',
  width: '49.5%',
  background: 'var( --secondary-color)',
  borderColor: 'var( --secondary-color)',
  [theme.breakpoints.down('sm')] : {
    height: '104px',
  },
  [theme.breakpoints.up('sm')] : {
    height: '120px',
  },
  [theme.breakpoints.up('md')] : {

  },


}));

export const  WidgetFormLink = styled(Box)(({theme}) => ({
  marginTop: '10px',
  '& > a' : {
    textDecoration: 'none',
    fontWeight: '600',
    lineHeight: '1.85',
    textAlign: 'left',
    color: '#4f5f71',
    fontSize: '0.95em',
    display: 'inline-flex',
  },

}));

export const WidgetText = styled(Typography)(({theme, fontWeight}) => ({
  color: 'white',
  fontFamily: `LexendDeca`,
  fontWeight: `${fontWeight}`
}));