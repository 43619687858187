import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getDeltaTime } from "../../helpers/getDeltaTime";
import { MidWidgetProps, Individual, Job, Employer } from "../typescript/types";
import Card from "../UI/Card/CardComponent";
import Icon from "../../asssets/icons";
import Avatar from '@mui/material/Avatar';
import { capitalize, stringAvatar } from "../../helpers/helperFunctions";
import useTranslate from "../../hooks/useTranslate";
import { Box, Typography } from "@mui/material";
import { InlineContainer, JobBadge, MultiLineOverFlow, SectionTitle, TitleBadgeWrapper } from "../UI/StyledComponents/GlobalStyledComponents";

interface RecentListing {
  routeId:string;
  title:string;
  ts:number;
  parent?:string;
  employer?:string;
  state: Partial<Individual> | Partial<Job> | Partial<Employer>
}

const WidgetComponent = (props: MidWidgetProps) => {
  const { latestItems, title, widget } = props;
  const t = useTranslate();

  const accessType = widget.startsWith('Job') ? t('public') : t('privateBtn');
  const accessIcon = widget.startsWith('Job') ? Icon.GlobeIcon : Icon.LockIcon;
  const isEnglishLocale = (localStorage.getItem('language')  || 'en') === 'en' ? true : false;
  

  return(
    <Card className="dash-main-level-cards">
      <InlineContainer
        justifyContent={'space-between'}
      >
        <TitleBadgeWrapper>
          <SectionTitle 
            className='top-widget-count-wrapper'
            variant="h2" 
            textAlign={'left'}  
            textTransform={'uppercase'} 
          >
            {title}  &nbsp;&nbsp;
          </SectionTitle>
          <Typography
            className='badge'
            variant="caption"
            color='#050505'
            style={{minHeight:'20px'}}
          >
            <img 
              src={accessIcon} 
              alt={accessType} 
              style={{paddingRight: '5px'}}
            />
            {accessType}
          </Typography>
        </TitleBadgeWrapper>
        <Link 
          key={nanoid()}
          to={`/${widget}s/create`}
        >
          <img id="dash-widget-icon-2" src={Icon.AddField} alt='+' /> 
          <Typography 
            variant="body1"
            lineHeight={'1.85'}
            color="#4f5f71" 
          >
              {t('add')} {widget === 'Individual' ? t('a(f)') : t('a(m)')} {isEnglishLocale ? 
              capitalize(widget.toLowerCase()) 
              : t(widget.toLowerCase())}
          </Typography>
        </Link>
      </InlineContainer>

      <WidgetListItems 
        widget={widget}
        list={latestItems!} 
      />
    </Card>
  );
};

export default WidgetComponent;

export const WidgetListItems = (props: {list: any, widget: string, isMobileAndJobWidget?:boolean}) => {
  const t = useTranslate();
  const isEnglishLocale = (localStorage.getItem('language')  || 'en') === 'en' ? true : false;
  const { list, widget, isMobileAndJobWidget } = props;
  const [recentListings, setRecentListings] = useState<RecentListing[]>([]);

  useEffect((
    function parseWidgetData(){
      let results: RecentListing[]=[];
      switch(widget){
        case 'Individual':
          results = list!.map( (obj:Partial<Individual>) => {
            return {
              routeId: `/individuals/${obj.leadProviderOrgId}/${encodeURIComponent(obj.id!)}`, 
              title: `${obj.firstName!.trim()} ${obj.lastName!.trim()}`,
              ts: obj.createdAt!,
              state: obj
            }
          });
          break;

        case 'Employer':
          results = list!.map( (obj:Partial<Employer>) => {
            return {
              routeId: `/employers/${encodeURIComponent(obj?.parentCompName!)}/${encodeURIComponent(obj?.orgName!)}`,
              title: obj?.orgName!,
              parent: obj?.parentCompName!,
              ts: obj.createdAt!,
              state: obj
            }
          });
          break;

        case 'Job':
          results = list!.map( (obj:Partial<Job>) => {
            return {
              routeId: `/jobs/${encodeURIComponent(obj.employerOrganizationName!)}/${encodeURIComponent(obj.id!)}`,
              title: obj.title!,
              ts: obj.createdAt!,
              employer: obj.employerOrganizationName!,
              state: obj
            }
          });
          break;
          default:
            throw Error('Something went wrong when parsing your widget data, check switch cases');
            
      }
      setRecentListings(currState => currState = [...results]);
    }
  ),[widget, list]);

  return (
    <>
      {
        !!recentListings!.length && recentListings?.sort((a:RecentListing, b:RecentListing) => { 
          return a.ts < b.ts ? 1 : -1 } )
            .map( (mostRecent:RecentListing, index:number) => {
              return (
                <Link 
                  key={`${index}-${mostRecent.title}`}
                  to={ `${mostRecent.routeId}` } 
                  state={ mostRecent.state }
                >
                  <Box className='mid-widget-cont inline'>
                    {/* conditionally render avatars and widget info based off of routeId prop */}
                    { mostRecent.routeId.startsWith('/individuals/') ? 
                      <Avatar  {...stringAvatar(mostRecent.title)} /> 
                      : 
                      null
                    }

                    { mostRecent.routeId.startsWith('/employers/') 
                      ? 
                      <Avatar  {...stringAvatar(mostRecent.parent!)} /> 
                      : 
                      null 
                    }
                    
                    {
                      isMobileAndJobWidget ? 
                      <Box
                        sx={{display: 'block'}}
                      >
                        <MultiLineOverFlow 
                          className="latest-name"
                          sx={{fontWeight: 600}}
                          variant='h3'
                          color={'black'}
                          lineCount={1}
                        >
                          { mostRecent.title } 
                        </MultiLineOverFlow>

                        <MultiLineOverFlow 
                          variant='body1'
                          sx={{fontWeight: 600}}
                          color={'text.secondary'}
                          lineCount={1}
                        >
                          { mostRecent.employer! }
                        </MultiLineOverFlow>
                        
                        <Typography
                          variant='caption'
                          sx={{fontWeight: 400}}
                          color='gray'
                        >
                          {getDeltaTime(mostRecent.ts!, t, isEnglishLocale, true)}
                        </Typography>
                      </Box>
                      :
                      <Box
                        className='widget-list-item'
                        sx={{
                          display: 'block',
                          width: '100%'
                        }}
                      >
                        <InlineContainer
                          justifyContent={'center'}
                        >
                          <MultiLineOverFlow 
                            className="latest-name"
                            variant="subtitle2" 
                            lineCount={1} 
                            width = '50%'
                          >
                            { mostRecent.title } 
                          </MultiLineOverFlow>
                          {
                            mostRecent.employer! && 
                            <JobBadge 
                              id='job-badge'
                              variant="caption" 
                              lineCount={1}
                            >
                              { mostRecent.employer!}
                            </JobBadge>
                          }
                        </InlineContainer>
                        <Typography 
                          variant="body2"
                        >
                          <span id="dash-time-stamp">
                            {getDeltaTime(mostRecent.ts!, t, isEnglishLocale, true)}
                          </span>
                        </Typography>
                      </Box>
                    }
                  </Box>
                  <br/>
                </Link>
              )
            })    
      }
    </>
  )
}