import dayjs from "dayjs";
import { Individual, Provider } from "../components/typescript/types";


export const getFullName = (provider?:Partial<Provider | Individual>) =>{
  let fullName =  provider ? (provider?.firstName + ' ' + provider?.lastName).trim() : '';
  return fullName;
}

export function appendRemainingDetails(addressObject: google.maps.places.PlaceResult):string{
  return addressObject.address_components!.map( dataBlock => { 
    if(dataBlock.types.includes('locality') || dataBlock.types.includes('country') || dataBlock.types.includes('postal_code')|| dataBlock.types.includes('administrative_area_level_1')){ 
      return dataBlock.short_name 
    }
    return '';
  })
  .filter(obj=>obj).join(', ');
}

export function numberOfDays(closeDateSeconds:number):number{
  let deltaSeconds = closeDateSeconds - dayjs().unix();
  let deltaDays = Math.floor(deltaSeconds / (3600 * 24));
  return deltaDays;
}
export function numberOfDaysOpenForWork(openTS:number):number{
  let deltaSeconds = dayjs().unix() - openTS;
  let deltaDays = Math.floor(deltaSeconds / (3600 * 24));
  return deltaDays;
}

export function stringToColor(str: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str?.length; i += 1) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */


  return { bgColor: `${color}30`, fontColor: color };
}

export function stringAvatar(name?: string, overrideBgColor?: boolean, replaceWith?:string) {

  return {
    sx: {
      bgcolor: stringToColor(overrideBgColor ? replaceWith! : name!).bgColor,
      textShadow: '0px 0px 20px rgba(5,113,183,1)',
      marginRight: '0.85em',
      fontSize: '1em',
      fontFamily: 'LexendDeca',
      fontWeight: 600,
      color: stringToColor(overrideBgColor ? replaceWith! : name!).fontColor,
      "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
        fontSize: '1.15em',
        width: '2.75em',
        height: '2.75em',
      },
    },
    children: getInitialsForAvatar(name!),
  };
}

export function getInitialsForAvatar(name:string){ //used to convert multiple scenarios.
  // return `${(name === undefined ? 'ES' : 
  //   name?.split(' ').length > 1 ?
  //    (
  //      (isLetter(name?.split(' ')[1])) ? 
  //       `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}` 
  //       : 
  //       `${name?.split(' ')[0][0]}${name?.split(' ')[0][1]}`
  //     )
  //   :
  //   (name?.split(' ').length === 1 && name.length > 1) ? `${name[0]}${name[1] }` 
  //   : 
  //   name[0])?.toUpperCase()
  // }`;
  return `${name === undefined ? 'ES' :  getInitials(name!)}`;

  function getInitials(fullString:string) {

    const eachWordArr = fullString.split(' ');
    let initials: string[]=[];

    if(eachWordArr.length > 1){
      eachWordArr.every((word: string) => {
        word.split('').every((letter:string) =>{
          
          if(initials.length === 2) return false;
          
          if(letter.match(/^[a-zA-Z0-9]+$/)){
            initials.push(letter);
            return false; //acts as a break. get first letter then break from looping word and go to next word
          }else{
            return true; //acts as a 'continue'. If not a number or a letter, continue to next character.
          }
        });
        return true;
      });
    }else{ // if single word just get two consecutive letters/numbers
      initials.push(eachWordArr[0][0]);
      initials.push(eachWordArr[0][1]);
    }

    return `${initials.join('')?.toUpperCase()}`

  }
}

export function stringDetailedColor(string: string) { 
  const color = '#0F117730';

  return color;
}


export function validateURL(url:string) {
  const parsed = new URL(url)
  return ['https:'].includes(parsed.protocol)
}

export function compareLabel( a:{label:string, value:number}, b:{label:string, value:number} ) {
  if ( a.label < b.label ){
    return -1;
  }
  if ( a.label > b.label ){
    return 1;
  }
  return 0;
}

export function capitalize( str:string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function getSortedRankedKeyValuePair(arr: number[]){
  const map = arr.reduce(function(obj:any, b){
    obj[b] = ++obj[b] || 1;
    return obj
  },{});

  let results = [];
  for (const property in map) {
    results.push( {id:`${property}`, count: `${map[property]}`});
  }
  return  results.sort((a:{id:string, count: string}, b:{id:string, count: string}) => {return +a.count > +b.count ? -1 : 1 });
}

export function getGroupedTagsCount(arr:any[]){
  const map = arr.reduce(function(obj:any, b){
    let el = b.join('-');
    obj[el] = ++obj[el] || 1;
    return obj
  },{})
  let results = [];
  for (const property in map) {
    results.push( {id:`${property}`, count: `${map[property]}`});
  }
  //DEBUG
  // console.log('EL: ', el)
  // console.log('Map', map);
  // console.log('Property: ', property);
  // console.log('Final', results);
  return results;
}

export const getShortDays = (value: any) : string[] => {

  function dayAbbr(day:string){
    
    if(day === 'Monday'){
      return 'Mon';
    }
    if(day === 'Tuesday'){
      return 'Tues';
    }
    if(day === 'Wednesday'){
      return 'Wed';
    }
    if(day === 'Thursday'){
      return 'Thur';
    }
    if(day === 'Friday'){
      return 'Fri';
    }
    if(day === 'Saturday'){
      return 'Sat';
    }
    if(day === 'Sunday'){
      return 'Sun';
    }
    else{
      return day;
    }
}
  const abbrDay = value.map((day:string) => { return dayAbbr(day)} );
  console.log(abbrDay)
  return abbrDay;


}

export function calculateAvailableHours(obj:Individual){
  return `${obj?.filledHours}/${obj?.availableHours} `
 }

 export default function getDistCalc(lng1:number, lat1:number, lng2:number, lat2:number ):number{

  var R = 6371; // Radius of the earth in kilometers
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lng2 - lng1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in KM
  return +d;

  function deg2rad(deg:number) {
    return deg * (Math.PI / 180)
  }
}