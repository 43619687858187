import { Button, Typography} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material";
import useDetectResize from "../../hooks/useDetectResize";
import { Box, breakpoints } from "@mui/system";
import { RecoFilterrButton, StickyRecoFilterContainer } from "../UI/StyledComponents/RecommendationStyledComponent";

interface PropsFilterOptions {
  userType: string;
  searchState: string;
  setQueryType: (query:string)=>void;
}

// const refreshStyling = {
//   backgroundColor:'#0F1177',
//   height: '100%',
//   display: 'inline-flex',
//   alignItems: 'center',
//   borderRadius: '0px 20px 20px 0px',
//   padding: '5px',
//   boxShadow: '1px 1px 4px 2px #0000001a',
// };

// const theme = createTheme({
//   components: {
//     MuiButton: {
//       variants: [{
//         props: { variant: "text", color: "primary" },
//         style: { color: 'var(--secondary-font)' }}],
//       styleOverrides: {
//         root: {
//             borderRadius: "20px", 
//             fontFamily: "Nunito!important",
//             fontWeight: '600',
//             minWidth: "175px",
//             textTransform: 'capitalize',
//             fontSize: '1.15em',
//             "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
//               fontSize: '1.5em',
//               minWidth: "200px",
//           },
//         },
//         contained: {
//           background: 'var(--primary-color)'
//         }
//       },
//     },
//   }
// });

const RecommendationFilterOptions = (props: PropsFilterOptions) => {
  const {userType, searchState, setQueryType} = props;
  const {isMobile, isTablet} = useDetectResize();
  
  // console.log('DEBUG USERTYPE:::::', userType.trim())
  return (
    <StickyRecoFilterContainer className='sticky-recom-toggle'>
      <Box className="recomm-toggle-container inline-btwn">
        <RecoFilterrButton
          id='location-filter-tag'
          variant={searchState === 'location' ? "contained" : "text"}
          size="small"
          onClick={() => {
            setQueryType('location');
          }}
        >
          <Typography 
            variant='body1'
            id='location-filter-tag' 
            className="text-overflow reco-filter-text"
          >
            {
              (isMobile || isTablet) ?
              'Nearest' 
              :
              `Nearest ${(userType === 'individualsToJob' || userType === 'individualsToEmployer') ? 'Individuals' : userType === 'job' ? 'Jobs' : 'Employers' }`
            }
          </Typography>
        </RecoFilterrButton>
        <RecoFilterrButton
          variant={searchState === 'aligned' ? "contained" : "text"}
          size="small"
          onClick={() => {
            setQueryType('aligned');
          }}
        >
          <Typography variant='body1'        
            id='aligned-filter-tag' 
            className="text-overflow reco-filter-text"
          >
            {
              (isMobile || isTablet) ?
              'Aligned' 
              :
              `Most Aligned ${(userType === 'individualsToJob' || userType === 'individualsToEmployer') ? 'Individuals' : userType === 'job' ? 'Jobs' : 'Employers' }`
            }
          </Typography>
        </RecoFilterrButton>
        <RecoFilterrButton
          variant={searchState === 'cumulative' ? "contained" : "text"}
          size="small"
          onClick={() => {
            setQueryType('cumulative');
          }}
        >
          <Typography variant='body1'
            id='cumulative-filter-tag'
            className="text-overflow reco-filter-text"
          >
            Cumulative
          </Typography>
        </RecoFilterrButton>
      </Box>
    </StickyRecoFilterContainer>
  );
};

export default RecommendationFilterOptions;
