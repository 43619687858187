import {  useEffect, useState } from 'react';

const useAutoCompleteAPI = (eventListenerFn: ()=>void) => {

  const [autoComplete, setAutoComplete] = useState<google.maps.places.Autocomplete>();

  useEffect(()=>{
    let autoCompleteListener: google.maps.MapsEventListener;
    if(autoComplete !== undefined){
      autoCompleteListener = google.maps.event.addListener(autoComplete!,"place_changed", eventListenerFn);
    }

    return () => {
      // console.warn('removed autocomplete');
      google.maps.event.removeListener(autoCompleteListener);
    }
  }, [autoComplete, eventListenerFn])
  
  function setWidget(input: HTMLInputElement){
    const options = {
      componentRestrictions: { country: "CA" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      types: ['address'],
    };

    setAutoComplete(new google.maps.places.Autocomplete(input, options));
  }

  return {
    autoComplete,
    setWidget,
  }
};


export default useAutoCompleteAPI;