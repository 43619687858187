import { nanoid } from '@reduxjs/toolkit';
import React from 'react'
import Icon from '../../asssets/icons';
import useTranslate from '../../hooks/useTranslate';
import IndividualAttachmentDownload from '../IndividualComponents/IndividualAttachmentDownload';
import { Job } from '../typescript/types'
import { Typography } from '@mui/material';

const JobAttachments = (props: { job?: Job, showTitle?:boolean}) => {
  const {job} = props;
  const t = useTranslate();
  return (
<section>
      {props.showTitle &&(    
        <div className='iet-header inline full-width'>
          <img className='attch-icon' src={Icon.DocDownload} alt='download-icon'/>
          <Typography variant="h4" style={{color: 'var(--font-color)'}}><b>{t('attachmentsTitle').toUpperCase()}</b></Typography>
        </div>
      )}
      
      <br/> 

      <Typography variant="h4" style={{color: 'var(--font-color)'}}>{t('jobDescriptionText').toUpperCase()}</Typography>
      <IndividualAttachmentDownload fileName={job?.jobDescFile} key={nanoid()}/> 
      
      
      <Typography variant="h4" style={{color: 'var(--font-color)'}}>{t('additionalFilesTitle').toUpperCase()}</Typography>
      <div className='stack-vertically'>
        {
          !!job?.additionalFiles?.length 
          ? 
          job?.additionalFiles?.map( (file:string, index:number) =>{
            return <IndividualAttachmentDownload fileName={file} key={index+'-additional-files'}/> 
          }) 
          : 
          <IndividualAttachmentDownload/> 
        }
      </div>
    </section>
  )
}

export default JobAttachments