import axios from 'axios';
import icon from '../asssets/icons/app-icon-mjm-96x96.png';
import  store  from '../store/index';


export function AskForNotificationPermission(){
    //ask for Notification + Push capabilities
    Notification.requestPermission(function(result){
      console.log(result)
      if( result !== 'granted'){
        console.log('Denied Permission Rights');
        
      }else{
        ConfigurePushSubscription(); 
      }
    });
}

export function DisplayConfirmNotification(data:{title:string, content: string}){
  console.log('Display Confirmation ')
  if('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(function(swRegistration){

      let options: NotificationOptions = {
        body: data.content,
        icon: icon, //should be 96x96 resolution
        lang: 'en-US',
        vibrate:[100, 50, 200],
        badge: icon,
        tag: 'registered-notif', //allows for stacking, set similar tags to same name to not spam users i.e ind-matched-notif
        renotify: true, // true active notifications will vibrate each time a new one comes in false, passive. If disabled (removed) it will send new notification every time.
        actions: [
          { action: 'confirm', title: 'Okay', icon: icon },
          { action: 'cancel', title: 'Cancel', icon: icon },
        ]
      }
      console.log(swRegistration);
      swRegistration.showNotification(data.title, options).then(()=>{
        ConfigurePushSubscription();
      });
    })
  }
}


function ConfigurePushSubscription(){
  const state = store.getState();
  const token = state.auth.token;
  const userId = state.providers.myProvider.id;
  const orgId = state.providers.myProvider.orgId;
  console.log(token);
  
  if(!('serviceWorker' in navigator)) {
    return;
  }

  let reg: ServiceWorkerRegistration;
  let vapidPubKey = 'BHDLxT4KCNB5Mmn-tAyR-yDmU8iveJ9S4S4iiGUBZKuuMlH_C6NdJqEZaSZ3eiYBLRCgvhhQvE5CQJ0yfyPYRAE';
  navigator.serviceWorker.ready
  .then((swRegister: ServiceWorkerRegistration) => {
    reg = swRegister;
    return swRegister.pushManager.getSubscription();
  })
  .then((sub: PushSubscription | null)=> {
    console.log(sub);
    if (sub === null) {
      //create new subscription
      return reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidPubKey
      })
    } else {
      //subscription exists. do nothing
      return undefined
    }
  })
  .then((newSub) => {
    console.log('sub');
    console.log(newSub);
    if(newSub !== undefined){

      let data = {data: newSub, provId: userId, orgId: orgId };
      console.info('Sending', data)
      //create async axios to write to table.  / axios. 
      return  axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/notification/push-subs`, data, {
                headers: {
                  'Authorization' : `Bearer ${token}`
                }
              })
    }else{
      return null
    }
  })
  .then((response) =>{
    if(response && response?.status === 200 ){
      console.info('Keywords Payload:', response.data);
      return response.data;
    }
  })
  .catch((err)=>{
    console.log(err);
  })

}

// Hey Patrick, read comments on commit.