import React, { useEffect, useState } from 'react'
import { Individual, Job } from '../../typescript/types';
import { IndMobileAvatar, InlineContainer, MultiLineOverFlow, StatusBadge } from '../../UI/StyledComponents/GlobalStyledComponents'
import { Avatar, Box, Paper, Typography } from '@mui/material'
import { capitalize, getFullName, stringAvatar } from '../../../helpers/helperFunctions'
import useTranslate from '../../../hooks/useTranslate'
import { HrsBadge, IndStatusBadge, JobHrsBadge } from '../../SharedComponents/SharedBadges';
import { KeywordBox, KeywordTitle } from '../../UI/StyledComponents/RecommendationStyledComponent';
import { convertTagsToString } from '../../../helpers/displayOptions';
import { baseTagStyling, interestStyling, skillStyling } from '../../../helpers/styling';
import { useSelector } from 'react-redux';
import { getTags } from '../../../store/keywordSlice';
import { RootState } from '../../../store';

const JobTabletCard = (props: {job: Job}) => {
  const { job } = props;
  const t = useTranslate();
  const TAGS = useSelector((state:RootState) => getTags(state, t));
  const [threeTagCharCount, setCount] = useState<number>(0);

  useEffect(() => {
    const arr = (convertTagsToString(job!.tags, true, TAGS?.all) as string[]).map( (tag:string, index:number) => { 
      if(index < 3){
        return tag;
      }else{
        return;
      }
    });
    const charCount = arr.filter(elm => elm !== undefined).join().length
    setCount(charCount);
  },[])

  return (
    //Card wrapper
    <Box
      sx={{
        position: 'relative',
        padding: '25px'
      }}
    >
      <InlineContainer className='job-tablet-card-top'
        justifyContent={'flex-start'}
        alignItems='center'
        width='100%'
        marginBottom= '0px'
      >
          {/* logo */}
          <IndMobileAvatar 
            {...stringAvatar(`${job?.employerOrganizationName!}`)}
          />
          <Box
            sx={{
              display: 'inline-grid',
              gap: '5px',
              width: '-webkit-fill-available',
            }}
          >
              {/* full name */}
              <Box
                sx={{
                  gridColumn: '1 /span 4',
                  gridRow:'1'
                }}
                >
                <InlineContainer className='job-tablet-card-badges'
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                >
                  <Box
                    
                  >
                    <MultiLineOverFlow
                      variant='h3' 
                      fontWeight={900}
                      width='100%'
                      sx={{paddingTop: '4px'}}
                      lineCount={1}
                    >
                      {job?.title!}
                    </MultiLineOverFlow>
                    <MultiLineOverFlow 
                      variant='subtitle2' 
                      fontWeight={900}
                      width='100%'
                      color='black'
                      sx={{paddingTop: '4px'}}
                      lineCount={1}
                    >
                      {job?.employerOrganizationName!}
                    </MultiLineOverFlow>
                  </Box>
                  <InlineContainer 
                    justifyContent={'space-around'}
                    alignItems={'flex-start'}
                    width= '30% !important'
                  >
                      <IndStatusBadge isOpen={job?.isJobActive} />
                      <JobHrsBadge totalHrs={job?.weeklyHrs} />
                  </InlineContainer>
                </InlineContainer>
              </Box>

              {/* status badge */}
              {/* <Box
                sx={{
                  gridColumn: '1 /span 3',
                  gridRow:'2'
                }}
              >
              </Box> */}
              {/* location */}
              <Box
                  textAlign='center'
                  sx={{
                    gridColumn: '1/span 4',
                    gridRow:'2'
                  }}
                >
                  <Typography
                    variant='body1'
                    marginTop='6px'
                    fontWeight={600}
                    sx={{
                      whiteSpace: 'break-spaces',
                      width: '100%',
                      fontSize: '14px !important',
                      textAlign:'left'
                    }}
                  >
                    {job?.address}
                  </Typography>
              </Box>
          </Box>
      </InlineContainer>



      {/* Skills and Interest Box */}
      <Box className='job-kw-cont'>
        <KeywordBox
          margin='28px 0 0px !important'
        >
          <KeywordTitle>
            <Typography
              variant='subtitle2'
            >
              Keywords
            </Typography>
          </KeywordTitle>
          <InlineContainer
            height= '100%'
          >
            <Box
              className="keywords-array inline-btwn hide-scroll"
              sx={{
                flexFlow: 'nowrap',
                overflow: 'hidden',
                marginTop: '5px',
                marginLeft: '10px'
              }}

            >
              {
                job && (convertTagsToString(job!.tags, true, TAGS?.all) as string[]).map((tag:string, index:number) => {
                  if(index < 2 && threeTagCharCount >=65){
                    return <Paper  sx={[baseTagStyling, skillStyling]} key={index+'-skillsTag'}> {tag}</Paper> 
                  }else if(index === 2 && threeTagCharCount >= 65){
                    return <Paper  sx={[baseTagStyling, skillStyling]} key={index+'-skillsTag'}> +{job!.tags.length - 2} more</Paper> 
                  }else if(index < 3 && threeTagCharCount < 65){
                    return <Paper  sx={[baseTagStyling, skillStyling]} key={index+'-skillsTag'}>{tag}</Paper>
                  }else if(index === 3 && threeTagCharCount < 65) {
                    return <Paper  sx={[baseTagStyling, skillStyling]} key={index+'-skillsTag'}> +{job!.tags.length - 3} more</Paper> 
                  }else{
                    return null
                  }
                })
              }
        
            </Box>
          </InlineContainer>
        </KeywordBox>
      </Box>
    </Box>
  )
}


export default JobTabletCard