
import { Typography } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';
import SanitizeHTML from '../SharedComponents/SanitizeHTML';
import { Job } from '../typescript/types';

const HowToApply = (props:{job?:Job, showTitle?:boolean}) => {
  const {job, showTitle} = props;
  const t = useTranslate();
  return (
    <>
      {showTitle && <Typography variant="h4" style={{color: 'var(--font-color)'}}>{t('howToApply').toUpperCase()}</Typography>}
      <section className='listing'>
        {(job !== undefined && job?.notes.length > 12) ? <Typography variant='body1'><SanitizeHTML htmlString={job!.notes}/></Typography> : <Typography variant="body1">{t('emptyInstructions')}</Typography>}
      </section>
    </>
  )
}

export default HowToApply