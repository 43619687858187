import {Box, Button, Typography } from "@mui/material";
import { ArrowBackIos,  EditOutlined } from '@mui/icons-material';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../asssets/icons";
import { displaySchedule } from "../../helpers/displayOptions";
import { getFullName } from "../../helpers/helperFunctions";
import { RootState } from "../../store";
import { getProviderById } from "../../store/providerSlice";
import CompanyDetailsComponent from "../CompanyDetailsComponent";
import RecommendationsWidgetComponent from "../DashboardComponents/RecommendationsWidgetComponent";
import EmploymentSupportLeadCardComponent from "../EmployerComponents/EmploymentSupportLeadCardComponent";
import { Individual } from "../typescript/types";
import Card from "../UI/Card/CardComponent";
import IndividualAttachments from "./IndividualAttachments";
import IndividualEmploymentTables from "./IndividualEmploymentTables";
import IndividualNotes from "./IndividualNotes";
import IndividualSkillsInterestsRestrictions from "./IndividualSkillsInterestsRestrictions";
import { goBackStyles } from "../../helpers/styling";
import { createTheme, ThemeProvider } from "@mui/material";
import { selectIndividualById } from "../../store/individualSlice";
import useTranslate from "../../hooks/useTranslate";
import { ViewColumn1, ViewColumn2, ViewComponentHeader } from "../UI/StyledComponents/ViewStyledComponents";
import { OverviewWidget } from "../EmployerComponents/SharedWidgets/OverviewWidget";
import { IndToggleContainer, IndViewContainer, JobEmpToggleButton, ViewContainer } from "../UI/StyledComponents/GlobalStyledComponents";

export const recommendBtnStyling = {
  borderRadius: '20px',
  minWidth: '120px',
  fontSize: '0.75em',
  fontFamily: 'LexendDeca',
  fontWeight: '600',
  "@media (max-resolution: 1dppx) and (min-height: 1030px)" : { fontSize: '1em' }
}


const editIndvBtnStyling ={
  marginRight: '8px',
  textTransform: 'none',
  borderRadius: '16px',
  fontSize: '0.85em',
  fontWeight: '600',
  backgroundColor: '#0F1177',
  fontFamily: 'Nunito',
  padding: '0.5em 1em',
  '.MuiSvgIcon-root' : {
    fontSize: '2em',
  }, 
  "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
    fontSize: '1.00em',
  },
};

export const ToggleTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          background: 'var(--primary-color)'
        },
        text: {
          color: 'var(--font-color)'
        }
      },
    },
  }
});

export default function IndividualComponent() {
  const { state } = useLocation();
  //console.info('State: ', state);
  const refIndividual = state as Individual;
  //TODO get useLocation() pathname, then parse the id out of it.
  const individual = useSelector((rState:RootState) => (selectIndividualById(rState, refIndividual!.id!)));
  const leadProvider = useSelector((state: RootState) => getProviderById(state, individual?.leadProviderId));
  const navigate = useNavigate();
  //console.log('Individual component render');
  const [isJobRecommendations ,setIsJobRecommendations] = useState<boolean>(true);
  const t = useTranslate();
  
  return (
    <IndViewContainer className='view-container-ind'>
      <ViewComponentHeader>
        <CompanyDetailsComponent 
          avatarStr={getFullName(individual!)}
          indvTitle={getFullName(individual!)} 
          img={''} 
          isOpenToWork={individual?.status} 
          hrsTop={individual?.filledHours} 
          hrsBottom={individual?.availableHours} 
        />

        <Box className='inline employer-nav'>
          <Box className='go-back inline-btwn' onClick={() => navigate('/individuals')}>
          <Button 
            variant="text" 
            startIcon={<ArrowBackIos />}
            sx={goBackStyles}
          >
          {t('individualViewBackBtn')}
          </Button>
          </Box>
          <Button 
            startIcon={<EditOutlined />}
            sx={editIndvBtnStyling}
            variant='contained'
            onClick={()=> navigate("edit", {state: individual!})}
          >
           {t('individualViewEditBtn')}
          </Button>
        </Box>
      </ViewComponentHeader>
    <Card 
      className='individual-content'
    >
     <ViewColumn1>
      <EmploymentSupportLeadCardComponent 
        name={getFullName(leadProvider!)} 
        email={leadProvider?.email}
        phone={leadProvider?.phone!} 
        photo={leadProvider?.photoUrl} 
      />
      <Typography 
         variant='h3'
      >
        INDIVIDUAL OVERVIEW   
      </Typography>  
      {individual && 
        <OverviewWidget 
          overViewOf='individual'
          overViewObj={individual as Individual}  
        />}
     </ViewColumn1>

     <ViewColumn2>
        <IndividualEmploymentTables individual={individual} />
        <IndividualSkillsInterestsRestrictions individual={individual} />
        <IndividualNotes individual={individual} showTitle={true}/>
        <IndividualAttachments individual={individual} showTitle={true}/>
     </ViewColumn2>
    </Card>

    <Box className="full-width inline-c">
      <IndToggleContainer className="styled-toggle-recomm">
        <ThemeProvider theme={ToggleTheme}>
          <JobEmpToggleButton 
            variant={isJobRecommendations ? 'contained' : 'text'}
            size="small"
            sx={recommendBtnStyling}
            onClick={() => {setIsJobRecommendations(true)}}
          >
            {t('jobNav').toUpperCase()}
          </JobEmpToggleButton>

          <JobEmpToggleButton 
            variant={!isJobRecommendations ? 'contained' : 'text'}
            size="small"
            sx={recommendBtnStyling}
            onClick={() => {setIsJobRecommendations(false)}}
          >
            {t('employerNav')}
          </JobEmpToggleButton>
        </ThemeProvider>
      </IndToggleContainer>
    </Box>
    <div className='inline-btwn full-width'>
      <RecommendationsWidgetComponent 
        userState={individual as Individual} 
        userType= {isJobRecommendations ? 'job':'employer'} 
        isIndividual={true} 
        isJob={isJobRecommendations} 
      />
    </div>
    </IndViewContainer>
  )
}

// export default IndividualComponent