import dayjs from 'dayjs';
import { Individual, NoteBullet } from '../typescript/types'
import Icon from '../../asssets/icons';
import useTranslate from '../../hooks/useTranslate';
import { capitalize } from '../../helpers/helperFunctions';
import { Box, Typography } from '@mui/material';
import { PinImage } from '../UI/StyledComponents/GlobalStyledComponents';

const IndividualNotes = (props: {individual?:Individual, showTitle?:boolean}) => {
  const {individual} = props;
  const t = useTranslate();
  return (
    <section>
      { 
        props.showTitle === true && 
        <Box className='iet-header form-icon inline full-width'>
          <img src={Icon.AttachmentIcon} alt='doc-icon'/>
          <Typography variant="h4">{t('notesComments').toUpperCase()}</Typography>
        </Box>
      }
      <div className='individual-notes'> 
      { 
      (individual && (JSON.parse(individual?.notes as unknown as string).length  === 0 ))
        ?
        <div className='listing-empty'>
          <p className='lisiting-info'>{t('emptyNotesMsgIndividual')}</p>
        </div>
        :  
        individual ? JSON.parse(individual?.notes as unknown as string)
          .map( (note: NoteBullet, index:number) => {
            return (
              <div className="note-parent inline full-width" key={ index + '-notesKey' }>
              <PinImage className='pin-img' src={Icon.Notes} alt="note-bullet" />
              <div className="detailed-note">
              <p className='note-content'>{note.body}</p>
              <p className='note-meta'>{capitalize(t('addedBy'))} {note.providerName} {t('on')} {dayjs(note.ts).format('MMM DD, YYYY')}</p>
              </div>
              </div>
            )
          }): null
       }
      </div>
    </section>
  )
}

export default IndividualNotes

