import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm,  Control,  UseFormRegister} from "react-hook-form";
import { useSelector } from "react-redux";
import Icon from "../asssets/icons";
import { useAppDispatch } from "../components/typescript/hooks";
import Card from "../components/UI/Card/CardComponent";
import { formatPhoneNumber } from "../helpers/formatNumber";
import useImgUpload from "../hooks/useImgUpload";
import { getAccessToken, getSignedUrl } from "../store/authSlice";
import { getMyProviderDetails, updateProviderInfo } from "../store/providerSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import axios from "axios";
import AltSuccessComponent from "../components/UI/Popup/AltSuccessComponent";
import Modal from "../components/UI/Popup/BackdropComponent";
import checkMark from "../asssets/nike-animation.gif";
import { regXPhoneNumber } from "../helpers/constants";
import useTranslate from "../hooks/useTranslate";
import PageTitleComponent from "../components/PageTitleComponent";
import { FormRow, FormTextInput, ImgContainer, ProfImgContainer, ProfImgWrapper, ProfileSettingsBox, ReqErrorMsg } from "../components/UI/StyledComponents/FormsStyledComponents";
import useDetectResize from "../hooks/useDetectResize";
import { InlineContainer } from "../components/UI/StyledComponents/GlobalStyledComponents";
import { formInputStyles } from "../helpers/styling";
import { KeywordSubmitOverlay, SuccessOverlay } from "../components/UI/StyledComponents/NotificationModalStyledComponents";

interface BasicInfoFormData{
  photo?: FileList;
  photoUrl?:string;
  fName:string;
  lName:string;
  phoneNumber:string;
  email: string;
  orgId: string;
}

const ProfileComponent = () => {
  const dispatch = useAppDispatch();
  const myProviderDetails = useSelector(getMyProviderDetails);
  const { imgUrl, uploadImgFn, setImgUrlOnLoad } = useImgUpload();
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>();
  const [email, setEmail] = useState<string>('');
  const { isDesktop, isLaptop, windowDimensions, isMobile, isTablet } = useDetectResize();


  const defaultValues = {
    fName: myProviderDetails?.firstName || '',
    lName: myProviderDetails?.lastName || '',
    phoneNumber: myProviderDetails?.phone || '',
    email: myProviderDetails?.email || '',
    orgId: myProviderDetails?.orgId || '',
    photoUrl: myProviderDetails?.photoUrl || '',
  }
  const accessToken = useSelector(getAccessToken);
  const {register, handleSubmit, formState: { errors }, reset, control, setValue, setFocus } = useForm<BasicInfoFormData>({defaultValues});
  const t = useTranslate();

  useEffect(()=>{
    setImgUrlOnLoad(myProviderDetails?.photoUrl || '');
    reset({...defaultValues});
    setEmail((currState:string) => currState = myProviderDetails?.email);

  },[myProviderDetails]);

  function showUploadedImg(e: ChangeEvent<HTMLInputElement>) {
    uploadImgFn(e)
  } 

  const clearImg = (e: { preventDefault: () => void; }) => {
    uploadImgFn('clear-img');
    setValue('photoUrl', '');
  }

  const onSubmit: SubmitHandler<BasicInfoFormData> = async (formData) => {
    console.log(formData);
   const processImageUpload = new Promise( (resolve, reject) => {
    if(formData.photo!.length !== 0){
      
      dispatch(getSignedUrl({
        orgId: myProviderDetails.id,
        fileName: 'prov-logo',
        contentType: formData.photo![0].type,
        subFolder: 'providers',
        fileType: 'png',
        token: accessToken!
      }))
      .then(unwrapResult)
            .then(async (res) => {
              return await axios({
                method: 'PUT', 
                url: res.signedUrl, 
                data: formData.photo![0]
              })
              .then(results=>{
                if(results.status === 200){
                  resolve(formData.photoUrl = `${process.env.REACT_APP_S3_RES_BUCKET}`+ res.key); 
                }else{
                  console.warn('Error uploading image. Please try again or contact the administrator.');
                  reject(alert('Error saving image, please try again'));
                }
              });
            });
    }else{
      return resolve(true);
    }
   });

   processImageUpload.then(()=>{
    console.log("dispatching update!");
    dispatch(updateProviderInfo({data: formData, token: accessToken!}))
    .then(unwrapResult)
      .then((response) => {
        setShowUpdateSuccess(true);
        setTimeout(()=>{
          setShowUpdateSuccess(false);
        },2000);
      })
   });
  }

  return(
    <>
      <Card 
        height={(isDesktop || isLaptop) ? '80vh' : ' fit-content'}
        hideBorder={(isDesktop || isLaptop) ? false : true}
      >
        <Box className='profile-title'
          sx={{
            padding: (isDesktop || isLaptop)  ?  '10px' : '0'
          }}
        >
          { (isDesktop || isLaptop) && <Typography variant="h1">{t('basicInfo')}</Typography>}
        </Box>
        <form className='prof-form'
          onSubmit={handleSubmit(onSubmit)}
        >
          <ProfileSettingsBox className='prof-setting-box'>
            <FormRow
              display={(isMobile || isTablet) ? 'inline-grid !important' : 'inline-table !important'}
              justifyContent={(isMobile || isTablet) ? 'space-around' : 'space-between'}
              height='fit-content'
              maxHeight={(isMobile || isTablet) ? '200px' : 'unset'}
            >
              <Typography
                className='prof-img' 
                variant="h4" 
                style={{color:'var(--font-color)'}}
              >
                {t('profileImg')}
              </Typography>

              <ImgContainer
                className='img-container' 
                display= {(isMobile || isTablet) ? 'inline-grid' : 'inline-flex'}
                justifyContent = 'space-between'
                marginBottom =  { (isMobile || isTablet) ? '20px' : '35px' }
                alignItems = { (isMobile || isTablet) ? 'center' : 'flex-end' }
                marginTop = '10px'
              >
                <Box
                  sx={{
                    display:'inline-flex',
                    alignItems: 'flex-end',
                    margin: '10px 0',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  
                >
                  <ProfImgWrapper
                    className='prof-img-wrpr'
                  >
                    <Controller
                      control={control}
                      name='photo'
                      render= {({ field }) => (
                        <input  //ImgInput
                          className='profile-logo-input'
                          type='file'
                          alt='logo-upload'
                          accept='image/png, image/jpeg'
                          {...register("photo", {
                            onChange: (e) => {
                              showUploadedImg(e)
                            }
                          })}
                        />
                      )}
                    />
                    <ProfImgContainer
                      className='prof-img-container' 
                      border={!imgUrl ? '2px dashed #8f8989' : 'none' }
                    >

                      <Box>
                        {
                          (imgUrl ? 
                          <img 
                            className='loaded-img' 
                            alt='uploaded-img'
                            src={imgUrl} 
                          />
                          :
                          <img 
                            className='no-img' 
                            src={Icon.AddImage} 
                            alt='icon-placeholder'
                          />)
                        }
                      </Box>
                      
                    </ProfImgContainer>
                  </ProfImgWrapper>
                  <div className='remove-img'>
                    {imgUrl 
                      && 
                      <IconButton color="warning" onClick={clearImg}>
                      {<DeleteOutlineOutlinedIcon />}
                      </IconButton>
                    } 
                  </div>
                </Box>

                <FormRow
                  height= '80px'
                >
                  <ControllerTextField
                    control={control}
                    register={register}
                    name="email"
                    placeholder={t('userEmail')}
                    className="full-width"
                    helperText= {(isDesktop || isLaptop) && t('changeEmailMsg')}
                    disabled={true}
                  />    
                </FormRow>
              </ImgContainer>
            </FormRow>

            <Box   
              display={(isMobile || isTablet) ?  'inline-grid !important' : 'inline-flex'}
              justifyContent={(isMobile || isTablet) ? 'space-around' : 'flex-end'}
              height= {'fit-content'}
              maxHeight={(isMobile || isTablet) ? '200px' : 'unset'}
            >
              <FormRow className='field-error'
                height= '80px'
                marginRight = '10px'
                display='inline !important'
              >
                <ControllerTextField
                  control={control}
                  register={register}
                  name="fName"
                  placeholder={t('fName')}
                  className="full-width"
                  rules={{
                    required: t('firstNameReqMsg'),
                    pattern: {
                      value: /^[A-Za-z0-9_-\s]*$/i,
                      message:
                        t('validNameReqMsg'),
                    },
                  }}
                />
                {errors?.fName && <ReqErrorMsg>{errors?.fName.message}</ReqErrorMsg>}
              </FormRow>

              <FormRow 
                className='field-error'
                height= '80px'
                display='inline !important'
              >
                <ControllerTextField
                  control={control}
                  register={register}
                  name="lName"
                  placeholder={t('lName')}
                  className="full-width"
                  rules={{
                    required: "A last name must be provided",
                    pattern: {
                      value: /^[A-Za-z0-9_-\s]*$/i,
                      message:
                        t('validNameReqMsg'),
                    },
                  }}
                />            
                {errors?.lName && <ReqErrorMsg>{errors?.lName.message}</ReqErrorMsg>}
              </FormRow>
            </Box>
            
            <FormRow className='field-error'
              height= '80px'
              justifyContent={(isMobile || isTablet) ? 'center' : 'flex-end'}
              display={(isMobile || isTablet) ? 'inline-grid !important' : 'initial !important'}
              gridTemplateColumns='211px'
              marginTop='0px'
            >
   
                <ControllerTextField
                  control={control}
                  register={register}
                  name="phoneNumber"
                  helperText={t('keepNumberUpdToDate')}
                  placeholder={t('phoneNumber')}
                  className="full-width"
                  customFunction={formatPhoneNumber}
                  rules={{
                    required: t('phoneNumberReqMsg'),
                    pattern: {
                      value: regXPhoneNumber,
                      message: t('validPhoneReq')
                    }
                  }}
                />
                <ReqErrorMsg>{errors?.phoneNumber && errors?.phoneNumber.message}</ReqErrorMsg>
   
            </FormRow>

            <InlineContainer 
              justifyContent='flex-end' 
              marginTop='10px'
            >
              <Button 
                type="submit" 
                variant='contained'
              >
                {t('submitSettingsBtn')}
              </Button>
            </InlineContainer>
          </ProfileSettingsBox>
        </form>
      </Card>
      {showUpdateSuccess 
      && 
      <Modal 
        onHide={()=>[setShowUpdateSuccess(false)]} 
        className='inv-modal'
      >
        <SuccessOverlay className='prof-updtd-overlay'
          onClick={(e)=>{e.stopPropagation()}}
          top = {windowDimensions.h}
          right = {windowDimensions.w}
        >
          <AltSuccessComponent 
            message={t('proifileUpdated')} 
            animation={checkMark}
          />
        </SuccessOverlay>
        
      </Modal>}
    </>
  )
};

interface PropsControllerTextField {
  register: UseFormRegister<BasicInfoFormData>;
  customFunction?: (e: string) => void;
  control: Control<BasicInfoFormData, any>;
  name: any;
  rules?: {
    required?: boolean | string;
    pattern?: { value: RegExp; message: string };
  };
  placeholder: string;
  className: string;
  id?:string;
  label?: string;
  focused?:boolean;
  type?: string;
  helperText?:string;
  disabled?: boolean;
}

const ControllerTextField = (props: PropsControllerTextField) => {
  const {
    control,
    name,
    placeholder,
    rules,
    register,
    className,
    customFunction,
    label,
    focused,
    type,
    id,
    helperText,
    disabled
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FormTextInput
          {...register(name)}
          id={id ? id :"outlined-required"}
          helperText={helperText}
          className={className}
          sx={formInputStyles}
          type={type ? type : 'text'}
          placeholder={placeholder}
          label={label ? label : placeholder}
          value={value || ""}
          autoFocus={focused ? true : false}
          disabled={disabled || false}
          onChange={(e) => {
            if (customFunction) {
              onChange(customFunction(e.target.value));
            } else {
              onChange(e.target.value);
            }
          }}
        />
      )}
    />
  );
};


export default ProfileComponent;

export const MobileProfileHeader = () => {
  const t = useTranslate();
  return(
    <PageTitleComponent greeting={t('basicInfo')} />
  )
}
