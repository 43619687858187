import Placeholder from './icons/img-placeholder.svg';
import AddImage from './icons/image-select.svg';
import User from './icons/default-profile.svg';
import Briefcase from './icons/briefcase.svg';
import UnplacedIndividuals from './icons/unplaced-individuals.svg';
import AddField from './icons/add-field.svg';
import DashboardIcon from './icons/dashboard-icon.svg';
// import WhiteDboard from './icons/white-dashboard-icon.svg';
// import BlackDboard from './icons/black-dashboard-icon.svg';
// import WhiteEicon from './icons/white-employer-icon.svg';
// import SlateEicon from './icons/slate-employer-icon.svg';
// import WhiteIicon from './icons/white-individuals-icon.svg';
// import SlateIicon from './icons/slate-individuals-icon.svg';
// import WhiteJicon from './icons/white-jobs-icon.svg';
// import SlateJicon from './icons/slate-jobs-icon.svg';
// import SlateSettings from './icons/slate-settings-icon.svg';
// import WhiteSettings from './icons/white-settings-icon.svg';
// import SlateHelp from './icons/slate-help-icon.svg';
// import WhiteHelp from './icons/white-help-icon.svg';
// import SlateInvite from './icons/slate-invite-icon.svg';
// import WhiteInvite from './icons/white-invite-icon.svg';
import WhiteDboard from './icons/new-dashboard-nav.png';
import BlackDboard from './icons/new-dashboard-nav.png';
import WhiteEicon from './icons/new-employer-nav.png';
import SlateEicon from './icons/new-employer-nav.png';
import WhiteIicon from './icons/new-individuals-nav.png';
import SlateIicon from './icons/new-individuals-nav.png';
import WhiteJicon from './icons/new-jobs-nav.png';
import SlateJicon from './icons/new-jobs-nav.png';
import SlateSettings from './icons/new-settings-nav.png';
import WhiteSettings from './icons/new-settings-nav.png';
import SlateHelp from './icons/new-help-nav.png';
import WhiteHelp from './icons/new-help-nav.png';
import BurgerMenu from './icons/burger-menu.svg';
import LockIcon from './icons/lock.svg';
import SlateInvite from './icons/new-invite-nav.svg';
import WhiteInvite from './icons/new-invite-nav.svg';
import GlobeIcon from './icons/globe.svg';
import EditIcon from './icons/edit-icon.svg';
import DetailedEmployer from './icons/detailed-employer.svg';
import DetailedPOrg from './icons/detailed-parent-org.svg';
import DetailedInd from './icons/detailed-industry.svg';
import DetailedLoc from './icons/detailed-location.svg';
import DetailedSkill from './icons/detailed-skill.svg';
import DetailedLoc2 from './icons/detailed-loc2.svg';
import DetailedMain from './icons/detailed-main.svg';
import DetailedPhone from './icons/detailed-phone.svg';
import DetailedEmail from './icons/detailed-email.svg';
import DetailedTags from './icons/detailed-tags.svg';
import recoIcon from './icons/chart-set-theory.svg';
import HandHeart from './icons/hand-holding-heart.svg';
import NearMe from './icons/near_me_icon.svg';
import DetailedWallet from './icons/wallet-icon.svg';
import DetailedClock from './icons/clock-icon.svg';
import DetailedSchedule from './icons/schedule-icon.svg';
import DetailedDate from './icons/start-date-icon.svg';
import DetailedTraining from './icons/training-class.svg';
import DetailedCert from './icons/diploma.svg';
import DetailedTraining2 from './icons/training.svg';
import DetailedJC from './icons/job-coach.svg';
import DetailedDay from './icons/daytime.svg';
import DetailedPlus from './icons/plus.svg';
import DetailedPCheck from './icons/police-check.svg';
import DetailedCCheck from './icons/credit-check.svg';
import DetailedJob from './icons/jobs-icon.svg';
import DetailedTime from './icons/time-past.svg';
import AttachmentIcon from './icons/attachment-icon.svg';
import DocDownload from './icons/doc-download.svg';
import Notes from './icons/notes-comments.svg';
import NoNot from './icons/unactivated-notification.svg'; 
import YesNot from './icons/activated-notification.svg';
import CloseHelp from './icons/close-icon.svg';
import Bday from './icons/cake-birthday.svg';
import CRM from './icons/card-account-details-outline.svg';
import JobSearchIcon from './icons/job-search.gif';
import AlertGif from './icons/alert-animation.gif';
import SwooshGif from './nike-animation.gif';
import InsightIcon from './icons/insight-ico.svg';

//Import icons assets here, exported into application.
const Icon = {
  AddImage,
  AddField,
  BlackDboard,
  Bday,
  BurgerMenu,
  Briefcase,
  CRM,
  DashboardIcon,
  EditIcon,
  User,
  UnplacedIndividuals,
  WhiteDboard,
  WhiteEicon,
  SlateEicon,
  WhiteIicon,
  SlateIicon,
  WhiteJicon,
  SlateJicon,
  SlateSettings,
  WhiteSettings,
  SlateHelp,
  WhiteHelp,
  SlateInvite,
  WhiteInvite,
  LockIcon,
  GlobeIcon,
  Placeholder,
  DetailedEmployer,
  DetailedPOrg,
  DetailedInd,
  DetailedLoc,
  DetailedSkill,
  DetailedLoc2,
  DetailedMain,
  DetailedPhone,
  DetailedEmail,
  DetailedTags,
  DetailedWallet,
  DetailedClock,
  DetailedSchedule,
  DetailedDate,
  DetailedTraining,
  DetailedTraining2,
  DetailedCert,
  DetailedJC,
  DetailedDay,
  DetailedPlus,
  DetailedPCheck,
  DetailedCCheck,
  DetailedJob,
  DetailedTime,
  recoIcon,
  HandHeart,
  NearMe,
  AttachmentIcon,
  DocDownload,
  Notes,
  NoNot,
  YesNot,
  CloseHelp,
  JobSearchIcon,
  AlertGif,
  SwooshGif,
  InsightIcon
};


export default Icon;