import { Avatar, Tooltip, TooltipProps, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { stringAvatar } from '../../helpers/helperFunctions';
import useTranslate from '../../hooks/useTranslate';
import { RootState } from '../../store';
import { getOrgDetails } from '../../store/providerSlice';
import styled from '@mui/system/styled';
import { tooltipClasses } from '@mui/material/Tooltip';
import useDetectResize from '../../hooks/useDetectResize';
import { ESPBox, EmpSupportCard, InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--secondary-color)',
    color: 'white'
  },
}));

interface ProviderCardInfoProps {
  name?:string; 
  phone?:string; 
  email?:string; 
  photo?:string; 
  orgId?:string;
  displayBorder?:boolean;
  centered?: boolean;
  hideAvatar?: boolean;
  isRecommendations?:boolean;
}

const EmploymentSupportLeadCardComponent = (props: ProviderCardInfoProps) => {
  const {name, phone, email, photo, orgId, displayBorder, centered, hideAvatar, isRecommendations} = props;
  const organization = useSelector((state:RootState) => getOrgDetails(state, orgId!));
  const { isMobile, isTablet, isLaptop, isDesktop } = useDetectResize();

  const t = useTranslate();
  return (
    <EmpSupportCard
      className='esp-widget'
      hasBorder = {displayBorder || false}
      centerText = {centered || false}
    >
      <Typography 
        variant={isRecommendations ? 'h3' : 'h5'} 
        textAlign={(isMobile || isTablet) ? 'center' : 'left'}
        fontWeight={600}
        color='black'
        sx={{ margin: '5px 0'}}
      >
        {t('providerCardTitle')}
      </Typography>
      <InlineContainer
       justifyContent={centered ? 'center' : 'flex-start'}
      >
        { 
          (isDesktop || isLaptop) 
          &&
          photo?.startsWith('https') 
          ? 
          <img 
            src={photo} 
            alt='ofProvider' 
            style={{width: '40px', height: 'auto', borderRadius: '25px'}}
          /> 
          : 
          (hideAvatar) 
          ? 
          ''
          :
          <Avatar {...stringAvatar(name!)} />
        }
        <ESPBox className='provider-details'>
          <CustomToolTip
            title={organization?.orgDetails} 
            placement="right-start"
          >
          <Typography variant='caption' fontWeight={'normal'}>{organization?.orgName}</Typography>
          </CustomToolTip>
          <Typography variant='body2' display={'block'} className='provider-name'>{name ? name : 'Loading...'}</Typography>
          <Typography variant='body1' display={'block'} fontWeight={'normal'}>{email}</Typography>
          <Typography variant='body1' display={'block'} fontWeight={'normal'} className='provider-num'>{phone}</Typography>
        </ESPBox>
      </InlineContainer>
    </EmpSupportCard>
  )
}

export default EmploymentSupportLeadCardComponent