import { Box } from '@mui/system'
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowParams, GridToolbarQuickFilter, MuiEvent } from '@mui/x-data-grid'
import React, { useState } from 'react'
import ReusableTableComponent from '../../ReportingComponents/Reports/ReportsTable'
import { getAllJobs } from '../../../store/jobSlice'
import useTranslate from '../../../hooks/useTranslate'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getProvidersInOrganization } from '../../../store/providerSlice'
import { getTags } from '../../../store/keywordSlice'
import useGridLocale from '../../../hooks/useGridLocale'
import useDetectResize from '../../../hooks/useDetectResize'
import { RootState } from '../../../store'
import { Job } from '../../typescript/types'
import CustomMobileSearchBarComponent from '../../SharedComponents/CustomMobileSearchBarComponent'
import { ViewTableContainer } from '../../UI/StyledComponents/GlobalStyledComponents'

import JobTabletCard from '../../IndividualComponents/MobileTablet/JobTabletCard'
import MobileTableCard from './MobileTableCard'
import useMobileTableWidths from '../../../hooks/useMobileTableWidths'

const MobileJobsTable = () => {
  const jobList = useSelector(getAllJobs)
  const [tableData, setTableData] = useState<Job[]>(jobList);
  const [pageSize, setPageSize] = React.useState(10);
  const t = useTranslate();
  let navigate = useNavigate();
  const TAGS = useSelector((state:RootState) => getTags(state, t));
  const allProviders = useSelector(getProvidersInOrganization);
  const gridLocaleSettings = useGridLocale();
  const {windowDimensions, isMobile} = useDetectResize();
  const {tableWidth} = useMobileTableWidths({mobilePercent: 0.8, tabletPercent: 0.75, mediaWidth: windowDimensions.w, isMobile: isMobile});


    //For Table view
    const columns: GridColDef[] = [
  
      {
        field: 'title',
        headerName: t('title'),
        type: 'string',
        width: tableWidth,
        
        renderCell: (params: GridRenderCellParams) => (
          isMobile 
          ?
          <MobileTableCard obj={params.row as Job} isJob={true} />
          :
          <JobTabletCard job={params.row as Job} />
        )
      }
    ];

  function displayData(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails){
    navigate(`${encodeURIComponent(params.row.employerOrganizationName)}/${params.row.id}`, {state: params.row});
  }

  return (
    <ViewTableContainer
      height={windowDimensions.h}
    >
      {ReusableTableComponent(
        tableData, 
        columns, 
        pageSize, 
        setPageSize, 
        displayData, 
        gridLocaleSettings, 
        t, 
        {
          '.MuiDataGrid-row' : {
            minHeight: '185px !important',
          },
          '.MuiDataGrid-cell': {
              minHeight: '185px!important',
              padding: '0px !important'
          },
          '.MuiDataGrid-virtualScrollerContent' : {
            paddingTop: '10px !important',
          }
        },
        true,
        CustomMobileSearchBarComponent,
        true,
     )}
    </ViewTableContainer>
  )
}

export default MobileJobsTable