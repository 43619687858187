import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, GridRenderCellParams, GridValueGetterParams, MuiEvent, GridToolbar } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllIndividuals } from '../../store/individualSlice';
import Avatar from '@mui/material/Avatar';
import { convertTagsToString, displaySchedule } from '../../helpers/displayOptions';
import dayjs from 'dayjs';
import { stringAvatar } from '../../helpers/helperFunctions';
import { getTags } from '../../store/keywordSlice';
import {useState, useEffect} from 'react';
import { Individual } from '../typescript/types';
import useTranslate from '../../hooks/useTranslate';
import useGridLocale from '../../hooks/useGridLocale';
import { RootState } from '../../store';


 function IndividualsTable(){
  
  const individualList = useSelector(getAllIndividuals);
  const [tableData, setTableData] = useState<Individual[]>(individualList);
  const [pageSize, setPageSize] = useState(10);
  let navigate = useNavigate();
  const t = useTranslate();
  const TAGS = useSelector((state:RootState) => getTags(state, t));
  useEffect(()=>{
    setTableData(currState => currState = [...individualList]);
  }, [individualList]);

  // REAL DATA //
  const columns: GridColDef[] = [
    { field: 'fullName',
      headerName: t('fullNameIndividual'),
      width: 250,
      cellClassName: 'table-first-column',
      headerClassName: 'table-first-column',
      valueGetter: ( params: GridValueGetterParams) => `${params.row.firstName}  ${params.row.lastName} ${!!params.row.pronouns.length ? `(${params.row.pronouns})` : ''}`,
      renderCell: (params: GridRenderCellParams) => (
        <div className='employer-img'>
          <Avatar {...stringAvatar(`${params.row.firstName} ${params.row.lastName}`)} />
          &nbsp;&nbsp;
          <p id='employer-img-text'>
            {params.value}
          </p>
        </div>
      )
    },
    {
      field: 'leadProviderName',
      headerName: t('leadProviderLabel'),
      type: 'string',
      width: 250,
    },
    {
      field: 'phone',
      headerName: t('phoneNumber'),
      type: 'string',
      width: 160,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      width: 160,
    },
    {
      field: 'address',
      headerName: t('address'),
      type: 'string',
      width: 160,
    },
    {
      field: 'dob',
      headerName: t('dob'),
      type: 'string',
      width: 150,
      valueGetter: ( params: GridValueGetterParams) =>`${dayjs(params.row.dob).format('MMM DD, YYYY') === 'Invalid Date' ? t('na') : dayjs(params.row.dob).format('MMM DD, YYYY')}`
    },
    {
      field: 'policeCheck',
      headerName: t('popoCheck'),
      type: 'string',
      width: 200,
      valueGetter: ( params: GridValueGetterParams) =>`${dayjs(params.row.policeCheck).format('MMM DD, YYYY') === 'Invalid Date' ? t('na') : dayjs(params.row.policeCheck).format('MMM DD, YYYY')}`
    },
    {
      field: 'creditCheck',
      headerName: t('creditCheck'),
      type: 'string',
      width: 200,
      valueGetter: ( params: GridValueGetterParams) =>`${dayjs(params.row.creditCheck).format('MMM DD, YYYY') === 'Invalid Date' ? t('na') : dayjs(params.row.creditCheck).format('MMM DD, YYYY')}`
    },
    {
      field: 'preferredRoles',
      headerName: t('prefRoles'),
      type: 'string',
      width: 160,
      valueGetter: ( params: GridValueGetterParams) => 
      `${!params.row.preferredRoles.length ? 'Not specified' : params.row.preferredRoles}`,
    },
    {
      field: 'minRate',
      headerName: t('minRate'),
      type: 'string',
      width: 200,
      valueGetter: ( params: GridValueGetterParams) =>  `$${params.row.minRate}`
    },
    {
      field: 'skills',
      headerName: t('skills'),
      type: 'string',
      width: 150,
      valueGetter: ( params: GridValueGetterParams) => 
      `${convertTagsToString(params.row.skills, true, TAGS?.all)}`,
    },
    {
      field: 'interests',
      headerName: t('interests'),
      type: 'string',
      width: 150,
      valueGetter: ( params: GridValueGetterParams) => 
      `${convertTagsToString(params.row.interests, true, TAGS?.all)}`,
    },
    {
      field: 'restrictionsText',
      headerName: t('restrictions'),
      type: 'string',
      width: 150,
      valueGetter: ( params: GridValueGetterParams) => 
      `${!params.row.restrictionsText.length ? t('none') : params.row.restrictionsText}`,
    },
    {
      field: 'status',
      headerName: t('status'),
      type: 'string',
      cellClassName: 'individual-status-column',
      width: 160,
      renderCell: ( params: GridRenderCellParams) => (
        <span className={`${params.row.status ? ' status-open' : ' status-closed'}`} >
          {`${params.row.status  ? t('indStatusOpen') : t('indStatusNotOpen')}`}
        </span>
      )
    },
    {
      field: 'daysAvailable',
      headerName: t('daysAvail'),
      type: 'string',
      width: 160,
      valueGetter: ( params: GridValueGetterParams) => 
      `${displaySchedule(params.row.daysAvailable, t)}`,
    },
    {
      field: 'filledHours',
      headerName: t('currentWorkingHrs'),
      type: 'string',
      width: 200,
    },
    {
      field: 'availableHours',
      headerName: t('totalAvailHrs'),
      type: 'string',
      width: 200,
    },
    {
      field: 'intakeDate',
      headerName: t('dateIntake'),
      type: 'string',
      width: 160,
      valueGetter: ( params: GridValueGetterParams) =>`${dayjs(params.row.intakeDate).format('MMM DD, YYYY') === 'Invalid Date' ? t('na') : dayjs(params.row.intakeDate).format('MMM DD, YYYY')}`
    },
  ];

  const gridLocaleSettings = useGridLocale();

  function displayData(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails){
    //console.log(params.row); console.log(event); console.log(details);
    navigate(`${params.row.leadProviderOrgId}/${params.row.id}`, {state: params.row});
  }
  return (
      <DataGrid
        getRowId={(row) => row.id} 
        rows={tableData}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[5,10,15,20]}
        checkboxSelection
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        onRowDoubleClick={displayData}
        pagination
        components={{ Toolbar: GridToolbar }}
        localeText={gridLocaleSettings}
      
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          pagination: {
            labelRowsPerPage: t('rowPerPage')
          }
        }}
        {...tableData}
        sx={{
          minHeight: '60px',
          border: 'none',

          "*" : {
            scrollbarWidth: 'thin',
            scrollbarColor: 'var(--secondary-color) #d3d9de',
          },
          "*::-webkit-scrollbar": {
            width: 6,
            height: 6,
          },
          "*::-webkit-scrollbar-track": {
            backgroundColor: "#d3d9de",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: "var(--secondary-color)",
            borderRadius: 2.5,
          },

          '.MuiDataGrid-columnHeaderTitleContainerContent > span' : {
            padding: '0px',
          },
          '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiInputBase-input' : {
            fontFamily: 'Nunito',
            fontSize: '0.9em',
          },
          '.MuiTablePagination-actions' : {
            fontSize: '0.9em',
          },
          '.MuiDataGrid-footerContainer' : {
            marginBottom: '2em',
          },
          '.MuiDataGrid-root' : {
            minHeight: '82vh',
          },
          '.MuiDataGrid-main' : {
            overflow: 'unset',
          },
          '.MuiDataGrid-virtualScroller' : {
            marginTop: '60px!important',
          },
          '.MuiDataGrid-cellCheckbox' : {
            borderRadius: '15px 0px 0px 15px',
            backgroundColor: 'var( --background)',
            minHeight: '90px!important',
            display: 'none',
          },
          '.MuiDataGrid-columnHeader' : {
            '&:first-of-type' : {
              backgroundColor: 'var( --background)',
              borderRadius: '15px 0px 0px 15px',
              minHeight: '40px',
              position: 'sticky',
              left: '0',
              '.MuiDataGrid-columnSeparator--sideRight' : {
                display: 'none',
              },
            },
          },
          '.MuiDataGrid-columnHeaderCheckbox' : {
            display: 'none',
          },
          '.MuiDataGrid-columnHeaders' : {
            minHeight: '65px !important',
            maxHeight: '65px !important',
            lineHeight: '40px !important',
            borderBottom: 'none',
            fontFamily: 'LexendDeca',
            color: '#4f5f71',

            "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
              fontSize: '1.00em',
            }
          },
          '.MuiDataGrid-columnHeaderTitle' : {
            fontWeight: 600,
          },
          '.MuiDataGrid-columnHeadersInner':{
            margin: '20px 0px',
            border: '1px solid #00000024',
            borderRadius: '15px',
          },
          
          '.MuiDataGrid-row' : {
            borderRadius: '15px',
            border: '1px solid #00000024',
            margin: '2px 0px',
            minHeight: '100px!important',
            alignItems: 'center',
            fontFamily: 'Nunito',
            fontWeight: 500,
            color: '#050505',
            "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
              fontSize: '1.00em',
            },
            "@media (min-resolution: 1.25dppx) and (min-width: 1030px)" : {
              minHeight: '75px !important'
            }
          },
          '.MuiDataGrid-cell' : {
            border: 'none',
            minHeight: '98px !important',
            "@media (min-resolution: 1.25dppx) and (min-width: 1030px)" : {
              minHeight: '73px !important'
            }
          },
          '.MuiDataGrid-toolbarContainer' : {
              '.MuiButton-root' : {
                fontFamily: 'LexendDeca',
                fontWeight: '600',
              }
          }
        }}
      />
  );
}

export  default IndividualsTable;

