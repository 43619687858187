import { styled } from "@mui/material/styles";
import { Box, Button, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { SystemProps, Theme } from "@mui/system";
import { ModalOverlayPositioning } from "./NotificationModalStyledComponents";
import { StyledProps } from "./GlobalStyledComponents";

export const RecoFilterrButton = styled(Button)(({ theme, variant }) => ({ 
  borderRadius: "20px", 
  fontFamily: "Nunito! important",
  fontWeight: '600',
  textTransform: 'capitalize',
  fontSize: '1.15em',

  '& > p' : {
    color: `${theme.palette.text.secondary} !important`,
  },

  ...(variant === 'contained' && {
    backgroundColor: `${theme.palette.primary} !important`,
    '& > p' : {
      color: 'white'
    }
  }),
  
  [theme.breakpoints.up('md')] : {
    minWidth: "125px",
    '@media (min-width: 1537px)':{
      minWidth: "175px",
    },
    '@media (max-width: 1100px)':{
      minWidth: "100px",
    },
    "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
      fontSize: '1.5em',
      minWidth: "200px",
    },
    
  },
  [theme.breakpoints.down('md')] : {
    minWidth: "95px",
  },
  

}));

export const StickyRecoFilterContainer = styled(Box)(({ theme }) => ({ 
  backgroundColor: 'white',
  height: '60px',
  position: 'sticky',
  top: '0px',
  zIndex: 3,
  borderRadius: '20px',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '10px',
  display: 'inline-flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')] : {
    '& > div':{
      width: '75%'
    }
  },
  [theme.breakpoints.up('md')] : {
    width: '100%',
    borderRadius: 0,
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '0px',
    top: '-6px'
  }
}));

export const RecoBackBtnWrapper = styled(Box)(({theme}) => ({
  display: 'inline-flex',
  alignItems: 'center',
  minWidth: 'min-content',
  cursor: 'pointer',
  [theme.breakpoints.down('md')] : {
    background: 'white',
    borderRadius: '10px',
    minHeight: '38px',
    border: `1px solid ${theme.palette.primary.dark}`,
  }
})); 

export const RecoCardItem = styled(Box)(({theme}) => ({
  display: 'inline-flex',
  width: '95%',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '90px',
  borderRadius: '10px',
  margin: '2px 10px',
  padding: '0.5em',
  color: `${theme.palette.text.primary}`,
  border: `1px solid ${theme.palette.primary.light}`,
  '&:hover' : {
    cursor: 'pointer',
    background: `${theme.palette.primary.light}`,
  },
  '&:active' : {
    background: `${theme.palette.primary.light}`,
  } 
}));

export const Badge = styled(Box)(({theme}) => ({
  padding: '0.3em 1em',
  fontWeight: '600',
  color: 'white',
  fontSize: '0.75em',
  textAlign: 'center',
  maxWidth: '70px',
  minWidth: '85px',
  width: 'fit-content',
  borderRadius: '8px',
  backgroundColor: 'white',
}));

export const DistBadge = styled(Badge)(({theme})=>({
  backgroundColor: `${theme.palette.primary.main}`,
}));

export const HoursBadge = styled(Badge)(({theme})=>({
  marginTop: '5px',
  backgroundColor: `${theme.palette.secondary.main}`,
}));

export const SkillsBadge = styled(Badge)(({theme}) => ({
  padding: '2px 3px',
  minWidth: '60px',
  borderRadius: '5px',
  color: 'white',
  backgroundColor: `${theme.palette.secondary.main}`,
  border: `1px solid ${theme.palette.secondary.main}`,
  margin: '0 2.5px',
  height: 'inherit',
  [theme.breakpoints.down('sm')] : {
    margin: 'unset',
    marginRight: '3px',
  },
}));

export const InterestBadge = styled(SkillsBadge)(({theme}) => ({
  color: `${theme.palette.text.secondary}`,
  border: `1px solid ${theme.palette.secondary.main}`,
  backgroundColor: 'white',

}));


export const HealthBarOuter = styled(Box)(({theme}) =>({
  width: '80%',
  height: '8px',
  borderRadius: '4px',
  minWidth: '125px',
  [theme.breakpoints.up('md')] : {
    minWidth: '200px',
    maxWidth: '250px',
    marginLeft: 'auto',
    marginRight: 'auto', 
  }
}));

export const HealthBarInner = styled(Box)(({theme}) =>({

}));

export const FullScreenOverlay = styled(ModalOverlayPositioning)(({theme, top, right}) => ({
  backgroundColor: 'white',

  overflow: 'hidden',
  borderRadius: '25px',
  border: `2px solid ${theme.palette.common.white}`,

  [theme.breakpoints.down('sm')] : {
    top: `calc(${top}px * 0.02)`,
    right: `calc(${right}px * 0.02)`,
    height: '96vh',
    width: '96vw',
  },
  [theme.breakpoints.up('sm')] : {
    top: `calc(${top}px * 0.1)`,
    right: `calc(${right}px * 0.05)`,
    height: '83vh',
    width: '90vw',
    padding: '10px'
  },
  
  [theme.breakpoints.up('md')] : {
    top: `calc(${top}px * 0.105)`,
    right: `calc(${right}px * 0.06)`,
  },

  [theme.breakpoints.up('lg')] : {
    ['@media(min-width: 2000px)']:{
      top: `calc(${top}px * 1.25)`,
      right: `calc(${right}px * 2.4)`,
    },
    ['@media(min-width: 2500px)']:{
      top: `calc(${top}px * 1.5)`,
      right: `calc(${right}px * 2.8)`,
    }
  },

}));

export const IconStyling = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',

  '& > img' : {
    backgroundColor: 'var(--background)',
    border: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
    borderRadius: '8px',
    marginRight: '1em',
  },
  
  [theme.breakpoints.down('md')] : {
    width: '35px',
  
    '& > img' : {
      width: '35px',
      height: '35px',
      padding: '0.45em',
    },
  },
  
  [theme.breakpoints.up('md')] : {
  
    width: '45px',
    height: '45px',
    '& > img' : {
      padding: '0.5em',
      width: '45px',
    }
  },

}));

export const DetailsPopupContainer = styled(Box)(({theme, height}) => ({
  height: `calc(${height}px * 0.58)`,
  overflow: 'scroll',
  "@media (min-height: 700px)": {
    height:  `calc(${height}px * 0.62)`,
  },
  "@media (min-height: 730px)": {
    height:  `calc(${height}px * 0.69)`,
  },
  "@media (min-height: 800px)": {
    height:  `calc(${height}px * 0.67)`,
  },
  "@media (min-height: 900px)": {
    height:  `calc(${height}px * 0.71)`,
  }
}));

export const ESPCardContainer = styled(Box)(({theme, height}) => ({
  height: `calc(${height}px * 0.14)`,
  position: 'absolute',
  bottom: '0',
  backgroundColor: 'white',
  width: '100%',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  borderTop: '2px solid #bdbdbd',
  [theme.breakpoints.down('sm')] : {
    "@media (min-height: 650px)": {
      height:  `calc(${height}px * 0.14)`,
    },
    "@media (min-height: 700px)": {
      height:  `calc(${height}px * 0.13)`,
    },
    "@media (min-height: 730px)": {
      height:  `calc(${height}px * 0.12)`,
    },
  },
  [theme.breakpoints.up('sm')] : {
    height: `calc(${height}px * 0.12)`
  }

}));

export const RecoTags =  styled(Box)<StyledProps>(({theme, background}) => ({
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
 
  [theme.breakpoints.down('md')] : {
    padding: '0.3em',
  },
  [theme.breakpoints.up('md')] : {
    padding: '0.5em 0.4em',
  },
}));

export const RecommendationSummaryWrapper = styled(Box)<StyledProps>(({theme, background}) => ({
  minHeight: '85px',
  padding: '10px 5px',
  borderBottom: '2px solid #bdbdbd',
  background: `${background}`,
  zIndex: 1,
  [theme.breakpoints.down('md')] : {
    minHeight: '70px',
  },
  [theme.breakpoints.up('md')] : {
    minHeight: '100px',
  },
}));

export const RecommendationTitle = styled(Box)(({theme}) => ({
  overflow: 'hidden',
  marginLeft: '5px',

  [theme.breakpoints.down('md')] :  {
    width: '95%'
  },
  [theme.breakpoints.up('md')] :  {
    width: '65%',
  },
}));

export const SkillsContainer = styled(Box)(({theme}) => ({
  display: 'grid',

  overflow: 'hidden',
  margin: '0px 3px',
  '&:hover':{
    height: 'max-content',
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')] : {
    height: 'fit-content',
    padding: '2px'
  },
  [theme.breakpoints.up('md')] : {
    height: '30px',
  },

}));

export const AltBadge = styled('span')<StyledProps>(({theme, backgroundColor, color}) => ({
  height: '30px',
  borderRadius: '5px',
  backgroundColor: `${backgroundColor}`,
  color: `${color || 'white'}`,
  fontSize: '0.75em',
  margin: '0 0.2rem',
  minWidth: '110px',
  textAlign: 'center',
  overflow: 'hidden',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
  [theme.breakpoints.down('md')] : {
  },
}));

export const KeywordsBadge = styled('span',{
  shouldForwardProp: (prop) => prop !== "isInterest"
})<StyledProps>(({theme, isInterest}) => ({
  
  // padding: '0.5em 1em',
  borderRadius: '5px',
  backgroundColor: `${isInterest ? 'white' : theme.palette.secondary.main}`,
  color: `${isInterest ? theme.palette.text.secondary : 'white'}`,
  border: `${isInterest ? `1px solid ` + theme.palette.text.secondary : 'none'}`,
  fontSize: '0.75em',
  //margin: '0 0.2rem',
  minWidth: 'fit-content',
  textAlign: 'center',
  overflow: 'hidden',
  transition: 'height 1s ease-in-out',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')] : {
    width: '85px',
    height: '30px',
  },
  [theme.breakpoints.up('md')] : {
    width: '110px',
    height: '30px',

    '&:hover' : {
      minHeight: '30px',
      height: 'max-content',
    },
  
  },
}));

export const KeywordTitle = styled(Box)<StyledProps>(({theme}) => ({
  position: 'absolute',
  background: `white`,
  top: '-10px',
  left: '10px',
  borderRadius: '5px',
  padding: '0px 5px',

}))

export const KeywordBox = styled(Box)<StyledProps>(({theme}) => ({
  position: 'relative',
  width: '100%',
  height: '40px',
  margin: '20px 0',
  borderRadius: '10px',

}))