import { ViewAccordion } from './ReusableAccordion';
import { useLocation, useNavigate } from 'react-router-dom';
import useTranslate from '../../../hooks/useTranslate';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getEmployerById, selectEmployerByName } from '../../../store/employerSlice';
import { getProviderById } from '../../../store/providerSlice';
import { getEmployerSpecificJobs } from '../../../store/jobSlice';
import { getTags } from '../../../store/keywordSlice';
import { EmployerAssociatedJobsWidget, EmployerNotesWidget, OverviewWidget, RecordDescriptionWidget } from '../SharedWidgets/OverviewWidget';
import { ArrowBackIos, EditOutlined } from '@mui/icons-material';
import EmploymentSupportLeadCardComponent from '../EmploymentSupportLeadCardComponent';
import {detailedAccordionStyling, marginBottomAccordion } from '../../../helpers/styling';
import { getFullName } from '../../../helpers/helperFunctions';
import { Employer } from '../../typescript/types';
import { Button, IconButton, Typography } from '@mui/material';
import { MobileContainerBody, MobileContainerHeader, SectionHeaderWrapper } from '../../UI/StyledComponents/GlobalStyledComponents';
import RecommendationsWidgetComponent from '../../DashboardComponents/RecommendationsWidgetComponent';
import { Box, style } from '@mui/system';
import { useEffect, useState } from 'react';
import ViewComponentSharedButtons from '../SharedWidgets/ViewComponentSharedButtons';
import useDetectResize from '../../../hooks/useDetectResize';



  export default function MobileEmployerComponent(props: {isNavCollapsed: boolean}) {
    const {state, pathname} = useLocation();

    const refEmployer = state as unknown as Employer;
    const employer = useSelector((rState:RootState) => (selectEmployerByName(rState, {orgName: decodeURIComponent(pathname.split('/')[3]), parentCompName: decodeURIComponent(pathname.split('/')[2])})));
    const leadProvider = useSelector((state:RootState) => (getProviderById(state, employer?.leadProviderId)));
    const employerSpecificJobs = useSelector((state:RootState) => (getEmployerSpecificJobs(state, employer?.id!)));
    const t = useTranslate();
    const TAGS = useSelector((state:RootState) => getTags(state, t));
    const {isMobile} = useDetectResize();
    const [expanded, setExpanded] = useState<string>('');
    
    const accordionSummaryContentSx = {
      '& > div > div.MuiAccordionSummary-content' : {
        margin: !isMobile ? '20px 0 !important' : '20px 0 !important'
      }
    }
    const topAccordionStyles = [ detailedAccordionStyling, accordionSummaryContentSx  ];

    useEffect(() => {
      if(!props.isNavCollapsed){
        setExpanded(currState => currState = '');
      }
      
    },[props.isNavCollapsed]);


    return (
      <>

        <ViewComponentSharedButtons 
          goBackUrl="/employers"
          refObj={employer!}
          leadProvider={leadProvider!}
        />
        <MobileContainerBody>
          <ViewAccordion 
            title={t('overview')}
            sx={topAccordionStyles}
            ac_id='panel1'
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <RecordDescriptionWidget 
              recordDetails={employer?.details!} 
            />
            <hr style={{margin: '20px -15px 20px', border: '1.5px solid #d9e1e7'}}/>
            <Typography 
              variant='h3'
            >
              EMPLOYER OVERVIEW   
            </Typography> 
            {
            employer! &&
            <OverviewWidget 
              overViewOf='employer'
              overViewObj={employer! as Employer}
            />
            } 
          </ViewAccordion>

          <ViewAccordion
            title={t('jobNav')}
            sx={topAccordionStyles}
            ac_id='panel2'
            expanded={expanded}
            setExpanded={setExpanded}
          >
            <EmployerAssociatedJobsWidget 
              employerSpecificJobs={employerSpecificJobs} 
            />
          </ViewAccordion>
          
          <ViewAccordion
            title={t('notesComments')}
            sx={topAccordionStyles}
            ac_id='panel3'
            expanded={expanded}
            setExpanded={setExpanded}
          >
          <EmployerNotesWidget employer={employer!}/>
          </ViewAccordion>
        </MobileContainerBody>

        <RecommendationsWidgetComponent 
          userState={employer as Employer} 
          userType={'individualsToEmployer'} 
          isIndividual={false}
        />           
      </>
    )
  }


  export const EmployerViewHeader = (props: {isRecommendations?: boolean, setBaseObject?: React.Dispatch<any>}) => {
    const {state, pathname} = useLocation();
    const refEmployer = state as unknown as Employer;
    const employer = useSelector((rState:RootState) => (selectEmployerByName(rState, {orgName: decodeURIComponent(pathname.split('/')[3]), parentCompName: decodeURIComponent(pathname.split('/')[2])})));
    const t = useTranslate();

    useEffect(()=>{
      console.warn('Emp Object');
      console.log(decodeURIComponent(pathname.split('/')[2]));
      console.log(employer);
      if(props.setBaseObject){
        props.setBaseObject(employer);
      }
    },[ employer]);

    return (
    <SectionHeaderWrapper

      sx={{
        textAlign: `${props.isRecommendations ? 'right' :'center' }`,
        display:`${props.isRecommendations ? 'inline-grid': 'block'}`,
        justifyItems:`${props.isRecommendations ? 'flex-end': 'unset'}`,
        marginBottom: `${props.isRecommendations ? '10px' : '25px'}`,
        paddingLeft:  `${props.isRecommendations ? '20px' : '0px'}`,
      }}
    >
        <Typography variant='h1' className='comp-detail-mn-title' sx={{width: 1}}>{employer?.orgName!}</Typography>
        <Box 
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: `${props.isRecommendations ? 'flex-end' : 'center'}`,
            marginTop: '5px'
          }}

        >
          <Typography
            className={`status-badge ${employer?.status! ? 'status-open' : 'status-closed'}`}
            variant='caption' 
          >
            {employer?.status ?  t('active') : t('inactive')}
          </Typography>
        </Box>
    </SectionHeaderWrapper>
    )
  }

