import { Typography } from '@mui/material'
import React from 'react'
import useDetectResize from '../hooks/useDetectResize'
import { Route, Routes } from 'react-router-dom';
import NavigationPlaceholderComponent from './NavigationComponent/NavigationPlaceholderComponent';
import { EmployerHeaderContent } from '../pages/EmployersComponent';
import { MobileDashboardGreeting } from '../pages/DashboardComponent';
import { SectionHeaderWrapper } from './UI/StyledComponents/GlobalStyledComponents';
import { EmployerCreateHeader, EmployerEditHeader } from './EmployerComponents/EmployersCreateComponent';
import { EmployerViewHeader } from './EmployerComponents/MobileTablet/MobileEmployerComponent';
import { MobileRecommendationsHeaderComponent } from './RecommendationComponents/MobileTablet/MobileRecommendationsComponent';
import { MobileProfileHeader } from '../pages/ProfileComponent';
import { MobileInviteHeader } from '../pages/InvitesComponent';
import { JobViewHeader } from './JobComponents/MobileTablet/MobileJobViewComponent';
import { JobCreateHeader, JobEditHeader } from './JobComponents/JobCreateComponent';
import { JobsHeaderContent } from '../pages/JobsComponent';
import { IndividualsHeaderContent } from '../pages/IndividualsComponent';
import { IndividualCreateHeader, IndividualEditHeader } from './IndividualComponents/IndividualCreateComponent';
import { IndividualViewHeader } from './IndividualComponents/MobileTablet/MobileIndividualViewComponent';



const PageTitleComponent = (props:{greeting:string, }) => {
  const { isMobile, isTablet} = useDetectResize();
  
  return (
    <SectionHeaderWrapper
      className="section-header"
      
    >
      <Typography 
        variant='h1'
        height='fit-content'
      >
        {props.greeting}
      </Typography>
    </SectionHeaderWrapper>
  )
}

export default PageTitleComponent


export const MobilePageTitleComponent = (props: {isCollapsed: boolean} ) => {
  const {isCollapsed} = props;

  const routes = [
    "dashboard",
    "employers/",
    "employers/create",
    "employers/:parentCompName/:orgName",
    "employers/:parentCompName/:orgName/edit",
    "employers/:parentCompName/:orgName/recommendations/:searchState/:userType",
    "/profile",
    "/invite",
    "jobs",
    "jobs/create",
    "jobs/:employerOrganizationName/:id",
    "jobs/:employerOrganizationName/:id/edit",
    "jobs/:employerOrganizationName/:id/recommendations/:searchState/:userType",
    "individuals",
    "individuals/create",
    "individuals/:leadProviderOrgId/:individualId",
    "individuals/:leadProviderOrgId/:individualId/edit",
    "individuals/:leadProviderOrgId/:individualId/recommendations/:searchState/:userType",
  ];

  const headerComponents: JSX.Element[] = [
    <MobileDashboardGreeting/>,
    <EmployerHeaderContent/>, //list of employers
    <EmployerCreateHeader/>,
    <EmployerViewHeader/>,
    <EmployerEditHeader/>,
    <MobileRecommendationsHeaderComponent/>,
    <MobileProfileHeader/>,
    <MobileInviteHeader/>,
    <JobsHeaderContent/>,
    <JobCreateHeader/>,
    <JobViewHeader/>,
    <JobEditHeader/>,
    <MobileRecommendationsHeaderComponent/>,
    <IndividualsHeaderContent/>,
    <IndividualCreateHeader/>,
    <IndividualViewHeader/>,
    <IndividualEditHeader/>,
    <MobileRecommendationsHeaderComponent/>,
  ]

  return(
    <Routes>
      {
        routes.map((route:string, index:number) => {
          return(
            <Route
              key={index+'-'+Math.random()}
              path={route}
              element={
                <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                  {isCollapsed && headerComponents[index]}
                </React.Suspense>
              }
            />
          )
        })
      }
    </Routes>
  )
}