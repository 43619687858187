import { nanoid } from '@reduxjs/toolkit'
import React from 'react'
import Icon from '../../asssets/icons'
import { Individual } from '../typescript/types'
import IndividualAttachmentDownload from './IndividualAttachmentDownload'
import useTranslate from '../../hooks/useTranslate'
import { Typography } from '@mui/material'

const IndividualAttachments = (props: {individual?:Individual, showTitle?:boolean}) => {
  const {individual} = props;
  const t = useTranslate();
  
  return (
    <section>
      {
        props.showTitle 
        &&
        ( 
          <div className='iet-header inline full-width'>
            <img className='attch-icon' src={Icon.DocDownload} alt='download-icon'/>
            <Typography variant="subtitle2" style={{color: 'var(--font-color)'}}><b>{t('attachmentsTitle').toUpperCase()}</b></Typography>
          </div>
        )
      }
      
      <br/> 

      <Typography variant="subtitle2" style={{color: 'var(--font-color)'}}>{t('resumeUploadTitle')}</Typography>
      <IndividualAttachmentDownload fileName={individual?.fileAttachments[0]} key={nanoid()}/> 
      
      <Typography variant="subtitle2" style={{color: 'var(--font-color)'}}>{t('coverLetterUploadTitle')}</Typography>
      <IndividualAttachmentDownload fileName={individual?.fileAttachments[1]} key={nanoid()}/> 
      
      <Typography variant="subtitle2" style={{color: 'var(--font-color)'}}>{t('references')}</Typography>
      <div className='stack-vertically'>
        {
          !!individual?.referenceAttachments!.length 
          ? 
          individual?.referenceAttachments!.map( (file:string, index:number) =>{
            return <IndividualAttachmentDownload fileName={file} key={index+'-reference'}/> 
          }) 
          : 
          <IndividualAttachmentDownload/> 
        }
      </div>

      <Typography variant="subtitle2" style={{color: 'var(--font-color)'}}>Certifications</Typography>
      <div className='stack-vertically'>
        {
          !!individual?.certificationAttachments!.length 
          ?
          individual!.certificationAttachments!.map( (file: string, index:number) =>{
            return <IndividualAttachmentDownload fileName={file} key={index+'-certs'}/> 
          })
          :
          <IndividualAttachmentDownload/> 
        }
      </div>
    </section>
  )
}

export default IndividualAttachments