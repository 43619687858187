import { Box, createTheme, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const formTheme = createTheme({
  components: {
    MuiTextField:{
      styleOverrides: {
        root:{
          borderRadius: '15px',
        },
      }
    },
    MuiFormControl:{
      styleOverrides:{
        root:{
          margin: '0px',
        },
        
      },
    },
    MuiInputLabel:{
      styleOverrides:{
        root:{
          color: '#000000de',
          fontFamily:'Nunito',
          fontWeight: '500',
        }
      }
    },
    MuiInputBase:{
      styleOverrides: {
        root: {
          borderRadius: '15px !important',
          height: '54px !important'
        }
      }
    },
}});

export const goBackStyles = {
  textTransform: 'none',
  color: 'var(--font-color)',
  fontSize: '0.85em',
  fontWeight: '600',
  fontFamily: 'Nunito',
  lineHeight: '1.85',
  textAlign: 'left',
  padding: '0.5em 1em',
  '.MuiSvgIcon-root' : {
    color: 'var(--primary-color)',
  }, 
  "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
    fontSize: '1.00em',
  },
}


export const reactSelectLabel = {
  fontWeight: '500',
  color: 'rgba(0, 0, 0, 0.6)',
  marginLeft: '10px',
  marginBottom: '-9px',
  width: 'min-content',
  zIndex: '3',
  fontFamily: 'LexendDeca',
  background: 'white'
}

export const muiSelectLabel = {
  fontSize: '0.75rem',
  fontWeight: '500',
  color: '#00000099',
  marginLeft: '12px',
  marginTop: '20px',
  width: 'min-content',
  zIndex: '2',
  fontFamily: 'LexendDeca',
}

export const quillSelectLabel = {
  fontFamily: 'LexendDeca',
  fontSize: '0.75rem',
  fontWeight: '500',
  color: '#00000099',
  margin: '20px 0px',
  marginLeft: '12px',
  width: 'min-content',
  zIndex: '2',
}

export const formInputStyles = {
  '.MuiOutlinedInput-root':{
    'borderRadius': '15px',
    'height': '50px',
    'fontWeight': '600',
    'fontFamily': 'Nunito',
    'margin': 0,
  },
  '.MuiFormControl-root' : {
      margin: '0px'
  }
}

export const formDescriptionStyling = {
  '.MuiOutlinedInput-root, .MuiInputLabel-root':{
    top: '-4px',
    padding: '3px',
    borderRadius: '15px',
    fontWeight: '600',
    fntFamily: 'Nunito',
  },
  '.MuiOutlinedInput-notchedOutline': {height: '100px'},
  '.MuiOutlinedInput-root' : {
    height: '100px !important'
  }
}

export const addItemBtnStyling = {
    marginRight: '8px',
    textTransform: 'none',
    borderRadius: '16px',
    backgroundColor: 'var(--primary-color)',
    fontSize: '0.85em',
    fontWeight: '600',
    fontFamily: 'Nunito',
    padding: '0.5em 1em',
    height: '3em',
    '.MuiSvgIcon-root' : {
      fontSize: '2em',
    }, 
    "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
      fontSize: '1.0em',
    },
}

export const editEmployerStyling={
  marginRight: '',
  textTransform: 'none',
  borderRadius: '16px',
  fontSize: '0.85em',
  fontWeight: '600',
  fontFamily: 'Nunito',
  color: '#050505',
  padding: '0.5em 1em',
  height: '3em',
  border: 'solid 2px var( --background)',
  '.MuiSvgIcon-root' : {
    color: 'var(--secondary-font)',
    fontSize: '2em',
  },
  "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
    fontSize: '1.0em',
  },
}

export const mobilebackToStyling = {
    border: '1px solid #d9e1e7',
    borderRadius: '16px',
    height: '48px',
    alignSelf: 'center',
}

export const mobilebacktoIcon = {
  fill: '#4f5f71',
  fontSize: '1.25rem',
}

export const backToStyling ={
  textTransform: 'none',
  color: '#050505',
  fontSize: '0.85em',
  fontWeight: '600',
  fontFamily: 'Nunito',
  lineHeight: '1.85',
  textAlign: 'left',
  padding: '0.5em 1em',
  height: '3em',
  '.MuiSvgIcon-root' : {
    color: 'var(--secondary-font)',
  }, 
  "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
    fontSize: '1.0em',
  },
}

export const reactSelectCustomStyles = {
    container: (provided:any) => ({
      ...provided,
      margin: '10px 0px',
      width: '100%'
    }),
    control: (provided:any) =>({
      ...provided,
      borderRadius: '15px',
      minHeight: '45px',
      height: 'fit-content',
      overflow: 'hidden'
    }),
    option: (provided:any) => ({
      ...provided,
      borderRadius: '15px',
      fontWeight: '600',
      fontFamily: 'Nunito',
    }),
    menu: (provided:any) => ({
      ...provided,
      zIndex: 20,
    }),
    indicatorsContainer: (provided:any) => ({
      height: 'inherit',
      display: 'inline-flex',
      alignItems: 'center'
    }),
    multiValue: (provided:any) => ({
      ...provided,
      borderRadius: '15px',
      backgroundColor: 'var(--secondary-color)', 
      fontFamiliy: 'Nunito',
      fontWeight: '600',
      padding: '5px'
     }),
     multiValueLabel: (styles:any) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles:any) => ({
      ...styles,
      color: 'white',
      padding: '0px 3px',
      ':hover': {
        backgroundColor: 'white',
        color: '#e50101',
        borderRadius: '15px',
        padding: '0px 3px'
      },
    }),
}

export const goBackReccStyles = {
  textTransform: 'none',
  color: '#050505',
  fontSize: '0.85em',
  fontWeight: '600',
  fontFamily: 'Nunito',
  lineHeight: '1.85',
  textAlign: 'left',
  padding: '0.1em 0.8em',
  '.MuiSvgIcon-root' : {
    color: 'var(--secondary-font)',
  }, 
  "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
    fontSize: '1.00em',
  },
}

export const recommenderToggleStyling = {
  fontSize: '0.75em',
  fontFamily: 'Nunito'
}

export const skillStyling = {
  color: 'var(--primary-color)',
};

export const interestStyling = {
  color: 'var(--primary-color)',
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
}

export const restrictionStyling = {
  color: '#eb2c2c',
}

export const baseTagStyling = {
  borderRadius: '8px',
  backgroundColor: '#cfe4f6',
  padding: '0.2em 0.5em',
  fontWeight: '600',
  lineHeight: '1.33',
  textAlign: 'center',
  display: 'inline-grid!important',
  textTransform: 'lowercase',
  margin: '2px 2px',
  fontSize: '0.83em',
  wordBreak: 'normal',
}

export const reportingHeaderStyles = {
  color: 'black',
  textAlign:'left',
  gridColumnStart: 2,
  fontSize: '1.2em',
  fontFamily: 'LexendDeca',
  fontWeight: 500
}

export const customTableStyling = {
  minHeight: '60px',
  border: 'none',

  "*": {
    scrollbarWidth: 'thin',
    scrollbarColor: 'var(--secondary-color) #d3d9de',
  },
  "*::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },
  "*::-webkit-scrollbar-track": {
    backgroundColor: "#d3d9de",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "#4f5f71",
    borderRadius: 2.5,
  },

  '.MuiDataGrid-columnHeaderTitleContainerContent > span': {
    padding: '0px',
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiInputBase-input': {
    fontFamily: 'Nunito',
    fontSize: '0.9em',
  },
  '.MuiTablePagination-actions': {
    fontSize: '0.9em',
  },
  '.MuiDataGrid-footerContainer': {
    marginBottom: '2em',
  },
  '.MuiDataGrid-root': {
    minHeight: '82vh',
  },
  '.MuiDataGrid-main': {
    overflow: 'unset',
  },
  '.MuiDataGrid-virtualScroller': {
    marginTop: '60px!important',
  },
  '.MuiDataGrid-cellCheckbox': {
    borderRadius: '15px 0px 0px 15px',
    backgroundColor: 'var( --background)',
    minHeight: '90px!important',
    display: 'none',
  },
  '.MuiDataGrid-columnHeader': {
    '&:first-of-type': {
      backgroundColor: 'var( --background)',
      borderRadius: '15px 0px 0px 15px',
      minHeight: '40px',
      position: 'sticky',
      left: '0',
      '.MuiDataGrid-columnSeparator--sideRight': {
        display: 'none',
      },
    },
  },
  '.MuiDataGrid-columnHeaderCheckbox': {
    display: 'none',
  },
  '.MuiDataGrid-columnHeaders': {
    minHeight: '65px !important',
    maxHeight: '65px !important',
    lineHeight: '40px !important',
    borderBottom: 'none',
    fontFamily: 'LexendDeca',
    color: '#4f5f71',

    "@media (max-resolution: 1dppx) and (min-height: 1030px)": {
      fontSize: '1.00em',
    }
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '.MuiDataGrid-columnHeadersInner': {
    margin: '20px 0px',
    border: '1px solid #00000024',
    borderRadius: '15px',
  },

  '.MuiDataGrid-row': {
    borderRadius: '15px',
    border: '1px solid #00000024',
    margin: '2px 0px',
    minHeight: '90px!important',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontWeight: 500,
    color: '#050505',
    "@media (max-resolution: 1dppx) and (min-height: 1030px)": {
      fontSize: '1.00em',
    }
  },
  '.MuiDataGrid-cell': {
    border: 'none',
    minHeight: '98px!important',
  },
  '.MuiDataGrid-toolbarContainer': {
    '.MuiButton-root': {
      fontFamily: 'LexendDeca',
      fontWeight: '600',
    }
  }
}

export const detailedAccordingStyling = {
      padding: '15px',
      borderRadius: '16px!important',
      border: 'solid 1.5px #d9e1e7',
      boxShadow: '3px 2px 2px 0px #6aa9bf24',
      margin: '2px 0',


      '.MuiAccordionSummary-root' : {
        color: '#050505',
        fontFamily: 'LexendDeca',
        textTransform: 'uppercase',
        padding: 'unset',

      },
}

export const detailedAccordionStyling = {
  padding: '15px',
  borderRadius: '16px!important',
  border: 'solid 1.5px #d9e1e7',
  boxShadow: 'unset',
  margin: '2px 0',



  '.MuiAccordionSummary-root' : {
    color: '#050505',
    fontFamily: 'LexendDeca',
    textTransform: 'uppercase',
    padding: 'unset',

  },

  '.MuiAccordionDetails-root' : {
    padding: 'unset',


  },

}

export const marginBottomAccordion = {
    marginBottom: '12px',

    '&.MuiAccordion-root.Mui-expanded:last-of-type' : {
      marginBottom: '12px',
    }
}

export const jobWidgetButton = {
  fontFamily: 'LexendDeca',
 
  '@media(max-width: 768px)' : {
    fontSize: '0.75rem',
  },
}

export const tagsStyle = {
  '.MuiTypography-root': {
    '@media(max-width: 768px)' : {
      width: 'unset',
    }
  }
}