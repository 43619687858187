import { useForm, SubmitHandler, Controller, UseFormRegister, Control, UseFormSetValue } from "react-hook-form";
import { Employer, Job, SelectType } from "../typescript/types";
import { useAppDispatch } from "../typescript/hooks";
import { getEmployers } from "../../store/employerSlice";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { Button,  Checkbox,  TextField, Select as MuiSelect, Switch, InputLabel, FormControlLabel, ThemeProvider, FormHelperText, FormControl, RadioGroup, Radio, TextFieldProps, MenuItem, SxProps, SelectChangeEvent, Typography, Box } from "@mui/material";
import SuccessComponent from "../UI/Popup/SuccessComponent";
import { Form, useLocation, useNavigate } from "react-router-dom";
import { updateJob, postJob, deleteJobFile, deleteJobRecord, selectJobById } from "../../store/jobSlice";
import Select, {
  GroupBase,
  MultiValue,
  SingleValue,
} from "react-select";
import { useSelector } from "react-redux";
import { getMyProviderDetails, getProvidersInOrganization } from "../../store/providerSlice";
import { displaySelectTypeOptions, getPreviousTags } from "../../helpers/displayOptions";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import useAutoCompleteAPI from "../../hooks/useAutoCompleteAPI"; //google API for finding long/lat
import { appendRemainingDetails } from "../../helpers/helperFunctions";
import { AWSBucketProps, getAccessToken, getSignedUrl } from "../../store/authSlice";
import PageTitleComponent from "../PageTitleComponent";
import { formInputStyles, formTheme, muiSelectLabel, quillSelectLabel, reactSelectCustomStyles, reactSelectLabel } from "../../helpers/styling";
import GoBackBtn from "../UI/Buttons/GoBackBtn";
import Icon from "../../asssets/icons";
import { getTags } from "../../store/keywordSlice";
import { formatOnlyDecimals, formatOnlyNumbers } from "../../helpers/formatNumber";
import UploadFileComponent from "../FormComponents/UploadFileComponent";
import axios from "axios";
import UploadMultiFileComponent from "../FormComponents/UploadMultiFileComponent";
import HideFormOverlayComponent from "../UI/Popup/HideFormOverlayComponent";
import useJobByQueryHook from "../../hooks/useJobByQueryHook";
import store, { RootState } from "../../store";
import useAutoCompleteItems from "../../hooks/useAutoCompleteItems"; //MUI auto complete functionality
import ControllerAutoComplete, { GlobalFormData } from "../FormComponents/ControllerAutoComplete";
import useTranslate from "../../hooks/useTranslate";
import DeleteResourceComponent from "../SharedComponents/DeleteResourceComponent";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { JOBSCHEDULE_OPTIONS, SCHEDULE_OPTIONS } from "../../helpers/constants";
import SubmitKeywordSuggestions from "../SharedComponents/SubmitKeywordSuggestions";
import PopupMessageComponent from '../UI/Popup/PopupMessageComponent';
import Modal from "../UI/Popup/BackdropComponent";
import ReusableToggleComponent from '../SharedComponents/ReusableToggleComponent';
import useDetectResize from "../../hooks/useDetectResize";
import { CustomMuiSelect, FormComponentWrapper, FormContainer, MobileFormFieldContainer, FormFieldContainer, FormPanel, FormRow, FormTextInput, HorizontalScrollContainer, PanelCarousel, RichTextFormatWrapper, ReqErrorMsg, TagFormRow, KeyWordFFContainer } from "../UI/StyledComponents/FormsStyledComponents";
import StepperWidget from "../EmployerComponents/SharedWidgets/StepperWidget";
import { InlineContainer } from "../UI/StyledComponents/GlobalStyledComponents";
import { KeywordSubmitOverlay, SuccessOverlay } from "../UI/StyledComponents/NotificationModalStyledComponents";


export interface JobFormData extends Partial<Job> {
  creator?:string;
  jobFile: File | null;
  multiFiles: FileList | null;
  reCalculateMetrics: boolean;
};



const radioLabelStyling = {
  display: 'inline-grid',
  '.MuiFormControlLabel-label' : {
    fontFamily:'Nunito !important', 
    fontWeight: 500,

  }, 
  '.MuiRadio-root.Mui-checked' : {
    color: '#0379A0'
  }
};

const mobileRadioLabelStyling = {
  '.MuiFormControlLabel-label' : {
    fontFamily:'Nunito !important', 
    fontWeight: 500
  }, 
  '.MuiRadio-root.Mui-checked' : {
    color: '#0379A0'
  },

    marginLeft: '4px',
    marginRight: '4px'
};

const JobCreateComponent = (props: {isEdit: boolean, isNavCollapsed: boolean}) => {
  let defaultValues: JobFormData = { //need to re-render this every time, otherwise you won't update the form.
    title: '',
    employerOrganizationName: 'default',
    jobLogo:'',
    industry: '',
    address: '',
    longitude: 0,
    latitude: 0,
    providerId: '',
    providerName: '',
    providerEmail: '',
    providerPhone: '',
    providerOrgId: '',
    description: '',
    isJobActive: true,
    minRate: 0,
    maxRate: 0,
    weeklyHrs: 0,
    daysAvailable: [0,0,0,0,0,0,0],
    idealStartDate: dayjs().format('MM/DD/YYYY'),
    isTrainingReq: false,
    areCertsReq: false,
    isTrainingProvided: false,
    jobCoachEnabled: false,
    tags: [],
    reqSkills: '',
    notes: '',  
    createdAt: 0,
    closeDate: dayjs().format('MM/DD/YYYY'),
    jobType:0,
    jobDescFile: '', //string ref presigned url
    additionalFiles: [],
    jobFile: null, //File type used in react-hook-form
    multiFiles: null,
    reCalculateMetrics: false
  };
  
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    getValues,
    setFocus,
    reset,
    watch
  } = useForm<JobFormData>({ defaultValues });
  
  const accessToken = useSelector(getAccessToken);
  const { isEdit, isNavCollapsed } = props;
  const { state, pathname } = useLocation();
  const { selectedJob, jobsFetched } = useJobByQueryHook(pathname.split('/')[3]); //used for query redirect migrate over from useLocation() state to this.
  const {optionItems, setOptionItems} = useAutoCompleteItems();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const existingJob = useSelector((state:RootState) =>  selectJobById(state, pathname.split('/')[3]));

  //For updated object, get state passed in.
  const [job, setJob] = useState<Job | null>(isEdit ? existingJob! : null); //used to load previous data if user is editing.
  
  //UI modal renderings
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [hideFormOverlay, setHideFormOverlay] = useState<boolean>(false);
  const [displayKeywordSubmit, setDisplayKeywordSubmit] = useState<boolean>(false);
  const [isSubmitSuccessful, setSubmitSuccessful] = useState(false);
  const [closeDateEnabled, setCloseDateEnabled] = useState<boolean>(true);
  const [ isAddressValid, setAddressValid ] = useState<boolean>(isEdit ? true : false);
  //Keyword/tag related hooks and variables
  const t = useTranslate();
  const keywords = useSelector((state:RootState) => getTags(state, t));
  const [tagOptions, setTagOptions] = useState<any>(isEdit ? getPreviousTags(job?.tags!, keywords?.all as unknown as SelectType[]) : null); //preloads your tags if it is an edit.
  const [tags, setTags] = useState<number[]>([]); //used for storing database record. Tags stores as an array of numbers.  Allows refreshing the page and tracking user inptus on forms.
  
  const [jobPath, setJobPath] = useState<string>(""); //used for routing
  const [newJob, setNewJob] = useState<Job>(); //used to pass state to navigation for loading detailed view. 
  const {autoComplete, setWidget:InitializeAutoComplete}  = useAutoCompleteAPI(handlePlaceSelect); //Google API custom hook for accessing PlacesAPI
  
  const [jobDescriptionFile, setJobDescriptionFile] = useState<string>(!isEdit ? 'empty' : job?.jobDescFile?.startsWith('https') ? job?.jobDescFile : 'empty' );
  const [additionalFiles, setAdditionalFiles] = useState<string[]>(!isEdit ? ['empty','empty'] : job?.additionalFiles === null ? ['empty','empty'] : job?.additionalFiles[0]?.includes('https://') ? job?.additionalFiles : ['empty','empty']);
  const employers: Employer[] = useSelector(getEmployers);
  const [jobLeadProvider, setJobLeadProvider] = useState<string>(isEdit ? job?.providerId! : '');
  const [activeStep, setActiveStep] = useState<number>(0);

  const warnUserMsg = t('permanentlyDeleteJobWarningMsg');
  //get employers relative to the organization
  const employerOptions: SelectType[] = useMemo(() => { //builds a list used for selection employers within organization
    return employers?.map((employer: { orgName: string, id:string }, index: any) => {
      return { value: employer.id, label: employer.orgName } as unknown as SelectType;
    });
  }, [employers]);

  // used to display employer organization name in Organization Name input field.
  const [defaultEmployer, setDefaultEmployer] = useState<SelectType>(employerOptions.find(employer => employer.value === job?.employerId)!);
  //builds your provider options based
  const providers = useSelector(getProvidersInOrganization);
  const myDetails = useSelector(getMyProviderDetails);
  const {isDesktop, isLaptop, isTablet, isMobile, windowDimensions} = useDetectResize();
  const [disableFormSubmit, setDisableFormInput] = useState<boolean>(false);
  const [formWidthStyling, setFormWidth] = useState<SxProps>({});
  const [displayCompletedMsg, setDisplayCompletedMsg] = useState<boolean>(false); 



  //creates a list of providers for an organization
  const providerOptions: SelectType[] = providers.map((provider) => {
    return {
      value: provider.id,
      label: `${provider.firstName} ${provider?.lastName}`,
    } as unknown as SelectType;
  });

  const formRef = useRef<null | HTMLDivElement>(null);
  const refPanel_1 = useRef<null | HTMLDivElement>(null);
  const refPanel_2 = useRef<null | HTMLDivElement>(null);
  const refPanel_3 = useRef<null | HTMLDivElement>(null);
  const refPanel_4 = useRef<null | HTMLDivElement>(null);
  const refPanel_5 = useRef<null | HTMLDivElement>(null);

  //this pre-loads address and lead provider when a user selects an employer from the drop down. it also changes it within the form. (case: wrong employer selected, and they want to change it)
  const handleEmployerChange = (e: SingleValue<SelectType>) => {
    let employer = employers?.find((employer) => employer.orgName === e?.label);    
    //console.info('handleEmployerChange: ', employer!);
    setValue('address', employer?.address);
    setValue('longitude', employer?.longitude);
    setValue('latitude', employer?.latitude);
    setValue('providerId', employer?.leadProviderId);
    setValue('industry', employer?.industry);
    setValue('title', ''); //removes previously stored job title, to avoid accidental duplicates
    setValue('employerOrganizationName', defaultEmployer?.label);
    setAddressValid((currState: boolean) => currState = true); //remove validation message.
    setOptionItems(store.getState().jobs.allJobs.filter((job:Job) => job.employerOrganizationName === employer?.orgName).map((job:Job) => job.title))
  }

  //take human readable input and processes it to array of numbers FML, shit code. redo.
  const updateDaysAvailableArray = (daysArr: string[]) => {
    const arr = [0,0,0,0,0,0,0];

    daysArr.forEach((day:string)=>{
      switch (day) {
        case 'Monday':
          arr[0] = 1;
          break;
        case 'Tuesday':
          arr[1] = 1;
          break;
        case 'Wednesday':
          arr[2] = 1;
          break;
        case 'Thursday':
          arr[3] = 1;
          break;
        case 'Friday':
          arr[4] = 1;
          break;
        case 'Saturday':
          arr[5] = 1;
          break;
        case 'Sunday':
          arr[6] = 1;
          break;
      }
    });
    setValue('daysAvailable', arr);
    console.log(getValues('daysAvailable'))
    return arr;
  }

  function getDaysArrString(daysAvail: number[]) {
   return daysAvail.map((day:number, index:number) => {
      if(isDesktop || isLaptop){
        const result = day === 1 ? JOBSCHEDULE_OPTIONS[index].text : 'null'; 
        return result;
      }else{
        const result = day === 1 ? JOBSCHEDULE_OPTIONS[index].abbr : 'null'; 
        return result;
      }
    }).filter(day => day !== 'null')
  }

  const handleCloseDateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(!e.target.checked) {
      setValue('closeDate', 'N/A');
    }
    setCloseDateEnabled(currState => currState = e.target.checked);

    let daysAvailMonkeyPatch = getValues('daysAvailable')!;
    //ughhh need to convert an array of days(strings) to an array of 0/1 in order for form to read it. When the form 
    if(!daysAvailMonkeyPatch.some((elm:any) => elm === 0)){
      const correctDataType = updateDaysAvailableArray(daysAvailMonkeyPatch as unknown as string[]);
      console.warn(correctDataType)
      setValue('daysAvailable', correctDataType);
    }
  };

  useEffect(()=>{
    //On startup, hook onto the form's address input, then feed it to custom hook to initialize the API.
    const input = document.getElementById("autocomplete") as HTMLInputElement;
    InitializeAutoComplete(input);

    if( isEdit ){
      // eslint-disable-next-line react-hooks/exhaustive-deps
      defaultValues = { 
        ...job,
        jobFile: null,
        multiFiles: null,
        reCalculateMetrics: false
      };


      reset({...defaultValues}); 
      handleEmployerChange(defaultEmployer as unknown as SingleValue<SelectType>);
      setTags(job?.tags!); //tags are decoupled from react-select input, because we don't write it in as a {label, value} object, just an array of numbers.
      setTagOptions((currState: any) => currState = getPreviousTags(tags, keywords?.all as unknown as SelectType[]));
      if(job?.closeDate.startsWith('N/A')){
        setCloseDateEnabled(currState => currState = false);
        setValue('closeDate', 'N/A');
      }
    }else {
      console.info('Default Values New Form:', defaultValues);
      setAddressValid(currState => currState = false);
      reset({...defaultValues});
    }
  },[]);


  useEffect(()=>{
    reset({...defaultValues})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);


  useEffect(()=>{
  if(!closeDateEnabled && isEdit){
    setValue('closeDate', 'N/A');
    setValue('isJobActive', job?.isJobActive);
  }else if (closeDateEnabled && isEdit){
    let active = (dayjs() < dayjs(job?.closeDate) && job?.isJobActive);
    setValue('isJobActive', active);
  }
  }, [closeDateEnabled, setValue, job]);

  useEffect(()=>{
    setTagOptions((currState: any) => currState = getPreviousTags(tags, keywords?.all as unknown as SelectType[]));
  },[tags]);

  useEffect(()=>{
    console.log("Adjusted form dimenstions...")
    setFormWidth((currState:any) => currState = {width: `${formRef?.current!.offsetWidth}px !important`});
  }, [formRef, windowDimensions.w, windowDimensions.h, isNavCollapsed ]);


    //used to properly align panel on scroll for desktop, little bit of a hack, but works. 

    useEffect(() => {
      if(errors.employerOrganizationName || errors?.title || errors?.address) {
        setActiveStep(0);
       return refPanel_1.current!.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
      } else if(errors?.tags || errors?.minRate || errors?.maxRate || errors?.weeklyHrs){
        setActiveStep(1);
        return refPanel_2.current!.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
      }else if(errors?.daysAvailable) {
        setActiveStep(2);
        return refPanel_3.current!.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
      }
    },
    [
      errors?.tags,
      errors?.minRate,
      errors?.maxRate,
      errors?.weeklyHrs,
      errors?.employerOrganizationName,
      errors?.title,
      errors?.address,
      errors?.daysAvailable,
    ]);
  
  console.log(errors);
  //processes drop down selection to selected keyword/tag items to tags state by value only.
  const handleChange = (e: MultiValue<any>) => {
    console.warn('Detected Change, will reCalculate Metrics')
    setValue('reCalculateMetrics', true); //trigger backend to re-calculate metrics
    let result: number[] = [];
    if (!!e.length) {
      result = [];
      e.forEach((selection: { label: string; value: string }) => {
        result.push(+selection!.value);
      });
    } else {
      result = [];
    }
    setTags(result);
  };

  const removeFileHandler = (index:number) => {
    //clears setState when delete is hit, should also delete from AWS and back to null.
      console.log(index);
    if(index === 0){

      //dispatch event to delete file and remove from aws.
      if(isEdit && job?.jobDescFile.startsWith('https://')){
        dispatch(deleteJobFile({
            jobId: job!.id,
            fileRef: job?.jobDescFile,
            provId: myDetails.id,
            isMulti:false, 
            token: accessToken!
          }
        ))
        .then(unwrapResult)
          .then((res: any) => {
            console.log({res});
            if(res.status === 200){
              //display file has been deleted
              console.log('files deleted!')
              setJobDescriptionFile(currState => currState = 'empty');
              setValue('jobDescFile', 'empty');
              setValue('jobFile', null);
              console.log(getValues())
            }else{
              //error occurred, please try again later.
              console.warn('Error deleting files, try again later')
            }
          });
      }
    }

    if(index === 1){
      setAdditionalFiles(['empty']);
      setValue('multiFiles', null);
      setValue('additionalFiles', ['empty']);

      if(isEdit && job?.additionalFiles[0].startsWith('https://')){
        
        let payload = {
          jobId: job!.id, 
          fileRef: job?.additionalFiles.toString(),
          provId: myDetails.id, 
          isMulti:true, 
          token: accessToken!
        };

        dispatch(deleteJobFile(payload))
        .then(unwrapResult)
          .then((res: any) => {
          console.log({res});
           if(res.status === 200){
            //display file has been deleted
           }else{
            //error occurred, please try again later.
           }
          });
      }
    }
  }

  function handlePlaceSelect(){
    let addressObject = autoComplete!.getPlace();
    console.info('AutoComplete Object: ', addressObject);
    setValue('reCalculateMetrics', true);
    if(addressObject !== undefined){
      let addressName = addressObject.name + ', ' + appendRemainingDetails(addressObject);
      setValue('address', addressName);
      setValue('longitude', addressObject!.geometry!.location!.lng());
      setValue('latitude', addressObject!.geometry!.location!.lat());
      setAddressValid((currState: boolean) => currState = true);
      
    }else{
      alert('You must enter a valid address');
      setValue('address', '');
      setFocus('address', {shouldSelect:true});
    }
  }

  const onSubmit: SubmitHandler<JobFormData> = async (data) => {
    setHideFormOverlay(currState=>currState=true);
    let employer = employers.find(employer => employer.orgName === data.employerOrganizationName)!;
    
    let leadProvider;
    if(isEdit){
      leadProvider = providers.find(provider => provider.id === jobLeadProvider);
    }else{
      leadProvider = providers.find(provider => provider.id === data.providerId);
    }

    data.providerName = `${leadProvider?.firstName.trim()} ${leadProvider?.lastName.trim()}`;
    data.providerEmail = leadProvider?.email;
    data.providerPhone = leadProvider?.phone;
    data.providerOrgId = `${leadProvider!.orgId}`;
    console.log('setting new provider as: ', data.providerName);
    data.daysAvailable = data.daysAvailable!.some(elm => elm === 0) ? data.daysAvailable : updateDaysAvailableArray(data.daysAvailable! as unknown as string[]);
    console.log(data.daysAvailable);
    data.tags = tags;
    data.employerId = employer!.id;
    data.employerLogo= employer.imgLogo;
    data.creator = myDetails.id;
    
    if(isEdit){ //this will handle automatic updates of the employer organization name relative to the job if it detects a change.
      data.employerOrganizationName = employers?.filter(employer => employer.id === job?.employerId).map(obj => {return obj.orgName})[0];
    }

    console.log('FORM SUBMISSION')
    console.log(data);

    if(isEdit && data.longitude === undefined){
      data.longitude = job?.longitude;
      data.latitude = job?.latitude;
    }

    const processSignedURLUpload = new Promise((resolve,reject)=>{
      console.info('Job Desc File', data.jobDescFile);
      if(data.jobFile !== null){
        return processFileUpload(resolve, reject, data.jobFile as File);
      }else{
        if(data.jobDescFile === null){
          return (resolve('no file uploaded'));
        }else{
          console.log(data.jobDescFile);
          return (resolve('keep file as is'));
        }
      }
    });

    processSignedURLUpload.then((value:any) => {
      console.info('SignedURL response value: ' , value);
      if(value.startsWith('https://')){
        data.jobDescFile = value as string;
      }
    })
    .catch((e)=>{
      console.warn('Job description upload error: ', e);
    })
    .finally(()=>{
      processAdditionalFiles().then(()=>{
        //do additional file upload promise next
        if(isEdit){
          dispatch( updateJob({updatedJob: data as JobFormData, token: accessToken!}))
          .then(unwrapResult)
          .then((res:{data:{job:Job[]}})=> {
            console.info('Response data for job EDIT', res.data.job!);
            handleRouteChange(res.data.job[0]!);
          }).catch((err: any)=>{
            alert('uh-oh something went wrong, try refreshing the page!');
            console.log('update error: ', err);
          });
        }else{
          dispatch(postJob({jobObject: data as JobFormData, token: accessToken!}))
          .then(unwrapResult)
          .then((res:{data:{job:Job[]}}) => {
            console.info('Response data for job POST', res.data.job!);
            handleRouteChange(res.data.job[0]!);
          }).catch((err: any)=>{
            alert('uh-oh something went wrong, try refreshing the page!');
            console.log('update error: ', err);
          });
        }
      });
    });

    function handleRouteChange(responseData: Job){
      setSubmitSuccessful(true);
      setNewJob(currState => currState = responseData!);
      setJobPath(`${encodeURIComponent(responseData.employerOrganizationName)}/${responseData.id}`);
      setHideFormOverlay(currState=>currState=false);
      setDisplaySuccessModal(currState =>  currState= true);
    }

    async function processFileUpload(resolve: (value: string) => void, reject: (value:string) => void, file:File){
      console.info('Deleted Files!', file);
      const imgData: AWSBucketProps = { 
        orgId: data?.providerOrgId!, 
        fileName: file!.name,
        contentType: file!.type, 
        subFolder: 'jobDocs', 
        fileType:'docx',
        token: accessToken!
      };
      
      dispatch(getSignedUrl(imgData)).then(unwrapResult)
       .then(async (res: { signedUrl: any; key: string; }) => {
         console.info('EmployerCreateComponent - SignedURL response', res);
         return await axios({
           method: 'PUT', 
           url: res.signedUrl, 
           headers:{
             'Content-Disposition': `attachment; filename=${file!.name}`
           },
           data: file
         })
         .then(results=>{
           console.info('SignedURL results: ', results);
           if(results.status === 200){
             return resolve(`${process.env.REACT_APP_S3_RES_BUCKET}` + res.key); 
           }else{
             console.warn('Error uploading image. Please try again or contact the administrator.');
             return reject('Error in posting file');
           }
         });
       });
   }

   async function processAdditionalFiles(){
    console.info('MultiFiles', data.multiFiles);
    if(data.multiFiles !== null){
      let promises = [];
      for(let i=0; i < data.multiFiles.length; i++){
        promises.push( new Promise(async (resolve, reject) => {
          return processFileUpload(resolve, reject, data.multiFiles![i] as File);
        }))
      }

      return (Promise.all(promises).then((value)=>{
        if((value![0] as unknown as string).startsWith('https://')){
          for(let i = 0; i < value!.length; i++){
            data.additionalFiles![i] = value![i] as string;
          }
          return data.additionalFiles;
        }
      }))
    }else{
      if(data.additionalFiles === null ){
        return data.additionalFiles = ['empty'];
      }else{
        return data.additionalFiles ;
      }
    }
  }

  };

  const navigateHandler = () => {
    setDisplaySuccessModal((currState) => (currState = false));
    navigate(`/jobs/${jobPath}`, {state: newJob});
  };

  const setJobType = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('jobType', +e.target.value)
    console.log(e.target.value);
  }

  //Used to detect job title change for UX purposes.
  const handleInput = (input?:string) => {
    setValue('title', input);
  }

  const goToPanel = (step:number) => {
    console.log('Current Step to scroll to is... ', step);
      step === 0 ? refPanel_1.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) : step === 1 ? refPanel_2.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) : step === 2 ? refPanel_3.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) : step === 3 ? refPanel_4.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) : refPanel_5.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    })
  }

  return (
    
    <>
      <>
        {(isDesktop || isLaptop) && <PageTitleComponent greeting={isEdit ? t('jobViewEditBtn') : t('addJobBtn')} />}
        <FormComponentWrapper>
          <form 
            //onSubmit={handleSubmit(onSubmit)} 
            encType="multipart/form-data"
            onKeyDown={(e) => {return e.code === 'Enter' ? e.preventDefault() : null}}
          >
            <FormContainer>
              { (isDesktop || isLaptop) ? 
                <StepperWidget
                  steps={['Company Details', 'Keywords & Rates', 'Dates & Requirements', 'Attachments']}
                  isMobileTabletWidth={false}
                  stackStepsTitle
                  disableFormSubmitOrg={disableFormSubmit} 
                  goToPanel={goToPanel}
                  isEdit={isEdit!}
                  getValues={getValues}
                  deleteThisRecordId={job?.id!}
                  deleteThisRecordFunc={deleteJobRecord}
                  hasDeleteAccess={isEdit && myDetails?.isAdmin!}
                  tags={tags}
                  isFormType="job"
                  setDisplayCompletedMsg={setDisplayCompletedMsg}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleSubmit={handleSubmit(onSubmit)}
                >
                  <HorizontalScrollContainer
                    ref={formRef}
                    height={windowDimensions.h}
                    className="hide-scroll"
                  >
                    <PanelCarousel 
                      className='job-panel hide-scroll'
                      height = {windowDimensions.h}
                      width = {windowDimensions.w}
                    >
                      <FormPanel
                        className='panel-1'
                        ref={refPanel_1}
                        sx={formWidthStyling}
                      >
                        <FormRow
                          height={windowDimensions.h}
                        >
                          <FormFieldContainer 
                            className='full-width'
                          >
                            <Controller
                              control={control} // from useForm()
                              name="employerOrganizationName"
                              rules={{ required: t('empOrgReqMsg') }}
                              render={({ field: {onChange, value, name} }) => (
                                <>
                                <InputLabel 
                                  sx={reactSelectLabel}
                                  required
                                >{t('orgName')}
                                </InputLabel>
                                <Select
                                  placeholder=''
                                  styles={reactSelectCustomStyles}
                                  options={employerOptions}
                                  value={isEdit ? employerOptions.find(option => option.value === getValues('employerId')) : employerOptions.find(option => option.value === value)}
                                  isDisabled={isEdit}
                                  onChange={(
                                    option: SelectType | null,
                                  ) => {  onChange(handleEmployerChange(option));
                                        setValue(name, option!.label);
                                  }}
                                />
                                </>
                              )}
                            />
                        
                            {errors?.employerOrganizationName && <ReqErrorMsg>{errors?.employerOrganizationName?.message} </ReqErrorMsg>}
                          </FormFieldContainer>

                        </FormRow>

                        <FormRow
                          justifyContent='space-between'
                          height={windowDimensions.h}
                        >
                          <FormFieldContainer className='form-half-width'>
                            <ControllerTextField
                              control={control}
                              register={register}
                              name="address"
                              disabled={false}
                              required={true}
                              rules={{
                                required: "Please provide an address by selecting an organization above.",
                              }}
                              className="full-width"
                              label={t('address')}
                              placeholder={t('address')}
                              id='autocomplete'
                              onClick={()=>{setAddressValid(currState => currState = false)}} 
                            />
                
                            {errors?.address?.type === 'required' ? <ReqErrorMsg>{errors?.address.message}</ReqErrorMsg> : !isAddressValid ? <ReqErrorMsg>{t('addressErrorMsg')}</ReqErrorMsg> : <FormHelperText sx={{marginLeft:'15px', marginBottom:'10px'}}>{t('addressErrorMsg')}</FormHelperText>}
                          </FormFieldContainer>
                          <FormFieldContainer 
                            className='form-half-width'
                            position='relative'
                          >
                            <Controller
                              control={control}
                              name="providerId"
                              render={({ field: {onChange, value, name} }) => (
                                <>
                                <InputLabel 
                                    sx={{...reactSelectLabel, marginTop: '0px', position: 'absolute', top: '-5px'}}
                                    required
                                >{t('esProviderLabel')}
                                </InputLabel>

                                <CustomMuiSelect
                                  variant="outlined"
                                  fullWidth
                                  required
                                  value ={value}
                                  disabled={(isEdit && (myDetails?.isAdmin || myDetails?.id === job?.providerId)) ? false : true}
                                  {...register("providerId")}
                                  onChange={(e: any) => {
                                    onChange(e);
                                    setJobLeadProvider((currState:string) => {
                                      return currState = e.target.value
                                    });
                                    console.log(e.target.value)
                                  }}
                              
                                >
                                {displaySelectTypeOptions(providerOptions)}
                                </CustomMuiSelect>
                                <FormHelperText sx={{marginLeft:'15px'}}>{t('esProviderplaceholder')}</FormHelperText>
                                </>
                              )}
                            />
                          </FormFieldContainer>
                         
                        </FormRow>

                        <FormRow
                          className="full-width"
                          justifyContent='space-between'
                          alignItems='center'
                          marginTop='10px'
                          height={windowDimensions.h}
                        >
                          <FormFieldContainer className='form-half-width'
                            minHeight='45px'
                          >
                            <ControllerAutoComplete
                              control={control as Control<Partial<GlobalFormData>, any>}
                              name='title'
                              placeholder='Eg. Cashier'
                              required={true}
                              rules={{
                                required: 'A job title must be entered'
                              }}
                              register={register as UseFormRegister<Partial<GlobalFormData>>}
                              label={t('jobTitleJobList')}
                              id='outlined-required'
                              optionItems={optionItems.filter(function(item, pos, self) {
                                return self.indexOf(item) == pos;
                              })}
                              throwMessage={t('jobDuplicateMsg')}
                              disabled={getValues('employerOrganizationName')! === 'default'}
                              handleInput={handleInput}
                            />
                            {
                              getValues('employerOrganizationName')! === 'default' ? 
                              <FormHelperText sx={{marginLeft:'15px'}}>{t('orgSelectPrompt')}</FormHelperText> 
                              :
                              errors?.title ? <ReqErrorMsg>{errors.title?.message}</ReqErrorMsg> : null
                            }
                          
                          </FormFieldContainer> 

                          <InlineContainer 
                            width='48% !important'
                            justifyContent='space-between' 
                            marginLeft='20px'
                            alignItems='flex-start !important'
                          >
                            <Controller
                              control={control}
                              name="jobType"
                              render={({ field: {onChange, value, name}}) => (
                                <div className="form-switch shift-right">
                                  <Typography variant='h5'>
                                    {t('jobTypeLabel')}
                                  </Typography>
                                  
                                  <div className="inline-ca">
                                    <FormControl sx={{margin:'0px !important'}}>
                                      <RadioGroup
                                        row
                                        name="controlled-radio-buttons-group"
                                        value={value}
                                        onChange={onChange = (e) => {
                                          setJobType(e)
                                        }}
                                        sx={{
                                          width: '17.58vw',
                                          justifyContent: 'space-evenly'
                                        }}
                                      >
                                        <FormControlLabel sx={radioLabelStyling} value={0} control={<Radio />} label={t('irlText')} />
                                        <FormControlLabel sx={radioLabelStyling} value={1} control={<Radio />} label={t('remoteText')} />
                                        <FormControlLabel sx={radioLabelStyling} value={2} control={<Radio />} label={t('hybridText')} />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </div>
                              )} 
                            />

                            <Controller
                              control={control}
                              name="isJobActive"
                              render={({ field: {onChange, value, name}}) => (
                                <div 
                                  className='form-switch job-switch' 
                                  style={{width: 'fit-content', marginLeft: 'auto', minHeight: '58.8px'}}
                                >
                                  <Typography 
                                    variant='h5'
                                    marginBottom='10px !important'
                                  >
                                    {t('jobStatusLabel')}
                                  </Typography>
                                  <ReusableToggleComponent 
                                    toggleValue={value} 
                                    toggleSelect={() => {
                                      setValue('isJobActive', !value)
                                      }}
                                    firstToggle={'Open'}
                                    secondToggle={'Closed'}
                              />
                              </div>
                              )}
                            />
                          
                          </InlineContainer>
                        </FormRow>
                        
                        <RichTextFormatWrapper
                        marginTop={'30px'}
                        >
                          <InputLabel sx={{...quillSelectLabel, margin: '10px 0 !important'}}>{t('jobDescriptionText')}</InputLabel>
                          <Controller
                            control={control}
                            name="description"
                            rules={{
                              validate: (value) => {
                                if (value!.length > 2500) {
                                  return `${t('descErrorMsg')} (${value!.length}/2500)`;
                                }
                              }
                            }}
                            render={({ field: { onChange, value } }) => (
                              <ReactQuill 
                                theme="snow" 
                                placeholder={t('jobDescriptionText')}
                                value={value} onChange={onChange} />
                              )}
                          />
                        
                          {errors.description?.type === 'validate' ? <ReqErrorMsg> { errors.description?.message} </ReqErrorMsg> : <FormHelperText sx={{marginLeft: '10px'}}>{t('twoThousandMaxCharMsg')}</FormHelperText>}
                        </RichTextFormatWrapper>

                      </FormPanel>

                      <FormPanel
                        className='panel-2'
                        ref={refPanel_2}
                        sx={formWidthStyling}
                      >
                        <TagFormRow height={windowDimensions.h}>
                          <FormFieldContainer 
                            className="full-width"
                            minHeight='85px'
                          >
                            <Controller
                              control={control} // from useForm()
                              name="tags"
                              rules={{validate: () => { if(tags.length === 0) { return 'Provide at least two keywords.'}}}}
                              render={({ field: {onChange, value, name} }) => (
                                <>
                                <InputLabel sx={reactSelectLabel} required>{t(('keywordsLabel'))}</InputLabel>
                                <Select
                                  required={true}
                                  closeMenuOnSelect={false}
                                  styles={reactSelectCustomStyles}
                                  options={(keywords.all as unknown as GroupBase<SelectType>[]).filter((tag:any) => !tags?.includes(tag?.value))}
                                  isMulti={true}
                                  value={tagOptions!}
                                  placeholder={t('skillsInterestsLabel')}
                                  onChange={(e) => {
                                    onChange(handleChange(e));
                                  }}
                                />
                                  {errors.tags?.message ? <ReqErrorMsg>{errors.tags?.message}</ReqErrorMsg> : <FormHelperText sx={{marginLeft:'15px'}}>{t('skillsInterestPlaceholder')}</FormHelperText> }
                                </>
                              )}
                            />
                          </FormFieldContainer>
                        </TagFormRow>
                      
                        <FormRow
                          display='inline-table !important'
                          maxHeight='235px'
                        >
                          <KeyWordFFContainer className="full-width">
                            <SubmitKeywordSuggestions 
                              labelNoun={t('keywords').toLowerCase()} 
                              displayModal={setDisplayKeywordSubmit}
                            />
                          </KeyWordFFContainer>
                        </FormRow>

                        <FormRow 
                          height={windowDimensions.h}
                          display='none !important'
                        >
                          <FormFieldContainer
                            className="full-width"
                            sx={{opacity: '0'}}
                          >
                            <ControllerTextField
                              control={control}
                              register={register}
                              name='reqSkills'
                              placeholder={t('reqSkillsPlaceholder')}
                              label={t('reqSkills')}
                              className='full-width'
                            />
                            {errors?.reqSkills && <ReqErrorMsg>{errors?.reqSkills.message}</ReqErrorMsg>}
                          </FormFieldContainer>
                        </FormRow>

                        <FormRow justifyContent='space-between'>
                          <FormFieldContainer className="form-third-width">
                            <ControllerTextField
                              register={register}
                              control={control}
                              name="minRate"
                              required
                              rules={{
                                validate:{
                                  isLessThan: (value:string) => +value < +getValues().maxRate!,
                                  isFullDecimal: (value:string) => value.substring(value.length -1) !== '.',
                                  isEmpty: (value:string) => +value !== 0
                                }
                              }}
                              placeholder={t('minRate')}
                              label={t('minHrRateLabel')}
                              className='full-width'
                              customFunction={formatOnlyDecimals}
                            />
                            <ReqErrorMsg>{errors.minRate?.type === 'isLessThan' ? t('minRateErrorMsg') : errors.minRate?.type === 'isEmpty' ? 'Please enter a value greater than zero' : errors.minRate?.message }</ReqErrorMsg>
                          </FormFieldContainer>
                          <FormFieldContainer className="form-third-width">
                            <ControllerTextField
                              register={register}
                              control={control}
                              name="maxRate"
                              rules={{
                                validate:{
                                  isGreaterThan: (value:string) => +value > +getValues().minRate!,
                                  isFullDecimal: (value:string) => value.substring(value.length -1) !== '.'
                                }
                              }}
                              placeholder={t('maxRate')}
                              label={t('maxHrRateLabel')}
                              className='full-width'
                              customFunction={formatOnlyDecimals}
                            />
                            <ReqErrorMsg>{errors.maxRate?.type === 'isGreaterThan' && t('maxRateErrorMsg')}</ReqErrorMsg>

                          </FormFieldContainer>
                          <FormFieldContainer className="form-third-width">
                            <ControllerTextField
                              register={register}
                              control={control}
                              name="weeklyHrs"
                              placeholder={t('estHrsPerWeek')}
                              required={true}
                              rules={{
                                required: 'Please add total hours per week.',
                              }}
                              label={t('wklyHrsLabel')}
                              className="full-width"
                              customFunction={formatOnlyNumbers}
                            />
                          <ReqErrorMsg>{errors?.weeklyHrs && errors.weeklyHrs?.message}</ReqErrorMsg>
                          </FormFieldContainer>
                        </FormRow>
                      </FormPanel>

                      <FormPanel
                        className='panel-3'
                        ref={refPanel_3}
                        sx={{...formWidthStyling}}
                      >   

                        <div className="iet-header form-icon inline">
                          <img src={Icon.DetailedTime} alt='icon'/>
                          <Typography variant="subtitle2">{t('reqDaysLabel')}</Typography>
                        </div>

                        <FormRow
                          height={windowDimensions.h}
                          marginBottom='20px'
                        >
                          <FormFieldContainer className="form-half-width" marginTop='0px !important' position='relative'>
                            <InputLabel sx={{position: 'absolute', top: '-5px', left: '14px', background: 'white', zIndex: 4, fontSize:'12px !important'}}>Selected Days</InputLabel>
                              <Controller
                                control={control}
                                name={`daysAvailable` as const}
                                rules={{
                                  required: t('selectDayLabel'),
                                  pattern: {
                                  value:  /^(?!default)\w*/i,
                                  message: t('selectDayReqMsg')
                                  }
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    <CustomMuiSelect
                                      {...register(`daysAvailable` as const)}
                                      name={`daysAvailable`}
                                      value={ getDaysArrString(getValues('daysAvailable')!)}
                                      multiple
                                      onChange={(e) => {
                                        onChange(e); 
                                        updateDaysAvailableArray(e.target.value as string[])
                                      }}
                                    >
                                      {JOBSCHEDULE_OPTIONS.filter((obj:{value:number, text:string, abbr:string}) => obj.text !== 'Rotating').map((option: {value:number, text:string, abbr:string}) => {
                                        return (
                                          <MenuItem 
                                            key={(isMobile || isTablet) ? option.abbr : option.text} 
                                            value={(isMobile || isTablet) ? option.abbr : option.text} 
                                          >
                                            {(isMobile || isTablet) ? option.abbr : option.text} 
                                          </MenuItem>
                                        )
                                      })}
                                    </CustomMuiSelect>
                                  </>
                                  )}
                              />
                            {errors.daysAvailable?.type === "required" && <ReqErrorMsg>{errors.daysAvailable.message}</ReqErrorMsg>}
                          </FormFieldContainer>
                        
                        </FormRow>

                        <FormRow
                          height={windowDimensions.h}
                          marginBottom='20px'
                        >
                          

                          <FormFieldContainer className="form-half-width">
                            <Controller
                              control={control}
                              name="idealStartDate"
                              render={({ field: { onChange, value } }) => (
                                <LocalizationProvider 
                                  dateAdapter={AdapterDateFns}
                    
                                >
                                  <DatePicker
                                    {...register("idealStartDate")}
                                    label={t('idealStartDate')}
                                    value={value}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                      <FormTextInput
                                      {...params}
                                        sx={{ width: "100%"}}
                                        error={false}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </FormFieldContainer>

                          <FormFieldContainer 
                            className="form-half-width"
                            justifyContent='flex-end'
                            gridTemplateColumns= '100%'
                          >
                            <div className='close-date-wrapper content'>
                              <Controller
                                control={control}
                                name="closeDate"
                                rules={
                                  { required: closeDateEnabled ? 'You must select a closing date, or disable this feature' : false,
                                    validate: { 
                                      date: d => closeDateEnabled ? (new Date(d as string).getTime() > (Date.now())) : true
                                    } 
                                  }
                                } 
                                render={({ field: { onChange, value } }) => (
                                  <LocalizationProvider 
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      {...register("closeDate")}
                                      label={t('closeDate')}
                                      value={value}
                                      disablePast={true}
                                      disabled={!closeDateEnabled}
                                      onChange={(e: any) => {
                                        onChange(e);
                                      }}
                                      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                        <FormTextInput
                                        {...params}
                                          sx={{ width: "100%" }}
                                          error={false}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                              { (errors?.closeDate?.type === 'date' ) && (<ReqErrorMsg>{t('jobClosingDateHelperText')}</ReqErrorMsg>)}

                              <FormControlLabel 
                                control={
                                  <Checkbox
                                    checked={closeDateEnabled}
                                    onChange={handleCloseDateState}
                                  />
                                } 
                                label={t('closingDateCheckBoxLabel')} 
                                sx={{justifyContent:'flex-end', width:'100%'}}
                              />
                            </div>
                          </FormFieldContainer>
                        </FormRow>
                       
                        <hr></hr>
                        <FormRow  
                          justifyContent='space-evenly' 
                          maxHeight='unset'
                          height='fit-content'
                          display='block !important'
                        >

                            <Box>
                              <ControllerCheckBox
                                control={control}
                                register={register}
                                name={"isTrainingReq"}
                                setValue={setValue}
                              />
                              <label>{t('trainingReqLabel')}</label>
                            </Box>
                          
                            <Box >
                              <ControllerCheckBox
                                control={control}
                                register={register}
                                name={"areCertsReq"}
                                setValue={setValue}
                              />
                              <label>{t('certsReqLabel')}</label>
                            </Box>
          
                          
                            <Box>
                              <ControllerCheckBox
                                control={control}
                                register={register}
                                name={"isTrainingProvided"}
                                setValue={setValue}
                              />
                              <label>{t('trainingAvailLabel')}</label>
                            </Box>
                          
                            <Box>
                              <ControllerCheckBox
                                control={control}
                                register={register}
                                name={"jobCoachEnabled"}
                                setValue={setValue}
                              />
                              <label>{t('openToCoachSupportColumn')}</label>
                            </Box>

                        </FormRow>
                        <hr></hr>
                          <InputLabel sx={quillSelectLabel}>{t('howToApply')}</InputLabel>
                          <Controller
                            control={control}
                            name="notes"
                            render={({ field: { onChange, value } }) => (
                              <ReactQuill 
                                theme="snow"
                                placeholder={t('notesPlaceholder')}
                                value={value} 
                                onChange={onChange} 
                              />
                            )}
                          />
                      </FormPanel>

                      <FormPanel
                        className='panel-4'
                        ref={refPanel_4}
                        sx={formWidthStyling}
                      >
                        <section className="individuals-file-attachments">
          
                          <div className="iet-header form-icon inline full-width">
                            <img src={Icon.AttachmentIcon} alt='icon'/>
                            <Typography variant="subtitle2">{t('attachmentsTitle')}</Typography>
                          </div>

                          <div className="iet-header form-icon inline full-width">
                            <Typography variant="subtitle2">{t('jobDescriptionText')}</Typography>
                          </div>
                          <UploadFileComponent 
                            text={t('uploadJobDescriptionBtn')}
                            fileName={jobDescriptionFile} 
                            filePostition={0} 
                            controllerName='jobFile'
                            setFileName={setJobDescriptionFile}
                            removeFileHandler={removeFileHandler}
                            control={control}
                            canDelete={(myDetails?.id === job?.providerId || myDetails?.canDelete || myDetails?.isAdmin)}
                          />
                          
                          <div className="iet-header form-icon inline full-width">
                            <Typography variant="subtitle2">{t('additionalFilesTitle')}</Typography>
                          </div>
                          <UploadMultiFileComponent 
                            text={t(('additionalFilesText'))} 
                            filesArr={additionalFiles} 
                            removeHandlerVal={1} 
                            fileSetState={setAdditionalFiles} 
                            removeFileHandler={removeFileHandler}
                            maxFiles={3} 
                            controllerName='multiFiles' 
                            control={control}
                            canDelete={(myDetails?.id === job?.providerId || myDetails?.canDelete || myDetails?.isAdmin)}
                          />
                        </section>
                      </FormPanel>
                    </PanelCarousel>
                  </HorizontalScrollContainer>
                  
                </StepperWidget>
                :
                <StepperWidget
                  steps={['Company Details', 'Job Details', 'Job Needs', 'Job Dates', 'How to Apply']}
                  isMobileTabletWidth={true}
                  stackStepsTitle
                  disableFormSubmitOrg={disableFormSubmit} 
                  goToPanel={goToPanel}
                  isEdit={isEdit!}
                  getValues={getValues}
                  deleteThisRecordId={job?.id!}
                  deleteThisRecordFunc={deleteJobRecord}
                  hasDeleteAccess={false}
                  tags={tags}
                  isFormType="job"
                  setDisplayCompletedMsg={setDisplayCompletedMsg}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  handleSubmit={handleSubmit(onSubmit)}
                >
                  <HorizontalScrollContainer
                    ref={formRef}
                    height={windowDimensions.h}
                  >
                    <PanelCarousel 
                      className='job-panel hide-scroll'
                      height = {windowDimensions.h}
                      width = {windowDimensions.w}
                    >
                      <FormPanel
                        className='panel-1'
                        ref={refPanel_1}
                        sx={formWidthStyling}
                        gridTemplateRows= '100px 100px 100px 100px'
                      >
                        {/* employer organization name */}
                        <FormRow height='fit-content'>
                          <MobileFormFieldContainer className='full-width'
                            display='relative'
                          >
                            <Controller
                              control={control} // from useForm()
                              name="employerOrganizationName"
                              rules={{ required: t('empOrgReqMsg') }}
                              render={({ field: {onChange, value, name} }) => (
                              <>
                                <InputLabel 
                                  sx={{...reactSelectLabel, top:'4px', left: '6px', fontSize: '11px !important'}}
                                  required
                                >{t('orgName')}
                                </InputLabel>
                                <Select
                                  placeholder=''
                                  styles={reactSelectCustomStyles}
                                  options={employerOptions}
                                  value={isEdit ? employerOptions.find(option => option.value === getValues('employerId')) : employerOptions.find(option => option.value === value)}
                                  isDisabled={isEdit}
                                  onChange={(
                                    option: SelectType | null,
                                  ) => {  onChange(handleEmployerChange(option));
                                        setValue(name, option!.label);
                                  }}
                                />
                                </>
                              )}
                            />
                            {errors?.employerOrganizationName && <ReqErrorMsg>{errors?.employerOrganizationName?.message} </ReqErrorMsg>}
                          </MobileFormFieldContainer>
                        </FormRow>

                        {/* address */}
                        <FormRow 
                          height='fit-content'
                        >
                          <MobileFormFieldContainer 
                            className='full-width'
                            marginTop='0'
                          >
                            <ControllerTextField
                              control={control}
                              register={register}
                              name="address"
                              disabled={false}
                              required={true}
                              rules={{
                                required: "Please provide an address by selecting an organization above.",
                              }}
                              className="full-width"
                              label={t('address')}
                              placeholder={t('address')}
                              id='autocomplete'
                              onClick={()=>{setAddressValid(currState => currState = false)}} 
                            />
                
                            {errors?.address?.type === 'required' ? <ReqErrorMsg>{errors?.address.message}</ReqErrorMsg> : !isAddressValid ? <ReqErrorMsg>{t('addressErrorMsg')}</ReqErrorMsg> : <FormHelperText sx={{marginLeft:'15px', marginBottom:'10px'}}>{t('addressErrorMsg')}</FormHelperText>}
                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* job type */}
                        <FormRow 
                          height='fit-content'
                          margin='10px 0'
                          alignItems='center'
                          justifyContent='space-around'
                        >
                          <MobileFormFieldContainer>
                            <FormRow
                              height={windowDimensions.h}
                              marginTop='0px'
                              maxHeight='60px'
                              display= 'block !important'
                            >
                              <Controller
                                control={control}
                                name="jobType"
                                render={({ field: { onChange, value } }) => (
                                  <FormTextInput
                                    id='outlined-required'
                                    label={t('jobTypeLabel')}
                                    fullWidth
                                    select
                                    {...register("jobType")}
                                    value ={value}
                                    onChange={(e) => {
                                      setJobType(e as ChangeEvent<HTMLInputElement>)
                                    }}
                                    
                                  >
                                  <MenuItem value={0} selected>{t('irlText')}</MenuItem>
                                  <MenuItem value={1} >{t('remoteText') }</MenuItem>
                                  <MenuItem value={2} >{t('hybridText') }</MenuItem>
                                  </FormTextInput>
                                )}
                              />
                            </FormRow>
                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* lead provider */}
                        <FormRow height={windowDimensions.h}>
                          <MobileFormFieldContainer className='full-width' position= 'relative'>
                            <Controller
                              control={control}
                              name="providerId"
                              render={({ field: {onChange, value, name} }) => (
                                <>
                                <InputLabel 
                                  sx={{...reactSelectLabel, top:'-4px', left: '4px', fontSize: '11px !important'}}
                                  required
                                >{t('esProviderLabel')}
                                </InputLabel>

                                <CustomMuiSelect
                                  variant="outlined"
                                  fullWidth
                                  required
                                  value ={value}
                                  disabled={(isEdit && (myDetails?.isAdmin || myDetails?.id === job?.providerId)) ? false : true}
                                  {...register("providerId")}
                                  onChange={(e: SelectChangeEvent<any>) => {
                                    onChange(e);
                                    setJobLeadProvider((currState:string) => {
                                      return currState = e.target.value
                                    });
                                    console.log(e.target.value)
                                  }}
                                >
                                {displaySelectTypeOptions(providerOptions)}
                                </CustomMuiSelect>
                                <FormHelperText sx={{marginLeft:'15px'}}>{t('esProviderplaceholder')}</FormHelperText>
                                </>
                              )}
                            />
                          </MobileFormFieldContainer>
                        </FormRow>
                      </FormPanel>

                      <FormPanel
                        className='panel-2'
                        ref={refPanel_2}
                        sx={formWidthStyling}
                        gridTemplateRows= '75px 100px 275px'
                      >  
                        <InlineContainer justifyContent='space-between' alignItems='center' height='60px !important' marginBottom='10px'>

                          <Typography variant='h3' width='max-content'>Job Status</Typography>
      
                          {/* status job type */}
                          <MobileFormFieldContainer 
                            width='50% !important'
                            margin='0px !important'
                          >
                            <Controller
                              control={control}
                              name="isJobActive"
                              render={({ field: {onChange, value, name}}) => (
                                <div style={{width: 'fit-content', marginLeft: 'auto'}}>
                                  <ReusableToggleComponent 
                                    toggleValue={value} 
                                    toggleSelect={() => {
                                      setValue('isJobActive', !value)
                                      }}
                                    firstToggle={'Open'}
                                    secondToggle={'Closed'}
                                  />
                                </div>
                              )}/>
                          </MobileFormFieldContainer>
                        </InlineContainer>
                        
                        {/* job title */}
                        <FormRow height='fit-content'  marginTop='0'>
                          <MobileFormFieldContainer className='full-width'  margin='0'>
                            <ControllerAutoComplete
                              control={control as Control<Partial<GlobalFormData>, any>}
                              name='title'
                              placeholder='Cashier - Day/Night (M/W/F)'
                              required={true}
                              rules={{
                                required: 'A job title must be entered'
                              }}
                              register={register as UseFormRegister<Partial<GlobalFormData>>}
                              label={t('jobTitleJobList')}
                              id='outlined-required'
                              optionItems={optionItems.filter(function(item, pos, self) {
                                return self.indexOf(item) == pos;
                              })}
                              throwMessage={t('jobDuplicateMsg')}
                              disabled={getValues('employerOrganizationName')! === 'default'}
                              handleInput={handleInput}
                            />
                            {
                              getValues('employerOrganizationName')! === 'default' ? 
                              <FormHelperText sx={{marginLeft:'15px'}}>{t('orgSelectPrompt')}</FormHelperText> 
                              :
                              errors?.title ? <ReqErrorMsg>{errors.title?.message}</ReqErrorMsg> : null
                            }                          
                          </MobileFormFieldContainer> 

                        </FormRow>
                        {/* description */}
                        <FormRow
                          height='fit-content'
                          maxHeight='none'
                        >
                          <RichTextFormatWrapper>
                            <Typography variant='h3' margin='10px 0'>{t('jobDescriptionText')}</Typography>
                            <Controller
                              control={control}
                              name="description"
                              rules={{
                                validate: (value) => {
                                  if (value!.length > 2500) {
                                    return `${t('descErrorMsg')} (${value!.length}/2500)`;
                                  }
                                }
                              }}
                              render={({ field: { onChange, value } }) => (
                                <ReactQuill 
                                  theme="snow" 
                                  placeholder={t('jobDescriptionText')}
                                  value={value} onChange={onChange} />
                                )}
                            />
                          
                            {errors.description?.type === 'validate' ? <ReqErrorMsg> { errors.description?.message} </ReqErrorMsg> : <FormHelperText sx={{marginLeft: '10px'}}>{t('twoThousandMaxCharMsg')}</FormHelperText>}
                          </RichTextFormatWrapper>
                        </FormRow>
                      </FormPanel>

                      <FormPanel
                        className='panel-3'
                        ref={refPanel_3}
                        sx={formWidthStyling}
                        display='contents'
                      >
                        {/* keywords */}
                        <FormRow height='fit-content' maxHeight='none'>
                          <MobileFormFieldContainer className="full-width">
                            <Controller
                              control={control} // from useForm()
                              name="tags"
                              rules={{validate: () => { if(tags.length === 0) { return 'Provide at least two keywords.'}}}}
                              render={({ field: {onChange, value, name} }) => (
                                <>
                                <InputLabel 
                                  sx={{...reactSelectLabel, margin: '5px'}} 
                                  required
                                  >
                                    {t(('keywordsLabel'))}
                                </InputLabel>
                                <Select
                                  required={true}
                                  closeMenuOnSelect={false}
                                  blurInputOnSelect={false}
                                  autoFocus={errors.tags !== undefined}
                                  placeholder={t('skillsInterestsLabel')}
                                  styles={reactSelectCustomStyles}
                                  options={(keywords.all as unknown as GroupBase<SelectType>[]).filter((tag:any) => !tags?.includes(tag?.value))}
                                  isMulti={true}
                                  value={tagOptions!}
                                  onChange={(e) => {
                                    onChange(handleChange(e));
                                  }}
                                />
                                  {errors.tags?.message ? <ReqErrorMsg>{errors.tags?.message}</ReqErrorMsg> : <FormHelperText sx={{marginLeft:'15px'}}>{t('skillsInterestPlaceholder')}</FormHelperText> }
                                </>
                              )}
                            />
                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* keyword suggestion */}
                        <TagFormRow
                          height='fit-content'
                          display='inline-table !important'
                        >
                          <MobileFormFieldContainer className="full-width">
                            <SubmitKeywordSuggestions 
                              labelNoun={t('keywords').toLowerCase()} 
                              displayModal={setDisplayKeywordSubmit}
                            />
                          </MobileFormFieldContainer>
                        </TagFormRow>
                        {/* req skills */}
                        <FormRow 
                          height='fit-content'
                          display='none !important'
                        >
                          <MobileFormFieldContainer
                            className="full-width"
                            sx={{opacity: '0'}}
                          >
                            <ControllerTextField
                              control={control}
                              register={register}
                              name='reqSkills'
                              placeholder={t('reqSkillsPlaceholder')}
                              label={t('reqSkills')}
                              className='full-width'
                            />
                            {errors?.reqSkills && <ReqErrorMsg>{errors?.reqSkills.message}</ReqErrorMsg>}
                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* minrate */}
                        <FormRow>
                          <MobileFormFieldContainer className="full-width">
                            <ControllerTextField
                              register={register}
                              control={control}
                              name="minRate"
                              required
                              rules={{
                                validate:{
                                  isLessThan: (value:string) => +value < +getValues().maxRate!,
                                  isFullDecimal: (value:string) => value.substring(value.length -1) !== '.',
                                  isEmpty: (value:string) => +value !== 0
                                }
                              }}
                              placeholder={t('minRate')}
                              label={t('minHrRateLabel')}
                              className='full-width'
                              customFunction={formatOnlyDecimals}
                            />
                            <ReqErrorMsg>{errors.minRate?.type === 'isLessThan' ? t('minRateErrorMsg') : errors.minRate?.type === 'isEmpty' ? 'Please enter a value greater than zero' : errors.minRate?.message }</ReqErrorMsg>
                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* maxRate */}
                        <FormRow>
                          <MobileFormFieldContainer className="full-width">
                            <ControllerTextField
                              register={register}
                              control={control}
                              name="maxRate"
                              required
                              rules={{
                                validate:{
                                  isGreaterThan: (value:string) => +value > +getValues().minRate!,
                                  isFullDecimal: (value:string) => value.substring(value.length -1) !== '.'
                                }
                              }}
                              placeholder={t('maxRate')}
                              label={t('maxHrRateLabel')}
                              className='full-width'
                              customFunction={formatOnlyDecimals}
                            />
                            <ReqErrorMsg>{errors.maxRate?.type === 'isGreaterThan' && t('maxRateErrorMsg')}</ReqErrorMsg>

                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* weekly hrsr */}
                        <FormRow>
                          <MobileFormFieldContainer className="full-width">
                            <ControllerTextField
                              register={register}
                              control={control}
                              name="weeklyHrs"
                              rules={{
                                required: 'Please add total hours per week.',
                              }}
                              placeholder={t('estHrsPerWeek')}
                              required={true}
                              label={t('wklyHrsLabel')}
                              className="full-width"
                              customFunction={formatOnlyNumbers}
                            />
                          <ReqErrorMsg>{errors?.weeklyHrs && errors.weeklyHrs?.message}</ReqErrorMsg>
                          </MobileFormFieldContainer>
                        </FormRow>
                      </FormPanel>

                      <FormPanel
                        className='panel-4'
                        ref={refPanel_4}
                        sx={formWidthStyling}
                        gridTemplateRows= '75px 100px 100px 100px 67px 60px 60px 60px'
                      >

                        <div className="iet-header form-icon inline">
                          <img src={Icon.DetailedTime} alt='icon'/>
                          <Typography variant="h3">{t('reqDaysLabel')}</Typography>
                        </div>
                        {/* daysAvailable */}
                        <FormRow
                          height={'fit-content'}
                        >
                          <MobileFormFieldContainer className="full-width" marginTop='0px !important' position='relative'>
                            <InputLabel sx={{position: 'absolute', top: '-5px', left: '14px', background: 'white', zIndex: 4, fontSize:'12px !important'}}>Selected Days</InputLabel>

                            <Controller
                              control={control}
                              name={`daysAvailable` as const}
                              rules={{
                                required: t('selectDayLabel'),
                                pattern: {
                                value:  /^(?!default)\w*/i,
                                message: t('selectDayReqMsg')
                                }
                              }}
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <CustomMuiSelect
                                    {...register(`daysAvailable` as const)}
                                    name={`daysAvailable`}
                                    value={ getValues('daysAvailable')!.map((day:number, index:number) => {
                            
                                        const result = day === 1 ? JOBSCHEDULE_OPTIONS[index].text : 'null'; 
                                        return result;
                                  
                                    }).filter(day => day !== 'null')}
                                    multiple
                                    onChange={(e) => {
                                      onChange(e); 
                                      updateDaysAvailableArray(e.target.value as string[])
                                    }}
                                  >
                                    {JOBSCHEDULE_OPTIONS.filter((obj:{value:number, text:string, abbr:string}) => obj.text !== 'Rotating').map((option: {value:number, text:string, abbr:string}) => {
                                      return (
                                        <MenuItem 
                                          key={option.text} 
                                          value={option.text} 
                                        >
                                          {option.text} 
                                        </MenuItem>
                                      )
                                    })}
                                  </CustomMuiSelect>
                                </>
                              )}
                            />
                            { errors.daysAvailable?.type === "required" && <ReqErrorMsg>{errors.daysAvailable.message}</ReqErrorMsg> }
                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* idealStartDate */}
                        <FormRow>               
                          <MobileFormFieldContainer className="full-width">
                            <Controller
                              control={control}
                              name="idealStartDate"
                              render={({ field: { onChange, value } }) => (
                                <LocalizationProvider 
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DatePicker
                                    {...register("idealStartDate")}
                                    label={t('idealStartDate')}
                                    value={value}
                                    onChange={(e: any) => {
                                      onChange(e);
                                    }}
                                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                      <FormTextInput
                                      {...params}
                                        sx={{ width: "100%"}}
                                        error={false}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            />
                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* closedDate */}
                        <FormRow>
                          <MobileFormFieldContainer 
                            className="full-width"
                            gridTemplateColumns= '100%'
                          >
                            <Box className='close-date-wrapper content'>
                              <Controller
                                control={control}
                                name="closeDate"
                                rules={
                                  { required: closeDateEnabled ? 'You must select a closing date, or disable this feature' : false,
                                    validate: { 
                                      date: d => closeDateEnabled ? (new Date(d as string).getTime() > (Date.now())) : true
                                    } 
                                  }
                                } 
                                render={({ field: { onChange, value } }) => (
                                  <LocalizationProvider 
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      {...register("closeDate")}
                                      label={t('closeDate')}
                                      value={value}
                                      disablePast={true}
                                      disabled={!closeDateEnabled}
                                      onChange={(e: any) => {
                                        onChange(e);
                                      }}
                                      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                        <FormTextInput
                                        {...params}
                                          sx={{ width: "100%", '& .MuiFormLabel-root' : { marginTop: '-3px'} }}
                                          error={false}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                              { (errors?.closeDate?.type === 'date' ) && (<ReqErrorMsg>{t('jobClosingDateHelperText')}</ReqErrorMsg>)}
                              <InlineContainer justifyContent={'flex-end'}>
                                <FormControlLabel 
                                  control={<Checkbox
                                  checked={closeDateEnabled}
                                  onChange={handleCloseDateState}
                                />} label={t('closingDateCheckBoxLabel')} />
                              </InlineContainer>
                            </Box>
                          </MobileFormFieldContainer>
                        </FormRow>
                        {/* checkboxes for certs x4  */}
                        <FormRow  
                          justifyContent='space-between' 
                          marginTop='20px'
                        >

                          <Box className="full-width"
                            display= 'inline-flex'
                            alignItems = 'center'
                          >
                            <ControllerCheckBox
                              control={control}
                              register={register}
                              name={"isTrainingReq"}
                              setValue={setValue}
                            />
                            <label>{t('trainingReqLabel')}</label>
                          </Box>
                        </FormRow>
                        <FormRow>
                          <Box className="full-width"
                            display= 'inline-flex'
                            alignItems = 'center'
                          >
                            <ControllerCheckBox
                              control={control}
                              register={register}
                              name={"areCertsReq"}
                              setValue={setValue}
                            />
                            <label>{t('certsReqLabel')}</label>
                          </Box>
        
                        </FormRow>
                        <FormRow>
                          <Box className="full-width"
                            display= 'inline-flex'
                            alignItems = 'center'
                          >
                            <ControllerCheckBox
                              control={control}
                              register={register}
                              name={"isTrainingProvided"}
                              setValue={setValue}
                            />
                            <label>{t('trainingAvailLabel')}</label>
                          </Box>
                        </FormRow>
                        <FormRow>
                          <Box className="full-width"
                            display= 'inline-flex'
                            alignItems = 'center'
                          >
                            <ControllerCheckBox
                              control={control}
                              register={register}
                              name={"jobCoachEnabled"}
                              setValue={setValue}
                            />
                            <label>{t('openToCoachSupportColumn')}</label>
                          </Box>

                        </FormRow>
                      </FormPanel>

                      <FormPanel
                        className='panel-5'
                        ref={refPanel_5}
                        sx={{...formWidthStyling, gridTemplateRows:'30px 230px 188px;'}}
                      >
                        <Box className="iet-header form-icon inline full-width">
                          <img src={Icon.AttachmentIcon} alt='icon'/>
                          <Typography variant='h3' height={'min-content'}> {t('howToApply')}</Typography>
                        </Box>
                        <FormRow
                          sx={{
                            '& > div' : {
                              width: '100% !important'
                            }
                          }}
                        >
                          <Controller
                            control={control}
                            name="notes"
                            render={({ field: { onChange, value } }) => (
                              <ReactQuill 
                                theme="snow"
                                placeholder={t('notesPlaceholder')}
                                
                                value={value} 
                                onChange={onChange} 
                              />
                            )}
                          />
                        </FormRow>
                        <FormRow>                     
                          <Box className="individuals-file-attachments">

                            <Box className="iet-header form-icon inline full-width">
                              <img src={Icon.AttachmentIcon} alt='icon'/>
                              <Typography variant="h3">{t('attachmentsTitle')}</Typography>
                            </Box>

                            <div className="iet-header form-icon inline full-width">
                              <Typography variant="subtitle2">{t('jobDescriptionText')}</Typography>
                            </div>

                            <UploadFileComponent 
                              text={t('uploadJobDescriptionBtn')}
                              fileName={jobDescriptionFile} 
                              filePostition={0} 
                              controllerName='jobFile'
                              setFileName={setJobDescriptionFile}
                              removeFileHandler={removeFileHandler}
                              control={control}
                              canDelete={(myDetails?.id === job?.providerId || myDetails?.canDelete || myDetails?.isAdmin)}
                            />
                            
                            <div className="iet-header form-icon inline full-width">
                              <Typography variant="subtitle2">{t('additionalFilesTitle')}</Typography>
                            </div>

                            <UploadMultiFileComponent 
                              text={t(('additionalFilesText'))} 
                              filesArr={additionalFiles} 
                              removeHandlerVal={1} 
                              fileSetState={setAdditionalFiles} 
                              removeFileHandler={removeFileHandler}
                              maxFiles={3} 
                              controllerName='multiFiles' 
                              control={control}
                              canDelete={(myDetails?.id === job?.providerId || myDetails?.canDelete || myDetails?.isAdmin)}
                            />

                          </Box>
                        </FormRow>
                      </FormPanel>
                    </PanelCarousel>

                  </HorizontalScrollContainer>
                  
              </StepperWidget>
              }
            </FormContainer>
          </form>
        </FormComponentWrapper>
      </>
        {
         (displayCompletedMsg && !isEdit) && (
          <Modal 
            onHide={() =>{setDisplayCompletedMsg(false)}} 
            className='key-wrd-submit-ovrl' 
          >
            <KeywordSubmitOverlay
              onClick={(e)=>{e.stopPropagation()}}
              top = {windowDimensions.h}
              right = {windowDimensions.w}
            >
              <PopupMessageComponent
                className="keyword-submit-popup"
                icon={Icon.SwooshGif}
                message={'Hey looks like you are all good to go, continue filling out the remaining form or save your progress to see recommended matches!'} 
                closeModal={() => { setDisplayCompletedMsg(false)}} 
                overrideButtonText={'Go Back'}
              />
            </KeywordSubmitOverlay>
          </Modal>
          )
        }

        {hideFormOverlay && (
          <HideFormOverlayComponent noun={t('savingJob')}/>
        )}
        {displaySuccessModal && (
          <SuccessComponent noun={t('job')} onAcknowledged={navigateHandler} />
        )}
        {displayKeywordSubmit && (
          <Modal 
            onHide={() =>{setDisplayKeywordSubmit(false)}} 
          >
            <KeywordSubmitOverlay
              onClick={(e)=>{e.stopPropagation()}}
              top = {windowDimensions.h}
              right = {windowDimensions.w}
            >
              <PopupMessageComponent 
                className="keyword-submit-popup"
                icon={Icon.SwooshGif}
                message={'Your submission has been recieved, keep an eye out in the weeks to come!'} 
                closeModal={() => { setDisplayKeywordSubmit(false)}} 
                overrideButtonText={'Go Back'}
              />
            </KeywordSubmitOverlay>
          </Modal>
        )}
    </>
  );
};

interface PropsControllerTextField {
  register: UseFormRegister<JobFormData>;
  customFunction?: (e: string) => void;
  onClick?: ()=>void;
  control: Control<JobFormData, any>;
  name: any;
  rules?: {
    required?: boolean | string;
    pattern?: { value: RegExp; message: string };
    validate?: {
      isLessThan?: (value: string) => boolean, 
      isGreaterThan?: (value: string) => boolean,
      isFullDecimal?: (value:string) => boolean,
      isEmpty?: (value: string) => boolean,
    }
  };
  required?:boolean;
  placeholder: string;
  className: string;
  label?: string;
  focused?:boolean;
  disabled?: boolean;
  type?:string;
  id?:string;
}

const ControllerTextField = (props: PropsControllerTextField) => {
  const {
    control,
    name,
    placeholder,
    rules,
    required,
    register,
    className,
    customFunction,
    onClick,
    label,
    focused,
    disabled,
    type,
    id
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FormTextInput
          {...register(name)}
          id={ id ? id : 'outlined-required'}
          className={className}
          type={type ? type : "text"}
          placeholder={placeholder}
          label={label ? label : placeholder}
          value={value || ""}
          required={required}
          autoFocus={focused ? true : false}
          disabled={disabled ? true : false}
          onChange={(e) => {
            if (customFunction) {
              onChange(customFunction(e.target.value));
            } else {
              onChange(e.target.value);
            }
          }}
          onClick={ onClick && onClick}
        />
      )}
    />
  );
};

interface PropsControllerCheckbox {
  control: Control<JobFormData, any>;
  name: any;
  register: UseFormRegister<JobFormData>;
  setValue: UseFormSetValue<JobFormData>;
}

const ControllerCheckBox = (props: PropsControllerCheckbox) => {
  const { control, register, name, setValue } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name,  } }) => (
        <Checkbox
          {...register(name)}
          onChange={(e) => {
            onChange(e.target.checked);
            setValue(name, (!!e.target.checked ? 1 : 0));
          }}
          checked={value > 0 ? true: false}
        />
      )}
    />
  );
};

export default JobCreateComponent;


export const JobCreateHeader = () => {
  const t = useTranslate(); 
  return(
    <PageTitleComponent greeting={t('addJobBtn')} />
  )
}

export const JobEditHeader = () => {
  const t = useTranslate(); 
  return(
    <PageTitleComponent greeting={t('jobViewEditBtn')} />
  )
}