import { useSelector } from 'react-redux';
import { stringAvatar } from '../../helpers/helperFunctions';
import useTranslate from '../../hooks/useTranslate';
import { RootState } from '../../store';
import { getOrgDetails } from '../../store/providerSlice';
import { Avatar, Box, Tooltip, TooltipProps, Typography } from '@mui/material';
import styled from '@mui/system/styled';
import { tooltipClasses } from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import { useState } from 'react';

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'var(--secondary-color)',
    color: 'white'
  },
}));


const EmploymentSupportLeadDetails = (props: {provider?: any, isVisible?:boolean, photo?: string, orgId?:string}) => {
  const {provider, isVisible, photo, orgId} = props;
  const organization = useSelector((state: RootState) => getOrgDetails(state, orgId || ''))
  const t = useTranslate();
  const [tooltipIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  return (
    <>
      {
        isVisible
        ?
        <Box className='es-details-cont'>
          <Box>
            <Typography variant="h5" marginBottom='5px !important'>{t('providerCardTitle')}</Typography>
            <Box className='es-img-info'>
              { photo?.startsWith('https') ? <img src={photo} alt='ofProvider'/> : <Avatar {...stringAvatar(provider?.name)} />}
              <Box 
                className='provider-details'
              >
                <CustomToolTip
                  title={organization?.orgDetails} 
                  placement="right-start"
                >
                  <Typography variant="body1" sx={{cursor: 'pointer'}}>{organization?.orgName}</Typography>
                </CustomToolTip>
                <InlineContainer>
                  <Typography variant="body1">{provider?.name} - {provider?.email}</Typography>
                  <CustomToolTip
                   open={tooltipIsOpen!}
                   onOpen={() => setTooltipIsOpen(true)}
                   onClose={() => setTooltipIsOpen(false)}
                   disableFocusListener
                   disableHoverListener
                   title="Copied!"
                  >
                    <ContentCopyIcon onClick={() => {
                      setTooltipIsOpen(true);
                      navigator.clipboard.writeText(`${provider?.email}`).then(()=>{
                        setTimeout(() => {
                          setTooltipIsOpen(false)
                        }, 750)
                      });
                    }} sx={{cursor: 'pointer', width: '18px', marginLeft: '15px'}}/>
                  </CustomToolTip>
                </InlineContainer>
                <Typography variant="body1">{provider?.phone}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        : 
        <Box className='details-provider-cover'>
          
        </Box>
      }
      </>
  )
}

export default EmploymentSupportLeadDetails