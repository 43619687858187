import React from 'react'
import Icon from "../../asssets/icons";
import { Individual } from '../typescript/types'
import IndividualEmploymentTable from './IndividualEmploymentTable';
import useTranslate from '../../hooks/useTranslate';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';


const IndividualEmploymentTables = (props: {individual?: Individual}) => {
  const {individual} = props;
  const t = useTranslate();
  return (
    <>
      <CurrentEmploymentHeader hours={individual?.filledHours!}/>
      <section className='reco-table hide-scroll'>
        <IndividualEmploymentTable records= {individual?.employmentRecord.filter( record =>  record.isCurrent )} />
      </section>
      <HistoricalEmploymentHeader/>
      <section className='reco-table hide-scroll'>
        <IndividualEmploymentTable records= {individual?.employmentRecord.filter( record =>  !record.isCurrent )} />
      </section>
    </>
  )
}

export default IndividualEmploymentTables

export const CurrentEmploymentHeader = (props: {hours: number}) => {
  const t = useTranslate();
  return(
    <Box className="iet-header inline full-width">
    <img src={Icon.DetailedJob} alt='icon'/>
    <Box className="iet-headertext">
      <Typography variant="h3">{t('empScheduleTitle')}</Typography>
      <Typography variant="caption">{`${props.hours} hours/week`}</Typography>
    </Box>
  </Box>
  )
}

export const HistoricalEmploymentHeader = () => {
  const t = useTranslate();
  return(
    <Box className="iet-header inline full-width">
    <img src={Icon.DetailedTime} alt='icon'/>
    <Typography variant="h3">{t('employmentHistory').toUpperCase()}</Typography>
  </Box>
  )
}