export const DAY_OPTIONS = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday'},    
];

export const INDUSTRY = [
  'ART EXHIBITION',
  'AUTOMOTIVE',
  'DATA MANAGEMENT',
  'COMMUNITY SERVICES',
  'COURIER/DELIVERY SERVICES',
  'DAY CARE',
  'EDUCATION',
  'ELECTRONICS MANUFACTURER',
  'EVENT SERVICES',
  'FOOD RETAILER',
  'FOOD SERVICES',
  'GROCERY',
  'HEALTH CARE',
  'HOSPITALITY SERVICES',
  'INDUSTRIAL EQUIPMENT SUPPLIER',
  'INDUSTRY',
  'INFORMATION TECHNOLOGY',
  'JANITORIAL',
  'LEGAL SERVICES',
  'MANUFACTURER',
  'QUICK SERVICE',
  'RECYCLING',
  'RETAIL',
  'RETAIL WAREHOUSE',
  'SECURITY',
  'SENIOR FACILITY',
  'SPORTS',
  'TOBACCONISTS ',
];

export const INDUSTRY_FR = [
  'EXPOSITION D’ART',
  'AUTOMOBILE',
  'GESTION DE DONNÉES',
  'SERVICES COMMUNAUTAIRES',
  'SERVICES DE MESSAGERIE/LIVRAISON',
  'GARDERIE',
  'ÉDUCATION',
  'FABRICATION DE MATÉRIEL ÉLECTRONIQUE',
  'SERVICES ÉVÉNEMENTIELS',
  'DÉTAILLANT ALIMENTAIRE',
  'SERVICES ALIMENTAIRES',
  'ÉPICERIE',
  'SOINS DE SANTÉ',
  'SERVICES DE L’ACCUEIL',
  'FOURNISSEUR DE MATÉRIEL INDUSTRIEL',
  'INDUSTRIE',
  'INFORMATIQUE',
  'NETTOYAGE ET ENTRETIEN',
  'SERVICES JURIDIQUES',
  'FABRICATION'
];


export const SCHEDULE_OPTIONS = [
  {value:0, text:'Monday', abbr:'Mon'},
  {value:1, text:'Tuesday', abbr:'Tue'},
  {value:2, text:'Wednesday', abbr:'Wed'},
  {value:3, text:'Thursday', abbr:'Thu'}, 
  {value:4, text:'Friday', abbr:'Fri'},
  {value:5, text:'Saturday', abbr:'Sat'},
  {value:6, text:'Sunday', abbr:'Sun'} ,
  {value:7, text:'Rotating', abbr:'Rotation'},
];

export const JOBSCHEDULE_OPTIONS = [
  {value:0, text:'Monday', abbr:'Mon'},
  {value:1, text:'Tuesday', abbr:'Tue'},
  {value:2, text:'Wednesday', abbr:'Wed'},
  {value:3, text:'Thursday', abbr:'Thu'}, 
  {value:4, text:'Friday', abbr:'Fri'},
  {value:5, text:'Saturday', abbr:'Sat'},
  {value:6, text:'Sunday', abbr:'Sun'} ,
];


export const DAYS_LIST = ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"];

export const NOTIFICATION = {
  JOB_NEW: 'JOB_NEW',
  JOB_UPDTD: 'JOB_UPDATE',
  JOB_CLOSE: 'JOB_CLOSE',
  INDIV_NEW: 'INDIVIDUAL_NEW',
  INDIV_UPDTD: 'INDIVIDUAL_UPDATE',
  INDIV_OPEN: 'INDIVIDUAL_OPEN',
  INDIV_MATCH: 'INDIVIDUAL_MATCH',
  EMPLOYER_NEW: 'EMPLOYER_NEW',
  EMPLOYER_UPDTD: 'EMPLOYER_UPDATE',
}

export const is14YearsInMilliseconds =  14 * 365 * 24 * 60 * 60 * 1000;

export const regXPhoneNumber = /^(?:1-)?\d{3}(?:[-.]\d{3})?[-.]\d{4}(?: ext\. \d{1,5})?$/i;

export const regXEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;