import { ArrowBackIos, EditOutlined } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import { mobilebackToStyling, mobilebacktoIcon } from '../../../helpers/styling'
import { getFullName } from '../../../helpers/helperFunctions'
import { Employer, Individual, Job, Provider } from '../../typescript/types'
import { IconWrapper, InlineContainer, MobileContainerHeader } from '../../UI/StyledComponents/GlobalStyledComponents'
import EmploymentSupportLeadCardComponent from '../EmploymentSupportLeadCardComponent'
import { useNavigate } from 'react-router-dom'

interface ButtonAndProviderProps {
  goBackUrl: string;
  leadProvider: Provider;
  refObj: Employer | Job | Individual;

}

const ViewComponentSharedButtons = (props: ButtonAndProviderProps) => {
  const navigate = useNavigate();
  const {
    leadProvider,
    refObj,
    goBackUrl,
  } = props;

  return (
    <MobileContainerHeader>

      <IconWrapper
        className='go-back-button' 
        onClick={() => navigate(goBackUrl)}
        marginRight='10px'
      >
        <IconButton
          sx={mobilebackToStyling}
        >
          <ArrowBackIos
            sx={mobilebacktoIcon}
            viewBox='0 0 15 24'
          />
        </IconButton>
      </IconWrapper>

    <EmploymentSupportLeadCardComponent 
      name={getFullName(leadProvider)} 
      email={leadProvider?.email} 
      phone={leadProvider?.phone}
      displayBorder
      hideAvatar
      centered
    />


    <IconWrapper
      marginLeft='10px'
    >
      <IconButton
        onClick={() => navigate("edit", {state: refObj!})} 
        sx={mobilebackToStyling}
      >
        <EditOutlined 
          sx={mobilebacktoIcon}
        />
      </IconButton>
      </IconWrapper>

  </MobileContainerHeader>
  )
}

export default ViewComponentSharedButtons