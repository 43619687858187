import { useEffect, useState } from 'react';
import { Employer, Individual, Job } from '../components/typescript/types';
import store from '../store';


function useRedirectedState(pathId:string){
  const [redirectedState, setRedirectedState] = useState<any>();

 
  useEffect(()=>{
    if(pathId.startsWith('ind')) {
      const individual = store.getState().individuals.allIndividuals.find((individual:Individual) => individual.id === pathId);
      setRedirectedState((currState: Individual) => {
        return currState = individual! as Individual
      });
      console.log(pathId, individual);
    } else if(pathId.startsWith('job')) {
      const job = store.getState().jobs.allJobs.find((job:Job) => job.id === pathId);
      
      setRedirectedState((currState: Job) => {
        return currState = job! as Job
      });
      console.log({job});
    } else {
      
      const emp = store.getState().employers.employers.find((employer:Employer) => employer.id === pathId);

      setRedirectedState((currState: Employer) => {
        return currState = emp! as Employer
      });
      console.log({emp});
    }
  }, [pathId, store]);

  return{
    redirectedState
  }
}

export default useRedirectedState;
