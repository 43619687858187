import { MenuItem } from "@mui/material"
import { nanoid } from "@reduxjs/toolkit"
import { GroupBase } from "react-select"
import { SelectType } from "../components/typescript/types"


//Helpers to convert data to proper formatting for table views or form views

//Formats an array of {values: x, label: y} for MUI MenuItems 
export const displaySelectTypeOptions = (arr: SelectType[]) => {
  return arr.map((obj:{value:string, label:string}) => {
    return( <MenuItem key={nanoid(5)} value={obj.value}>{obj.label}</MenuItem>)
  })
}

// Formats an array into MUI MenuItem options { values: x labels: x}
export const displayStringOptions = (arr: string[]) => {
  return arr.map((str: string) => {
    return( <MenuItem key={nanoid(5)} value={str}>{str}</MenuItem>)
  })
}

//Will return a string or array of Tags converted converted from their lookup form
export const convertTagsToString = (arr:number[], asArray:boolean = true, TAGS: {value:number, label: string}[]): string[] | string => {

  let strArr: string[] = [];
  const maxDisplay = 10;
  if(arr === undefined){
    return [''];
  }
  if(arr.length === 1){
    let tagObj = (TAGS?.find((tag: {value:number, label:string}) => +tag.value === +arr[0]));
    let tag = tagObj !== undefined ? tagObj?.label : 'ERR_TagToString conversion failed';
    strArr.push(tag);
    return strArr;
  }


  arr.forEach((value:number, index)=>{
    if(index < maxDisplay ){
      
      let  tag = TAGS?.find((tag: {value:number, label:string}) => +tag.value === +value)!;
      if(tag !== undefined) strArr.push(tag?.label);

    }else{
      return
    }
  })
  
  return asArray ? strArr as string[] : strArr.join(", ") as string;
}

//Converts number[] (usually TAG constant) to react-select friendly {value, label} objects 
export function getPreviousTags(arr: number[], options: SelectType[]) {

  let results: GroupBase<SelectType>[] = [];
  if(arr === undefined) return [];
  arr.forEach((arrIndex: number) => {
    return results.push({
      label: options?.find( option => +option.value === arrIndex )?.label!,
      value: arrIndex?.toString(),
    } as unknown as GroupBase<SelectType>);
  });
  return results;
}

//Formats Schedule converting a string of 0's and 1's to day abbreviations.
export const displaySchedule = (stringArr:number[], t: (str:string)=>string) =>{
  let arr = stringArr.join('');
  let dayArr:string[]=[];
  //console.log(arr);
  if(arr===''){
    return t('na')
  }
  if(arr === '1111100'){
   dayArr.push(t('MonToFri'));
  }else if(arr ==='1111111'){
   dayArr.push(t('MonToSun'));
  }else{
    stringArr.forEach((day:number, index:number)=>{
      if(day === 1 && index === 0){
        dayArr.push(t('Mon'));
      }else if( day === 1 && index === 1){
        dayArr.push(t('Tues'));
      } else if( day === 1 && index === 2){
        dayArr.push(t('Wed'));
      }else if( day === 1 && index === 3){
        dayArr.push(t('Thur'));
      }else if( day === 1 && index === 4){
        dayArr.push(t('Fri'));
      }else if( day === 1 && index === 5){
        dayArr.push(t('Sat'));
      }else if( day === 1 && index === 6){
        dayArr.push(t('Sun'));
      }
    });
  }
  //console.log(dayArr);
  return dayArr.join(", ");
}

