import { Button, Typography } from "@mui/material";
import { OverviewContainer, OverviewTitle, OverviewRowItem, OverviewBadges } from "../../UI/StyledComponents/ViewStyledComponents";
import { Provider,Employer, NoteBullet, Job, Individual, BaseDetails } from "../../typescript/types";
import Icon from "../../../asssets/icons";
import Box from "@mui/system/Box";
import SanitizeHTML from "../../SharedComponents/SanitizeHTML";
import useTranslate from "../../../hooks/useTranslate";
import { useLocation, useNavigate } from "react-router-dom";
import { convertTagsToString, displaySchedule } from "../../../helpers/displayOptions";
import dayjs from "dayjs";
import 'dayjs/locale/en';
import { jobWidgetButton, tagsStyle } from "../../../helpers/styling";
import { IconStyling } from "../../UI/StyledComponents/RecommendationStyledComponent";
import { useEffect, useState } from "react";
import RecommendationsOverviewComponent from "../../RecommendationComponents/RecommendationOverviewComponent";
import { RootState } from "../../../store";
import { getTags } from "../../../store/keywordSlice";

import { useSelector } from "react-redux";
import { InlineContainer, PinImage } from "../../UI/StyledComponents/GlobalStyledComponents";
import { getEmployerById } from "../../../store/employerSlice";

type OverviewClassification = 'employer' | 'job' | 'individual';


interface OverviewProps {
  overViewOf: OverviewClassification,
  overViewObj?: Employer | Job | Individual,
}

const ICONS: any = {
  orgName: Icon.DetailedEmployer, //employer & shared props
  parentCompName: Icon.DetailedPOrg,
  industry: Icon.DetailedInd,
  address: Icon.DetailedLoc,     
  reqSkills: Icon.DetailedSkill,
  multiLocation: Icon.DetailedLoc2,
  pocFirstName: Icon.DetailedMain,
  pocPhone: Icon.DetailedPhone,
  pocEmail: Icon.DetailedEmail,
  tags: Icon.DetailedTags,  
  hourlyRate: Icon.DetailedWallet, //remaining job props 
  weeklyHrs: Icon.DetailedClock,
  daysAvailable: Icon.DetailedDay,
  idealStartDate: Icon.DetailedDate,
  isTrainingReq: Icon.DetailedTraining,
  areCertsReq: Icon.DetailedCert,
  isTrainingProvided: Icon.DetailedTraining2,
  jobCoachEnabled: Icon.DetailedJC,
  minRate: Icon.DetailedWallet, //remaining ind props
  email: Icon.DetailedEmail,
  phone: Icon.DetailedPhone,
  policeCheck: Icon.DetailedPCheck,
  creditCheck: Icon.DetailedCCheck,
  dob: Icon.Bday,
  preferredRoles: Icon.DetailedPlus,
  agencyId: Icon.CRM,
};


export const OverviewWidget = (props: OverviewProps) => {
  const { 
    overViewOf,
    overViewObj 
  } = props;

  const {state} = useLocation();
  const t = useTranslate();
  const [ overViewData, setOverviewData] = useState<string[][]>([]); //specific props you want to access for Individual/Employer/Individual
  const [data, setData] = useState<Employer | Job | Individual>(overViewObj!);

  //Gets prop names of object. 
  useEffect(() => {
    // console.warn(overViewObj);
    if(overViewObj !== undefined){
      setData(overViewObj!);
    }
  },[overViewObj])

  //Sets the items you'd like to display in overview, mapped, so order matters.
  useEffect( () => {
    let keyFilter: string[][] =[];
    
    if(overViewOf ==='job'){

      keyFilter =   [
        ['industry',  t('industry').toUpperCase()], 
        ['address',  t('address').toUpperCase()],
        ['minRate','maxRate', t('hourlyRate').toUpperCase() ], 
        ['weeklyHrs', t('wklyHrsLabel').toUpperCase()], 
        ['daysAvailable', t('schedule').toUpperCase()], 
        ['idealStartDate', t('prefStartDate').toUpperCase()], 
        ['isTrainingReq', t('trainingReq').toUpperCase()], 
        ['areCertsReq', t('certsReqLabel').toUpperCase()], 
        ['isTrainingProvided', t('trainingAvailLabel').toUpperCase()], 
        ['jobCoachEnabled', t('openToCoachSupportColumn').toUpperCase()], 
        ['reqSkills', t('reqSkills').toUpperCase()], 
        ['tags', t('keywords').toUpperCase() ]
      ]
      setOverviewData( currState => { return currState = [...keyFilter]});
      
    }else if(overViewOf === 'employer'){

      keyFilter =[
        ['orgName', t('orgName').toUpperCase()],
        ['parentCompName', t('parentOrgName').toUpperCase()],
        ['industry', t('industry').toUpperCase()],
        ['address', t('address').toUpperCase()],
        ['reqSkills', t('reqSkills').toUpperCase()],
        ['multiLocation', t('multiLocation').toUpperCase()],
        ['pocFirstName', 'pocLastName', t('mainCname').toUpperCase()],
        ['pocPhone', t('mainCphone').toUpperCase()],
        ['pocEmail', t('mainCemail').toUpperCase()],
        ['tags', t('keywords').toUpperCase()]
      ];
      setOverviewData( currState => { return currState = [...keyFilter]});

    }else{

      keyFilter =[
        ['daysAvailable', t('daysAvail').toUpperCase()],
        ['preferredRoles',t('prefRoles').toUpperCase()],
        ['minRate', t('hourlyRate').toUpperCase()],
        ['email', 'EMAIL'],
        ['phone', t('phoneNumber').toUpperCase()],
        ['address', t('address').toUpperCase()],
        ['dob', t('dob').toUpperCase()],
        ['policeCheck', t('popoCheck').toUpperCase()],
        ['creditCheck', t('creditCheck').toUpperCase()],
      ];
      setOverviewData( currState => { return currState = [...keyFilter]});

    }
  },[overViewOf, overViewObj]);

  return(
    <>
    { 
      overViewData?.map( (listItem: string[], index: number) => {
        return(
          <OverViewRowItem 
            key={JSON.stringify(listItem)}
            obj={data}
            propAndTitle= {listItem}
            icon = {ICONS[listItem[0]]}
            overviewOf = {overViewOf}
          />
        )
      })

    }
    </>
  )
}

export const RecordDescriptionWidget = (props: { recordDetails: string}) => {
  const { recordDetails } = props;
  const t = useTranslate();

  return(
    <>
      <Typography variant='h2'>{t('employerViewHeader3').toUpperCase()}</Typography>
      <section>
        <div>
          <em>
            { 
              recordDetails ? 
              <SanitizeHTML htmlString={recordDetails}/> : 
              'No details available for this employer...'
            }
          </em>
        </div>
      </section>
    </>
  )
}

export const EmployerAssociatedJobsWidget = (props: {employerSpecificJobs: Job[], showTitle?:boolean}) => {
  const { employerSpecificJobs} = props;
  const t = useTranslate();

  return(
    <>
     {props.showTitle && <Typography variant='h2'>{t('jobNav').toUpperCase()}</Typography>}
      <section>
        { 
          employerSpecificJobs.length === 0 ? 
          <div className='listing-empty'>
            <p className='lisiting-info'>Currently no jobs listed with this employer.</p>
          </div>
          :
          employerSpecificJobs.map((job) => { return <JobListingComponent key={job.id} job={job}/>})
        }
      </section>
    </>
  )
}

export const EmployerNotesWidget = (props: { employer: Employer, showTitle?:boolean}) =>{
  const {employer } = props;
  const t = useTranslate();

  return(<>
    {props.showTitle && <Typography variant='h2'>{t('notesComments').toUpperCase()}</Typography>}
    <section>
      {employer &&
        (JSON.parse(employer.notes as unknown as string)).length === 0  
        ?
        <div className='listing-empty'>
          <p className='lisiting-info'>{t('emptyNotesMsgEmployerView')}</p>
        </div>
        :
        employer ?
        (JSON.parse(employer?.notes as unknown as string))
          .map((note: NoteBullet, index:number) => {
            return (
              <NoteBulletComponent key={`${index}-${employer.createdAt}`} employer={employer} note={note} index={index}/>
            )
        }) : null
      }
    </section>
  </>)
}

export const JobListingComponent = (props:{job:Job}) => {
  const {job} = props;
  const navigate = useNavigate();
  const jobURI = `/jobs/${encodeURIComponent(job.employerOrganizationName)}/${job.id}`;
  const t = useTranslate();
  
  return(
      <div className='listing' key={job.id}>
        <div className='listing-info'>
          {job.title} <span className={`${job.isJobActive ? 'open-badge' : 'closed-badge'} active-badge`}>{job.isJobActive ? t('open') : t('closed')}</span>
        </div>
        <Button sx={jobWidgetButton}  variant='text' onClick={()=>{navigate(jobURI, {state: job})}}>{t('viewJobBtn')}</Button>
      </div>
  );
}   

export const NoteBulletComponent = (props:{employer: Employer, note: NoteBullet, index:number}) => {
  const {employer, note, index} = props;
  const t = useTranslate();
  const isEnglishLocale = (localStorage.getItem('language')  || 'en') === 'en' ? true : false;

  return(
    <Box className='note-parent inline full-width listing'
      key={`${employer.id}-${index}`}
    >
      <PinImage className='note-logo' src={Icon.Notes} alt="note-bullet" />
      <Box className="detailed-note">
        <p className='note-content'>{note.body}</p>
        <p className='note-meta'>{t('addedBy')} {note.providerName} {t('on')} { isEnglishLocale ? dayjs(note.ts).locale('en').format('HH:mm MMM/DD/YYYY') : dayjs(note.ts).locale('fr').format('HH:mm MMM/DD/YYYY')}</p>
      </Box>
    </Box>
  )
};

const OverViewRowItem = (props: {obj: Job | Individual | Employer, propAndTitle: string[], icon:string, overviewOf:string}) => {

  const { 
    obj, 
    propAndTitle, 
    icon, 
    overviewOf 
  } = props;

  const t = useTranslate();
  const TAGS = useSelector((state:RootState) => getTags(state, t));

  const displayJobData = (objTyped?: Job) => {
    return (
      <> 
      {
      propAndTitle[0]! === 'tags' 
      ? 
        <Box sx={tagsStyle}>
          {(convertTagsToString((objTyped as Job)![propAndTitle[0] as keyof Job] as number[], true, TAGS?.all) as string[])
            .map((tag, index) => {
              return<OverviewBadges key={`${index}-${tag}`}>{tag}</OverviewBadges>
            })
          } 
        </Box>
        :
        propAndTitle[0] === 'daysAvailable' 
        ?
        <Typography
          variant='body1'
          className='detail-data'
        > { displaySchedule((objTyped as Job)[propAndTitle[0] as keyof Job]! as number[], t) }
        </Typography>
          :
        propAndTitle[0] === 'idealStartDate' 
        ?
        <Typography
          variant='body1'
          className='detail-data'
        > { dayjs((objTyped as Job)[propAndTitle[0] as keyof Job] as string).format('MMM DD, YYYY') }
        </Typography>
        :
        (propAndTitle[0] === 'isTrainingReq' || propAndTitle[0] === 'areCertsReq' || propAndTitle[0] === 'isTrainingProvided' ||  propAndTitle[0] === 'jobCoachEnabled' ) 
        ?
        <Typography
          variant='body1'
          className='detail-data'
        > { `${(objTyped as Job)[propAndTitle[0] as keyof Job]  ? 'Yes' : 'No' }` }
        </Typography>
      
        : 
        propAndTitle[0] === 'minRate' 
        ?
        <Typography
          variant='body1'
          className='detail-data'
        > {`$${Number((objTyped as Job)['minRate'])?.toFixed(2)} - $${Number((objTyped as Job)['maxRate'])?.toFixed(2)}/hour` }
        </Typography>
        : 
        propAndTitle[0] === 'weeklyHrs' 
        ?
        <Typography
          variant='body1'
          className='detail-data'
        > 
          { `${(objTyped as Job)[propAndTitle[0] as keyof Job]} hrs/wk`}
        </Typography>
        :
        <Typography
          variant='body1'
          className='detail-data'
        > 
          {`${(objTyped as Job)[propAndTitle[0] as keyof Job]}`} 
        </Typography>
      }

      </> 
    )
  }

  const displayIndividualData = (objTyped?: Individual) => {
    return (
      <> 
      { propAndTitle[0] === 'daysAvailable' 
        ?
        <Typography
          variant='body1'
        >
          {`${displaySchedule(objTyped![propAndTitle[0] as keyof Individual] as number[], t)}`}

        </Typography> 
        :
        (propAndTitle[0] === 'dob' || propAndTitle[0] === 'policeCheck' || propAndTitle[0] === 'creditCheck' ) 
        ? 
        <Typography
          variant='body1'
        >
          { dayjs(objTyped![propAndTitle[0] as keyof Individual] as string).format('MMM DD, YYYY') === 'Invalid Date'  
            ?
            t('none') 
            :
            `${dayjs(objTyped![propAndTitle[0] as keyof Individual] as string).format('MMM DD, YYYY') }` 
          }
        </Typography>
        :  
        propAndTitle[0] === 'minRate' 
        ? 
        <Typography
          variant='body1'
        >
          {`$${Number(objTyped![propAndTitle[0] as keyof Individual])?.toFixed(2)}/hour` }
        </Typography>
        : 
        <Typography 
          variant = 'body1' 
        >
          {`${objTyped![propAndTitle[0] as keyof Individual]}` || t('none') } 
        </Typography>
      }
      </>
    )
  }

  const displayEmployerData = (objTyped?: Employer) => {
    return(
      <>
      {
       propAndTitle[0] === undefined ? 
       <></>
       :
       propAndTitle[0] === 'tags' 
       ? 
       <Box sx={tagsStyle}>{
         (convertTagsToString(objTyped![propAndTitle[0] as keyof Employer]! as number[], true, TAGS?.all) as string[])
          .map((tag, index)=> {
            return<OverviewBadges className='details-tag' key={`${index}-${tag}`}>{tag}</OverviewBadges>}) 
       }
       </Box>
       :
       propAndTitle[0] === 'pocFirstName' ? 
        <Typography
          variant='body1'
          className='detail-data'
        > 
        {`${objTyped![propAndTitle[0] as keyof Employer]! } ${objTyped![propAndTitle[1] as keyof Employer]!}`}
      </Typography>
       :
       propAndTitle[0] === 'multiLocation' ?
        <Typography
          variant='body1'
          className='detail-data'
        > 
        {  objTyped![propAndTitle[0] as keyof Employer]! ?  t('yes') :  t('no') }
        </Typography>
       : 
       <Typography
          variant='body1'
          className='detail-data'
        > 
          {`${objTyped![propAndTitle[0] as keyof Employer]!}`}
        </Typography>
      }
      </>
    )
  }

  return(
    <OverviewRowItem
      sx={{
        '&:last-of-type' : {
          alignItems: overviewOf === 'individual' ? 'center' : 'flex-start',
          marginTop: overviewOf === 'individual' ? '0px' : '10px'
        }
      }}
    >
      <IconStyling>
        <img 
          src={icon} 
          alt='overview-icon' 
        />
      </IconStyling>
      <Box>
        <OverviewTitle 
          variant='h4'
          lineHeight='1.3 !important'  
        >
          { propAndTitle.length === 2 ? propAndTitle[1] : propAndTitle.length === 3 ? propAndTitle[2] : '🚩🚩🚩' } 
        </OverviewTitle>

        { 
          overviewOf === 'employer' ? displayEmployerData(obj as Employer) 
          : 
          overviewOf === 'job' ? displayJobData(obj! as Job) 
          :
          displayIndividualData(obj! as Individual)
        }
      </Box>
    </OverviewRowItem> 
  )
}
