import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Job } from '../components/typescript/types';
import { getJobStatus } from '../store/jobSlice';
import store from '../store/index';

const useJobQueryHook = (path:string) => {
  const jobStatus = useSelector(getJobStatus);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [jobsFetched, setJobsFetched] = useState<boolean>(false);

  useEffect(()=>{
    if(jobStatus==='succeeded'){
      const job: Job | null = store.getState().jobs.allJobs.find((job:Job) => job.id === path) || null;
      setSelectedJob((currState: Job | null) => {return currState = job! });
      setJobsFetched(currState => { return currState = true});
    }

  },[jobStatus, setSelectedJob, setJobsFetched, path]);


  return {
    selectedJob,
    jobsFetched
  }
}


export default useJobQueryHook;