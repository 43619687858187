import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from ".";

import { IndividualFormData } from "../components/IndividualComponents/IndividualCreateComponent";
import { EmployerMetrics, Individual, JobMetrics } from "../components/typescript/types";
import { getSortedRankedKeyValuePair } from "../helpers/helperFunctions";

interface IndividualSlice {
  allIndividuals: Individual[];
  individualsBySKill: Individual[];
  individualsByInterest: Individual[];
  status: string;
  error:string | null;
  IEM: EmployerMetrics[];
  IJM: JobMetrics[];
  myOrgId: string;
}


export const fetchIndividuals = createAsyncThunk('individual/fetchIndividuals', async (params:{orgId?:string, token:string}) => {
  return axios
  .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/individual/${params.orgId!}`, {
    headers: {
      'Authorization' : `Bearer ${params.token}`
    }
  })
  .then((response)=>{
    return response.data;
  })
  .catch((err)=>{
    return err.message;
  });
});

export const postIndividual = createAsyncThunk('individual/postIndividual', async (params: {individualObj: IndividualFormData, token:string}) => {
  return axios
  .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/individual/create`, params.individualObj, {
    headers: {
      'Authorization' : `Bearer ${params.token}`
    }
  })
  .then(response => {
    console.info('Reducer Indv Post:', response);
    return response.data;
  }).catch((err)=>{
    return err.message;
  })
});

export const updateIndividual = createAsyncThunk('individual/updateIndividual', async (params: {individualObj: IndividualFormData, token:string}) => {
  return axios
  .put(`${process.env.REACT_APP_SERVER_URL}/api/v1/individual/update`, params.individualObj!, {
    headers: {
      'Authorization': `Bearer ${params.token}`
    }
  })
  .then(response => {
    console.info('Reducer Indv Update:', response);
    return response.data;
  })
  .catch((err) => {
    return err.message;
  })
});

export const deleteIndividualFile = createAsyncThunk('individual/deleteFile', async(params: {individualId:string, fileRef: string, updatedFileRef:string[], provId:string, isMulti:boolean, isCerts:boolean, token:string}) => {
  return axios
  .put(`${process.env.REACT_APP_SERVER_URL}/api/v1/individual/update/file`, {
      individualId: params.individualId, 
      fileRef: params.fileRef,  //deleted files
      updatedFileRef: params.updatedFileRef, //non-deleted files only for file_attachments field.
      provId: params.provId, 
      isMulti: params.isMulti, 
      isCerts: params.isCerts, 
      token: params.token
    },{
    headers: {
    'Authorization': `Bearer ${params.token}`
    }
  })
  .then((response) => {
    return response.data;
  })
  .catch(err => {
    return err.message;
  })
});

export const deleteIndividualRecord = createAsyncThunk('individual/deleteRecord', async (params: { deletePayload: { creator:string, lookupId: string }, token:string}) => {
  console.info('Reducer delete individual', params.deletePayload);

  return axios({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/individual/delete`,
    data: params.deletePayload,
    headers: {'Authorization': `Bearer ${params.token}`}
  }).then((res) => {
    return res.data;
  }).catch(err => {
    return err.message;
  });
});

const initialState: IndividualSlice = {
  allIndividuals: [],
  individualsBySKill: [],
  individualsByInterest:[],
  status: 'idle',
  error: null,
  IEM: [],
  IJM: [],
  myOrgId: '',
}

const individualSlice = createSlice({
  name:'individuals',
  initialState,
  reducers: {
    individualDeleted:{
      reducer( state: IndividualSlice, action: PayloadAction<{individualId:string}>){
        return{
          ...state,
          allIndividuals: state.allIndividuals.filter(individual => individual.id !== action.payload.individualId)
        }
      },
      prepare:(individualId: string) => {
        return {
          payload:{ individualId }
        }
      }
    },
    socketIndividualPayload : (state:IndividualSlice, action: PayloadAction<any>) => {
      console.info('Individual Socket',action.payload);
      const {individual, isNew} = action.payload;
      getIndividual();
      console.log(current(state))

      function getIndividual(){
        if(individual[0].leadProviderOrgId === state.myOrgId && isNew){
          console.log('new individual!');
          void state.allIndividuals.push(individual[0]);
        }else if( individual[0].leadProviderOrgId === state.myOrgId && !isNew){
          console.log('update individual!');
          let index = state.allIndividuals.findIndex(indv => indv.id === individual[0].id);
          console.log('index found! ', index);
          state.allIndividuals[index] = {...individual[0]};
          console.info(state.allIndividuals);
          state.allIndividuals = [...state.allIndividuals];
        }else{
          console.log('do nothing to individual!')
        }
      }
    },
    socketIndividualAttachmentUpdate : (state: IndividualSlice, action: PayloadAction<{data:Individual[]}>) => {
      console.log('In Slice!')
      console.info('Here is the stuff', action.payload);
      const updatedIndividual = action.payload.data[0];
      const myOrgId = state.allIndividuals[0].leadProviderOrgId;
      if(myOrgId === updatedIndividual.leadProviderOrgId){
        console.log("UPDATE INDIVIDUAL RECORD")
        const index = state.allIndividuals.findIndex(ind => ind.id === updatedIndividual.id);
        state.allIndividuals[index] = {...updatedIndividual};
        state.allIndividuals = [...state.allIndividuals];
      }
    },
    setIndividualsBySkill: (state:IndividualSlice, action: PayloadAction<any>) => {
      const {totalCount} = action.payload;
      console.log(action.payload);
      console.log('totalCount', totalCount)
      let indBySkill = state.allIndividuals.filter( (ind: Individual) => { 
        console.log(current(ind))
        return totalCount <=2 ? +ind.skills.length === +totalCount : ind.skills.length > 2})
      console.log(indBySkill);
      state.individualsBySKill = [...indBySkill];
    },
    setIndividualsByInterest: (state:IndividualSlice, action: PayloadAction<any>) => {
      const {totalCount} = action.payload;
      console.log(action.payload);
      console.log('totalCount', totalCount)
      let indByInterests = state.allIndividuals.filter( (ind: Individual) => { 
        console.log(current(ind))
        return totalCount <=2 ? +ind.interests.length === +totalCount : ind.interests.length > 2})

      state.individualsByInterest = [...indByInterests];
    }
  },
  extraReducers(builder) {
    builder
    .addCase(fetchIndividuals.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(fetchIndividuals.fulfilled, (state, action) => {
      state.status = 'succeeded';
      const loadedIndividuals = action.payload.data.individuals.map((individual: Individual) => {
        return individual;
      });
      state.allIndividuals = [...loadedIndividuals];
      state.myOrgId = action.payload.data.myOrgId;
    })
    .addCase(fetchIndividuals.rejected, (state, action) => {
      state.status = 'error';
      state.error = action.error.message!;
    })
    .addCase(postIndividual.fulfilled, (state,action)=>{
      // state.status = 'posted';
      // console.info('postIndivid xReducer payload:', action.payload);
      // state.allIndividuals = [...state.allIndividuals];
    })
    .addCase(updateIndividual.fulfilled, (state,action)=>{
      // state.status = 'updated';
      // console.info('updatedIndivid xReducer payload: ', action.payload);
      // const updateIndex = state.allIndividuals.findIndex((individual: Individual) => individual.id === action.payload.data.individuals[0].id);
      // state.allIndividuals[updateIndex] = action.payload.data.individuals[0];
      // state.allIndividuals = [...state.allIndividuals];
    })
    .addCase(deleteIndividualFile.fulfilled, (state, action) => {
      console.log(action.payload);
      const updateIndex = state.allIndividuals.findIndex(ind => ind.id === action.payload.indId);
      console.log(updateIndex);
      if(action.payload.isMulti === false){
        state.allIndividuals[updateIndex].fileAttachments = action.payload.updatedAttachments;
        state.allIndividuals = [...state.allIndividuals];
      }else{
        if(action.payload.isCerts){
          state.allIndividuals[updateIndex].certificationAttachments = ['Empty'];
        }else{
          state.allIndividuals[updateIndex].referenceAttachments = ['Empty'];
        }
        state.allIndividuals = [...state.allIndividuals];
      }
    })
    .addCase(deleteIndividualRecord.fulfilled, (state,action) => {
      console.info('individual deleted from system', action.payload.data);
    });
  } 
});

export const {socketIndividualPayload, individualDeleted, socketIndividualAttachmentUpdate, setIndividualsBySkill, setIndividualsByInterest} = individualSlice.actions;
export const getAllIndividuals = (state:{individuals: IndividualSlice}) => state.individuals.allIndividuals;
export const getSortedIndividualBySkill = (state:{individuals: IndividualSlice}) => state.individuals.individualsBySKill;
export const getSortedIndividualByInterests = (state:{individuals: IndividualSlice}) => state.individuals.individualsByInterest;
export const getIndividualSkillCount = ((state:{individuals: IndividualSlice}, filterByStatus: string) => {

  let filterStatus = filterByStatus === 'all' ? (true || false) : filterByStatus.startsWith('open') ? true : false;

  const filteredSkills = filterByStatus === 'all' ? state.individuals.allIndividuals.map((ind: Individual) => {
    return ind.skills
  }).flat() : state.individuals.allIndividuals.filter((ind:Individual) => ind.status === filterStatus).map((ind: Individual) => {
    return ind.skills
  }).flat();

  const results = getSortedRankedKeyValuePair(filteredSkills)
  return results;
});

export const getIndividualInterestCount = ((state: {individuals: IndividualSlice}, filterByStatus: string) => {
  
  let filterStatus = filterByStatus === 'all' ? (true || false) : filterByStatus.startsWith('open') ? true : false;

  const filteredInterests = filterByStatus === 'all' ? state.individuals.allIndividuals.map((ind: Individual) => {
    return ind.interests
  }).flat()  : state.individuals.allIndividuals.filter((ind:Individual) => ind.status === filterStatus).map((ind: Individual) => {
    return ind.interests
  }).flat();

  const results = getSortedRankedKeyValuePair(filteredInterests)
  return results;
});
export const selectIndividualById = (state: RootState, queryID:string) => {return state.individuals.allIndividuals.find(individual => individual.id === queryID)} 
export const getIndividualStatus = (state:{individuals: IndividualSlice}) => state.individuals.status;
export const getIndividualError = (state: {individuals: IndividualSlice}) => state.individuals.error;


export const getIndividualsCount = ( state: {individuals: IndividualSlice}) =>{
 return state.individuals.allIndividuals.reduce((count:number, individual:Individual) => {
  return individual.status ? count + 1 : count;
 },0);
}

export default individualSlice.reducer;
