import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Icon from '../../asssets/icons';
import { convertTagsToString, displaySchedule } from '../../helpers/displayOptions';
import useTranslate from '../../hooks/useTranslate';
import { RootState } from '../../store';
import { getTags } from '../../store/keywordSlice';
import IndividualAttachments from '../IndividualComponents/IndividualAttachments';
import IndividualEmploymentTables from '../IndividualComponents/IndividualEmploymentTables';
import IndividualNotes from '../IndividualComponents/IndividualNotes';
import IndividualSkillsInterestsRestrictions from '../IndividualComponents/IndividualSkillsInterestsRestrictions';
import JobDescription from '../JobComponents/JobDescription';
import JobNotes from '../JobComponents/JobHowToApply';
import { BaseDetails, Employer, Individual, Job } from '../typescript/types';
import { IconStyling } from '../UI/StyledComponents/RecommendationStyledComponent';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { OverviewBadges, RecommendationDetailsContainer } from '../UI/StyledComponents/ViewStyledComponents';
import { tagsStyle } from '../../helpers/styling';
import HowToApply from '../JobComponents/JobHowToApply';
import useDetectResize from '../../hooks/useDetectResize';

interface PropsRecommDetails{
  selectedItem: BaseDetails;
  userType:string;
  isVisible:boolean;
}


const ICONS: any = {
  address: Icon.DetailedLoc,     
  minRate: Icon.DetailedWallet,
  weeklyHrs: Icon.DetailedClock,
  daysAvailable: Icon.DetailedDay,
  idealStartDate: Icon.DetailedDate,
  isTrainingReq: Icon.DetailedTraining,
  areCertsReq: Icon.DetailedCert,
  isTrainingProvided: Icon.DetailedTraining2,
  jobCoachEnabled: Icon.DetailedJC,
  industry: Icon.DetailedInd,
  reqSkills: Icon.DetailedSkill,
  tags: Icon.DetailedTags,  
  preferredRoles: Icon.DetailedPlus,
  email: Icon.DetailedEmail,
  phone: Icon.DetailedPhone,
  dob: Icon.Bday,
  policeCheck: Icon.DetailedPCheck,
  creditCheck: Icon.DetailedCCheck,
  agencyId: Icon.CRM ,
  pocFirstName: Icon.DetailedMain,
  pocPhone: Icon.DetailedEmail,
  pocEmail: Icon.DetailedPhone,
  multiLocation: Icon.DetailedLoc2
};

const RecommendationsOverviewComponent = (props: PropsRecommDetails) => {
  const { 
    selectedItem, 
    userType, 
    isVisible
  } = props;
  
  const [keyList, setKeyList] = useState<string[]>([]);
  const [ userTypeKeys, setUserTypeKeys] = useState<string[]>([]);
  const {isMobile, isTablet } = useDetectResize();
  const t = useTranslate();
  
  const TITLES: any = {
    j_address: t('address').toUpperCase(),
    j_minRate: t('hourlyRate').toUpperCase(),
    j_weeklyHrs: t('wklyHrsLabel').toUpperCase(),
    j_daysAvailable: t('schedule').toUpperCase(),
    j_idealStartDate: t('prefStartDate').toUpperCase(),
    j_isTrainingReq: t('trainingReq').toUpperCase(),
    j_areCertsReq: t('certsReqLabel').toUpperCase(),
    j_isTrainingProvided: t('trainingAvailLabel').toUpperCase(),
    j_jobCoachEnabled: t('openToCoachSupportColumn').toUpperCase(),
    j_industry: t('industry').toUpperCase(),
    j_reqSkills: t('reqSkills').toUpperCase(),
    j_tags: t('keywords').toUpperCase(),
    j_desc: t('howToApply').toUpperCase(),
    i_daysAvailable: t('daysAvail').toUpperCase(),
    i_preferredRoles: t('prefRoles').toUpperCase(),
    i_minRate: t('hourlyRate').toUpperCase(),
    i_email: 'EMAIL',
    i_phone: t('phoneNumber').toUpperCase(),
    i_address: t('address').toUpperCase(),
    i_dob: t('birthDate').toUpperCase(),
    i_policeCheck: t('popoCheck').toUpperCase(),
    i_creditCheck: t('creditCheck').toUpperCase(),
    i_agencyId: 'CRM ID',
    e_address: t('address').toUpperCase(),
    e_pocFirstName: t('mainCname').toUpperCase(),
    e_pocPhone: t('mainCphone').toUpperCase(),
    e_pocEmail: t('mainCemail').toUpperCase(),
    e_industry: t('industry').toUpperCase(),
    e_multiLocation: t('multiLocation').toUpperCase(),
    e_reqSkills: t('reqSkills').toUpperCase(),
    e_tags: t('keywords').toUpperCase(),
  };
  
  useEffect(() => {
    if(selectedItem !== null){
      const objectKeys = Object.keys(selectedItem!);
      setKeyList(currState => currState = [...objectKeys]);
      console.info('keys | selectedItem  | userType ', {objectKeys, selectedItem, userType} );
    }    
  }, [selectedItem]);
  
  useEffect(()=>{
    let keyFilter: string[] =[];
    if(userType ==='job'){
      keyFilter =   ["address", "minRate", "maxRate", "weeklyHrs", "daysAvailable", "idealStartDate", "isTrainingReq", "areCertsReq", "isTrainingProvided", "jobCoachEnabled", "industry", "reqSkills", "tags"];
      setUserTypeKeys( currState => { return currState = [...keyFilter]});
    }else if(userType === 'employer'){
      keyFilter =['address','pocFirstName','pocPhone','pocEmail','industry','multiLocation','reqSkills','tags'];
      setUserTypeKeys( currState => { return currState = [...keyFilter]});
    }else{
      keyFilter =['daysAvailable','preferredRoles','minRate','maxRate','email','phone','address','dob','policeCheck','creditCheck','agencyId'];
      setUserTypeKeys( currState => { return currState = [...keyFilter]});
    }
  },[userType])

  const summaryComponent = () => { 
    const userTypePrefix = userType === 'job' ? 'j' : userType === 'employer' ? 'e' : 'i';
    return userTypeKeys.map((keyId: string, index ) => {
      if(keyList.includes(keyId) && keyId !== 'maxRate'){
        return (
          <DetailTicket 
            key={`${keyId}=${index}`} 
            obj={selectedItem!} 
            property={keyId} 
            title={TITLES[`${userTypePrefix}_${keyId}`as keyof any]} 
            icon={ICONS[keyId as keyof any]} 
            userType={userType} 
          />
        )
      }
      return <div key={`${keyId}=${index}`}></div>
    })
  }

  return (
    isVisible ? 
    <RecommendationDetailsContainer 
      className='scrollable-hidden'
    >
        {summaryComponent()}
        {
          (userType === 'individualsToJob' || userType === 'individualsToEmployer') 
          && 
          <>
            <IndividualEmploymentTables individual={selectedItem as Individual}/>
            <IndividualSkillsInterestsRestrictions individual={selectedItem as Individual}/>
            <IndividualNotes individual={selectedItem as Individual} showTitle={(isMobile || isTablet) ? false : true}/>
            <IndividualAttachments individual={selectedItem as Individual} showTitle={(isMobile || isTablet) ? false : true}/>
          </>
        }
        {
          (userType === 'job') 
          && 
          <>
          <Box
            margin='20px 0'
          >
            <JobDescription job={selectedItem as Job} />
          </Box>
          <Box
           margin='20px 0'
          >
            <HowToApply job={selectedItem as Job} showTitle={true} />
          </Box>
          </>
        }
    </RecommendationDetailsContainer>
    :
    <RecommendationDetailsContainer className='details-cover hide-scroll'>
      {/* Show nothing here, just style it if you want. */}
    </RecommendationDetailsContainer>
  )
}



export default RecommendationsOverviewComponent;

const DetailTicket = (props: {obj: Job | Individual | Employer, property: string, title:string, icon:string, userType:string}) => {
  /* annndd this is where I fail at typescript. */
  const { 
    obj, 
    property, 
    title, 
    icon, 
    userType } = props;
  const t = useTranslate();
  const TAGS = useSelector((state:RootState) => getTags(state, t));

  const displayJobData = (objTyped: Job) => {
    return (
      <> 
      {
        property === 'tags' 
        ? 
        <Box sx={tagsStyle}>
        {
        (convertTagsToString((objTyped as Job)[property as keyof Job] as number[], true, TAGS?.all) as string[])
          .map((tag, index) => {
            return<OverviewBadges key={`${index}-${tag}`}>{tag}</OverviewBadges>
          })} 
        </Box>
        :
        property === 'daysAvailable' 
        ?
        <Typography variant='body1'>  
          {displaySchedule((objTyped as Job)[property as keyof Job] as number[], t)}
        </Typography>
        :
        property === 'idealStartDate' 
        ? 
        <Typography variant='body1'>  
          {dayjs((objTyped as Job)[property as keyof Job] as string).format('MMM DD, YYYY') }
        </Typography>
        :
        (property === 'isTrainingReq' || property === 'areCertsReq' || property === 'isTrainingProvided' ||  property === 'jobCoachEnabled' ) 
        ? 
        <Typography variant='body1'>  
         { (objTyped as Job)[property as keyof Job] ? 'Yes' : 'No' }
        </Typography>
        : 
        property === 'minRate' 
        ?
        <Typography variant='body1'>  
         {`$${Number((objTyped as Job)['minRate'])?.toFixed(2)} - $${Number((objTyped as Job)['maxRate'])?.toFixed(2)}/hour` }
        </Typography>
        : 
        property === 'weeklyHrs' 
        ? 
        <Typography variant='body1'>  
          {`${(objTyped as Job)[property as keyof Job]} hrs/wk`}
        </Typography>
        :
        <Typography variant='body1'> 
          {`${(objTyped as Job)[property as keyof Job]}`}
        </Typography>
      }
      </> 
    )
  }

  const displayIndividualData = (objTyped: Individual) => {
    return (
      <Typography variant='body1'> 
      { 
        property === 'daysAvailable' 
        ? 
        displaySchedule(objTyped[property as keyof Individual] as number[], t)
        :
        (property === 'dob' || property === 'policeCheck' || property === 'creditCheck' ) 
        ? 
        ( dayjs(objTyped[property as keyof Individual] as string).format('MMM DD, YYYY') === 'Invalid Date' 
        ? 
        t('none') 
        : 
        dayjs(objTyped[property as keyof Individual] as string).format('MMM DD, YYYY') )
        :  
        property === 'minRate' ? `$${Number(objTyped[property as keyof Individual])?.toFixed(2)}/hour` 
        : 
        `${objTyped[property as keyof Individual]}`
      
      }
      </Typography>
    )
  }

  const displayEmployerData = (objTyped: Employer) =>{
    return(
      <>{
       property === 'tags' 
       ? 
       <Box sx={tagsStyle}>{
         (convertTagsToString(objTyped[property as keyof Employer] as number[], true, TAGS?.all) as string[])
          .map((tag, index)=> {
            return<OverviewBadges className='details-tag' key={`${index}-${tag}`}>{tag}</OverviewBadges>}) 
       }
       </Box>
       :
       property === 'pocFirstName' ? 
      <Typography variant='body1'>
        {`${objTyped['pocFirstName']} ${objTyped['pocLastName']}`}
      </Typography>
       :
       property === 'multiLocation' ?
      <Typography variant='body1'>
        {objTyped[property as keyof Employer] ?  t('yes') : t('no') }
      </Typography>
       : 
       <Typography variant='body1'>
        {`${objTyped[property as unknown as keyof Employer]}`}
       </Typography>
       
        
      }
      </>
    )
  }

  return(
    <>
     {  <InlineContainer
          alignItems={'flex-start'}
          marginTop='10px'
        >
        <IconStyling>
          <img 
            src={icon} 
            alt={property} 
          />
        </IconStyling>
        <Box 
          className='content full-width'
        >
          <Typography 
            variant='h4'
          >
            {title}
          </Typography>
          <Box
           className='detail-data'
          >
            { 
              userType === 'job' 
              ? 
              displayJobData(obj as Job) 
              :
              userType === 'employer' 
              ?
              displayEmployerData(obj as Employer) 
              : 
              displayIndividualData(obj as Individual)
            }
          </Box>
        </Box>
      </InlineContainer> }
    </>
  )
}

