import loadingAnimation from '../../../asssets/nike-animation.gif'

const CheckMark = () => {
  return (
    <img 
      className='check-gif'
      src={loadingAnimation} alt='gif-checkmark'
    />
  )
}

export default CheckMark