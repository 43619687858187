import React, { useContext } from 'react'
import { TranslationContext } from '../transtlation/translationContext'

const useTranslate = () => {
    const context = useContext(TranslationContext)

    return (key: string) => {
        const translation = context?.find((translation: any) => translation.key === key)?.value
        const fallback = '🚩🚩🚩';
        return translation || fallback 
    }
}

export default useTranslate