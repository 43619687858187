import { Box, Button, IconButton, Tooltip } from '@mui/material';
import React from 'react'
import { Controller } from 'react-hook-form';
import Icon from '../../asssets/icons';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Control} from "react-hook-form";

const UploadMultiFileComponent = (props: {text: string, filesArr: string[], removeHandlerVal:number, maxFiles:number, controllerName: string, fileSetState: (value: React.SetStateAction<string[]>) => void, removeFileHandler: (index:number) => void, control: Control<any,any>, canDelete:boolean }) =>{
  const {
    text, 
    filesArr, 
    removeHandlerVal, 
    fileSetState, 
    removeFileHandler, 
    maxFiles, 
    controllerName, 
    control, 
    canDelete 
  } = props;

  return (  
    filesArr && 
    <Box className="blue-stitch inline-a file-upload">

    <Button 
      variant="text" 
      component="label"
      disabled={!canDelete || filesArr![0]?.startsWith('https')}
    >
    <Tooltip 
      title={filesArr[0]?.startsWith('https://') ? ( filesArr.map(file=> file.split('-').pop()) ).join() : filesArr.join(', ')}
      placement='top'
    >
      {
        (filesArr[0].toLowerCase() === 'empty' || filesArr[0] === '' || filesArr[0] === null || filesArr[0] === undefined) 
        ?
        <div className="inline-a">
          <img src={Icon.AttachmentIcon} alt='icon'/>
          {'+ '+text}
        </div>
        :

          <p className="text-overflow vertically-stack">
            {`${ filesArr.length } ${filesArr.length === 1 ? text : text+'s'} attached`}
          </p>
      }
    </Tooltip>
    <Controller
      name={controllerName as any}
      control={control}
      render={({ field }) => (
          <input
            type="file"
            name={controllerName as any}
            onChange={(e) => {
              if(e.target.files!.length > maxFiles) return alert(`A maximum of ${maxFiles} files can be uploaded`);
              field.onChange(e.target.files!);
              fileSetState((currState) => { 
                let newArr = [...currState];
                if(e.target.files !== undefined){
                  for(let i=0; i<e.target.files!.length; i++){
                    newArr[i] = e.target.files![i]?.name;
                  }
                  return newArr;
                }
                return newArr;
              });

            }}
            multiple={true}
            hidden
            disabled={!canDelete}
          />
        )}
      />
    </Button>
    <Tooltip 
      title={filesArr[0]?.startsWith('https://') ? 'Delete all? ' + ( filesArr.map(file=> file.split('-').pop()) ).join() : filesArr.join(', ')}
      placement='top'
    >
        <div className="meat-balls notif-highlight">
          {
            (canDelete && filesArr[0]?.startsWith('https://')) 
            && 
            <IconButton 
              color="inherit"  
              onClick={()=>{removeFileHandler(removeHandlerVal)}}
              sx={{
                opacity: filesArr[0] !== 'empty' ? '1':'0', 
                width: filesArr[0] !== 'empty' ? '50px':'0px', 
                display: filesArr[0] !== 'empty' ? 'content':'none',
              }} 
              >
              {<DeleteOutlineOutlinedIcon />}
            </IconButton>
          }
        </div>
      </Tooltip>
    </Box>
  )
}

export default UploadMultiFileComponent;