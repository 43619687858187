import React, { useEffect, useState } from 'react'
import { BaseDetails, Employer, Individual, Job } from '../../typescript/types';
import { InlineContainer } from '../../UI/StyledComponents/GlobalStyledComponents';
import { Box, display } from '@mui/system';
import { Typography } from '@mui/material';
import getDistCalc, { calculateAvailableHours, getFullName } from '../../../helpers/helperFunctions';
import RecommendationShowTagMatches from '../RecommendationShowTagMatches';
import RecommendationsOverviewComponent from '../RecommendationOverviewComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getProviderById } from '../../../store/providerSlice';
import EmploymentSupportLeadCardComponent from '../../EmployerComponents/EmploymentSupportLeadCardComponent';
import useDetectResize from '../../../hooks/useDetectResize';
import { DetailsPopupContainer, ESPCardContainer } from '../../UI/StyledComponents/RecommendationStyledComponent';


interface MobileDetailsProp{
  selectedItem: Partial<BaseDetails>;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  isIndividual: boolean;
  baseObject: any;
  updateTrigger: boolean;
  userType: string;
}

const MobileRecommendationDetailsComponent = (props: MobileDetailsProp) => {
  const {
    selectedItem,
    closeModal,
    baseObject,
    updateTrigger,
    isIndividual,
    userType,
  } = props;

  const [isType, setType] = useState<string>('');
  const leadProvider = useSelector((state:RootState) => (getProviderById(state, (isType === 'job' ? (selectedItem as Job)?.providerId : (selectedItem as (Individual | Employer))?.leadProviderId))));
  const { windowDimensions, isMobile } = useDetectResize();
 
  useEffect(() => {
    console.warn('BaseObject', baseObject);
    console.warn('SelectedItem', selectedItem)

    selectedItem?.hasOwnProperty('firstName') 
    ?
    setType(currState => currState='individual')
    :
    (selectedItem.hasOwnProperty('employerId'))
    ?      
    setType(currState => currState='job')
    :
    setType(currState => currState='employer')
    
  }, [selectedItem]);


  function buildNestedTags(item: Individual) {
    let nestedTags: {interests: number[], skills: number[], restrictions: number[]} = {interests: item?.interests, skills: item?.skills, restrictions: item?.restrictions};
    return nestedTags || null;
  }

  return (
    <Box
      className='mobile-recommended-details-poopup'
      position='relative'
      height='inherit'
    >
      <InlineContainer 
        justifyContent='space-between' 
        alignItems='flex-end'
        padding='10px 15px'
        position='relative'
      >
        <Typography
          variant='h2'
        >
          {isType === 'individual' ? getFullName(selectedItem as Individual).toUpperCase() : isType === 'job' ? (selectedItem as Job).title : (selectedItem as Employer)?.orgName}
        </Typography>
        <Box
         onClick={() => {
           closeModal(currState => currState = false)
         }}
         sx={{
          border: `2px solid black`,
          padding: '3px 8px',
          borderRadius: '50%',
          color: 'black',
          fontWeight: 600
         }}
        >
          X
        </Box>
      </InlineContainer>
      {/* Top details module in recommendations, shows skill + interest count, total km from job and filled hours */}
      <RecommendationShowTagMatches 
        nestedTags={buildNestedTags(isIndividual ? (baseObject as Individual)! : (selectedItem as Individual))} 
        distance={getDistCalc(+baseObject.longitude, +baseObject.latitude, +selectedItem?.longitude!, +selectedItem?.latitude!)} 
        tags={isIndividual ? (selectedItem as Job|Employer)?.tags! : (baseObject as Job|Employer)?.tags!} 
        availability={isIndividual ? calculateAvailableHours(baseObject as Individual) : calculateAvailableHours(selectedItem as Individual)} 
        isVisible={true}
        restrictionsText={isIndividual ? (baseObject as Individual)?.restrictionsText! : (selectedItem as Individual)?.restrictionsText!}
        updateDetected={updateTrigger}
      />
      <DetailsPopupContainer
        height = {windowDimensions.h}
      >
        <RecommendationsOverviewComponent 
          selectedItem ={selectedItem! as BaseDetails} 
          userType={userType!.split(' ')[0]}
          isVisible={true}
        />
      </DetailsPopupContainer>

      <ESPCardContainer
        className='esp-card-container'
         height={windowDimensions.h}
      >
        <EmploymentSupportLeadCardComponent 
          name={getFullName(leadProvider)} 
          email={leadProvider?.email} 
          phone={leadProvider?.phone}
          isRecommendations={true}
        />
      </ESPCardContainer>
    </Box>
  )
}

export default MobileRecommendationDetailsComponent