import { Box, SxProps, Theme } from "@mui/system";
import { CustomCard } from "../StyledComponents/GlobalStyledComponents";

interface CardProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
  height?: string;
  hideBorder?: boolean;
  customSx?:SxProps<Theme>;
}

const Card = (props: CardProps) =>{
  const {
    children, 
    className, 
    onClick, 
    height,
    customSx,
    hideBorder
  } = props;

  return(
    <CustomCard 
      className={className}
      sx={customSx} 
      height={height}
      onClick={onClick}
      hideBorder = {hideBorder}
    >
      {children}
    </CustomCard>
  )
}

export default Card;