import {useState, useEffect} from 'react';
import { Provider } from '../components/typescript/types';


const useInactiveTimer = (minutes: number, myProviderDetails: Provider) => {
  const [userInactive, setUserInactive] = useState<boolean>(false);
  const inactiveFor = minutes * 60 * 1000;
  useEffect(()=>{
    //initiailze timer when user is logged into the system.
    if(myProviderDetails){
      console.log('Set timer for: ', minutes);
      trackInactivity();
    }
  },[myProviderDetails]);

  function trackInactivity(){
    
    let time: NodeJS.Timeout = setTimeout(()=>{},0); //initialize time variable

    window.onload = resetTimer;
    
    // DOM Events to trigger reset timer
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;
  
    function resetTimer() { 
      setUserInactive(currState => currState = false);   

      clearTimeout(time);
      
      time = setTimeout(()=>{
        setUserInactive(currState => currState = true);
      }, inactiveFor);
    }
  }

  return{
    userInactive,
    setUserInactive,
  }
}

export default useInactiveTimer;