import { NavLink, useLocation } from "react-router-dom";
import Icon from "../../asssets/icons";
import { Box, createTheme, Drawer, List, ListItem, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import Logo from '../../asssets/imgs/logo-big.png';
// import miniLogo from '../../asssets/imgs/logo-small.png';
import miniLogo from '../../asssets/imgs/new-logo-v.svg';
import Logo from '../../asssets/imgs/new-logo-lrg.svg';
import Modal from "../../components/UI/Popup/BackdropComponent";
import { getMyProviderDetails } from "../../store/providerSlice";
import { useSelector } from "react-redux";
import useTranslate from "../../hooks/useTranslate";
import AssessmentIcon from '@mui/icons-material/Assessment';
import useDetectResize from "../../hooks/useDetectResize";
import { CustomNavDrawer, InlineContainer } from "../UI/StyledComponents/GlobalStyledComponents";
import { NavTitle } from "../UI/StyledComponents/NavigationStyledComponents";
import { HelpModal, HelpOverlay } from "../UI/StyledComponents/NotificationModalStyledComponents";


const navIconStyling = {
  justifyContent: 'center !important',
  width: '50px !important',
  marginLeft: 'auto !important',
  marginRight: 'auto !important',
}


const NavigationComponent = (props: {isCollapsed:boolean, setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>}) => {
  const { isCollapsed, setIsCollapsed } = props;
  const { windowDimensions } = useDetectResize();
  let activeNavBtn = "active-nav inline";
  let navBtn = 'inactive-nav inline';
  const location = useLocation();  //use this to toggle images when user navigates to different sections of the site.  
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const myProvider = useSelector(getMyProviderDetails);
  const t = useTranslate();
  // const navstyles = useStyles();

  return (
        //Add sx object which detects width and media type. 
        //
      <CustomNavDrawer
        className={`${isCollapsed ? ' skinnyStyles' : ' chunkyStyles'}`}
        variant="permanent"
        anchor="left"
        sx={{
          zIndex: 0,
        }}
      >
        <div 
          className="platform-logo inline-c"
          onClick={()=>{setIsCollapsed(currState => currState = !currState)}}
        >
          { 
            isCollapsed ?
            <img 
              className={`${isCollapsed && 'animate-rotate-y-reverse'} dash-sm-logo`}
              src={miniLogo} alt='small-logo'/>
            :
            <img 
            className='dash-lg-logo'
            src={Logo} alt='main-logo'/>
          }
        </div>

        <List 
          sx={{
            paddingTop: '2.4rem',
            flex: 1,
            "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
              paddingTop: '1.9rem',
            },
          }}
        >
          <ListItem
            sx={ isCollapsed ? navIconStyling : {}}
          >
            <NavLink
              to="/dashboard"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              >
              <InlineContainer justifyContent='flex-start' className={`${!isCollapsed && 'nav-txt-wrpr'}`}>
                <img
                  className={`${isCollapsed && 'animate-rotate-y-reverse'}`} 
                  src={location.pathname === "/dashboard" ? Icon.WhiteDboard : Icon.BlackDboard} 
                  alt='dash'
                />
                {!isCollapsed && <NavTitle variant='h3' className={!isCollapsed && 'animate-rotate-y'}>{t('dashboardNav')}</NavTitle>}
              </InlineContainer>
            </NavLink>
          </ListItem>

          <ListItem
            sx={ isCollapsed ? navIconStyling : {}}
          >
            <NavLink
              to="/employers"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              >
              <InlineContainer justifyContent='flex-start' className={`${!isCollapsed && 'nav-txt-wrpr'}`}>
                <img
                  className={`${isCollapsed && 'animate-rotate-y-reverse'}`} 
                  src={location.pathname.startsWith("/employers") || location.pathname.startsWith("/Employers") ? Icon.WhiteEicon : Icon.SlateEicon} 
                  alt='employers'
                />
                { !isCollapsed && <NavTitle variant='h3' className={!isCollapsed && 'animate-rotate-y'}>{t('employerNav')}</NavTitle> }
              </InlineContainer>
            </NavLink>
          </ListItem>

          <ListItem
            sx={ isCollapsed ? navIconStyling : {}}
          >
            <NavLink
              to="/jobs"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              >
                <InlineContainer justifyContent='flex-start' className={`${!isCollapsed && 'nav-txt-wrpr'}`}>
                  <img
                    className={`${isCollapsed && 'animate-rotate-y-reverse'}`} 
                    src={location.pathname.startsWith("/jobs") || location.pathname.startsWith("/Jobs") ? Icon.WhiteJicon : Icon.SlateJicon} 
                    alt='jobs'
                  />
                  { !isCollapsed && <NavTitle variant='h3' className={!isCollapsed && 'animate-rotate-y'}>{t('jobNav')}</NavTitle> }
                </InlineContainer>
            </NavLink>
          </ListItem>

          <ListItem
            sx={ isCollapsed ? navIconStyling : {}}
          >
            <NavLink
              to="/individuals"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              >
              <InlineContainer justifyContent='flex-start' className={`${!isCollapsed && 'nav-txt-wrpr'}`}>
                <img
                  className={`${isCollapsed && 'animate-rotate-y-reverse'}`} 
                  src={location.pathname.startsWith("/individuals") || location.pathname.startsWith("/Individuals")  ? Icon.WhiteIicon : Icon.SlateIicon} 
                  alt='individuals'
                />
                { !isCollapsed && <NavTitle variant='h3' className={!isCollapsed && 'animate-rotate-y'}>{t('individualNav')+'s'}</NavTitle> }
              </InlineContainer>
            </NavLink>
          </ListItem>
        </List>
        
        <List sx={{
          paddingTop: '1.4em',
          }}
        >
          <ListItem
            sx={ isCollapsed ? navIconStyling : {}}
          >
            <NavLink
              to="/insights"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              onClick={() => { setIsCollapsed(true) }}
            >
              <InlineContainer justifyContent='flex-start' className={`${!isCollapsed && 'nav-txt-wrpr'}`}>
                <img
                  className={`${isCollapsed && 'animate-rotate-y-reverse'}`} 
                  src={location.pathname.startsWith("/insights") || location.pathname.startsWith("/Insights")  ? Icon.InsightIcon : Icon.InsightIcon} 
                  alt='insight'
                />
                { !isCollapsed && <NavTitle variant='h3' className={!isCollapsed && 'animate-rotate-y'}> Insights</NavTitle> }
              </InlineContainer>
            </NavLink>
          </ListItem>
          {
           myProvider?.isAdmin
           && 
           <ListItem
              sx={ isCollapsed ? navIconStyling : {}}
            >
              <NavLink
                to="/invite"
                className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn} 
                >
                <InlineContainer justifyContent='flex-start' className={`${!isCollapsed && 'nav-txt-wrpr'}`}>
                  <img 
                    className={`${isCollapsed && 'animate-rotate-y-reverse'}`}
                    src={location.pathname.startsWith("/invite") ? Icon.WhiteInvite : Icon.SlateInvite}
                    alt='Invite'
                  />
                  { !isCollapsed && <NavTitle variant='h3' className={!isCollapsed && 'animate-rotate-y'}>{t('inviteNav')}</NavTitle> }
                </InlineContainer>
              </NavLink>
           </ListItem>
          }

          <ListItem
            sx={ isCollapsed ? navIconStyling : {}}
          >
            <NavLink
              to="/profile"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              >
              <InlineContainer justifyContent='flex-start' className={`${!isCollapsed && 'nav-txt-wrpr'}`}>
                <img 
                  className={`${isCollapsed && 'animate-rotate-y-reverse'}`}
                  src={location.pathname === "/profile" ? Icon.WhiteSettings : Icon.SlateSettings} 
                  alt='dash'
                />
                { !isCollapsed && 
                  <NavTitle variant='h3' className={!isCollapsed && 'animate-rotate-y'}>
                    {t('settingsTitle')}
                  </NavTitle> 
                }
              </InlineContainer>
            </NavLink>
          </ListItem>

          <ListItem
            sx={ isCollapsed ? navIconStyling : {}}
          >
            <div className="inactive-nav" onClick={() => {
                setShowHelpModal(true)
              }}>
              <InlineContainer justifyContent='flex-start' className={`${!isCollapsed && 'nav-txt-wrpr'}`}>
                <img
                  className={`${isCollapsed && 'animate-rotate-y-reverse'}`}
                  src={location.pathname === "/nothing-here-yet" ? Icon.WhiteHelp : Icon.SlateHelp} 
                  alt='dash'
                />
                { !isCollapsed && 
                <NavTitle variant='h3' className={!isCollapsed && 'animate-rotate-y'}>
                  {t('helpBtn')}
                </NavTitle> 
                }
              </InlineContainer>
            </div>
          </ListItem>
        </List>
        {
          showHelpModal 
          && 
          <Modal 
            className="modal"
            onHide={()=>[setShowHelpModal(false)]} 
          >
            <HelpOverlay className='responsive-help-overlay'
              onClick={(e)=>{e.stopPropagation()}}
              top = {windowDimensions.h}
              right = {windowDimensions.w}
            >
              <HelpGuideComponent t={t}/>
            </HelpOverlay>
          </Modal>
        }
      </CustomNavDrawer>
  );
};

export default NavigationComponent;


export const HelpGuideComponent = (params: { t: (str:string) => any })=>{
  const {t} = params
  return(
    <HelpModal>
      <Box className="help-modal-header">
        <Box className="help-modal-title">
          <Typography variant="h3" textAlign={'center'}>{t('helpPrompt')}</Typography>
        </Box>
      </Box>
      <Box className="help-modal-content">
        <a href="https://airtable.com/shrqKk1pjydUYFTgR" target="_blank" rel="noopener noreferrer">{t('helpReportOption')}</a>
        <br/>
        <br/>
        <a href="mailto: dev@p4g.ca" target="_blank" rel="noopener noreferrer">{t('helpEmailOption')}</a>
        <br/>
        <br/>
        <Box className='help-modal-content help-download inline-ca'>
          <a href='https://guide.myjobmatch.ca/'  target="_blank" rel="noopener noreferrer">
            {t('helpGuideOption')} {t('here')} </a>
        </Box>
      </Box>
   </HelpModal>
  )
}