import { useCallback, useEffect, useState } from "react";


interface MediaObj {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
};

//upper limit (BP) for each device. based off Bootstrap breakpoints
const mobileBP = 768; //sm
const tabletBP = 1025;  //md 
const laptopBP = 1200  //lg
const dekstopBP = 1400; //xlg

const defaultValues= {
  isMobile: window.innerWidth < mobileBP ? true : false,
  isTablet: (window.innerWidth >= mobileBP && window.innerWidth <= tabletBP) ? true : false,
  isLaptop: (window.innerWidth > tabletBP && window.innerWidth <= laptopBP) ? true : false,
  isDesktop: (window.innerWidth > laptopBP) ? true : false,
}

const useDetectResize = () => {
  const [mediaType, setMediaType] = useState<MediaObj>(defaultValues);
  const [windowDimensions, setWindowDimensions] = useState<{w:number, h:number}>({w: window.innerWidth, h: window.innerHeight});
  let timeout = false;

  useEffect(() => {
    // window.resize event listener will call handleWindowResize() whenever screen width is adjusted.
    window.addEventListener('resize', handleWindowResize);
    console.debug(window.innerWidth);
    return () => window.removeEventListener('resize', handleWindowResize);
  },[window.innerWidth])

  //register callback so our function isn't instantiated on each re-render.
  const handleWindowResize = useCallback((timeout:any) => { 
    // clear the timeout
    clearTimeout(timeout);
    // debounce getDimensions function ever N ms
    timeout = setTimeout(getDimensions, 300);

    //store width/height and mediaState 
    function getDimensions(){
      let width = window.innerWidth;
      let height = window.innerHeight;
      setWindowDimensions(currState => currState = {w:width, h: height});
      console.log(`Viewport width: ${width}`);

      const mediaTypes = {
        isMobile: width <= mobileBP ? true : false,
        isTablet: (width > mobileBP && width <= tabletBP) ? true : false,
        isLaptop: (width > tabletBP && width <= laptopBP) ? true : false,
        isDesktop: (width > laptopBP) ? true : false,
      }
      setMediaType(currState => currState = mediaTypes);
    }

  }, [window.innerWidth]); 


  
  return{
    isMobile: mediaType.isMobile,
    isTablet: mediaType.isTablet,
    isLaptop: mediaType.isLaptop,
    isDesktop: mediaType.isDesktop,
    windowDimensions
  }
}

export default useDetectResize;