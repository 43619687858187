import { configureStore } from '@reduxjs/toolkit';


import providerReducer  from './providerSlice';
import jobsReducer from './jobSlice';
import individualReducer from './individualSlice';
import employerReducer from './employerSlice';
import notificationReducer from './notificationSlice';
import notificationMiddleware from './middleware/notificationMiddleware';
import authReducer  from './authSlice';
import keywordReducer from './keywordSlice';

const store = configureStore({
  reducer: {
    providers: providerReducer, 
    jobs: jobsReducer,
    individuals: individualReducer,
    employers: employerReducer,
    notification: notificationReducer,
    auth: authReducer,
    keyword: keywordReducer,
  },
  middleware: (getDefaultMiddleware) =>{return getDefaultMiddleware().concat(notificationMiddleware)},
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {  provider: providerState, jobs: jobsState, individuals: individualState, employers: employerState  etc...}
export type AppDispatch = typeof store.dispatch

export default store;