import dayjs from "dayjs";
import fr from 'dayjs/locale/fr';

  export function getDeltaTime(ts:number, t: any, isEnglish:boolean, isMobile = false){
    let totalSeconds = (dayjs().unix() - ts); // convert from ms to seconds.

    const HOUR = 3600;
    const DAY = 3600*24;
    const WEEK = 3600*24*7;

    if(totalSeconds < 60){
      return t('lessMinute');
    }else if(totalSeconds > 60 && totalSeconds < HOUR){
      let mins = Math.round(totalSeconds/60);
      return ` ${mins} ${mins === 1 ? 'min': 'mins'} ago.`;
    } else if(totalSeconds > HOUR && totalSeconds < DAY){
      let hrs = Math.round(totalSeconds/HOUR);
      return ` ${hrs} ${hrs === 1 ? 'hr':'hrs'} ${t('ago')}.`;
    } else if(totalSeconds > DAY && totalSeconds < DAY*2){
      return `${t('oneDayPast')}`;
    } else if(totalSeconds > DAY*2 && totalSeconds <= WEEK){
      let days  = Math.floor(totalSeconds/DAY);
      return `${days} ${t('daysAgo')}`;
    } else {
      return `${ isMobile ?  '' : t('on')} ${ isEnglish ? dayjs(ts*1000).format('MMM DD, YYYY') :  dayjs(ts*1000).format('DD MMM YYYY') }`
    }
  }

