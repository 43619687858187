import React, { useState } from 'react'
import { MobileNavListContainer, NavListIcon, NavTitle } from '../UI/StyledComponents/NavigationStyledComponents'
import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'
import useTranslate from '../../hooks/useTranslate'
import Icon from '../../asssets/icons'
import { useSelector } from 'react-redux'
import { getMyProviderDetails } from '../../store/providerSlice'
import Modal from '../UI/Popup/BackdropComponent'
import { HelpGuideComponent } from './NavigationComponent'
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents'
import TranslationComponent from '../../transtlation/TranslationComponent'
import { HelpOverlay } from '../UI/StyledComponents/NotificationModalStyledComponents'
import { AskForNotificationPermission } from '../../helpers/service-worker-helpers'
import useDetectResize from '../../hooks/useDetectResize'

const MobileNavListComponent = (props: {hideMenu: () => void}) => {

  const { hideMenu } = props;
  let activeNavBtn = 'active-nav';
  let navBtn = 'inactive-nav';
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const myProvider = useSelector(getMyProviderDetails);
  const location = useLocation();  //use this to toggle images when user navigates to different sections of the site.  
  const t = useTranslate();
  const {windowDimensions} = useDetectResize();


  return (
    <MobileNavListContainer>
        <List 
          sx={{
            flex: 1,
            display: 'inline-grid',
            height: 'fit-content',
            justifyContent: 'center',
            "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
              paddingTop: '1.9rem',
            },
            '& > li' : {
              width: '200px',
            }
          }}
        >
          <ListItem
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <NavLink
              to="/dashboard"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              onClick={hideMenu}
            >
              <InlineContainer 
                className='mobile-nav'
                justifyContent={'flex-start'} 
                alignItems='center'
              >
                <NavListIcon 
                  src={location.pathname === "/dashboard" ? Icon.WhiteDboard : Icon.BlackDboard} 
                  alt='dash'
                />
                  <NavTitle variant='h3'>
                    {t('dashboardNav')}
                  </NavTitle> 
              </InlineContainer>
            </NavLink>
          </ListItem>

          <ListItem
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <NavLink
              to="/employers"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              onClick={hideMenu}
            >
              <InlineContainer 
                className='mobile-nav'
                justifyContent={'flex-start'} 
                alignItems='center'
              >
                <NavListIcon 
                  src={location.pathname.startsWith("/employers") || location.pathname.startsWith("/Employers") ? Icon.WhiteEicon : Icon.SlateEicon} 
                  alt='employers'
                />
                <NavTitle variant='h3'> 
                  {t('employerNav')}
                </NavTitle>
              </InlineContainer>
            </NavLink>
          </ListItem>

          <ListItem
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <NavLink
              to="/jobs"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              onClick={hideMenu}
            >
              <InlineContainer 
                className='mobile-nav'
                justifyContent={'flex-start'} 
                alignItems='center'
              >
                  <NavListIcon

                    src={location.pathname.startsWith("/jobs") || location.pathname.startsWith("/Jobs") ? Icon.WhiteJicon : Icon.SlateJicon} 
                    alt='jobs'
                  />
                  <NavTitle variant='h3'> 
                    {t('jobNav')}
                  </NavTitle>
                </InlineContainer>
            </NavLink>
          </ListItem>

          <ListItem
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <NavLink
              to="/individuals"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              onClick={hideMenu}
            >
              <InlineContainer 
                className='mobile-nav'
                justifyContent={'flex-start'} 
                alignItems='center'
              >
                <NavListIcon 
                  src={location.pathname.startsWith("/individuals") || location.pathname.startsWith("/Individuals")  ? Icon.WhiteIicon : Icon.SlateIicon} 
                  alt='individuals'
                />
                  <NavTitle variant='h3'> 
                    {t('individualNav')}
                  </NavTitle>
              </InlineContainer>
            </NavLink>
          </ListItem>
            {/* Hide Insights from mobile app */}
          {/* <ListItem
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <NavLink
              to="/insights"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              onClick={hideMenu}
            >
              <InlineContainer 
                className='mobile-nav'
                justifyContent={'flex-start'} 
                alignItems='center'
              >
                <NavListIcon 
                  src={location.pathname.startsWith("/insights") || location.pathname.startsWith("/Insights")  ? Icon.InsightIcon : Icon.InsightIcon} 
                  alt='insight'
                />
                  <NavTitle variant='h3'> 
                    Insights
                  </NavTitle>
              </InlineContainer>
            </NavLink>
          </ListItem> */}
          {
           myProvider?.isAdmin
           && 
           <ListItem
            sx={{
              justifyContent: 'flex-start'
            }}
           >
              <NavLink
                to="/invite"
                className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
                onClick={hideMenu} 
              >
              <InlineContainer 
                className='mobile-nav'
                justifyContent={'flex-start'} 
                alignItems='center'
              >
                  <NavListIcon 

                    src={location.pathname.startsWith("/invite") ? Icon.WhiteInvite : Icon.SlateInvite}
                    alt='Invite'
                  />
                    <NavTitle variant='h3'> 
                      {t('inviteNav')}
                    </NavTitle>
                </InlineContainer>
              </NavLink>
           </ListItem>
          }

          <ListItem
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <NavLink
              to="/profile"
              className={(props: {isActive:boolean}) => props.isActive ? activeNavBtn : navBtn}
              onClick={hideMenu}
            >
              <InlineContainer 
                className='mobile-nav'
                justifyContent={'flex-start'} 
                alignItems='center'
              >
                <NavListIcon 
                  src={location.pathname === "/profile" ? Icon.WhiteSettings : Icon.SlateSettings} 
                  alt='dash'
                />
                  <NavTitle variant='h3'> 
                    {t('settingsTitle')}
                  </NavTitle>
              </InlineContainer>
            </NavLink>
          </ListItem>

          <ListItem
            sx={{
              justifyContent: 'flex-start'
            }}
          >
            <div className="inactive-nav" 
              onClick={(e) => {
                setShowHelpModal(true)
                e.stopPropagation();
              }}
            >
              <InlineContainer 
                className='mobile-nav'
                justifyContent={'flex-start'} 
                alignItems='center'
              >
                <NavListIcon
                  src={location.pathname === "/nothing-here-yet" ? Icon.WhiteHelp : Icon.SlateHelp} 
                  alt='dash'
                />
                  <NavTitle variant='h3'> 
                    {t('helpBtn')}
                  </NavTitle>
              </InlineContainer>
            </div>
          </ListItem>
        </List>
        <Box
          sx={{
            display:'inline-grid',
            gap:'10px',
            marginTop:'10px',
            marginLeft:'auto',
            marginRight:'auto',
            width:'50%'
          }}
        >
          <TranslationComponent/>
          {('Notification' in window && 'serviceWorker' in navigator) &&
            <Button 
              variant='contained'
              onClick={() => {AskForNotificationPermission()}}
            >
              Enable Notifications
            </Button>
          }
        </Box>
        {
          showHelpModal 
          && 
          <Modal
            className="modal"
            onHide={()=>[setShowHelpModal(false)]} 
          >
            <HelpOverlay
             className={`help-overlay-wrapper`}
             onClick={(e)=>{e.stopPropagation()}}
             top = {windowDimensions.h}
             right = {windowDimensions.w}
            >
              <HelpGuideComponent t={t}/>
            </HelpOverlay>
          </Modal>
        }
    </MobileNavListContainer>
  )
}

export default MobileNavListComponent