import { Box, Typography } from "@mui/material"
import Card from "../Card/CardComponent"
import { AltSuccessModal } from "../StyledComponents/NotificationModalStyledComponents"

const AltSuccessComponent=(props:{message: string, animation: string})=>{
  return (
    <>
      <AltSuccessModal>
        <img src={props.animation} alt="success-gif"/>
        <Typography 
          variant="body1">
            {props.message}
        </Typography>
      </AltSuccessModal>
    </>
  )
}

export default AltSuccessComponent