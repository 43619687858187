//Styling, helpers, functions
import { Box, Button, Typography } from '@mui/material';
import { addItemBtnStyling, backToStyling, editEmployerStyling } from '../../helpers/styling';
import { Employer } from '../typescript/types';
import { getFullName } from '../../helpers/helperFunctions';
import { Add, ArrowBackIos,  EditOutlined } from '@mui/icons-material';
//React
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getEmployerSpecificJobs } from '../../store/jobSlice';
//Components
import Card from '../UI/Card/CardComponent';
import CompanyDetailsComponent from '../CompanyDetailsComponent';
import { getProviderById } from '../../store/providerSlice';
import EmploymentSupportLeadCardComponent from './EmploymentSupportLeadCardComponent';
import RecommendationsWidgetComponent from '../DashboardComponents/RecommendationsWidgetComponent';
//Misc
import { getTags } from '../../store/keywordSlice';
import { getEmployerById } from '../../store/employerSlice';
import useTranslate from '../../hooks/useTranslate';

import {  ViewComponentHeader, NavButtonWrapper, ViewColumn1, ViewColumn2 } from '../UI/StyledComponents/ViewStyledComponents';
import { EmployerAssociatedJobsWidget, EmployerNotesWidget, OverviewWidget, RecordDescriptionWidget } from './SharedWidgets/OverviewWidget';
import { RecommendationsInlineContainer } from '../UI/StyledComponents/FormsStyledComponents';
import { ViewContainer } from '../UI/StyledComponents/GlobalStyledComponents';

export default function EmployerComponent() {
  const {state} = useLocation();
  const navigate = useNavigate();
  const refEmployer = state as unknown as Employer;
  const employer = useSelector((rState:RootState) => (getEmployerById(rState, refEmployer.id)));
  const leadProvider = useSelector((state:RootState) => (getProviderById(state, employer?.leadProviderId)));
  const employerSpecificJobs = useSelector((state:RootState) => (getEmployerSpecificJobs(state, employer?.id!)));
  const t = useTranslate();
  const TAGS = useSelector((state:RootState) => getTags(state, t));


  return (
    <ViewContainer className='view-container'>
      <ViewComponentHeader>
        <CompanyDetailsComponent 
          avatarStr={employer?.orgName!}
          employerTitle={employer?.orgName!}
          employerSubtitle={employer?.orgName !== employer?.parentCompName ? employer?.parentCompName! : null}
          img={employer?.imgLogo} 
          isOpenToWork={employer?.status} 
        />
        
        <NavButtonWrapper>
          <Box 
            className='go-back inline-btwn' 
            onClick={() => navigate("/employers")}
          >
            <Button 
              variant="text" 
              startIcon={<ArrowBackIos/>}
              sx={backToStyling}
            >
              {t('backBtnEmployerView')}
            </Button>
          </Box>
            <Button 
              onClick={() => navigate("/jobs/create", {state: employer!}) }
              startIcon={<Add />}
              sx={addItemBtnStyling}
              variant='contained'
            >
            {t('addJobBtn')}
            </Button>
            <Button
              onClick={()=> navigate("edit", {state: employer!})} 
              startIcon={<EditOutlined />}
              sx={editEmployerStyling}
              variant='outlined'
            >
            {t('employerViewEditBtn')}
            </Button>
            {/* <DeleteResourceComponent                      
              recordId={employer?.id!}
              warnUserMsg={warnUserMsg}
              subject='employer'
              deleteAsyncThunkFunc={deleteEmployer}
              customStyle={editEmployerStyling}
            /> */}
        </NavButtonWrapper>
      </ViewComponentHeader>

      <Card className='employer-content'>

        <ViewColumn1>
          <EmploymentSupportLeadCardComponent 
            name={getFullName(leadProvider)} 
            email={leadProvider?.email} 
            phone={leadProvider?.phone} 
            photo={leadProvider?.photoUrl}
          />
          <Typography 
            variant='h3'
          >
            EMPLOYER OVERVIEW   
          </Typography>   
          {employer && <OverviewWidget 
            overViewOf='employer'
            overViewObj={employer as Employer}
          />}
        </ViewColumn1>

        <ViewColumn2>
            <RecordDescriptionWidget 
              recordDetails={employer?.details!} 
            />
            <EmployerAssociatedJobsWidget employerSpecificJobs={employerSpecificJobs} showTitle={true} />
            <EmployerNotesWidget employer={employer!} showTitle={true} />
        </ViewColumn2>

      </Card>

      <RecommendationsInlineContainer>
        <RecommendationsWidgetComponent 
          userState={employer as Employer} 
          userType={'individualsToEmployer'} 
          isIndividual={false}
        />    
      </RecommendationsInlineContainer>
    </ViewContainer>
  )
}


