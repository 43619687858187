
import { ThemeContext } from '@emotion/react';
import { ThemeOptions, createTheme } from '@mui/material/styles';
import { createBreakpoints } from "@mui/system";
import { Theme } from '@mui/material/styles';


const breakpoints = createBreakpoints({});

const manualBreakpoints = {
  values: {
    xs: 300, // max-width small mobile
    sm: 768, //max-width mobile
    md: 1025, //max-width tablet
    lg: 1200, //max-width laptop
    xl: 1400, //min-width xlLaptop 
  }
}

const h1Custom = {
  fontSize: 28,
  lineHeight: 1.2,
  fontFamily: 'LexendDeca',
  fontWeight: 600,
  color: '#050505',
  [breakpoints.down("sm")]: {
    fontSize: 20,
  },
  [breakpoints.up("sm")]: {
    fontSize: 30
  },
  [breakpoints.up("md")]: {
    fontSize: 32,
    '@media (min-resolution: 1.25dppx)':{
      fontSize: 28
    }
  },
  [breakpoints.up("lg")]: {
    fontSize: 34,
    '@media (min-resolution: 1.25dppx)':{
      fontSize: 26
    }
  }
};

const h2Custom = {
  fontSize: 16,
  lineHeight: 1.2,
  fontFamily: 'LexendDeca',
  fontWeight: 600,
  color: '#050505',
  [breakpoints.up("sm")]: {
    fontSize: 16,
    '@media (min-resolution: 1.25dppx)':{
      fontSize: 16
    },
    '@media (min-height: 1000px)':{
      fontSize: 18
    },
  },
  [breakpoints.up("md")]: {
    fontSize: 20,
    '@media (max-width: 1100px)':{
      fontSize: 16
    },
    '@media (min-resolution: 1.25dppx)':{
      fontSize: 18
    }
  },
};

const h3Custom = {
  fontSize: 18,
  lineHeight: 1.2,
  fontFamily: 'LexendDeca',
  fontWeight: 600,
  color: '#0F1177',
  textWrap: 'balance',

  [breakpoints.down("sm")]: {
    fontSize: 15,
  },
  [breakpoints.up("sm")]: {
    fontSize: 16
  },
  [breakpoints.up("md")]: {
    fontSize: 18,
    '@media (min-resolution: 1.25dppx)':{
      fontSize: 14
    }
  },
  [breakpoints.up("lg")]: {
    fontSize: 18,
    '@media (min-resolution: 1.25dppx)':{
      fontSize: 15.5
    }
  }
};

const h4Custom = {
  fontSize: 14,
  lineHeight: 1.2,
  fontFamily: 'LexendDeca',
  fontWeight: 500,
  color: '#050505',
  [breakpoints.down("sm")]: {
    fontSize: 14,
  },
  [breakpoints.up("sm")]: {
    fontSize: 14
  },
  [breakpoints.up("md")]: {
    fontSize: 14,
    '@media (min-resolution: 1.25dppx)':{
      fontSize: 12
    }
  },
  [breakpoints.up("lg")]: {
    fontSize: 16,
    '@media (min-resolution: 1.25dppx)':{
      fontSize: 12
    }
  }
};

const h5Custom = {
  fontSize: 13,
  lineHeight: 1.2,
  fontFamily: 'LexendDeca',
  fontWeight: 400,
  color: '#0F1177',
  [breakpoints.down("sm")]: {
    fontSize: 13,
  },
  [breakpoints.up("sm")]: {
    fontSize: 13,
  },
  [breakpoints.up("md")]: {
    fontSize: 14
  },
  [breakpoints.up("lg")]: {
    fontSize: 14,
  }
};

const h6Custom = {
  fontSize: 10,
  lineHeight: 1.2,
  fontFamily: 'LexendDeca',
  fontWeight: 400,
  [breakpoints.down("sm")]: {
    fontSize: 10,
  },
  [breakpoints.up("sm")]: {
    fontSize: 12,
  },
  [breakpoints.up("md")]: {
    '@media (max-width: 1100px)':{
      fontSize: 12
    },
    fontSize: 14,
  },
  [breakpoints.up("lg")]: {
    fontSize: 16,
  }
};

const s1Custom = {
  fontSize: 11,
  lineHeight: 1.2,
  fontFamily: 'Nunito',
  fontWeight: 400,
  [breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [breakpoints.up("sm")]: {
    fontSize: 14,
  },
  [breakpoints.up("md")]: {
    fontSize: 14
  },
  [breakpoints.up("lg")]: {
    fontSize: 16,
  }
};

const s2Custom = {
  fontSize: 13,
  lineHeight: 1.2,
  fontFamily: 'Nunito',
  fontWeight: 600,
  color: '#050505',
  [breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [breakpoints.up("sm")]: {
    fontSize: 14,
  },
  [breakpoints.up("md")]: {
    fontSize: 14
  },
  [breakpoints.up("lg")]: {
    fontSize: 16,
  }
};

const b1Custom = {
  fontSize: 12,
  lineHeight: 1.2,
  fontFamily: 'Nunito',
  fontWeight: 400,
  color: 'black',
  [breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [breakpoints.up("sm")]: {
    fontSize: 14,
  },
  [breakpoints.up("md")]: {
    '@media (max-width: 1100px)':{
      fontSize: 12
    },
    fontSize: 14,
  },
  [breakpoints.up("lg")]: {
    fontSize: 14,
  }
};

const b2Custom = {
  fontSize: 12,
  lineHeight: 1.2,
  fontFamily: 'Nunito',
  fontWeight: 9900,
  color: '#4f5f71',
  [breakpoints.down("sm")]: {
    fontSize: 12,
  },
  [breakpoints.up("sm")]: {
    fontSize: 14,
  },
  [breakpoints.up("md")]: {
    '@media (max-width: 1100px)':{
      fontSize: 12
    },
    fontSize: 14,
  },
  [breakpoints.up("lg")]: {
    fontSize: 14,
  }
};

const captionCustom = {
  fontSize: 10,
  lineHeight: 1.2,
  fontFamily: 'Nunito',
  fontWeight: 600,
  [breakpoints.down("sm")]: {
    fontSize: 10,
  },
  [breakpoints.up("sm")]: {
    fontSize: 12,
  },
  [breakpoints.up("md")]: {
    fontSize: 11,
  },
  [breakpoints.up("lg")]: {
    fontSize: 11,
  }
};
  
export const globalTheme = createTheme({
  breakpoints: manualBreakpoints,
  typography: {
    fontFamily: [
      'Nunito',
      'LexendDeca',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: h1Custom,
    h2: h2Custom,
    h3: h3Custom,
    h4: h4Custom,
    h5: h5Custom,
    h6: h6Custom,
    subtitle1: s1Custom,
    subtitle2: s2Custom,
    body1: b1Custom,
    body2: b2Custom,
    caption: captionCustom
  },
  palette: {
    primary: {
      main: '#0F1177', //primary-color
      light: '#E4F2F6', //backgrounud blue
      dark: '#dee1e8' //border color for mobile containers
    },
    secondary: {
      main: '#0379A0', //soviet-blue
      light: '#0379A0',
    },
    warning: {
      main: '#ba2343',
    },
    text:{
      primary: '#0F1177',
      secondary: '#4f5f71',
    },
    common:{
      white: '#d9e1e7' //grey outline of boxes.
    },
    action: {
      disabledBackground: 'lightgray',
      disabled: 'rgba(0, 0, 0, 0.38)'
    }
  },
  components: {
    MuiAccordion:{
      styleOverrides: {
        root: {
          '&:before':{
            backgroundColor: 'unset !important',
          },
          [breakpoints.down('sm')] : {
            margin: '5px 0',
          },
          [breakpoints.up('sm')] : {
            margin: '10px 0',
          },
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides:{
        root:{
          [breakpoints.down('sm')] : {
            minHeight: '15px'
          },
          [breakpoints.up('sm')] : {
            
            minHeight: '80px'
          },
        },
        content:{
          [breakpoints.down('sm')] : {
            margin: '3px 0'
          },
          [breakpoints.up('sm')] : {
            margin: '10px 0'
          },
        }
      }
    },
    MuiAvatar: {
      styleOverrides:{
        root: {
          [breakpoints.down('sm')] : {

          },
          [breakpoints.up('sm')] : {

          },
        }
      }
    },
    MuiButton:{
      styleOverrides:{
        root: {
          '@media (max-width: 350px)' : {
            fontSize: '11px !important',
            
          },
          '@media (max-width: 400px)' : {
            fontSize: '12px !important',
            padding: '6px 8px',
            
          }
        },
        outlined:{
          border: '#bcd8ff',
          color: 'black'
        },
        contained:{
          backgroundColor: '#0F1177 !important',
          color: '#E4F2F6 !important' 
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          checked: {
            color: 'pink !important'
          }
        }
      }
    },
    MuiList:{
      styleOverrides:{
        root: {
          [breakpoints.down('md')] : {
            gridAutoRows: 'min-content',
            gap: '2rem',
            [breakpoints.down('sm')] : {
              gap: '1.25rem'
            }
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          height: '4.0rem',
          [breakpoints.down('md')] :{
            height: '2.25rem'
          },
          [breakpoints.up('md')] : {
            '@media (min-resolution: 1.5dppx)' : {
              '@media (max-height: 675px)' : {
                height: '3.5rem'
              }
            }
          }
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: '3.5px 4px 7.5px 6px !important',
          }
        }
      }
    },
    MuiFormHelperText:{
      styleOverrides: {
        root: {
          [breakpoints.down('sm')]:{
            fontSize: '12px !important',
            padding: '4px 10px !important',
          },
          [breakpoints.up('md')]:{
            fontSize: '12px !important'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1,
          color: 'rgba(0, 0, 0, 0.6)',
          fontFamily: 'LexendDeca',
          fontWeight: 500,
          [breakpoints.down('sm')] : {
            fontSize: 15
          },
          [breakpoints.up('sm')] : {

          },
          [breakpoints.up('md')] : {

          },
          [breakpoints.up('lg')] : {

          },
        },
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131'
          },
        }
      }
    }
  },

});
