import { Box, Button, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { convertTagsToString } from '../../helpers/displayOptions';
import { baseTagStyling, interestStyling, skillStyling } from '../../helpers/styling';
import useTranslate from '../../hooks/useTranslate';
import { RootState } from '../../store';
import { getIEMData } from '../../store/employerSlice';
import { getIJMData } from '../../store/jobSlice';
import { getTags } from '../../store/keywordSlice';
import { AltBadge, KeywordsBadge, RecoTags, RecommendationSummaryWrapper, RecommendationTitle, SkillsContainer } from '../UI/StyledComponents/RecommendationStyledComponent';
import useDetectResize from '../../hooks/useDetectResize';

interface PropsTagMatches{
  title?:string;
  nestedTags: { 
    skills: number[];
    interests: number[];
    restrictions: number[];
  }
  distance: number;
  tags: number[];
  availability: string;
  isVisible:boolean;
  restrictionsText:string;
  updateDetected: boolean;
}

const RecommendationShowTagMatches = (props: PropsTagMatches) => {
  const { title, nestedTags, tags, distance, availability, isVisible, restrictionsText, updateDetected} = props;
  const [skillsArr, setSkillsArr] = useState<number[]>([]);
  const [interestsArr, setInterestsArr] = useState<number[]>([]);
  const [restrictrionsArr, setRestrictrionsArr] = useState<number[]>([]);
  const IEM = useSelector(getIEMData);
  const IJM = useSelector(getIJMData);
  const { isMobile, isTablet } = useDetectResize();

  useEffect(()=>{
    getMatchesArr(nestedTags, tags);
  }, [nestedTags, tags, IJM, IEM]);

  function getMatchesArr(nestedTags: {skills: number[], interests: number[], restrictions: number[]}, tags: number[]){
     let matchedSkills = tags?.filter(tag =>  nestedTags.skills?.includes(tag));
     let matchedInterests = tags?.filter(tag => nestedTags.interests?.includes(tag));
     let matchedRestrictions = tags?.filter(tag => nestedTags.restrictions?.includes(tag));
     setSkillsArr(currState => currState = matchedSkills);
     setInterestsArr(currState =>  currState = matchedInterests);
     setRestrictrionsArr(currState => currState = matchedRestrictions);
  }

  const HoursAndDistance = () => {
    return(
      <RecoTags className="reco-tags full-width">
        <RecommendationTitle
          display={(isMobile || isTablet) ? 'none' : 'inline-flex'}
        >
          {(title! && (!isMobile || !isTablet)) && <Typography variant='h5' className={title?.length > 25 ? 'reco-tags-title': ''}>{title}</Typography>}
        </RecommendationTitle>
        <Box className="reco-metrics">
          <AltBadge 
            backgroundColor={'white !important'}
            color ={'#050505'}
            sx={{border: '1px solid #050505'}}
          >
            {distance?.toFixed(1)} km {isMobile ? '' : 'away'}
          </AltBadge>
          <AltBadge backgroundColor={`var(--secondary-font)`}>
            {`${availability} hrs ${(isMobile || isTablet) ? '' : 'avail.'}`} 
          </AltBadge>
        </Box>
      </RecoTags>
    )
  }

  const SkillsInterestRestrictions = () => {
    return(
      <Box className='contents full-width'>
        <Box className='inline grid'>
          {/* <h5 className='badge-type'>Matching keywords</h5> */}
          <Box className='reco-keyword-detail inline full-width'>
            <SkillsDisplayContainer arr={skillsArr!} type='Skills'/>
            <SkillsDisplayContainer arr={interestsArr!} type='Interests'/>
            <SkillsDisplayContainer arr={restrictrionsArr!} type='Restrictions' restrictionsText={restrictionsText} />
          </Box>
        </Box>
     </Box>
    )
  }

  //console.info( title, nestedTags, tags);
  return (
    <>
      {
        isVisible 
        &&
        <RecommendationSummaryWrapper
          background= {( isMobile || isTablet) ? 'none' : `var(--background)`}
        >
          {
           ( isMobile || isTablet) ?
           <>
            <SkillsInterestRestrictions/>
            <HoursAndDistance/>
           </>
           :
           <>
            <HoursAndDistance/>
            <SkillsInterestRestrictions/>
          </>
          }

        </RecommendationSummaryWrapper>
      }
    </>
  )
}

export default RecommendationShowTagMatches


const SkillsDisplayContainer = (props:{arr:number[], type:string, restrictionsText?:string})=> {
  const { arr, type, restrictionsText} = props;
  const t = useTranslate();
  const TAGS = useSelector((state: RootState) => getTags(state, t));
  const styling = type.toLocaleLowerCase() === 'skills' ? skillStyling : interestStyling;
  const { isMobile, isTablet } = useDetectResize();
  const [showBadges, setShowBadges] = useState<boolean>(false);

  function displayedBadges(){      
    return (
      convertTagsToString(arr as number[], true, TAGS?.all) as string[])
        .map( (tag, index) => { 
          if(index < 4){
              return (
                <Paper 
                  key={`${index}-${tag}`}
                  className={`${type.toLowerCase()}-badge`} 
                  sx={[baseTagStyling, styling ]}
                >
                  {tag}
                </Paper>
              )
          }else if(index === 4 ){
            return(
              <Paper
                key={`${index}-${tag}`}
                className={`${type.toLowerCase()}-badge`} 
                sx={[baseTagStyling, styling ]}
              >
                +{arr.length - 3} more...
              </Paper>
            )
          }else{
            return null;
          }
          
    })
  }

  const mobileShow = () => {
    setShowBadges(currState => !currState);
  }

  return(
    <SkillsContainer 
      className={`text-overflow ${type.toLowerCase()}-abs-pos`}
    >
      {type.toLocaleLowerCase() !== 'restrictions' ?
          <Box>
            <KeywordsBadge 
              className={`text-overflow`}
              onClick={mobileShow}
              isInterest ={type.toLocaleLowerCase() === 'skills' ? false : true}
            > 
              {type.toLowerCase() !== 'restrictions' && arr?.length} {type}
            </KeywordsBadge>
            <Box 
              className='skills-expandible-container'
              sx={{
                display: (isMobile || isTablet) ? showBadges ? 'grid' : 'none' : 'grid'
              }}
            >
              {displayedBadges()}
            </Box>
          </Box>
        :
        true
        ?  
        <Tooltip 
          title={restrictionsText} 
          placement="bottom"
          enterDelay={200} 
          leaveDelay={200}  
        >
          <KeywordsBadge 
            className={`${restrictionsText!.length > 0 ? 'status-closed ' : 'no-highlight'} tag-badge`}
            sx={{backgroundColor: '#eb393d !important', width: 'max-content !important'}}
          > 
            {restrictionsText!.length > 0 ? type : 'No Restrictions'}
          </KeywordsBadge>
        </Tooltip> 
        : 
        <></>
      }
  </SkillsContainer>
  )
}