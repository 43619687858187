import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Icon from '../../asssets/icons';
import useTranslate from '../../hooks/useTranslate';
import { Employer, Individual, Job } from '../typescript/types';
import Card from '../UI/Card/CardComponent'
import { RecommendationsCardInfo, RecommendationsContainer } from '../UI/StyledComponents/ViewStyledComponents';
import { Box, Typography } from '@mui/material';
import useDetectResize from '../../hooks/useDetectResize';

interface PropsRecommendWidget {
  userType:string;
  userState: Job | Employer | Individual;
  isJob?:boolean;
  isIndividual:boolean;
}

const RecommendationsWidgetComponent = (props:PropsRecommendWidget) => {
  const navigate = useNavigate();
  const {userState, userType, isIndividual, isJob} = props;
  const { isMobile, isTablet, windowDimensions } = useDetectResize();
  const t = useTranslate();

  return (
      <RecommendationsContainer className='recomm-nav-widget'>
        {/* Nearest */}
        <Card 
          className={`${(isMobile || isTablet) ? '' : 'employer-nav-btns'}`} 
          onClick={()=>navigate(`recommendations/location/${userType}`, {state: userState})}
          customSx={{
            gridColumn: `${isMobile ? '1/span 2' : '1 / span 1'}`,
            gridRow:  `1`,
            width: '100%'
          }}
        >
          <Box className='inline-btwn nav-btn'> 
            <RecommendationsCardInfo>
              {
                (isMobile || isTablet) ?
                <Typography variant='h3'>{ t('nearest').toUpperCase() }</Typography>
                :
                <Typography variant='h4'>{ !isIndividual ? t('nearestIndividualBtn').toUpperCase() : isJob ? t('nearestJobBtn').toUpperCase() : t('nearestEmployersBtn').toUpperCase()}</Typography>                
              }
              <img src={Icon.NearMe} alt='location'></img>
            </RecommendationsCardInfo>
          </Box>
        </Card>
        {/* Aligned */}
        <Card 
          className={`${(isMobile || isTablet) ? '' : 'employer-nav-btns'}`} 
          onClick={()=>navigate(`recommendations/aligned/${userType}`, {state: userState})}
          customSx={{
            gridColumn:  `${isMobile ? '1/span 2' : '2 / span 1'}`,
            gridRow: `${isMobile ? '2' : '1'}`,
            width: '100%'
          }}
        >
          <Box className='inline-btwn nav-btn'> 
            <RecommendationsCardInfo>
            { 
              (isMobile || isTablet) ?
              <Typography variant='h3'>{ t('aligned').toUpperCase() }</Typography>
              :
              <Typography variant='h4'>{ !isIndividual ? t('alignedIndividualBtn').toUpperCase() : isJob ? t('alignedJobsBtn').toUpperCase() : t('alignedEmployersBtn').toUpperCase()} </Typography>
            }
            <img src={Icon.HandHeart} alt='combo'></img>
            </RecommendationsCardInfo>
          </Box>
        </Card>
       {/* Cumulative */}
        <Card 
          className={`${(isMobile || isTablet) ? '' : 'employer-nav-btns'}`} 
          onClick={()=>navigate(`recommendations/cumulative/${userType}`, {state: userState})}
          customSx={{
            
            gridColumn: `${isMobile ? '1/span 2' : '1 / span 2'}`,
            gridRow: `${isMobile ? '3' : '2'}`,
            width: '100%'
          }}
        >
          <Box className='inline-btwn nav-btn'> 
            <RecommendationsCardInfo>
            { 
              (isMobile || isTablet) ?
              <Typography variant='h3'>{ t('cumulative').toUpperCase() }</Typography>
              :
              <Typography variant='h4'>{t('cumBtn').toUpperCase()}</Typography>
            }
              <img src={Icon.recoIcon} alt='cumulative'></img>
            </RecommendationsCardInfo>
          </Box>
        </Card>
      </RecommendationsContainer>
  )
}

export default RecommendationsWidgetComponent