import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
//import DataTable from "../components/EmployerComponents/EmployerTableComponent";
//import RecommendationsComponent from "../components/RecommendationComponents/RecommendationsComponent";
import AddIcon from '@mui/icons-material/Add';
import Icon from "../asssets/icons";
import NavigationPlaceholderComponent from "../components/NavigationComponent/NavigationPlaceholderComponent";
import { addItemBtnStyling } from "../helpers/styling";
import PageTitleComponent from "../components/PageTitleComponent";
import useTranslate from "../hooks/useTranslate";
import EmployerComponent from "../components/EmployerComponents/EmployerComponent";
import useDetectResize from "../hooks/useDetectResize";
import Box from "@mui/system/Box";
import MobileEmployersTable from "../components/EmployerComponents/MobileTablet/MobileEmployersTable";
import { InlineContainer, SectionHeaderWrapper, SectionTitle, TitleBadgeWrapper } from "../components/UI/StyledComponents/GlobalStyledComponents";
import MobileEmployerComponent from "../components/EmployerComponents/MobileTablet/MobileEmployerComponent";
import MobileRecommendationsComponent from "../components/RecommendationComponents/MobileTablet/MobileRecommendationsComponent";

const EmployersCreateComponent = React.lazy(() => import( '../components/EmployerComponents/EmployersCreateComponent'));
const RecommendationsComponent = React.lazy(() => import( "../components/RecommendationComponents/RecommendationsComponent"));
const DataTable = React.lazy(() => import( "../components/EmployerComponents/EmployerTableComponent"));


function EmployersComponent(props: {isNavCollapsed:boolean}){
  const { isNavCollapsed } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const t = useTranslate();
  const { isMobile, isTablet, isLaptop, isDesktop } = useDetectResize();

  useEffect(()=>{
    let timer = setTimeout(()=>{setIsLoading(false);},2000);
    return () => {
      clearTimeout(timer);
    }
  },[isLoading]);
  

  const EmployersListDesktopLaptopView = () => {
    return(
      <>
        <PageTitleComponent greeting={t('employerNav')}/>
      
        <div className="employers-table-cont">
          <EmployerHeaderContent/>
          <DataTable/>
        </div>
      </>
    )
  }

  const MobileEmployerWrapper = () => {
    return(
      <Box>
        <MobileEmployersTable/>
      </Box>
    )
  }

  return(
    <>
      <Routes>
        <Route  
          path="/"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              { 
                (isDesktop || isLaptop) 
                ? 
                <EmployersListDesktopLaptopView/> 
                : 
                (isMobile || isTablet) ? <MobileEmployerWrapper/> : <p>Condition not met -_-</p> 
              }
            </React.Suspense>
          } 
        />
        <Route  
          path="create"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              { <EmployersCreateComponent isEdit={false} isNavCollapsed={isNavCollapsed}/> }
            </React.Suspense>
          } 
        />
        <Route
          path=":parentCompName/:orgName"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              { (isDesktop || isLaptop) 
                ?  
                <EmployerComponent />
                : (isMobile || isTablet) ? 
                <MobileEmployerComponent  isNavCollapsed={isNavCollapsed}/> 
                : 
                <p>Condition not met -_-</p> }
            </React.Suspense>
          } 
        />
        <Route
          path=":parentCompName/:orgName/edit"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              { <EmployersCreateComponent isEdit={true} isNavCollapsed={isNavCollapsed}/> }
            </React.Suspense>
          } 
        />
        <Route
          path=":parentCompName/:orgName/recommendations/:searchState/:userType"
          element={
            <React.Suspense fallback={<>Loading....</>}>
                { (isDesktop || isLaptop) ? <RecommendationsComponent/> : (isMobile || isTablet) ? <MobileRecommendationsComponent/> : <p>Condition not met -_-</p> }
            </React.Suspense>
          } 
        />
      </Routes>
    </>
  )
};

export default EmployersComponent;


export const EmployerHeaderContent = () => {
  const navigate = useNavigate();
  const {isMobile, isTablet} = useDetectResize();
  const t = useTranslate();

  return(
    <SectionHeaderWrapper
      className="section-header"
    >
      <InlineContainer
        width={isTablet ? '90% !important' : '100%'}
        marginLeft= {isMobile ? 'none' : 'auto'}
        marginRight= {isMobile ? 'none' : 'auto'}
        flexGrow={(isMobile || isTablet) ? 'none' : 1}
      >
        <TitleBadgeWrapper>
          <SectionTitle 
            variant='h2'
            marginRight={(isMobile || isTablet) ? '5px' : '10px'}
          >
            {t('empList').toUpperCase()}
          </SectionTitle>
          <Typography
            className='badge'
            variant='caption'
            color='#050505'
            fontWeight={'bold'}
            style={{minHeight:'20px'}}
          > 
            <img 
              src={Icon.LockIcon} 
              alt='Private'
              style={{paddingRight: '5px'}}
            />
              {(isMobile) ? null : t('privateBtn')}
          </Typography>
        </TitleBadgeWrapper>

        <Button variant="contained" startIcon={<AddIcon />}
          onClick={()=>{navigate('create')}}
          sx={addItemBtnStyling}
        >
        {(isMobile) ? 'Add' : t('addEmployerBtn')}
        </Button>
      </InlineContainer>
    </SectionHeaderWrapper>
  )
}

