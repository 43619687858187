import React, { useState, useEffect } from 'react'
import ReusableTableComponent from '../../ReportingComponents/Reports/ReportsTable'
import useTranslate from '../../../hooks/useTranslate';
import { useSelector } from 'react-redux';
import { getEmployers } from '../../../store/employerSlice';
import { Employer } from '../../typescript/types';

import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowParams, GridToolbarQuickFilter, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import useGridLocale from '../../../hooks/useGridLocale';
import { getProvidersInOrganization } from '../../../store/providerSlice';

import { RootState } from '../../../store';
import { getTags } from '../../../store/keywordSlice';
import { useNavigate } from 'react-router-dom';
import {Box, width} from '@mui/system';
import useDetectResize from '../../../hooks/useDetectResize';
import EmployerMobileTableCard, { EmployerTabletCard } from './EmployerMobileTableCard';
import { ViewTableContainer } from '../../UI/StyledComponents/GlobalStyledComponents';
import useMobileTableWidths from '../../../hooks/useMobileTableWidths';
import CustomMobileSearchBarComponent from '../../SharedComponents/CustomMobileSearchBarComponent';

interface TableWidhtsProp {
  mobile: number;
  tablet: number;
}

const MobileEmployersTable = () => {
  const employerList = useSelector(getEmployers)
  const [tableData, setTableData] = useState<Employer[]>(employerList);
  const [pageSize, setPageSize] = React.useState(10);
  const t = useTranslate();
  let navigate = useNavigate();
  const TAGS = useSelector((state:RootState) => getTags(state, t));
  const allProviders = useSelector(getProvidersInOrganization);
  const gridLocaleSettings = useGridLocale();
  const {windowDimensions, isMobile} = useDetectResize();
  const {tableWidth} = useMobileTableWidths({mobilePercent: 0.8, tabletPercent: 0.65, mediaWidth: windowDimensions.w, isMobile: isMobile});

  const mobileCardHeight =  {
    '.MuiDataGrid-row' : {
      minHeight: '145px !important',
    },
    '.MuiDataGrid-cell': {
        minHeight: '145px !important',
    },
    '.MuiDataGrid-virtualScroller' : {
      marginTop: '10px !important'
    }
  }

  const tabletCardHeight =  {
    '.MuiDataGrid-row' : {
      minHeight: '145px !important',
    },
    '.MuiDataGrid-cell': {
        minHeight: '145px !important',
    },
    '.MuiDataGrid-virtualScroller' : {
      marginTop: '10px !important'
    }
  }

  //For Table view
  const columns: GridColDef[] = [
  
    {
      field: 'orgName',
      headerName: t('orgName'),
      type: 'string',
      width: tableWidth,
      
      renderCell: (params: GridRenderCellParams) => (
        isMobile ? 
        <EmployerMobileTableCard 
          emp={params.row as Employer}
        />
        :
        <EmployerTabletCard
          emp={params.row as Employer} />
      )
    }
  ];

  function displayData(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails){
    console.log(params.row); console.log(event); console.log(details);
    navigate(`${encodeURIComponent(params.row.parentCompName)}/${encodeURIComponent(params.row.orgName)}`, {state: params.row as Employer});
  }

  const sortListByTitle = () => {
    const sortedList = employerList.sort((a: Employer, b: Employer) => a.orgName < b.orgName ? -1 : 1);
     setTableData(currState => currState = employerList.sort());
  }

  return (
    <ViewTableContainer
      height={windowDimensions.h}
    >
      {ReusableTableComponent(
        tableData, 
        columns, 
        pageSize, 
        setPageSize, 
        displayData, 
        gridLocaleSettings, 
        t, 
        isMobile ? mobileCardHeight : tabletCardHeight,
        true, //hideHeader
        CustomMobileSearchBarComponent,
        true //isMobile
     )}
    </ViewTableContainer>
  )
}

export default MobileEmployersTable
