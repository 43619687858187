import React from 'react'
import useTranslate from '../../hooks/useTranslate';
import { Job } from '../typescript/types';
import SanitizeHTML from '../SharedComponents/SanitizeHTML';
import { Typography } from '@mui/material';

const JobDescription = (props: {job?: Job}) => {
  const {job} = props;
  const t = useTranslate();
  return (
    <>
      <Typography variant="h4" style={{color: 'var(--font-color)'}}>{t('jobDescriptionText').toUpperCase()}</Typography>
      <section className='listing'>
        {!!job?.description?.length ? <SanitizeHTML htmlString={job?.description}/>  : <Typography variant="body1">{t('noDetailsAvailable')}</Typography>}
      </section>
    </>
  )
}

export default JobDescription