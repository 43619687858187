import { Typography } from "@mui/material";
import { capitalize } from "../../../helpers/helperFunctions";
import {LargePuffLoader} from "../Animation/PuffLoader"


const HideFormOverlayComponent = (props:{noun:string}) => {
  const {noun} = props;
  return (
    <div className='waiting-overlay'>
      <div className='waiting-text'>
        <LargePuffLoader/>
        <br/>
        <Typography variant="h1">{capitalize(noun)} </Typography>
      </div>
    </div>
  )
}

export default HideFormOverlayComponent