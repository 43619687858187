import React, { useEffect, useState } from 'react'
import { Individual, Job } from '../../typescript/types'
import { IndMobileAvatar, InlineContainer, MobileCardTitle, MultiLineOverFlow, StatusBadge } from '../../UI/StyledComponents/GlobalStyledComponents'
import { Avatar, Box, Typography } from '@mui/material'
import { capitalize, getFullName, stringAvatar } from '../../../helpers/helperFunctions'
import useTranslate from '../../../hooks/useTranslate'
import { HrsBadge, IndStatusBadge } from '../../SharedComponents/SharedBadges'


const MobileTableCard = (props: {obj: Job | Individual, isJob: boolean}) => {
  const { obj, isJob } = props;
  const [shortAddr, setShortAddr] = useState<string>('');

  const t = useTranslate();

  useEffect(() => {
    if(obj.hasOwnProperty('address')){
      const addrArr = obj!.address.split(' ');
      const newShortStr = addrArr.slice(0,-3).join(' ');
      addrArr.length < 6 ? setShortAddr(currState => currState = obj?.address) : setShortAddr(currState => currState = newShortStr.substring(0, newShortStr.length-1))
    }
  }, [obj])

  return (
    <Box className='mob-job-card'
      sx={{
        display: 'inline-grid',
        height: '185px',
        width: '100%',
        padding: '15px 0px',
        gridTemplateRows: '65% 35%'
      }}
    >

      <Box className='mob-job-first'
        sx={{
          position: 'relative',
          textAlign:'center',
          padding: ''
        }}
      >
        <Box
          className='avatar-box' 
          sx={{
            width: isJob ? 45 : 75,
            marginLeft: 'auto',
            marginRight: 'auto',
            '& > div' : {
              width: '45px',
              height: '45px'
            }
          }}
        > 
         {isJob ? 
            <Avatar {...stringAvatar((obj as Job).employerOrganizationName.trim(), true, (obj as Job).title)}/>
          :
            <IndMobileAvatar {...stringAvatar(`${(obj as Individual)?.firstName} ${(obj as Individual)?.lastName}`)}/>}
        </Box>
        <MultiLineOverFlow 
          variant='h3' 
          fontWeight={900}
          sx={{paddingTop: '4px'}}
          lineCount={isJob ? 2 : 1}
        >
          {isJob ? (obj as Job).title : getFullName(obj! as Individual)}
        </MultiLineOverFlow>
        {isJob && 
        <MultiLineOverFlow 
          variant='subtitle1'
          sx={{paddingTop: '2px'}}
          lineCount={1}
        >
          { (obj as Job).employerOrganizationName}
        </MultiLineOverFlow>}

        <Box className='mob-job-badge'
          sx={{
            position: 'absolute',
            top: '-4%',
            right: '3%',
            width: 'min-content',
          }}
        >
          <IndStatusBadge isOpen={isJob ? (obj as Job)?.isJobActive : (obj as Individual).status} />
        </Box>
      </Box>

      <InlineContainer className='mob-job-second'
        display={isJob ? 'inline-flex' : 'inline-grid !important'}
        justifyContent='space-between'
        alignItems='flex-start'
        marginTop='5px'
        paddingTop= '10px'
        borderTop='1px solid #00000024'
        gridTemplateColumns='50% 1% 49%'
      >
        <Box
          textAlign='center'
          sx={{
            width: isJob ? '50%' : '100%'
          }}
        >
          <MobileCardTitle variant='h5'>
             {isJob ? 'CLOSING DATE' : 'LOCATION'}
          </MobileCardTitle>
          <MultiLineOverFlow
            variant='body1'
            marginTop='6px'
            lineCount={2}
            sx={{textWrap:  'wrap', width:'78%', marginLeft: 'auto', marginRight: 'auto'}}
          >
            {isJob ? (obj as Job).closeDate : shortAddr}
          </MultiLineOverFlow>
        </Box>
        <Box className='card-divider'
          sx={{
            width: 0,
            height: '40px',
            borderLeft: '1px solid #00000024'
          }}
        />
        <Box className='mob-card-hrs'
          textAlign='center'
          sx={{
            width: isJob ? '50%' : '100%'
          }}
        >
          <MobileCardTitle variant='h5'>
             {isJob ? 'HOURS TO FILL' : 'HOURS'}
          </MobileCardTitle>
            <Typography
              variant='body1'
              marginTop='6px'
            >
              { isJob ?  (obj as Job).weeklyHrs : `${(obj as Individual)?.filledHours}/${(obj as Individual)?.availableHours}`}
            </Typography>
        </Box>
      </InlineContainer>
    </Box>
  )
}

export default MobileTableCard

