import { styled } from "@mui/material/styles";
import { Box, Paper, Typography } from "@mui/material";

export const MobileNavListContainer = styled(Box)(({theme}) => ({
  position: 'absolute',
  zIndex: 4,
  top: 0,
  height: '100%',
  width: '100%',
  backgroundColor: `${theme.palette.primary.light}`,
  display:'inline-grid',
  alignItems: 'center',
}));

export const MobileNavBackDrop = styled(Box)(({theme}) => ({
  width: '100vw',
  height: '90vh',

  position: 'absolute',
  top: '60px',
  left: '0',

  background: `${theme.palette.primary.light}`,
  [theme.breakpoints.up('sm')]: {
    top: '86px',
    height: '93vh',
  }
}));

export const ProfileIconWrapper = styled(Box)(({theme}) => ({
  position: 'relative',
  cursor: 'pointer',
  zIndex: '5',
  height: '40px',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgrounduColor: 'white',
  borderRadius: '10px',
  margin: '0px 5px',

  '& > img' : {
    width: '40px',
    height: '40px',
    borderRadius: '25%',
    cursor: 'pointer',
    margin: '0px 5px',
    animation: 'opacity-transition 2.5s ease-in-out 0s reverse',
  },
  [theme.breakpoints.up('sm')] : {
    '& > img' : {
      width: '45px',
      height: '45px',
      borderRadius: '25%',
    },
  }
}));

export const MenuIconWrapper = styled(Box)(({theme}) => ({
  width: '35px',
  height: '35px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('md')] : {
    width: '45px',
    height: '45px',
  }
}));

export const NavTitle = styled(Typography)(({theme}) => ({
  marginLeft: '10px',
  color: '#0f1177',
  '@media (min-resolution: 1.5dppx)' : {
    '@media (max-height: 675px)' : {
      fontSize: '14px !important',
    }
  }
}));

export const NavListIcon = styled('img')(({theme}) => ({
  width: '7vw',
  height: 'auto',
  [theme.breakpoints.down('md')] : {
    width: '9vw',
    maxWidth: '35px'
  }

}))