import { Box, Button, Card, Typography } from '@mui/material'
import React from 'react';
import { PopupModalContainer } from '../StyledComponents/NotificationModalStyledComponents';


const ConfirmDeleteComponent = (props: {msg:string, confirmAction: () => void, goBack: () => void}) => {
  return (
    <PopupModalContainer 
      className="remove-history-confirmation"
    >
      <Typography variant='h4'>{props.msg}</Typography>
      <br/>
      <Box className='inline-around full-width'>
        <Button
          variant='contained' 
          onClick={props.confirmAction}
        >Yes
        </Button>
        <Button 
          variant='outlined'
          onClick={props.goBack}
        >No</Button>
      </Box>
    </PopupModalContainer>
  )
}

export default ConfirmDeleteComponent