import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import JobComponent from "../components/JobComponents/JobComponent";
import JobCreateComponent from "../components/JobComponents/JobCreateComponent";
import JobsTable from "../components/JobComponents/JobsTableComponent";
import RecommendationsComponent from "../components/RecommendationComponents/RecommendationsComponent";
import AddIcon from '@mui/icons-material/Add';
import Icon from "../asssets/icons";
import NavigationPlaceholderComponent from "../components/NavigationComponent/NavigationPlaceholderComponent";
import { addItemBtnStyling } from "../helpers/styling";
import PageTitleComponent from "../components/PageTitleComponent";
import useTranslate from "../hooks/useTranslate";
import useDetectResize from "../hooks/useDetectResize";
import MobileJobsTable from "../components/JobComponents/MobileTablet/MobileJobsTable";
import { InlineContainer, SectionHeaderWrapper, SectionTitle, TitleBadgeWrapper } from "../components/UI/StyledComponents/GlobalStyledComponents";
import MobileJobViewComponent from "../components/JobComponents/MobileTablet/MobileJobViewComponent";
import MobileRecommendationsComponent from "../components/RecommendationComponents/MobileTablet/MobileRecommendationsComponent";


const JobsComponent = (props: {isNavCollapsed:boolean}) => {
  const { isNavCollapsed } = props;
  const navigate = useNavigate();
  const t = useTranslate();
  const { windowDimensions, isMobile, isTablet, isLaptop, isDesktop } = useDetectResize();

  return(
    <>
      <Routes>
        <Route  
          path="/"
          element={
            <React.Suspense 
              fallback={<NavigationPlaceholderComponent />}
            >
              {

              (isDesktop || isLaptop) ? 
              <>
                <PageTitleComponent greeting={t('jobNav')}/>
                
                <Box className="employers-table-cont">
                  <SectionHeaderWrapper
                    className="section-header"
                  >
                    <InlineContainer
                    width={isTablet ? '90% !important' : '100%'}
                    marginLeft= {isMobile ? 'none' : 'auto'}
                    marginRight= {isMobile ? 'none' : 'auto'}
                    flexGrow={(isMobile || isTablet) ? 'none' : 1}
                  >
                      <TitleBadgeWrapper>
                        <SectionTitle 
                          variant="h2" 
                          className="table-text"
                        >
                          {t('jobList').toUpperCase()}&nbsp;
                        </SectionTitle>
                            <Typography 
                              className="badge"
                              variant="caption"
                              fontWeight={'bold'}
                              color='#050505'
                              style={{minHeight: '20px'}}
                            >
                              <img 
                                src={Icon.GlobeIcon} 
                                alt='Public'
                                style={{paddingRight:'5px'}}
                              >
                              </img>{t('public')}
                            </Typography>
                      </TitleBadgeWrapper>
                      <Button variant="contained" startIcon={<AddIcon />}
                        onClick={()=>{navigate('create')}}
                        sx={addItemBtnStyling}
                      >
                        {t('addJobBtn')}
                      </Button>
                    </InlineContainer>
                  </SectionHeaderWrapper>
                  
                  <JobsTable/>
                </Box>
              </>
              :
              <MobileJobsTable/>
              }
            </React.Suspense>
          } 
        />
        <Route  
          path="create"
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              { <JobCreateComponent isEdit={false} isNavCollapsed={isNavCollapsed} /> }
            </React.Suspense>
          } 
        />
        <Route
          path=":employerOrganizationName/:id" //jobId
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              { (isDesktop || isLaptop) 
                ?  
                <JobComponent/> 
                : (isMobile || isTablet) ? 
                <MobileJobViewComponent  isNavCollapsed={isNavCollapsed}/> 
                : <p>Condition not met -_-</p> 
              }
            </React.Suspense>
          } 
        />
        <Route
          path=":employerOrganizationName/:id/edit" //jobId
          element={
            <React.Suspense fallback={<NavigationPlaceholderComponent />}>
              {<JobCreateComponent isEdit={true} isNavCollapsed={isNavCollapsed}/> }
            </React.Suspense>
          } 
        />
        <Route
          path=":employerOrganizationName/:id/recommendations/:searchState/:userType"
          element={
            <React.Suspense fallback={<>Loading....</>}>
            { 
              (isDesktop || isLaptop) ?  
              <RecommendationsComponent/> 
              : (isMobile || isTablet) ? 
              <MobileRecommendationsComponent/> 
              : <p>Condition not met -_-</p> 
            }
            </React.Suspense>
          } 
        />
      </Routes>
    </>
  )
};

export default JobsComponent;

export const JobsHeaderContent = () => {
  const navigate = useNavigate();
  const {isMobile, isTablet} = useDetectResize();
  const t = useTranslate();

  return(
    <SectionHeaderWrapper
      className="table-header"
    >
      <InlineContainer
        width={isTablet ? '90% !important' : '100%'}
        marginLeft= {isMobile ? 'none' : 'auto'}
        marginRight= {isMobile ? 'none' : 'auto'}
        flexGrow={(isMobile || isTablet) ? 'none' : 1}
      >
        <TitleBadgeWrapper>
          <SectionTitle 
            variant='h2'
            marginRight={(isMobile || isTablet) ? '5px' : '10px'}
          >
            {t(('jobList')).toUpperCase() }
          </SectionTitle>
          <Typography
            className='badge'
            variant='caption'
            color='text.secondary'
            style={{ minHeight: '20px'}}
          > 
            <img 
              src={Icon.GlobeIcon} 
              alt='public'
              style={{paddingRight: '5px'}}
            />
              {(isMobile) ? null : t('public')}
          </Typography>
        </TitleBadgeWrapper>

          <Button variant="contained" startIcon={<AddIcon />}
            onClick={()=>{navigate('create')}}
            sx={addItemBtnStyling}
          >
          {(isMobile) ? 'Add' : t('addJobBtn')}
          </Button>
        </InlineContainer>
    </SectionHeaderWrapper>
  )
}
