import { useEffect, useState } from "react";
import NotificationComponent from "../components/NotificationComponent/NotificationComponent";
import Button from '@mui/material/Button';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Modal from "./UI/Popup/BackdropComponent";
import { hasUnreadNotifications } from "../store/notificationSlice";
import { useSelector } from "react-redux";

import Icon from '../asssets/icons';
import LogoutButton from "./AuthorizationComponents/AuthZeroLogoutButton";
import { getMyProviderDetails } from "../store/providerSlice";
import { useNavigate } from "react-router-dom";
import PuffLoader from "./UI/Animation/PuffLoader";
import TranslationComponent from "../transtlation/TranslationComponent";
import useTranslate from "../hooks/useTranslate";
import useDetectResize from "../hooks/useDetectResize";
import { LogoutOverlay, ModalOverlayPositioning, NotificationOverlay } from "./UI/StyledComponents/NotificationModalStyledComponents";
import Card from "./UI/Card/CardComponent";


interface HeaderProps {
  children?: React.ReactNode | string;
}

export default function HeaderComponent(props: HeaderProps) {
  const [dropDownNotification, setDropDownNotification] = useState<boolean>(false);
  const [dropDownProfile, setDropDownProfile] = useState<boolean>(false);
  const [profileIcon, setProfileIcon] = useState<string>('');
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const unreadNotifications = useSelector(hasUnreadNotifications);
  const myProviderDetails = useSelector(getMyProviderDetails);
  const {windowDimensions} = useDetectResize();

  useEffect(()=>{
    setProfileIcon(currState=>{return currState = myProviderDetails?.photoUrl});
    const displayImgTimer = setTimeout(()=>{
      setShowProfile(true);
    }, 2500);

    return () => {
      clearTimeout(displayImgTimer);
    }
  }, [myProviderDetails?.photoUrl]);

  return(
    <header className="header-component">
      <div className="header-right inline-a">
        <TranslationComponent/>
        <div className="header-notification-icon" 
          onClick={()=>{
            setDropDownNotification((state)=> !state ); 
            setDropDownProfile(false)
            }}
          >
          {unreadNotifications ? <img src={Icon.YesNot} alt='unread-notification'/> : <img src={Icon.NoNot} alt="notifications" />}
        </div>

        <div className="header-profile-icon"
          onClick={()=>{
            setDropDownProfile((state) => !state); 
            setDropDownNotification(false)
          }}
        >
        { 
          !showProfile 
          &&
          <PuffLoader loading={!showProfile} color={'#0F1177'}/>
        }

        { 
          myProviderDetails?.photoUrl?.startsWith('https://') ?  
          <img className= {`profile-img-header`} src={profileIcon} alt='headshot' loading="lazy"/> 
          : 
          <AccountCircleIcon fontSize='large' color='primary'/>
        }
        </div>
      </div>

          {
            dropDownNotification && 
            <Modal 
              onHide={()=>{setDropDownNotification(false)}} 
              extraStyling='modal-header-positioning'
            >
              <NotificationOverlay
                className={`modal-overlay-wrapper`}
                onClick={(e)=>{e.stopPropagation()}}
                top = {windowDimensions.h }
                right = {windowDimensions.w }
              >
                <NotificationComponent closeModal={() => {setDropDownNotification(false)}}/>
              </NotificationOverlay>
            </Modal>
          }

          {
            dropDownProfile && 
            <Modal 
              onHide={()=>{setDropDownProfile(false)}} 
              extraStyling='modal-header-positioning'
            >
              <LogoutOverlay
                className={`modal-overlay-wrapper`}
                onClick={(e)=>{e.stopPropagation()}}
                top = { windowDimensions.h }
                right = { windowDimensions.w }
              >
                <ProfileAndLogoutComponent/>
              </LogoutOverlay>
            </Modal>
          }
    </header>
  )
};


export const ProfileAndLogoutComponent = () => {
  const navigate = useNavigate();
  const t = useTranslate();
  const cardStyle = {
    height: 'fit-content',
    minWidth: '120px',
    background: 'white',
    color: 'black',
    maxWidth: '450px',
    display: 'inline-grid',
    borderRadius: '10px',
    border: '2px solid lightgrey',
    marginTop: '10px',
    animation: 'rotateMenu 250ms ease-in',
  }

  return(
    <Card  customSx={cardStyle} >
      <Button 
        variant='text'
        sx={{
          fontWeight: '600',
          fontFamily: 'Nunito',
          color: '#444444',
        
          '&:hover':{
            background: 'var(--primary-color)',
            color: 'white'
          }} 
        }
        onClick={()=>{navigate('/profile')}}
      >
        {t('settingsTitle')}
      </Button>
      <LogoutButton/>
    </Card>
  )
}
