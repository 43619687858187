import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, GridRenderCellParams, GridValueGetterParams, MuiEvent, GridToolbar } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllJobs, getJobStatus, setJobStatus } from '../../store/jobSlice';

import { convertTagsToString, displaySchedule } from '../../helpers/displayOptions';
import dayjs from 'dayjs';
import { getTags } from '../../store/keywordSlice';
import { getInitialsForAvatar, stringAvatar, stringToColor } from '../../helpers/helperFunctions';
import { Avatar, capitalize } from '@mui/material';
import { useState, useEffect } from 'react';
import { Job } from '../typescript/types';
import { useAppDispatch } from '../typescript/hooks';
import useTranslate from '../../hooks/useTranslate';
import useGridLocale from '../../hooks/useGridLocale';
import { RootState } from '../../store';
import SanitizeHTML from '../SharedComponents/SanitizeHTML';

export const origStyling = {
  minHeight: '60px',
  border: 'none',

  "*" : {
    scrollbarWidth: 'thin',
    scrollbarColor: '#4f5f71 #d3d9de',
  },
  "*::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },
  "*::-webkit-scrollbar-track": {
    backgroundColor: "#d3d9de",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "var(--secondary-color)",
    borderRadius: 2.5,
  },
  '.MuiDataGrid-columnHeaderTitleContainerContent > span' : {
    padding: '0px',
  },
  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiInputBase-input' : {
    fontFamily: 'Nunito',
    fontSize: '0.9em',
  },
  '.MuiTablePagination-actions' : {
    fontSize: '0.9em',
  },
  '.MuiDataGrid-footerContainer' : {
    marginBottom: '2em',
  },
  '.MuiDataGrid-root' : {
    minHeight: '82vh',
  },
  '.MuiDataGrid-main' : {
    overflow: 'unset',
  },
  '.MuiDataGrid-virtualScroller' : {
    marginTop: '60px!important',
  },
  '.MuiDataGrid-cellCheckbox' : {
    borderRadius: '15px 0px 0px 15px',
    backgroundColor: 'var( --background)',
    minHeight: '98px !important',
    display: 'none',
  },
  '.MuiDataGrid-columnHeader' : {
    '&:first-of-type' : {
      backgroundColor: 'var( --background)',
      borderRadius: '15px 0px 0px 15px',
      minHeight: '40px',
      position: 'sticky',
      left: '0',
      '.MuiDataGrid-columnSeparator--sideRight' : {
        display: 'none',
      },
    },
  },
  '.MuiDataGrid-columnHeaderCheckbox' : {
    display: 'none',
  },
  '.MuiDataGrid-columnHeaders' : {
    minHeight: '65px !important',
    maxHeight: '65px !important',
    lineHeight: '40px !important',
    borderBottom: 'none',
    fontFamily: 'LexendDeca',
    color: '#4f5f71',

    "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
      fontSize: '1.00em',
    }
  },
  '.MuiDataGrid-columnHeaderTitle' : {
    fontWeight: 600,
  },
  '.MuiDataGrid-columnHeadersInner':{
    margin: '20px 0px',
    border: '1px solid #00000024',
    borderRadius: '15px',
  },
  
  '.MuiDataGrid-row' : {
    borderRadius: '15px',
    border: '1px solid #00000024',
    margin: '2px 0px',
    minHeight: '100px!important',
    alignItems: 'center',
    fontFamily: 'Nunito',
    fontWeight: 500,
    color: '#050505',
    "@media (max-resolution: 1dppx) and (min-height: 1030px)" : {
      fontSize: '1.00em',
    },
    "@media (min-resolution: 1.25dppx) and (min-width: 1030px)" : {
      minHeight: '75px !important'
    }
  },
  '.MuiDataGrid-cell' : {
    border: 'none',
    minHeight: '98px !important',
    "@media (min-resolution: 1.25dppx) and (min-width: 1030px)" : {
      minHeight: '73px !important'
    }
  },
  '.MuiDataGrid-toolbarContainer' : {
      '.MuiButton-root' : {
        fontFamily: 'LexendDeca',
        fontWeight: '600',
      }
  }
};


 export default function JobsTable(){
  
  const jobList = useSelector(getAllJobs);
  const [pageSize, setPageSize] = useState<number>(10);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslate();
  const TAGS = useSelector((state:RootState) => getTags(state, t));
  const [tableData, setTableData] = useState<Job[]>(jobList);
  const jobStatus = useSelector(getJobStatus);
  const gridLocaleSettings = useGridLocale();
  
  useEffect(()=>{
    setTableData(currState => currState = [...jobList]);
  }, [jobList]);

  useEffect(()=>{
    if(jobStatus === 'posted' || jobStatus === 'updated'){
      setTableData(currState => currState = [...tableData]);
      console.log('updated table');
      dispatch(setJobStatus('succeeded'));
    }
  },[jobStatus, jobList, tableData, dispatch])

  const columns: GridColDef[] = [
    { field: 'title',
      headerName: t('jobTitleJobList'),
      width: 300,
      cellClassName: 'table-first-column',
      headerClassName: 'table-first-column',
      renderCell: (params: GridRenderCellParams) => (
        <div className='employer-img'>
          <Avatar {...stringAvatar(params.row.employerOrganizationName.trim(), true, params.value)}/>
          &nbsp;&nbsp;
          <p id='employer-img-text'>{params.value}</p>
        </div>
      )
    },
    {
      field: 'employerOrganizationName',
      headerName: t('orgName'),
      type: 'string',
      width: 250,
      
    },
    {
      field: 'providerName',
      headerName: t('supportLead'),
      type: 'string',
      width: 250,
    },
    {
      field: 'address',
      headerName: t('address'),
      type: 'string',
      width: 160,
    },
    {
      field: 'description',
      headerName: t('description'),
      type: 'string',
      width: 160,
      valueGetter: ( params: GridValueGetterParams) => `${params.row.description}`,
      renderCell: (params: GridRenderCellParams) => (<SanitizeHTML htmlString={params.formattedValue} /> )
    },
    {
      field: 'tags',
      headerName: t('keywords'),
      type: 'string',
      width: 180,
      cellClassName: 'tags-column',
      valueGetter: ( params: GridValueGetterParams) => 
      `${convertTagsToString(params.row.tags, true, TAGS?.all)}`,
      renderCell: (params: GridRenderCellParams) => (
        <div>
          {
            params.value.length !== 0 
            ? 
            params.value
              .split(',')
                .slice(0,2)
                  .map( (keyword: string, index: number) => (
                    <p key={index + '-' + keyword}>{keyword}</p>
                  ))
            :
            null
          }
        </div>
      )
    },
    
    {
      field: 'closeDate',
      headerName: t('closeDate'),
      type: 'string',
      width: 150,
      valueGetter: ( params: GridValueGetterParams) => 
      `${ params.row.closeDate === 'N/A' ? capitalize(t('open')) : dayjs(params.row.closeDate).unix() < dayjs().unix() ? capitalize(t('closed')) : dayjs(params.row.closeDate).format('MMM DD, YYYY') }`,
    },
    {
      field: 'reqSkills',
      headerName: t('reqSkills'),
      type: 'string',
      width: 150,
    },
    {
      field: 'weeklyHrs',
      headerName: t('hrsPerWk'),
      type: 'string',
      width: 160,
    },
    {
      field: 'daysAvailable',
      headerName: t('schedule'),
      type: 'string',
      width: 160,
      valueGetter: ( params: GridValueGetterParams) => 
      `${displaySchedule(params.row.daysAvailable, t)}`,
    },
    {
      field: 'idealStartDate',
      headerName: t('prefStartDate'),
      type: 'string',
      width: 180,
    },
    {
      field: 'areCertsReq',
      headerName: t('certReq'),
      type: 'string',
      width: 200,
      valueGetter: ( params: GridValueGetterParams) => 
      `${params.row.areCertsReq ? t('yes'): t('no')}`,
    },
    {
      field: 'isTrainingProvided',
      headerName: t('trainingAvail'),
      type: 'string',
      width: 180,
      valueGetter: ( params: GridValueGetterParams) => 
      `${params.row.isTrainingProvided ? t('yes'): t('no')}`,
    },
    {
      field: 'jobCoachEnabled',
      headerName: t('openToTraining'),
      type: 'string',
      width: 180,
      valueGetter: ( params: GridValueGetterParams) => 
      `${params.row.jobCoachEnabled ? t('yes'): t('no')}`,
    },
    {
      field: 'isTrainingReq',
      headerName: t('trainingReq'),
      type: 'string',
      width: 180,
      valueGetter: ( params: GridValueGetterParams) => 
      `${params.row.isTrainingReq ? 'Yes' : 'No'}`,
    },
    {
      field: 'createdAt',
      headerName: t('onboardDate'),
      type: 'string',
      width: 180,
      valueGetter: ( params: GridValueGetterParams) => `${dayjs(params.row.createdAt*1000).format('MMM DD, YYYY') || 'empty'}`,
    },
  ];

  function displayData(params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails){
    //console.log(params.row); console.log(event); console.log(details);
    navigate(`${encodeURIComponent(params.row.employerOrganizationName)}/${params.row.id}`, {state: params.row});
  }

  return (
      <DataGrid
        getRowId={(row) => row.id} 
        rows={tableData}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[5,10,15,20]}
        checkboxSelection
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        onRowDoubleClick={displayData}
        pagination
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          pagination: {
            labelRowsPerPage: t('rowPerPage')
          }
        }}
        localeText={gridLocaleSettings}
        {...tableData}
        sx={origStyling}
      />
  );
}



