import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { Control, Controller, SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../components/typescript/hooks";
import Card from "../components/UI/Card/CardComponent";
import Modal from "../components/UI/Popup/BackdropComponent";
import { getFullName } from "../helpers/helperFunctions";
import { getAccessToken } from "../store/authSlice";
import { getMyProviderDetails, postProviderInvite } from "../store/providerSlice";
import inviteSuccess from "../asssets/invite-animation.gif";
import AltSuccessComponent from "../components/UI/Popup/AltSuccessComponent";
import useTranslate from "../hooks/useTranslate";
import { FormRow, InviteInfoText, ProfileSettingsBox, ReqErrorMsg } from "../components/UI/StyledComponents/FormsStyledComponents";
import { Box, Typography } from "@mui/material";
import { InlineContainer } from "../components/UI/StyledComponents/GlobalStyledComponents";
import useDetectResize from "../hooks/useDetectResize";
import { formInputStyles } from "../helpers/styling";
import PageTitleComponent from "../components/PageTitleComponent";
import { CenteredOverlay } from "../components/UI/StyledComponents/NotificationModalStyledComponents";

interface InviteFormData{
  fName:string;
  lName:string;
  email:string;
  providerName:string;
}

const InvitesComponent = () => {
  const dispatch = useAppDispatch();
  const accessToken = useSelector(getAccessToken);
  const { register, handleSubmit, formState: { errors }, reset, control, setValue, setFocus } = useForm<InviteFormData>();
  const myProviderDetails = useSelector(getMyProviderDetails);
  const [showInviteSuccess, setShowInviteSuccess] = useState<boolean>(false);
  const t = useTranslate();
  const {isMobile, isTablet, windowDimensions} = useDetectResize();

  useEffect(()=>{
    console.info('Invite Commponent: ', myProviderDetails);
  },[myProviderDetails, isMobile, isTablet]);

  const onSubmit: SubmitHandler<InviteFormData> = (data) => {
    const providerName =  getFullName(myProviderDetails);
    console.info('MyProviderDetails: ', myProviderDetails);
    console.info('Details', data);
    // wait for response from backend, then give success modal

    dispatch(postProviderInvite({data:{email: data.email, fName: data.fName, lName: data.lName, orgId: myProviderDetails.orgId, providerName: providerName}, token: accessToken!}))
    .then(unwrapResult)
      .then(() => {
        setShowInviteSuccess(true);
        reset(); 
      });
  }

  console.log(errors);

  return(
    <>
      <Card 
        className="card-invite"
        customSx={ {
          width: '100%',
          height: '80vh',
          maxHeight: '885px',
          display: 'flex',
          alignItems: (isMobile || isTablet) ? 'flex-start' : 'center' ,
          border: (isMobile || isTablet) ? 'none' : '2px solid var( --background)' ,
          justifyContent: 'center'}}
      >
        <Box className='invite-form-wrapper'>

          <InviteInfoText>
           { (!isMobile && !isTablet) && <Typography variant='h1' color= 'black'>{t('inviteTitle')}</Typography> }
            <Typography sx={{color: 'text.primary'}}>{t('inviteText')}</Typography>
          </InviteInfoText>

          <form 
            onSubmit={handleSubmit(onSubmit)}
          >
            <ProfileSettingsBox>
              <Box
                height='fit-content'
                maxWidth={(isMobile || isTablet) ? 'unset' : '450px'}
                marginTop={(!isMobile || !isTablet) ? '20px' : '0' }
              >
                <FormRow
                  display={(isMobile || isTablet) ? 'inline-grid !important' : 'inline-flex !important'}
                  justifyContent={(isMobile || isTablet) ? 'space-around' : 'space-between'}
                  height='fit-content'
                  maxHeight={(isMobile || isTablet) ? '200px' : 'unset'}
                >
                  <Box 
                    className='field-error'
                    width={isMobile ? '320px' : isTablet ? '550px' : '215px'}
                  >
                    <ControllerTextField
                      control={control}
                      register={register}
                      name="fName"
                      placeholder={t('fName')}
                      className="full-width"
                      rules={{
                        required: 'This field is required',
                        maxLength: 80
                      }}
                    />   
                    {errors?.fName && <ReqErrorMsg>{errors?.fName.message}</ReqErrorMsg>}
                  </Box>

                  <Box 
                    className='field-error'
                    width={isMobile ? '320px' : isTablet ? '550px' : '215px'}
                  >
                      <ControllerTextField
                        control={control}
                        register={register}
                        name="lName"
                        placeholder={t('lName')}
                        className="full-width"
                        rules={{
                          required: 'This field is required',
                          maxLength: 80
                        }}

                      />    
                    {errors?.lName && <ReqErrorMsg>{errors?.lName.message}</ReqErrorMsg>}
                  </Box>
                </FormRow>
                <FormRow
                  display={(isMobile || isTablet) ? 'block!important' : 'inline-flex'}
                  justifyContent={(isMobile || isTablet) ? 'space-around' : 'flex-end'}
                  width={isMobile ? '320px!important' : isTablet ? '550px!important' : '100%'}
                  height={'fit-content'}
                  margin={(isMobile || isTablet ) ? 'auto' : 'unset'}
                  marginTop='0px'
                >
                  <div className='field-error'>
                    <ControllerTextField
                      control={control}
                      register={register}
                      name="email"
                      placeholder={"Email"}
                      className="full-width"
                      helperText= {t('inviteEmailMsg')}
                    />   
                    {errors?.email && <ReqErrorMsg>{errors?.email.message}</ReqErrorMsg>}
                    {errors?.email?.type === 'pattern' && <Typography variant="body1">{t('emailErrorMsg')}</Typography>}
                  </div>
                </FormRow>
              </Box>
            
              <InlineContainer
                justifyContent={'flex-end'}
                display={(isMobile || isTablet) ? 'block!important' : 'inline-flex'}
                margin={(isMobile || isTablet ) ? 'auto' : 'unset'}
                width={(isMobile || isTablet ) ? 'unset!important' : '100%'}
                marginTop={(isMobile || isTablet ) ? '50px' : '20px'}
              >
                <Button type="submit" variant='contained'>{t('inviteBtn')}</Button>
              </InlineContainer>
            </ProfileSettingsBox>
          </form>
        </Box>
        {showInviteSuccess 
          && 
          <Modal 
            className='modal invite-modal'
            onHide={()=>[setShowInviteSuccess(false)]} 
          >
            <CenteredOverlay
              onClick={(e)=>{e.stopPropagation()}}
              top = {windowDimensions.h}
              right = {windowDimensions.w}
            >
              <AltSuccessComponent 
                message='Invitation sent!' animation={inviteSuccess}
              />
            </CenteredOverlay>
          </Modal>}
      </Card>
    </>
  )
};

interface PropsControllerTextField {
  register: UseFormRegister<InviteFormData>;
  customFunction?: (e: string) => void;
  control: Control<InviteFormData, any>;
  name: any;
  rules?: {
    required?: boolean | string;
    pattern?: { value: RegExp; message: string };
    maxLength?: number;
  };
  placeholder: string;
  className: string;
  id?:string;
  label?: string;
  focused?:boolean;
  type?: string;
  helperText?:string;
  disabled?: boolean;
}

const ControllerTextField = (props: PropsControllerTextField) => {
  const {
    control,
    name,
    placeholder,
    rules,
    register,
    className,
    customFunction,
    label,
    focused,
    type,
    id,
    helperText,
    disabled
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <TextField
          {...register(name)}
          id={id ? id :"outlined-required"}
          helperText={helperText}
          className={className}
          sx={formInputStyles}
          type={type ? type : 'text'}
          placeholder={placeholder}
          label={label ? label : placeholder}
          value={value || ""}
          autoFocus={focused ? true : false}
          disabled={disabled || false}
          onChange={(e) => {
            if (customFunction) {
              onChange(customFunction(e.target.value));
            } else {
              onChange(e.target.value);
            }
          }}
        />
      )}
    />
  );
};


export default InvitesComponent;


export const MobileInviteHeader = () => {
  const t = useTranslate();
  return(
    <PageTitleComponent greeting={t('inviteTitle')} />
  )
}