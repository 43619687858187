//MUI
import {  Button,
  Checkbox,
  MenuItem,
  TextField,
  Switch,
  IconButton,
  Typography,
  InputLabel,
  Tooltip,
  FormHelperText, 
  SxProps,
  Box
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//React & Forms
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { GroupBase, MultiValue, SingleValue,} from "react-select";
import {  useForm,  useFieldArray,  SubmitHandler,  Controller,  Control,  UseFormRegister,  UseFormSetValue } from "react-hook-form";
//Components
import Card from '../UI/Card/CardComponent';
import dayjs from "dayjs";
import Modal from "../UI/Popup/BackdropComponent";
import SuccessComponent from "../UI/Popup/SuccessComponent";
import ConfirmDeleteComponent from "../UI/Popup/ConfirmDeleteComponent";
// import { TAGS } from "../../helpers/constants";
import { Employment, Individual, NoteBullet, Provider, SelectType } from "../typescript/types";
import { getMyProviderDetails, getProvidersInOrganization } from "../../store/providerSlice";
import { deleteIndividualFile, deleteIndividualRecord, getAllIndividuals, postIndividual, updateIndividual } from "../../store/individualSlice";
import { formatOnlyDecimals, formatOnlyNumbers, formatPhoneNumber } from "../../helpers/formatNumber";
import { useAppDispatch } from "../typescript/hooks";
import { getPreviousTags } from "../../helpers/displayOptions";
import { unwrapResult } from "@reduxjs/toolkit";
import useAutoCompleteAPI from "../../hooks/useAutoCompleteAPI";
import { appendRemainingDetails, capitalize, getShortDays } from "../../helpers/helperFunctions";
import { getAccessToken, getSignedUrl } from "../../store/authSlice";
import axios from "axios";
import PageTitleComponent from "../PageTitleComponent";
import { formDescriptionStyling, formInputStyles, formTheme, quillSelectLabel, reactSelectCustomStyles, reactSelectLabel } from "../../helpers/styling";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import GoBackBtn from "../UI/Buttons/GoBackBtn";
import Icon from "../../asssets/icons";
import { is14YearsInMilliseconds, regXEmail, regXPhoneNumber, SCHEDULE_OPTIONS } from "../../helpers/constants";

import { getInterests, getSkills, getTags } from "../../store/keywordSlice";
import UploadMultiFileComponent from "../FormComponents/UploadMultiFileComponent";
import HideFormOverlayComponent from "../UI/Popup/HideFormOverlayComponent";
import ControllerAutoComplete, { GlobalFormData } from "../FormComponents/ControllerAutoComplete";
import useAutoCompleteItems from "../../hooks/useAutoCompleteItems";
import useTranslate from "../../hooks/useTranslate";
import DeleteResourceComponent from "../SharedComponents/DeleteResourceComponent";
import { RootState } from "../../store";
import SubmitKeywordSuggestions from "../SharedComponents/SubmitKeywordSuggestions";
import PopupMessageComponent from "../UI/Popup/PopupMessageComponent";
import ReusableToggleComponent from "../SharedComponents/ReusableToggleComponent";
import { CustomMuiSelect, FormComponentWrapper, FormContainer, MobileFormFieldContainer, FormFieldContainer, FormPanel, FormRow, HorizontalScrollContainer, PanelCarousel, FormTextInput, CommentsWrapper, ReqErrorMsg, TagFormRow } from "../UI/StyledComponents/FormsStyledComponents";
import { DeleteOverlay, KeywordSubmitOverlay } from '../UI/StyledComponents/NotificationModalStyledComponents';
import useDetectResize from "../../hooks/useDetectResize";
import StepperWidget from "../EmployerComponents/SharedWidgets/StepperWidget";
import { InlineContainer, StackContents } from "../UI/StyledComponents/GlobalStyledComponents";
import { deleteEmployer } from "../../store/employerSlice";
import { ErrorSharp } from "@mui/icons-material";

export interface IndividualFormData extends Partial<Individual> {
  resumeUpload: null | File;
  coverLetterUpload:null | File;
  references:FileList | null;
  certifications:FileList | null;
  creator?:string;
  calculateMetrics: boolean;

}

const IndividualCreateComponent = (props: {isEdit?:boolean, isNavCollapsed: boolean}) => {
  let defaultValues: IndividualFormData = {
    leadProviderId: "",
    leadProviderName: '',
    leadProviderOrgId: '',
    profileImg: '',
    firstName: "",
    lastName: "",
    pronouns: "",
    agencyId: "",
    govId: "",
    preferredComms: "",
    requiredAccommodation: "N/A",
    phone: "",
    email: "",
    address: "",
    longitude: 0,
    latitude: 0,
    intakeDate: dayjs().format(),
    dob: '',
    policeCheck: '',
    creditCheck: '',
    preferredRoles: "",
    minRate: 0,
    maxRate: 100,
    skills: [],
    interests: [],
    restrictions: [],
    restrictionsText:'',
    status: true,
    daysAvailable: [1, 1, 1, 1, 1, 0, 0],
    availableHours: 0,
    filledHours: 0,
    notes: [],
    fileAttachments: [], // xyzAttachments[] are references to aws presingedURLS
    referenceAttachments: [], // xyzAttachments[] are references to aws presingedURLS
    certificationAttachments: [], // xyzAttachments[] are references to aws presingedURLS
    employmentRecord: [],
    resumeUpload: null, //x4 nulls are all used to handle File uploads and are not sent
    coverLetterUpload:null,
    references: null,
    certifications: null,
    setOpenTS: dayjs().unix(), //used to create a 'check-in' notifciation. This updates as filled hours updates, and becomes -1 when status === false.
    calculateMetrics: true
  };

  const t = useTranslate();
  // Form
  const { isEdit, isNavCollapsed } = props;
  const keywords = useSelector((state:RootState) => getTags(state, t));
  const skillKeywords = useSelector((state: RootState) => getSkills(state, t));
  const interesKeywords = useSelector((state: RootState) => getInterests(state, t));

  const { handleSubmit, control, register, setValue, getValues, formState: { errors, isValid }, reset, setFocus } = useForm({defaultValues});
  const { fields, prepend, remove } = useFieldArray({ control, name: "employmentRecord", }); 
  const { fields: fieldsNote,  prepend: prependNotes, remove: removeNote} = useFieldArray({control, name:'notes'});
  
  // Navigation 
  const [displaySuccessModal, setDisplaySuccessModal] = useState<boolean>(false);
  const [displayKeywordSubmit, setDisplayKeywordSubmit] = useState<boolean>(false);
  const [individualPath, setIndividualPath] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch(); 
  const [newIndividual, setNewIndividual] = useState<Individual>();

  // Tags
  const [interests, setInterests] = useState<number[]>([]); 
  const [skills, setSkills] = useState<number[]>([]); 
  const [restrictions] = useState<number[]>([]); 
  
  // Redux, Hooks and States

  const { state } = useLocation();
  const individual: Individual = state as unknown as Individual;
  const allIndividuals = useSelector(getAllIndividuals);
  const [skillOptions, setSkillsOptions] = useState<any>(isEdit ? getPreviousTags(individual?.skills!, skillKeywords as unknown as SelectType[]) : null);
  const [interestOptions, setInterestsOptions] = useState<any>(isEdit ? getPreviousTags(individual?.interests!, interesKeywords as unknown as SelectType[]) : null); 
  const [hideFormOverlay, setHideFormOverlay] = useState<boolean>(false);
  const providerDetails = useSelector(getMyProviderDetails);
  const providers = useSelector(getProvidersInOrganization);
  const [ isAddressValid, setAddressValid ] = useState<boolean>(isEdit ? true : false);
  const myDetails = useSelector(getMyProviderDetails);
  const accessToken = useSelector(getAccessToken);
  const {autoComplete, setWidget} = useAutoCompleteAPI(handlePlaceSelect);

  // File Attachment references (presignedURL)
  const [fileNames, setFileNames] = useState<string[]>(!isEdit ? ['empty','empty'] : individual?.fileAttachments.length === 0 ? ['empty', 'empty'] : individual?.fileAttachments );
  const [refFiles, setRefFiles] = useState<string[]>(!isEdit ? ['empty'] : individual?.referenceAttachments.length === 0 ? ['empty'] : individual?.referenceAttachments );
  const [certFiles, setCertFiles] = useState<string[]>(!isEdit ? ['empty'] : individual?.certificationAttachments.length === 0 ? ['empty'] : individual?.certificationAttachments);
  const [activeStep, setActiveStep] = useState<number>(0);

  const {optionItems, setOptionItems } = useAutoCompleteItems();
  const { isDesktop, isLaptop, isMobile, isTablet, windowDimensions } = useDetectResize();
  //builds the providers user can select from.
  const providerOptions: SelectType[] = providers.map((provider) => {
    return {
      value: provider.id,
      label: `${provider.firstName} ${provider?.lastName}`,
    } as unknown as SelectType;
  });

  //Used to display user's saved tags, each type of tag set in the react-select input's default value.
  const [defaultProvider, setDefaultProvider] = useState<SingleValue<SelectType>>(isEdit ? providerOptions.find(provider => provider.value === individual.leadProviderId)! : { value: "default", label: t('selectProvider') }); //build function called getProvider() to build select input
 
  //handles the popup for confirming employment record delete, employmentIndex passes the fieldArray object to be deleted. confirm...RecordDelete toggles popup.
  const [employmentIndex, setEmploymentIndex] = useState<number>();
  const [confirmEmploymentRecordDelete, setConfirmEmploymentRecordDelete] = useState<boolean>(false);

  // stepperWidget helpers
  const [disableFormSubmit, setDisableFormInput] = useState<boolean>(false);
  const [formWidthStyling, setFormWidth] = useState<SxProps>({});
  const [displayCompletedMsg, setDisplayCompletedMsg] = useState<boolean>(false); 
  const warnUserMsg = t('deleteIndividualWarningMsg');


  const formRef = useRef<null | HTMLDivElement>(null);
  const refPanel_1 = useRef<null | HTMLDivElement>(null);
  const refPanel_2 = useRef<null | HTMLDivElement>(null);
  const refPanel_3 = useRef<null | HTMLDivElement>(null);
  const refPanel_4 = useRef<null | HTMLDivElement>(null);
  const refPanel_5 = useRef<null | HTMLDivElement>(null);
  const refPanel_6 = useRef<null | HTMLDivElement>(null);

  
  //on loading of form, check if it's a new form or an edit, process values if record is pre-existing one
  useEffect(()=>{
      const input = document.getElementById("autocomplete") as HTMLInputElement;
      setWidget(input);
      console.log(individual);
      if(isEdit){
        setSkills(currState => currState = [...individual!.skills]);
        setInterests(currState => currState = [...individual!.interests]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let modifiedValues = {
          ...individual, 
          resumeUpload: null, 
          coverLetterUpload: null, 
          references: null,
          certifications: null,  
          notes: JSON.parse(individual!.notes as unknown as string || ''),
          setOpenTS: individual.setOpenTS, //each update will write previous unless changed @ filled hours.
          calculateMetrics: false,
        };

        setValue('longitude', individual.longitude);
        setValue('latitude', individual.latitude);
        reset({...modifiedValues});
        setSkillsOptions((currState: any) => {return currState = getPreviousTags(skills, skillKeywords! as unknown as SelectType[])});  
        setInterestsOptions((currState: any) => {return currState = getPreviousTags(interests, interesKeywords! as unknown as SelectType[]) });
      }else{
        console.log('resetting form');
        setAddressValid(currState => currState = false);
        reset({...defaultValues});
      }
  }, []);

  // used to build form select options.
  useEffect(()=>{
    setOptionItems(allIndividuals.map((individual:Individual) => { return individual.firstName.trim() + ' ' + individual.lastName.trim() }))
  }, [allIndividuals, setOptionItems])

  //used to populate the form these states are only client side UI, see setSkills/setInterests for DB saved data.
  useEffect(()=>{
    setSkillsOptions((currState: any) => {return currState = getPreviousTags(skills, skillKeywords! as unknown as SelectType[])});  
    setInterestsOptions((currState: any) => {return currState = getPreviousTags(interests, interesKeywords! as unknown as SelectType[]) } );
  }, [interests, skills]);

  // if someone resizes the window, this updates all dimensions TODO: test and polish UI for resizing (panels specifically)
  useEffect(()=>{
    console.log("Adjusted form dimenstions...")
    setFormWidth((currState:any) => currState = {width: `${formRef?.current!.offsetWidth}px !important`});
  }, [formRef, windowDimensions.w, windowDimensions.h, isNavCollapsed ]);

  // used to properly align panel on scroll for desktop, little bit of a hack, but works, otherwise the focus event doesn't snap align panels, giving bad UI.
  useEffect(() => {

    console.log(errors);

    if(errors?.leadProviderId || errors?.address || errors?.dob || errors?.firstName || errors?.lastName || errors?.phone) {
      setActiveStep(0)
      return refPanel_1.current!.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
    } else if(errors?.skills || errors?.interests){
      setActiveStep(1)
      return refPanel_2.current!.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
    }else if(errors?.minRate || errors?.availableHours) {
      setActiveStep(2)
      return refPanel_3.current!.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'})
    }
  },
  [
    errors?.leadProviderId,
    errors?.address,
    errors?.dob,
    errors?.firstName,
    errors?.lastName,
    errors?.phone,
    errors?.skills,
    errors?.interests,
    errors?.minRate,
    errors?.availableHours
  ]);

  function handlePlaceSelect(){
    let addressObject = autoComplete!.getPlace();

    if(addressObject !== undefined){
      let addressName = addressObject.name + ', ' + appendRemainingDetails(addressObject);
      setValue('address', addressName);
      setValue('longitude', addressObject!.geometry!.location!.lng());
      setValue('latitude', addressObject!.geometry!.location!.lat());
      setValue('calculateMetrics', true);
      setAddressValid((currState: boolean) => currState = true);
    }else{
      alert('You must enter a valid address');
      setValue('address', '');
      setFocus('address', {shouldSelect:true});
    }
  }

  const updateDaysAvailableArray = (daysArr: string[]) => {
    const arr = [0,0,0,0,0,0,0];
    
    daysArr.forEach((day:string)=>{
      switch (day) {
        case 'Monday':
          arr[0] = 1;
          break;
        case 'Tuesday':
          arr[1] = 1;
          break;
        case 'Wednesday':
          arr[2] = 1;
          break;
        case 'Thursday':
          arr[3] = 1;
          break;
        case 'Friday':
          arr[4] = 1;
          break;
        case 'Saturday':
          arr[5] = 1;
          break;
        case 'Sunday':
          arr[6] = 1;
          break;
      }
    });
    setValue('daysAvailable', arr);
  }

  const onSubmit: SubmitHandler<IndividualFormData> = async(data) => {

    console.log('clicked!!!');
    setHideFormOverlay(currState=>currState = true);
    //sets tags to form field before submission
    data.interests = interests;
    data.skills = skills;
    data.restrictions = restrictions;
    data.profileImg = 'defaultProfileImg.jpg' // placeholder for later iterations
    data.creator = myDetails.id;
    
    //convert to array of numbers, for some reason it saves the boolean, couldn't format it on the form level.
    data.daysAvailable = data!.daysAvailable!.map(day => {return day ? 1 : 0});
    
    //this processes the nested field array of employment records to update the filled hours component.
    data.filledHours! = data.employmentRecord!.reduce((prevValue, currentValue) => prevValue += (currentValue.isCurrent ? +currentValue.hours: 0),0);
    
    //this checks if filled hours have changed, and resets the openTS on update for notifying users. 
    if( isEdit && data.filledHours < data.availableHours! ){
      data.setOpenTS = dayjs().unix(); //used in check-in notification for individual. Rest this to current timestamp if user has hours need to be filled.
    } 

    if(data.status === false) {
      data.setOpenTS = -1; //if user is closed, don't checkin on them.
    }
    //write json object to db as string, postgresql syntax formatting fail nodejs backend, no fun. kept as string in db record
    data.employmentRecord = JSON.stringify(data.employmentRecord).replace( /</g, '\\u003c') as unknown as Employment[];
    data.leadProviderOrgId = (providers.find((provider: Provider) => provider.id === data.leadProviderId))!.orgId;
    data.notes = JSON.stringify(data.notes) as unknown as NoteBullet[];
    
    //TODO is address | skills | interests |  touched? send updates.
    if( isEdit && data.latitude === undefined){
      data.latitude = individual.latitude;
      data.longitude = individual.longitude;
    }

    //if files exist, assign individuals
    const processResumeUpload = new Promise((resolve, reject) => {
      console.info('Resume', data.resumeUpload);
      if(data.resumeUpload !== null){
        return processFileUpload(resolve, reject, data.resumeUpload as File);
      }else{
       return resolve('no file uploaded');
      }
    });

    //if files exist, assign individuals
    const processCoverLetter = new Promise((resolve, reject) => {
      console.info('Resume', data.resumeUpload);
      if(data.coverLetterUpload !== null){
        return processFileUpload(resolve, reject, data.coverLetterUpload as File);
      }else{
        resolve('no file uploaded');
      }
    });

    // Chain of file uploads to S3 Bucket, after completion, send to server with links to files.
    // Resume(file)=>Coverletter(files)=>References(fileList)=>Certification(fileList)=>(dispatchFormSubmit)=>unwrapResults returned from server and store in redux
    processResumeUpload.then((value: any)=>{
      if(value.startsWith('https://')){
        data.fileAttachments![0] = value as string;
      }
    })
    .catch((e)=>{
      console.warn('Resume error',e)
    })
    .finally(()=>{

      processCoverLetter.then((value: any)=>{
        if(value.startsWith('https://')){
          data.fileAttachments![1] = value as string;
        }
      })
      .catch((e)=>{  
        console.warn('error on cover letter upload', e);
      })
      .finally(()=>{
        
        processReferences().then(()=>{
          processCertifications(data.certifications!, data.certificationAttachments!).then(()=>{
                  
             console.info('FORM SUBMIT:', data);
         
             if(isEdit){
               dispatch(updateIndividual({individualObj: data as unknown as IndividualFormData, token: accessToken!}))
               .then(unwrapResult)
               .then((response: {data: { individuals: Individual[] }}) => {
                 console.info('Client-side response Individual update: ', response.data!);
                 handleRouteChange(response.data.individuals[0]!);
               });         
             }else{
               dispatch(postIndividual({individualObj: data as unknown as IndividualFormData, token: accessToken!}))
               .then(unwrapResult)
               .then((response:{data: { individuals: Individual[] }}) => {
                 console.info('Client-side response Individual create: ', response.data.individuals[0]!);
                 handleRouteChange(response.data.individuals[0]!); //set state to pass to navigation to individual component view.
               });
             }
           })
        })
      })
    });

    async function processReferences(){
      console.info('References: ', data.references);
      if(data.references !== null){
        let promises = [];
        for(let i=0; i < data.references.length; i++){
          promises.push( new Promise(async (resolve, reject) => {
            return processFileUpload(resolve, reject, data.references![i] as File);
          }))
        }

        return (Promise.all(promises).then((value)=>{
          if((value![0] as unknown as string).startsWith('https://')){
            for(let i = 0; i < value!.length; i++){
              data.referenceAttachments![i] = value![i] as string;
            }
            return data.referenceAttachments;
          }
        }))
      }else{
        return data.referenceAttachments;
      }
    }

    async function processCertifications(attachments: FileList, linksArr: string[]){
      console.info('Certifications', attachments);
      if(attachments !== null){
        let promises = [];
        for(let i=0; i < attachments.length; i++){
          promises.push( new Promise(async (resolve, reject) => {
            return processFileUpload(resolve, reject, attachments![i] as File);
          }))
        }

        return (Promise.all(promises).then((value)=>{
          if((value![0] as unknown as string).startsWith('https://')){
            for(let i = 0; i < value!.length; i++){
              linksArr[i] = value![i] as string;
            }
            console.info('certification: ', linksArr);
            return linksArr;
          }
        }))
      }else{
        return linksArr;
      }
    }

    function handleRouteChange(responseData: Individual){
      setNewIndividual((currState) => { return currState = responseData! }); //set state to pass to navigation to individual component view.
      setIndividualPath((currState) =>  { return currState = `${responseData!.leadProviderOrgId}/${responseData!.id}` }); 
      setHideFormOverlay(currState => currState = false);
      setDisplaySuccessModal(currState => currState = true);    //display the popup modal.
    }

    async function processFileUpload(resolve: (value: string) => void, reject: (value:string) => void, file:File){
        dispatch(getSignedUrl({ 
        orgId: providerDetails.orgId, 
        fileName: file!.name,
        contentType: file!.type, 
        subFolder: 'individuals', 
        fileType:'docx',
        token: accessToken!
      }))
      .then(unwrapResult)
        .then(async (res) => {
          console.info('EmployerCreateComponent - SignedURL response', res);
          return await axios({
            method: 'PUT', 
            url: res.signedUrl, 
            headers:{
              'Content-Disposition': `attachment; filename=${file!.name}`
            },
            data: file
          })
          .then(results=>{
            console.info('SignedURL results: ', results);
            if(results.status === 200){
              return resolve(`${process.env.REACT_APP_S3_RES_BUCKET}` + res.key); 
            }else{
              console.warn('Error uploading image. Please try again or contact the administrator.');
              return reject('Error in posting file');
            }
          });
        });
    }
  };

  const handleLeadProviderChange = (
    option: SingleValue<string | SelectType>
  ) => {
    const selectedProvider = option as SingleValue<SelectType>;
    const selectProviderId = selectedProvider!.value;
    setValue('leadProviderName', selectedProvider!.label);
    setDefaultProvider(currState => { return currState = selectedProvider})
    return selectProviderId;
  };

  const handleTagChange = (e: MultiValue<any>, type:string) => {
    setValue('calculateMetrics', true);
    let result: number[] = [];
    //console.info('Incoming Change', e);
    if (!!e.length) {
      result = [];
      e.forEach((selection: { label: string; value: string }) => {
        result.push(+selection!.value);
      });
    }
    // console.info('Resultant Array: ', result);
      type === 'skills' ? setSkills(result) 
      : type === 'interests' ? setInterests(result)
      : console.log('error occurred in handleTagChange');
  };

  const navigateHandler = () => {
    console.log('button to navigate to next page is clicked!!!!');
    setDisplaySuccessModal((currState) => (currState = false));
    navigate(`/individuals/${individualPath}`, {state: newIndividual});
  };

  const displayConfirmEmploymentDelete = (index:number) => {
    setEmploymentIndex(index);
    setConfirmEmploymentRecordDelete(true);
  }

  const cancelActionHandler = () => {
    setConfirmEmploymentRecordDelete(false);
    setEmploymentIndex(currState => currState = undefined);
  }

  const deleteEmploymentRecord = () => {
    setConfirmEmploymentRecordDelete(false);
    remove(employmentIndex);
    setEmploymentIndex(currState => currState = undefined);
    window.location.reload();
  }

  const sortDays = (event:any, index:number) => {
    let employmentRecordDaysArr: Employment['day'] = getValues('employmentRecord')![index].day;
    //if input matches employmentRecordDays, push that employmentRecordDays into a result array
    let resultArr: {value:number, text:string}[] = [];
    SCHEDULE_OPTIONS.forEach((dayObject: {value:number, text:string}) => { if(employmentRecordDaysArr.includes(dayObject.text)) resultArr.push(dayObject)});
    //take result array and sort, map, then set to new value
    resultArr.sort((a:{value:number,text:string}, b:{value:number, text:string}) => { return a.value < b.value ? -1: 1})
    employmentRecordDaysArr = resultArr.map((x:{value:number, text:string}) => {return x.text});
    console.log(employmentRecordDaysArr);
    setValue(`employmentRecord.${index}.day`, employmentRecordDaysArr);
    return;
  }

  const removeFileHandler = (index:number) => {
    // i'm sorrry. So, we rebuild the array of file references for each, and dispatch it to backend to send a deleted file ref to old_resources table
    // we then update the individuals record with updatedFileRef (non-deleted file...which is stored as an array). poor architecture.
    if(index < 2){
      let updatedFileRef: string[] = []; // updated array of non-deleted files.
      const fileRef = fileNames[index]; // get file you want to delete
      setFileNames(currState => {
        let fileArr = [...currState];
        fileArr[index] = 'empty';
        updatedFileRef = fileArr;
        return currState =[...fileArr];
      });

      if(index === 0){ 
        console.info('UpdatedFileRef: ', updatedFileRef);
        setValue('resumeUpload', null);
      };

      if(index === 1){
        setValue('coverLetterUpload', null);
      } 

      if(isEdit && individual?.fileAttachments[index].startsWith('https://')){
        dispatch(deleteIndividualFile({
            individualId: individual?.id!, 
            fileRef: fileRef,  //deleted files
            updatedFileRef: updatedFileRef, //non-deleted files used only for writing to file_attachments field.
            provId: myDetails.id, 
            isMulti: false, //deleting certs or references? 
            isCerts: false,  //deleting certs NOT references
            token: accessToken!
          }
        ))
        .then(unwrapResult)
        .then((res:any) => {
          console.log({res});
          if(res.status === 200){
            console.log('updating file attachments');
            setValue('fileAttachments', res.updatedAttachments);
          }else{
            
          }
        })
      }

    }

    if(index === 2){


      if(isEdit && individual?.referenceAttachments[0].startsWith('https://')){
        let updatedFileRef: string[] = []; // updated array of non-deleted files.
        const fileRef = individual?.referenceAttachments.toString(); // get file you want to delete
        dispatch(deleteIndividualFile({
            individualId: individual?.id!, 
            fileRef: fileRef,  //deleted files
            updatedFileRef: updatedFileRef, //non-deleted files only for file_attachments field.
            provId: myDetails.id, 
            isMulti: true, //deleting certs or references? 
            isCerts: false,  //deleting certs NOT references
            token: accessToken!
          }
        ))
        .then(unwrapResult)
        .then((res:any) => {
          console.log({res});
          if(res.status === 200){
            console.log('updating file attachments');
            setRefFiles(['empty']);
            setValue('references', null);
            setValue('referenceAttachments', res.updatedAttachments);
          }else{
            
          }
        })
      }
    }
    
    if(index === 3){

      
      if(isEdit && individual?.certificationAttachments[0].startsWith('https://')){
        let updatedFileRef: string[] = []; // updated array of non-deleted files.
        const fileRef = individual?.certificationAttachments.toString(); // get file you want to delete
        dispatch(deleteIndividualFile({
            individualId: individual?.id!, 
            fileRef: fileRef,  //deleted files
            updatedFileRef: updatedFileRef, //non-deleted files only for file_attachments field.
            provId: myDetails.id, 
            isMulti: true, //deleting certs or references? 
            isCerts: true,  //deleting certs NOT references
            token: accessToken!
          }
        ))
        .then(unwrapResult)
        .then((res:any) => {
          console.log({res});
          if(res.status === 200){
            setCertFiles(['empty']);
            setValue('certifications', null);
            setValue('certificationAttachments', res.updatedAttachments);
          }else{
            
          }
        })
      }
    }

  }

  const UploadFileComponent = (props:{ text: string, fileName:string, filePostition:number, controllerName:string, canDelete:boolean }) => {
    const {text, fileName, filePostition, controllerName, canDelete} = props;

    return (
      <div className='blue-stitch inline-a file-upload'>
        <Button 
          variant="text" 
          component="label" 
          disabled={!canDelete || fileName?.startsWith('https')}
        >
          {
            (fileName?.toLowerCase() === 'empty' || fileName === '' || fileName === null || fileName === undefined) ? 
            <div className="inline-a">
              <img src={Icon.AttachmentIcon} alt='icon'/>
               {text}
            </div>
            : 
            <Tooltip
              title={(fileName?.startsWith('https') ? fileName.split('-').pop() : fileName) || ''}
              placement='top'
            >
              <Typography variant="body1" className='text-overflow'>
                { fileName?.startsWith('https') ? fileName.split('-').pop() : fileName}
              </Typography>
            </Tooltip>
          }

          <Controller
            control={control}
            name={controllerName as any}
            render={({ field}) => (
              <input
                type="file"
                name={controllerName as any}
                onChange={(e) => {
                  field.onChange(e.target.files![0]);
                  setFileNames((currState) => { 
                    let newArr = [...currState];
                    newArr[filePostition] = e.target.files![0]?.name;
                    return currState = [...newArr];
                  })
                  e.target.value = ''; //edge case, if user removes, then re-adds same file, onChange will not fire if you don't do this, causing a glitch in
                }}
                multiple={false}
                disabled={!canDelete}
                hidden
            />
          )}
        />
        </Button>
        <Tooltip
          title={fileName?.startsWith('https') ? fileName.split('-').pop() + ' will be deleted' : fileName + ' will be deleted' || ''}
          placement='top'
        >
          <div className="meat-balls notif-highlight">
            {
              (canDelete && fileName?.startsWith('https://'))
              && 
              <IconButton 
                color="inherit"  
                onClick={()=>{removeFileHandler(filePostition)}}
                sx={{
                  opacity: fileName !== 'empty' ? '1':'0', 
                  width: fileName !== 'empty' ? '50px':'0px', 
                  display: fileName !== 'empty' ? 'content':'none',
                }} 
              >
                {<DeleteOutlineOutlinedIcon />}
              </IconButton>
            }
          </div>
        </Tooltip>
      </div>
    ) 
  }

  const goToPanel = (step:number) => {
    console.log('Current Step to scroll to is... ', step);
      step === 0 ? refPanel_1.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) : step === 1 ? refPanel_2.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) : step === 2 ? refPanel_3.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) : step === 3 ? refPanel_4.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) :  step === 4 ? refPanel_5.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    }) : refPanel_6.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    })
  }
  //setValues to each unregistered field skills/ interests/ restrictions
  console.log(errors); //debug checks for form validation errors
  return (
    <>
      {
        (isDesktop || isLaptop) && 
        <PageTitleComponent greeting={isEdit ? t('individualViewEditBtn') : t('addIndividualBtn')} />
      }
      <FormComponentWrapper>
        <form 
          className="mob-form-container" 
          //onSubmit={handleSubmit(onFormSubmit)}
          onKeyDown={(e) => {return e.code === 'Enter' ? e.preventDefault() : null}}
        >
          <FormContainer>
            { 
              (isDesktop || isLaptop) 
              ? 
              <StepperWidget
                steps={['Details', 'Keywords', 'Preferences', 'Additional', 'History', 'Attachments']}
                isMobileTabletWidth={true}
                stackStepsTitle
                disableFormSubmitOrg={disableFormSubmit} 
                goToPanel={goToPanel}
                isEdit={isEdit!}
                getValues={getValues}
                deleteThisRecordId={individual?.id!}
                deleteThisRecordFunc={deleteIndividualRecord}
                hasDeleteAccess={isEdit && myDetails?.isAdmin!}
                tags={skills}
                isFormType="individual"
                setDisplayCompletedMsg={setDisplayCompletedMsg}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                handleSubmit={handleSubmit(onSubmit)}
              >
                <HorizontalScrollContainer
                  ref={formRef}
                  height={windowDimensions.h}
                >
                  <PanelCarousel 
                    className='ind-panel hide-scroll'
                    height = {windowDimensions.h}
                    width = {windowDimensions.w}
                  >
                    {/* panel 1 */}
                    <FormPanel
                      className='panel-1'
                      ref={refPanel_1}
                      sx={formWidthStyling}
                    >
                      <Typography variant="h4" textTransform={"uppercase"} className='form-title'>
                        {t('individualFormTitle')}
                      </Typography>
                       {/* lead provider */}
                    
                      <FormRow
                        height={windowDimensions.h}
                        maxHeight='105px'
                      >
                        <FormFieldContainer className='form-half-width'>
                          <Controller
                            control={control}
                            name="leadProviderId"
                            rules={{ required: t('selectProvPlease')}}
                            render={({ field: { onChange } }) => (
                              <>
                                <InputLabel 
                                  sx={reactSelectLabel}
                                  required
                                >
                                  {t('leadProvLabel')}
                                </InputLabel>
                                <Select
                                  styles={reactSelectCustomStyles}
                                  options={providerOptions}
                                  value={defaultProvider as SelectType}
                                  placeholder={t('leadProvLabel')}
                                  onChange={(option: SingleValue<string | SelectType>) => {
                                    onChange(handleLeadProviderChange(option))
                                  }}
                                  isDisabled={!isEdit ? false : isEdit && (myDetails?.id === individual?.leadProviderId || myDetails?.isAdmin) ? false : true}
                                />
                              </> 
                            )}
                          />
                          {errors.leadProviderId && <ReqErrorMsg>{errors.leadProviderId.message}</ReqErrorMsg>}
                        </FormFieldContainer>
                          {/* status */}
                        <FormFieldContainer
                          className='form-qrtr-width'
                        >
                          <Controller
                            control={control}
                            name='status'
                            render={({ field: {onChange, value, name}}) => (
                              <div 
                                className='form-switch ind-switch'
                                style={{width: 'fit-content', marginLeft: 'auto'}}
                              >
                              <Typography variant="subtitle2">Individual Status</Typography>
                                <ReusableToggleComponent 
                                  toggleValue={value} 
                                  toggleSelect={() => {
                                    setValue('status', !value)
                                  }}
                                  firstToggle={'Open'}
                                  secondToggle={'Not Open'}
                                />
                              </div>
                            )} 
                          />
                        </FormFieldContainer>
                      </FormRow>

                 

                      {/* firstname + lastname */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerAutoComplete
                            id="outlined-required"
                            control={control as Control<Partial<GlobalFormData>, any>}
                            register={register as UseFormRegister<Partial<GlobalFormData>>}
                            required={true}
                            name="firstName"
                            label={capitalize(t('individualFirstNameLabel'))}
                            placeholder={t('individualFirstNameLabel')}
                            className='full-width'
                            rules={{
                              required: t('firstNameReqMsg'),
                              pattern: {
                                value: /^[A-Za-z0-9_-\s]*$/i,
                                message:
                                  t('validNameReqMsg'),
                              }}}
                              optionItems={optionItems.filter(function(item, pos, self) {
                                return self.indexOf(item) == pos;
                              })}
                              throwMessage={t('indExistsMsg')}
                          />
                        {errors.firstName && <ReqErrorMsg>{errors.firstName.message}</ReqErrorMsg>}
                        </FormFieldContainer>

                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerAutoComplete
                            id="outlined-required"
                            control={control as Control<Partial<GlobalFormData>, any>}
                            register={register as UseFormRegister<Partial<GlobalFormData>>}
                            required={true}
                            name="lastName"
                            label={capitalize(t('individualLastNameLabel'))}
                            placeholder={t('individualLastNameLabel')}
                            className='full-width'
                            rules={{
                              required: t('lastNameReqMsg'),
                              pattern: {
                                value: /^[A-Za-z0-9_-\s]*$/i,
                                message:
                                t('validNameReqMsg'),
                              },
                            }}
                            optionItems={optionItems.filter(function(item, pos, self) {
                              return self.indexOf(item) == pos;
                            })}
                            throwMessage={t('indExistsMsg')}
                          />
                          {errors.lastName && <ReqErrorMsg>{errors.lastName.message}</ReqErrorMsg>}
                        </FormFieldContainer>
                      </FormRow>

                      {/* phone & email*/}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerTextField
                            id="outlined-required"
                            className='full-width'
                            control={control}
                            register={register}
                            required={true}
                            name="phone"
                            placeholder={t('phoneNumber')}
                            customFunction={formatPhoneNumber}
                            rules={{
                              required: t('phoneNumberReqMsg'),
                              maxLength: 35,
                              pattern: {
                                value: regXPhoneNumber,
                                message: t('validPhoneReq'),
                              },
                            }}
                          />
                          {
                            <ReqErrorMsg>{
                              errors?.phone?.type === 'required' ? 
                              errors?.phone?.message : errors?.phone?.type === 'maxLength' ? 
                              t('phoneErrorMsg') : errors?.phone?.type === 'pattern' ?
                              t('phoneHelperText') : null
                            }</ReqErrorMsg>
                          }

                        </FormFieldContainer>

                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerTextField
                            id="outlined-required"
                            className='full-width'
                            control={control}
                            register={register}
                            required={false}
                            name="email"
                            rules={{
                              pattern: {
                                value: regXEmail,
                                message: t('emailErrorMsg')
                              }
                            }}
                            placeholder={t('mainCemail')}
                          />
                          {errors.email && <ReqErrorMsg>{errors.email.message}</ReqErrorMsg>}
                        </FormFieldContainer>
                      </FormRow>

                      {/* address */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={true}
                            name="address"
                            rules={{
                              required: "Please provide a valid address.",
                            }}
                            id="autocomplete"
                            className=" "
                            placeholder={t('address')}
                            onClick={()=>{setAddressValid(currState => currState = false)}}
                          />
                          {errors?.address?.type === 'required' ? 
                            <ReqErrorMsg>{errors?.address.message}</ReqErrorMsg> : !isAddressValid ? 
                            <ReqErrorMsg>{t('addressHelperText') } </ReqErrorMsg> : <FormHelperText sx={{marginLeft:'15px'}}>{t('selectAddressPlaceholder')}</FormHelperText> }
                        </FormFieldContainer>
                      </FormRow>
                      {/* dob */}
                      <FormRow height={windowDimensions.h}>
                        <FormFieldContainer
                          className='full-width'
                        >                  <Controller
                        control={control}
                        name="dob"
                        rules={
                          { required: t('dobRequired'),
                            validate: { 
                              age: (d) =>  Date.now() - (new Date(d as string).getTime()) > +is14YearsInMilliseconds
                            } 
                          }
                        } 
                        render={({ field: { onChange, value } }) => (
                          <LocalizationProvider 
                            dateAdapter={AdapterDateFns}
                            sx={{
                              '.MuiInputBase-root':{
                              'borderRadius':'30px',
                              'height':'80px'
                            },
                          }}
                          >
                            <DatePicker
                              {...register("dob")}
                              label={t('dobLabel')}
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                              }}
                              renderInput={(params) => (
                                <FormTextInput
                                  {...params}
                                  sx={{ width: "100%" }}
                                  error={false}
                                  required
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                        { 
                          (errors.dob?.type === 'required' ) 
                          ? 
                          <ReqErrorMsg>
                            {errors?.dob.message}
                          </ReqErrorMsg> 
                          : 
                          errors.dob?.type === 'age' 
                          ? 
                          <ReqErrorMsg>
                            {t('dobErrorMsg')}
                          </ReqErrorMsg> 
                          : 
                          <FormHelperText 
                            sx={{marginLeft:'15px'}}
                          >
                            {t('dobHelperText')}
                          </FormHelperText> 
                        }
                        </FormFieldContainer>
                      </FormRow>
                    </FormPanel>

                    {/* panel 2 */}
                    <FormPanel
                      className='panel-2'
                      ref={refPanel_2}
                      sx={formWidthStyling}
                    >
                      {/* skills */}
                      <TagFormRow height={windowDimensions.h} >
                        <FormFieldContainer
                          className='full-width'
                          minHeight='85px'
                        >
                          <div className='select-provider'>
                            <Controller
                              control={control} // from useForm()
                              name="skills"
                              rules={{ 
                                validate: () => { if(skills.length === 0) { return 'Provide at least two skills.'}}
                            }}
                              render={({ field: {onChange, value, name} }) => (
                                <>
                                <InputLabel 
                                  sx={reactSelectLabel}
                                  required
                                >
                                    {t('skillsTitle')}
                                  </InputLabel>
                                <Select
                                  required
                                  closeMenuOnSelect={false}
                                  blurInputOnSelect={false}
                                  autoFocus={errors.skills !== undefined}
                                  placeholder={t('skillsTitle')}
                                  styles={reactSelectCustomStyles}
                                  options={(skillKeywords as unknown as GroupBase<any>[]).filter((tag:any) => !skills.includes(tag.value))}
                                  isMulti={true}
                                  value={skillOptions!}
                                  onChange={(e) => {
                                    onChange(handleTagChange(e, 'skills'));
                                  }}
                                />
                                </>
                              )}
                            />
                            {errors.skills?.message && <ReqErrorMsg>{errors.skills?.message}</ReqErrorMsg>}
                          </div>
                        </FormFieldContainer>
                      </TagFormRow>
                      {/* interests */}
                      <TagFormRow height={windowDimensions.h} >
                        <FormFieldContainer
                          className='full-width'
                          minHeight='85px'
                        >
                          <div className='select-provider'>
                            <Controller
                              control={control} // from useForm()
                              name="interests"
                              rules={{ validate: () => { if(interests.length === 0) { return 'Provide at least two interests.'}}}}
                              render={({ field: {onChange, value, name} }) => (
                                <>
                                  <InputLabel 
                                    sx={reactSelectLabel}
                                    required
                                  >
                                    {t('interestsTitle')}
                                  </InputLabel>
                                  <Select
                                    required={true}
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    placeholder={t('interestsTitle')}
                                    styles={reactSelectCustomStyles}
                                    options={(interesKeywords as unknown as GroupBase<never>[]).filter((tag:any) => !interests.includes(tag.value))}
                                    isMulti={true}
                                    value={interestOptions!}
                                    onChange={(e) => {
                                      onChange(handleTagChange(e, 'interests'));
                                    }}
                                  />
                                </>
                              )}
                            />
                            {errors.interests?.message && <ReqErrorMsg>{errors.interests?.message}</ReqErrorMsg>}
                          </div>
                          <br></br>
                          <SubmitKeywordSuggestions labelNoun={t('skillsAndIntersts')} displayModal={setDisplayKeywordSubmit}/>
                        </FormFieldContainer>
                      </TagFormRow>
                    </FormPanel>

                    {/* panel 3 */}
                    <FormPanel
                      className='panel-3'
                      ref={refPanel_3}
                      sx={formWidthStyling}
                    >
                      {/* restrictiontexts + req'd accomodation */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='form-half-width'
                        >                
                       
                            <ControllerTextField
                              control={control}
                              register={register}
                              required={false}
                              name="restrictionsText"
                              rules={{
                                maxLength: 150,
                              }}
                              label={t('restrictions')}
                              placeholder={t('restrictionsTextPlaceholder')}
                              className="full-width"
                            />
                            {errors.restrictionsText?.type === "maxLength" ? <ReqErrorMsg>{t('restrictionsHelperText')}</ReqErrorMsg> : <FormHelperText sx={{paddingLeft: '15px'}}>{t('restrictionsHelperText')}</FormHelperText>}
                          
                        </FormFieldContainer>
    
                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="requiredAccommodation"
                            label={t('accomodationsLabel')}
                            placeholder={t('acommodationsPlaceholder')}
                            className="full-width"
                            helperText={t('acommodationsPlaceholder')}
                          />
                        </FormFieldContainer>
                      </FormRow>
                      {/* minRate | maxRate*/}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={true}
                            name="minRate"
                            rules={{
                              validate:{
                                isLessThan: (value:string) => +value < 1000,
                                isZero: (value: string) => +value !== 0,
                                isFullDecimal: (value:string) => value.substring(value.length -1) !== '.',
                                isEmpty: (value: string) => value.length !== 0
                              }
                            }}
                            type='text'
                            className='full-width'
                            placeholder={t('minRate')}
                            label={t('minHrRateLabel')}
                            customFunction={formatOnlyDecimals}
                          />
                          <ReqErrorMsg>
                            {
                              errors.minRate?.type === 'isLessThan' ? 
                              t('expectedSalaryErrorMsg'): errors.minRate?.type === 'isFullDecimal' ? 
                              t('numberErrorMsg') :  errors?.minRate?.type === 'isZero' ?  
                              'Please add a non-zero number' : errors?.minRate?.type === 'isEmpty' ? 'Please fill in a minimum rate' : null
                            }
                          </ReqErrorMsg>
                        </FormFieldContainer>

                        <FormFieldContainer
                          className='form-half-width'
                          sx={{display: 'none !important'}}
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="maxRate"
                            rules={{
                              validate:{
                                isGreaterThan: (value:string) => +value > 0,
                              }
                            }}
                            className='full-width'
                            placeholder={t('maxRate')}
                            label={t('maxHrRateLabel')}
                            customFunction={formatOnlyNumbers}
                          />
                          <ReqErrorMsg>{errors.maxRate?.type === 'isGreaterThan' && t('maxRateErrorMsg')}</ReqErrorMsg>
                        </FormFieldContainer>
                      </FormRow>
                      {/* avilable hrs */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='full-width'
                        >

                          <ControllerTextField
                            control={control}
                            register={register}
                            required={true}
                            rules={{
                              required: t('availableHoursErrorMsg'),
                              maxLength: 2,
                            }}
                            name="availableHours"
                            className="full-width"
                            placeholder={t('availableHrsHelperText')}
                            label={t('totalHrsLabel')}
                            customFunction = {formatOnlyNumbers}
                          />
                          {errors.availableHours?.type === "maxLength" 
                          ? 
                          <ReqErrorMsg>{t('availableHoursErrorMsg')}</ReqErrorMsg> 
                          : 
                          <FormHelperText sx={{paddingLeft: '15px'}}>{t('totalHrsPlaceholder')}</FormHelperText>}

                        </FormFieldContainer>
                      </FormRow>
                    </FormPanel>
                    
                    {/* panel 4 */}
                    <FormPanel
                      className='panel-4'
                      ref={refPanel_4}
                      sx={formWidthStyling}
                    >
                      {/* daysAvailable */}
                      <Box 
                        className="iet-header form-icon inline full-width"
    
                      >
                        <img src={Icon.DetailedTime} alt='icon'/>
                        <Typography variant="subtitle2">{t('daysAvailTitle')}</Typography>
                      </Box>

                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='full-width'
                        >
                          <Controller
                            control={control}
                            name={`daysAvailable` as const}
                            rules={{
                              required: t('selectDayLabel'),
                              pattern: {
                                value:  /^(?!default)\w*/i,
                                message: t('selectDayReqMsg')
                              }
                            }}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <CustomMuiSelect
                                  {...register(`daysAvailable` as const)}
                                  name={`daysAvailable`}
                                  value={ getValues('daysAvailable')!.map((day:number, index:number) => {
                                    const result = day === 1 ? SCHEDULE_OPTIONS[index].text : 'null'; 
                                    return result;
                                  }).filter(day => day !== 'null')}
                                  multiple
                                  onChange={(e) => {
                                    onChange(e); 
                                    updateDaysAvailableArray(e.target.value as string[])
                                  }}
                                >
                                  {SCHEDULE_OPTIONS.filter((obj:{value:number, text:string, abbr:string}) => obj.text !== 'Rotating').map((option: {value:number, text:string, abbr:string}) => {
                                    return (
                                      <MenuItem 
                                        key={option.text} 
                                        value={option.text} 
                                      >
                                        {option.text} 
                                      </MenuItem>
                                    )
                                  })}
                                </CustomMuiSelect>
                              </>
                            )}
                          />
                          {errors.daysAvailable?.type === "required" ? <ReqErrorMsg>{errors.daysAvailable.message}</ReqErrorMsg> : <FormHelperText className='helper-text' sx={{marginLeft:'15px'}}>{t('daysAvailHelperText')}</FormHelperText>}
                        </FormFieldContainer>
                      </FormRow>

                      {/* intake date */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='full-width'
                        >
                          <Controller
                            control={control}
                            name="intakeDate"
                            rules={{ required: "When was this individual registered?" }}
                            render={({ field: { onChange, value } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  {...register("intakeDate")}
                                  label={t('dateIntake')}
                                  className="calendar-styling"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  renderInput={(params) => (
                                    <FormTextInput
                                      {...params}
                                      sx={{ width: "100%" }}
                                      error={false}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </FormFieldContainer>
                      </FormRow>
                      {/* preferred role */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="preferredRoles"
                            className="full-width"
                            placeholder={t('prefRoles')}
                            helperText={t('preferredRolesHelperText')}
                          />
                        </FormFieldContainer>
                      </FormRow>
                    </FormPanel>

                    {/* panel 5 */}
                    <FormPanel
                      className='panel-5'
                      ref={refPanel_5}
                      sx={formWidthStyling}
                    >
                      {/* pronouns preferred comms */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="pronouns"
                            placeholder={t('chooseYourProNoun')}
                            className="full-width"
                          />
                        </FormFieldContainer>

                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="preferredComms"
                            placeholder={t('prefCommsLabel')}
                            className="full-width"
                          />
                        </FormFieldContainer>
                      </FormRow>

                      {/* agency id govId */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="agencyId"
                            placeholder={t('agencyIdLabel')}
                            className="full-width"
                          />
                        </FormFieldContainer>
  
                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="govId"
                            placeholder={t('govIdLabel')}
                            className="full-width"
                          />
                        </FormFieldContainer>
                      </FormRow>
                      {/* five O check + cc Check */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <Controller
                            control={control}
                            name="policeCheck"
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  {...register("policeCheck")}
                                  label={t('popoCheck')}
                                  className="calendar-styling"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  renderInput={(params) => (
                                    <FormTextInput
                                      {...params}
                                      sx={{ width: "100%" }}
                                      error={false}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </FormFieldContainer>

                        <FormFieldContainer
                          className='form-half-width'
                        >
                          <Controller
                            control={control}
                            name="creditCheck"
                            render={({ field: { onChange, value } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  {...register("creditCheck")}
                                  label={t('creditCheck')}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  renderInput={(params) => (
                                    <FormTextInput
                                      {...params}
                                      sx={{ width: "100%" }}
                                      error={false}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </FormFieldContainer>
                      </FormRow>
                    </FormPanel>
                    <FormPanel
                      className='panel-6'
                      ref={refPanel_6}
                      sx={formWidthStyling}
                    >

                      {/* comments check */}
                      <FormRow
                        height='fit-content'
                        display='inline-table !important'
                      >
                        <FormFieldContainer
                          className='full-width'
                          height='fit-content !important'
                        >
                          <Box className='comments-wrapper'>
                            <div className='full-width inline-btwn inline-a blue-stitch'>
                              <div className='iet-header form-icon inline full-width'>
                                <img src={Icon.AttachmentIcon} alt='doc-icon'/>
                                <Typography variant="subtitle2">{t('notesComments')}</Typography>
                              </div>
                              <Button
                                  type="button"
                                  variant="outlined"
                                  sx={{
                                    minWidth: '180px', 
                                    background:'var(--primary-color)', 
                                    color: 'white',
                                    '&:hover': {
                                      color: 'var(--primary-color)',
                                      boxShadow: 'none',
                                    }
                                  }}
                                  onClick={() => {
                                    prependNotes({
                                      body: "",
                                      ts: dayjs().format('MM/DD/YY'),
                                      providerName: providerDetails.firstName + ' ' + providerDetails.lastName,
                                    });
                                  }}
                                >
                                  {'+' + t('notesComments')}
                                </Button>
                            </div>
                              {fieldsNote.map(
                                (
                                  { id },
                                  index
                                ) => {
                                  return (
                                    <div className='employment-record-item' key={id}>
                                      <div className='inline-btwn full-width employer-note-cont'>

                                        <Controller
                                            control={control}
                                            name={`notes.${index}.body` as const}
                                            rules={{
                                              validate: (value) => {
                                                if (value!.length > 1000) {
                                                  return `${t('notesErrorMsg')} (${value!.length}/1000)`;
                                                }
                                              }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                              <FormTextInput
                                                {...register(`notes.${index}.body`)}
                                                id="outlined-multiline-static"
                                                sx={{...formDescriptionStyling, '& .MuiOutlinedInput-input': {height: '115%', padding: '10px'}}}
                                                fullWidth
                                                label={t('notesComments')}
                                                multiline
                                                rows={4}
                                                value={value}
                                                onChange={onChange}
                                                disabled={getValues(`notes.${index}.providerName`) !== `${myDetails?.firstName} ${myDetails?.lastName}`}
                                                error={!!errors.notes?.[index]?.body?.message}
                                                helperText={errors.notes?.[index]?.body?.type === 'validate' ? errors.notes?.[index]?.body?.message : t('thousandMaxChars')}
                                              />
                                            )}
                                        />
                                        <div className='disabled-note-props'>
                                        <Controller
                                            control={control}
                                            name={`notes.${index}.providerName` as const}
                                            render={({ field: { onChange, value } }) => (
                                              <FormTextInput
                                                {...register(`notes.${index}.providerName`)}
                                                id="standard-basic"
                                                disabled
                                                label={t('creator')}
                                                variant="standard"
                                                sx={{'.MuiInputBase-input':{textAlign:'center'}}}
                                                value={ value || `${providerDetails?.firstName} ${providerDetails?.lastName}` }
                                                onChange={onChange}
                                              />
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            name={`notes.${index}.ts` as const}
                                            render={({ field: { onChange, value } }) => (
                                              <FormTextInput
                                                {...register(`notes.${index}.ts`)}
                                                id="standard-basic"
                                                disabled
                                                label= {t('created')}
                                                variant="standard"
                                                sx={{'.MuiInputBase-input':{textAlign:'center'}}}
                                                value={dayjs().format('MM/DD/YY')}
                                                onChange={onChange}
                                              />
                                            )}
                                        />
                                        </div>
                                        <div className="meat-balls">
                                          <IconButton color="error" onClick={()=>{removeNote(index)}}>
                                            {<DeleteOutlineOutlinedIcon />}
                                          </IconButton>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            }   
                          </Box>
                        </FormFieldContainer>
                      </FormRow>
                      {/* employment record */}
                      <FormRow
                        height='fit-content'
                        display='inline-table !important'
                      >
                        <FormFieldContainer
                          className='full-width'
                          marginTop='0px !important'
                          height='fit-content !important'
                        >
                          <Box className="employment-record">

                              <div className="emloyment-record-header inline-btwn inline-a full-width blue-stitch">
                                <div className="iet-header form-icon inline full-width">
                                  <img src={Icon.DetailedJob} alt='icon'/>
                                  <Typography variant="subtitle2">{t('currentEmploymentTitle')}</Typography>
                                </div>
                                <Button
                                  type="button"
                                  variant="outlined"
                                  sx={{
                                    minWidth: '180px', 
                                    background:'var(--primary-color)', 
                                    color: 'white',
                                    '&:hover': {
                                      color: 'var(--primary-color)',
                                      boxShadow: 'none',
                                    }
                                  }}
                                  onClick={() => {
                                    prepend({
                                      employerName: "",
                                      role: "",
                                      day: [""],
                                      hours: 0,
                                      isCurrent: true,
                                      isPlaceMaker: true,
                                      startDate: '',
                                    });
                                  }}
                                >
                                  { '+' + t('addJobBtn')}
                                </Button>
                              </div>
                                {fields.map(
                                  (
                                    { id, employerName, role, day, hours, isCurrent, isPlaceMaker, startDate },
                                    index
                                  ) => {
                                    return (
                                      <Box className='employment-record-item' key={`${id}-${index}`}>
                                        
                                        <Box className='full-width individual-note-cont'
                                          display='inline-table'
                                        >
                                          <FormRow 
                                            display='inline-grid'
                                            gridTemplateColumns='25% 25% 25% 25%' 
                                          >
                                              {/* employer name */}
                                              <Box className='display-contents'>
                                                <ControllerTextField
                                                  control={control}
                                                  register={register}
                                                  required={true}
                                                  name={`employmentRecord.${index}.employerName` as const}
                                                  rules={{
                                                    required: t('employerNamePleaseMsg'),
                                                  }}
                                                  className=""
                                                  label={t('employerName')}
                                                  placeholder={t('employersName')}
                                                />
                                                { (!!errors?.employmentRecord?.[index]?.employerName ) && (<ReqErrorMsg>{errors?.employmentRecord?.[index]?.employerName?.message}</ReqErrorMsg>)}
                                              </Box>
                                                {/* role */}
                                              <div className='display-contents'>
                                                <ControllerTextField
                                                  control={control}
                                                  register={register}
                                                  required={true}
                                                  name={`employmentRecord.${index}.role` as const}
                                                  rules={{
                                                    required: t('roleReqMsg'),
                                                  }}
                                                  className=""
                                                  label={t('role')}
                                                  placeholder={t('reqRoleLabel')}
                                                />
                                                {(!!errors?.employmentRecord?.[index]?.role ) && (<ReqErrorMsg>{errors?.employmentRecord?.[index]?.role?.message}</ReqErrorMsg>)}
                                              </div>
                                              {/* hours */}
                                              <div className='display-contents'>
                                                <ControllerTextField
                                                  control={control}
                                                  register={register}
                                                  required={true}
                                                  name={`employmentRecord[${index}].hours`}
                                                  rules={{
                                                    required: t('hoursReqErrorMsg'),
                                                  }}
                                                  className=""
                                                  label={t('totalHrsLabel')}
                                                  placeholder={t('totalHrsLabel')}
                                                />
                                                {(!!errors?.employmentRecord?.[index]?.hours ) && (<ReqErrorMsg>{errors?.employmentRecord?.[index]?.hours?.message}</ReqErrorMsg>)}
                                              </div>
                                              {/* isActive? */}
                                              <Controller
                                                control={control}
                                                name={`employmentRecord.${index}.isCurrent` as const}
                                                render={({ field: {onChange, value, name}}) => (
                                                  <div className="form-switch">
                                                    <div className="inline-ca">
                                                    {!value && <Typography variant="body1" className={!value ? 'active-switch':'inactive-switch'}>{t('noLongWorksHere')}</Typography> }
                                                    {value && <Typography variant="body1" className={value ? 'active-switch':'inactive-switch'}>{t('roleActiveMsg')}</Typography> }
                                                      <Switch
                                                        checked={value}
                                                        onChange={(e)=>onChange(e)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    </div>
                                                  </div>
                                                )} 
                                              />

                                          </FormRow>
                                  
                                          {/* startDate */}
                                          <FormRow className='display-contents' justifyContent='space-between' gridTemplateColumns='25% 46% 25%' display='inline-grid !important'>
                                            <Controller
                                              control={control}
                                              name={`employmentRecord.${index}.startDate` as const}
                                              render={({field: {onChange, value}}) => (
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                  <DatePicker
                                                    disableFuture
                                                    label={t('startDateLabel')}
                                                    openTo="year"
                                                    views={['year', 'month', 'day']}
                                                    value={value || null}
                                                    onChange={onChange}
                                                    renderInput={(params) => 
                                                    <FormTextInput 
                                                      sx={{
                                                        height: '50px',
                                                        width: '245px !important',
                                                        '& > label' : {
                                                          marginTop: '-3px',
                                                          fontWeight: '600'
                                                        },
                                                        '& > div' : {
                                                          height: 'inherit'
                                                        }
                                                      }
                                                    } 
                                                    {...params} 
                                                    />}
                                                  />
                                                </LocalizationProvider>
                                              )} />
                                            {/* day  */}
                                            <Box className='display-contents'
                                              
                                            >
                                              <Controller
                                                control={control}
                                                name={`employmentRecord.${index}.day` as const}
                                                rules={{
                                                  required: t('selectDayLabel'),
                                                  pattern: {
                                                    value:  /^(?!default)\w*/i,
                                                    message: t('selectDayReqMsg')
                                                  }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                  <>
                                                    <CustomMuiSelect
                                                      {...register(`employmentRecord.${index}.day` as const)}
                                                      name={`employmentRecord[${index}].day`}
                                                      value={typeof value === 'string' ? [value] : value }
                                                      multiple
                                                      onChange={(e)=>{onChange(e); sortDays(e, index);}}
                                                      sx={{ 
                                                        'minWidth':'150px',
                                                        'borderRadius': '15px',
                                                        'height': '45px',
                                                        'fontWeight': '600',
                                                        'fontFamily': 'Nunito',
                                                      }}
                                                      >
                                                      {SCHEDULE_OPTIONS.map((option: {value:number, text:string, abbr:string}) => {
                                                        return (
                                                          <MenuItem 
                                                          key={option.text} 
                                                          value={option.text} 
                                                          >
                                                            {t(option.text)}
                                                          </MenuItem>
                                                        )
                                                      })}
                                                    </CustomMuiSelect>
                                                  </>
                                                )}
                                                />
                                              { (!!errors?.employmentRecord?.[index]?.day ) && (<ReqErrorMsg>{errors?.employmentRecord?.[index]?.day?.message}</ReqErrorMsg>)}
                                            </Box>
                                          </FormRow>
            

                                        </Box>

                                        <Box className="inline-btwn full-width ">
                                          <InlineContainer justifyContent='flex-start'>
                                            <ControllerCheckBox
                                              control={control}
                                              name={`employmentRecord.${index}.isPlaceMaker` as const}
                                              register={register}
                                              setValue={setValue}
                                            />
                                            <div className='check-box-multiline'>
                                              <label>{t('individualPlacedByMJM')}</label>
                                              <Typography variant="subtitle1"> {t('individualPlacedByMJMII')}</Typography>
                                            </div>
        
                                          </InlineContainer>
                                          <InlineContainer justifyContent='flex-end'>
                                            <Box className="meat-balls">
                                              <IconButton color="error" onClick={()=>{displayConfirmEmploymentDelete(index)}}>
                                                {<DeleteOutlineOutlinedIcon />}
                                              </IconButton>
                                            </Box>
                                          </InlineContainer>
                                        </Box>

                                      </Box>
                                    );
                                  }
                                )}
                          </Box>
                        </FormFieldContainer>
                      </FormRow>
                      {/* attachments */}
                      <Box className="individuals-file-attachments"
                        sx={{
                          display: 'inline-grid',
                          height:'fit-content !important',
                          gridTemplateRows: '40px 150px 150px',
                          gridTemplateColumns: '60vw',
                          marginTop: '0px', 
                        }}
                      >
                        <FormFieldContainer 
                          className="full-width" 
                          minHeight='none !important'
                          
                        >
                          <InlineContainer 
                            className="iet-header form-icon"
                            justifyContent='flex-start'
                          >
                            <img src={Icon.AttachmentIcon} alt='icon'/>
                            <Typography variant="subtitle2">{t('attachmentsTitle')}</Typography>
                          </InlineContainer>

                        </FormFieldContainer>

                        <FormRow
                          height='fit-content'
                          maxHeight='none'
                        >
                          <FormFieldContainer
                            className='form-half-width'
                          >
                            <div className="iet-header form-icon inline full-width">
                              <Typography variant="subtitle2">{t('resumeUploadTitle')}</Typography>
                            </div>
                          {   fileNames && <UploadFileComponent 
                              text={t('resumeUploadLabel')} 
                              fileName={fileNames[0]} 
                              filePostition={0} 
                              controllerName='resumeUpload'
                              canDelete={(myDetails?.id === individual?.leadProviderId || myDetails?.canDelete || myDetails?.isAdmin)}
                            />}
                          </FormFieldContainer>

                          <FormFieldContainer
                            className='form-half-width'
                          >
                            <div className="iet-header form-icon inline full-width">
                              <Typography variant="subtitle2">{t('coverLetterUploadTitle')}</Typography>
                            </div>
                            {fileNames && <UploadFileComponent 
                              text={t('coverLetterUploadLabel')} 
                              fileName={fileNames[1]} 
                              filePostition={1} 
                              controllerName='coverLetterUpload'
                              canDelete={(myDetails?.id === individual?.leadProviderId || myDetails?.canDelete || myDetails?.isAdmin)}
                            />}
                          </FormFieldContainer>
                        </FormRow>

                        <FormRow 
                          className="full-width" 
                          height='fit-content' 
                          maxHeight='none'
                        >
                          <FormFieldContainer className="form-half-width">
                            <Box className="iet-header form-icon full-width"
                              display='flex'
                            >
                              <Typography variant="subtitle2">{t('refTitle')}</Typography>
                              <FormHelperText sx={{marginLeft: '14px', marginTop: '0px'}} className='ref-helper-text'>{t('refHelperText')}</FormHelperText>
                            </Box>
                            {/* <UploadMultiFileComponent text='Upload References' filesArr={refFiles} removeHandlerVal={2} fileSetState={setRefFiles} maxFiles={3} controllerName='references'/> */}
                            <UploadMultiFileComponent 
                                text={t('refLabel')} 
                                filesArr={refFiles} 
                                removeHandlerVal={2} 
                                fileSetState={setRefFiles} 
                                maxFiles={3} 
                                controllerName='references' 
                                removeFileHandler={removeFileHandler} 
                                control ={control}
                                canDelete={(myDetails?.id === individual?.leadProviderId || myDetails?.canDelete || myDetails?.isAdmin)}
                              />
                          </FormFieldContainer>
                          <FormFieldContainer className="form-half-width">
                            <Box className="iet-header form-icon full-width"
                              display='flex'
                            >
                              <Typography variant="subtitle2">{t('certificationTitle')}</Typography>
                              <FormHelperText sx={{marginLeft: '14px', marginTop: '0px'}} className='ref-helper-text'>{t('certsHelperText')}</FormHelperText>
                            </Box>
                            <UploadMultiFileComponent 
                              text={t('certsLabel')} 
                              filesArr={certFiles} 
                              removeHandlerVal={3} 
                              fileSetState={setCertFiles} 
                              maxFiles={5} 
                              controllerName='certifications'
                              removeFileHandler={removeFileHandler} 
                              control ={control}
                              canDelete={(myDetails?.id === individual?.leadProviderId || myDetails?.canDelete! || myDetails?.isAdmin)}
                            />
                          </FormFieldContainer>
                        </FormRow>
                      </Box>                       
                    </FormPanel>
                  </PanelCarousel>
                </HorizontalScrollContainer>
                
              </StepperWidget>
              :
              <StepperWidget
                steps={['Details', 'Keywords', 'Preferences', 'Additional', 'History', 'Docs']}
                isMobileTabletWidth={true}
                stackStepsTitle
                disableFormSubmitOrg={disableFormSubmit} 
                goToPanel={goToPanel}
                isEdit={isEdit!}
                getValues={getValues}
                deleteThisRecordId={individual?.id!}
                deleteThisRecordFunc={deleteIndividualRecord}
                hasDeleteAccess={false}
                tags={skills}
                isFormType="individual"
                setDisplayCompletedMsg={setDisplayCompletedMsg}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                handleSubmit={handleSubmit(onSubmit)}
              >
                <HorizontalScrollContainer
                  ref={formRef}
                  height={windowDimensions.h}
                >
                  <PanelCarousel 
                    className='ind-panel hide-scroll'
                    height = {windowDimensions.h}
                    width = {windowDimensions.w}
                  >
                    {/* panel 1 */}
                    <FormPanel
                      className='panel-1'
                      ref={refPanel_1}
                      sx={formWidthStyling}
                    >
                      {/* status */}
                      <FormRow
                        marginTop='10px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <Controller
                            control={control}
                            name='status'
                            render={({ field: {onChange, value, name}}) => (
                              <Box 
                                className='form-switch ind-switch'
                                style={{width: 'fit-content', margin: '4px 0', marginLeft: 'auto'}}
                              >
                              <Typography variant="subtitle2">Individual Status</Typography>
                                <ReusableToggleComponent 
                                  toggleValue={value} 
                                  toggleSelect={() => {
                                    setValue('status', !value)
                                  }}
                                  firstToggle={'Open'}
                                  secondToggle={'Not Open'}
                                />
                              </Box>
                            )} 
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* lead provider */}
                      <FormRow 
                        height={windowDimensions.h}
                        marginTop='0px'
                        marginBottom='18px'
                      >
                        <MobileFormFieldContainer className='full-width'
                          position='relative'
                        >
                          <Controller
                            control={control}
                            name="leadProviderId"
                            rules={{ required: t('selectProvPlease')}}
                            render={({ field: { onChange } }) => (
                            <>
                              <InputLabel 
                                sx={{...reactSelectLabel, top:'4px', left: '6px', fontSize: '11px !important'}}
                                required
                              >
                                {t('leadProvLabel')}
                              </InputLabel>
                              <Select
                                styles={reactSelectCustomStyles}
                                options={providerOptions}
                                value={defaultProvider as SelectType}
                                placeholder={t('leadProvLabel')}
                                onChange={(option: SingleValue<string | SelectType>) => {
                                  onChange(handleLeadProviderChange(option))
                                }}
                                isDisabled={!isEdit ? false : isEdit && (myDetails?.id === individual?.leadProviderId || myDetails?.isAdmin) ? false : true}
                              />
                            </> 
                            )}
                          />
                          {errors.leadProviderId && <ReqErrorMsg>{errors.leadProviderId.message}</ReqErrorMsg>}
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* firstname */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='0px'
                        marginBottom='10px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerAutoComplete
                            id="outlined-required"
                            control={control as Control<Partial<GlobalFormData>, any>}
                            register={register as UseFormRegister<Partial<GlobalFormData>>}
                            required={true}
                            name="firstName"
                            label={capitalize(t('individualFirstNameLabel'))}
                            placeholder={t('individualFirstNameLabel')}
                            className='form-half-width full-width'
                            rules={{
                              required: t('firstNameReqMsg'),
                              pattern: {
                                value: /^[A-Za-z0-9_-\s]*$/i,
                                message:
                                  t('validNameReqMsg'),
                              }}}
                              optionItems={optionItems.filter(function(item, pos, self) {
                                return self.indexOf(item) == pos;
                              })}
                              throwMessage={t('indExistsMsg')}
                          />
                        {errors.firstName && <ReqErrorMsg>{errors.firstName.message}</ReqErrorMsg>}
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* lastname */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='0px'
                        marginBottom='10px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                          marginTop='0px'
                        >
                          <ControllerAutoComplete
                            id="outlined-required"
                            control={control as Control<Partial<GlobalFormData>, any>}
                            register={register as UseFormRegister<Partial<GlobalFormData>>}
                            required={true}
                            name="lastName"
                            label={capitalize(t('individualLastNameLabel'))}
                            placeholder={t('individualLastNameLabel')}
                            className='form-half-width full-width'
                            rules={{
                              required: t('lastNameReqMsg'),
                              pattern: {
                                value: /^[A-Za-z0-9_-\s]*$/i,
                                message:
                                t('validNameReqMsg'),
                              },
                            }}
                            optionItems={optionItems.filter(function(item, pos, self) {
                              return self.indexOf(item) == pos;
                            })}
                            throwMessage={t('indExistsMsg')}
                          />
                          {errors.lastName && <ReqErrorMsg>{errors.lastName.message}</ReqErrorMsg>}
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* phone */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='7px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            id="outlined-required"
                            className='form-half-width full-width'
                            control={control}
                            register={register}
                            required={true}
                            name="phone"
                            placeholder={t('phoneNumber')}
                            customFunction={formatPhoneNumber}
                            rules={{
                              required: t('phoneNumberReqMsg'),
                              maxLength: 35,
                              pattern: {
                                value: regXPhoneNumber,
                                message: t('validPhoneReq'),
                              },
                            }}
                          />
                          {
                            <ReqErrorMsg>{errors?.phone?.type === 'required' ? 
                              errors?.phone?.message : errors?.phone?.type === 'maxLength' ? 
                              t('phoneErrorMsg') : errors?.phone?.type === 'pattern' ? 
                              t('phoneHelperText') : null }
                            </ReqErrorMsg>
                          }
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* email */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='15px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            id="outlined-required"
                            className='form-half-width full-width'
                            control={control}
                            register={register}
                            required={false}
                            name="email"
                            rules={{
                              pattern: {
                                value: regXEmail,
                                message: t('emailErrorMsg')
                              }
                            }}
                            placeholder={t('mainCemail')}
                          />
                          {errors.email && <ReqErrorMsg>{errors.email.message}</ReqErrorMsg>}
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* address */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='10px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'                          
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={true}
                            name="address"
                            rules={{
                              required: "Please provide a valid address.",
                            }}
                            id="autocomplete"
                            className=" "
                            placeholder={t('address')}
                            onClick={()=>{setAddressValid(currState => currState = false)}}
                          />
                          {errors?.address?.type === 'required' ? <ReqErrorMsg>{errors?.address.message}</ReqErrorMsg> : !isAddressValid ? <ReqErrorMsg>{t('addressHelperText') } </ReqErrorMsg> : <FormHelperText>{t('selectAddressPlaceholder')}</FormHelperText> }
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* dob */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop={'20px'}
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >                  
                        <Controller
                          control={control}
                          name="dob"
                          rules={
                            { required: t('dobRequired'),
                              validate: { 
                                age: (d) =>  Date.now() - (new Date(d as string).getTime()) > +is14YearsInMilliseconds
                              } 
                            }
                          } 
                          render={({ field: { onChange, value } }) => (
                            <LocalizationProvider 
                              dateAdapter={AdapterDateFns}
                              sx={{
                                '.MuiInputBase-root':{
                                'borderRadius':'25px',
                                'height':'80px'
                              },
                            }}
                            >
                              <DatePicker
                                {...register("dob")}
                                label={t('dobLabel')}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                renderInput={(params) => (
                                  <FormTextInput
                                    {...params}
                                    sx={{ width: "100%" }}
                                    error={false}
                                    required
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                        { 
                          (errors.dob?.type === 'required' ) 
                          ? 
                          <ReqErrorMsg
                          >
                            {errors?.dob.message}
                          </ReqErrorMsg> 
                          : 
                          errors.dob?.type === 'age' 
                          ? 
                          <Typography
                            variant="body1" 
                            className="form-error-message"
                            paddingLeft='10px'
                          >
                            {t('dobErrorMsg')}
                          </Typography> 
                          : 
                          <FormHelperText 
                            sx={{paddingLeft: '10px'}}
                          >
                            {t('dobHelperText')}
                          </FormHelperText> 
                        }
                        </MobileFormFieldContainer>
                      </FormRow>
                    </FormPanel>

                    {/* panel 2 */}
                    <FormPanel
                      className='panel-2'
                      ref={refPanel_2}
                      sx={formWidthStyling}
                    >
                      {/* skills */}
                      <FormRow
                        height='fit-content'
                        maxHeight='none'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <Controller
                            control={control} // from useForm()
                            name="skills"

                            rules={{ 
                              validate: () => { if(skills.length === 0) { return 'Provide at least two skills.'}}
                          }}
                            render={({ field: {onChange, value, name} }) => (
                              <>
                              <InputLabel 
                                sx={reactSelectLabel}
                                required
                              >
                                {t('skillsTitle')}
                              </InputLabel>
                              <Select
                                required
                                closeMenuOnSelect={false}
                                blurInputOnSelect={false}
                                autoFocus={errors.skills !== undefined}
                                placeholder={t('skillsTitle')}
                                styles={reactSelectCustomStyles}
                                options={(skillKeywords as unknown as GroupBase<any>[]).filter((tag:any) => !skills.includes(tag.value))}
                                isMulti={true}
                                value={skillOptions!}
                                onChange={(e) => {
                                  onChange(handleTagChange(e, 'skills'));
                                }}
                              />
                              </>
                            )}
                          />
                          {errors.skills?.message && <ReqErrorMsg>{errors.skills?.message}</ReqErrorMsg>}

                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* interests */}
                      <FormRow
                        height='fit-content'
                        maxHeight='none'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >

                            <Controller
                              control={control} // from useForm()
                              name="interests"
                              rules={{ validate: () => { if(interests.length === 0) { return 'Provide at least two interests.'}}}}
                              render={({ field: {onChange, value, name} }) => (
                                <>
                                  <InputLabel 
                                    sx={reactSelectLabel}
                                    required
                                  >
                                    {t('interestsTitle')}
                                  </InputLabel>
                                  <Select
                                    required
                                    closeMenuOnSelect={false}
                                    blurInputOnSelect={false}
                                    placeholder={t('interestsTitle')}
                                    styles={reactSelectCustomStyles}
                                    options={(interesKeywords as unknown as GroupBase<never>[]).filter((tag:any) => !interests.includes(tag.value))}
                                    isMulti={true}
                                    value={interestOptions!}
                                    onChange={(e) => {
                                      onChange(handleTagChange(e, 'interests'));
                                    }}
                                  />
                                </>
                              )}
                            />
                            {errors.interests?.message && <ReqErrorMsg>{errors.interests?.message}</ReqErrorMsg>}

                        </MobileFormFieldContainer>
                      </FormRow>
                      <br></br>
                      <SubmitKeywordSuggestions labelNoun={t('skillsAndIntersts')} displayModal={setDisplayKeywordSubmit}/>
                    </FormPanel>

                    {/* panel 3 */}
                    <FormPanel
                      className='panel-3'
                      ref={refPanel_3}
                      sx={formWidthStyling}
                    >
                      {/* restrictiontexts */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='20px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >                
 
                            <ControllerTextField
                              control={control}
                              register={register}
                              required={false}
                              name="restrictionsText"
                              rules={{
                                maxLength: 150,
                              }}
                              label={t('restrictions')}
                              placeholder={t('restrictionsTextPlaceholder')}
                              className="full-width"
                            />
                            {errors.restrictionsText?.type === "maxLength" ? <ReqErrorMsg>{t('restrictionsHelperText')}</ReqErrorMsg> : <FormHelperText>{t('restrictionsHelperText')}</FormHelperText>}

                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* req'd acc */}
                      <FormRow
                        height={windowDimensions.h }
                        maxHeight={'100px'}
                        marginTop='20px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="requiredAccommodation"
                            label={t('accomodationsLabel')}
                            placeholder={t('acommodationsPlaceholder')}
                            className="full-width"
                            helperText={t('acommodationsPlaceholder')}
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* minRate */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='20px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={true}
                            name="minRate"
                            rules={{
                              validate:{
                                isLessThan: (value:string) => +value < 1000,
                                isZero: (value: string) => +value !== 0,
                                isFullDecimal: (value:string) => value.substring(value.length -1) !== '.',
                                isEmpty: (value: string) => value.length !== 0
                              }
                            }}
                            type='text'
                            className='full-width'
                            placeholder={t('minRate')}
                            label={t('minHrRateLabel')}
                            customFunction={formatOnlyDecimals}
                          />
                          <ReqErrorMsg>{errors.minRate?.type === 'isLessThan' ? t('expectedSalaryErrorMsg'): errors.minRate?.type === 'isFullDecimal' ? t('numberErrorMsg') :  errors?.minRate?.type === 'isZero' ?  'Please add a non-zero number' : errors?.minRate?.type === 'isEmpty' ? 'Please fill in a minimum rate' : null }</ReqErrorMsg>
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* maxRate */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='20px'
                        display='none !important'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                          sx={{display: 'none'}}
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="maxRate"
                            rules={{
                              validate:{
                                isGreaterThan: (value:string) => +value > 0,
                              }
                            }}
                            className='full-width'
                            placeholder={t('maxRate')}
                            label={t('maxHrRateLabel')}
                            customFunction={formatOnlyNumbers}
                          />
                          <ReqErrorMsg>{errors.maxRate?.type === 'isGreaterThan' && t('maxRateErrorMsg')}</ReqErrorMsg>
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* avilable hrs */}
                      <FormRow
                        height={windowDimensions.h}
                        marginTop='20px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >

                          <ControllerTextField
                            control={control}
                            register={register}
                            required={true}
                            rules={{
                              required: t('availableHoursErrorMsg'),
                              maxLength: 2,
                            }}
                            name="availableHours"
                            className="full-width"
                            placeholder={t('availableHrsHelperText')}
                            label={t('totalHrsLabel')}
                            customFunction = {formatOnlyNumbers}
                          />
                          {errors.availableHours?.type === "maxLength" 
                          ? 
                          <ReqErrorMsg>{t('availableHoursErrorMsg')}</ReqErrorMsg> :
                          errors.availableHours?.type === 'required' 
                          ?
                          errors.availableHours?.message
                          :
                          <FormHelperText sx={{paddingLeft: '15px'}}>{t('totalHrsPlaceholder')}</FormHelperText>}

                        </MobileFormFieldContainer>
                      </FormRow>

                      <InlineContainer 
                        justifyContent='flex-start'
                        marginTop='30px'
                        marginBottom='15px'
                      >
                        <img src={Icon.DetailedTime} alt='icon'/>
                        <Typography variant="h3" sx={{marginLeft: '10px' }}>{t('daysAvailTitle')}</Typography>
                      </InlineContainer>

                      {/* daysAvailable */}
                      <FormRow
                        height={windowDimensions.h}
                        maxHeight={'100px'}
                      >
                        <MobileFormFieldContainer
                          position = 'relative'
                        >
                          <InputLabel
                            sx={{
                              ...quillSelectLabel,
                              margin: '0px', 
                              marginTop: '5px',
                              position: 'absolute',
                              top: '-15px',
                              background: 'white', 
                              fontSize: '12px !important'
                            }}
                          >
                            Available Days
                          </InputLabel>
                          <Controller
                            control={control}
                            name={`daysAvailable` as const}
                            rules={{
                              required: t('selectDayLabel'),
                              pattern: {
                                value:  /^(?!default)\w*/i,
                                message: t('selectDayReqMsg')
                              }
                            }}
                            render={({ field: { onChange, value } }) => (
                              <>
                                <CustomMuiSelect
                                  {...register(`daysAvailable` as const)}
                                  name={`daysAvailable`}
                                  value={ getValues('daysAvailable')!.map((day:number, index:number) => {
                                      return day === 1 ? SCHEDULE_OPTIONS[index].text : 'null'; 
                                  }).filter(day => day !== 'null')}
                                  multiple
                                  onChange={(e) => {
                                    onChange(e); 
                                    updateDaysAvailableArray(e.target.value as string[])
                                  }}
                                >
                                  {SCHEDULE_OPTIONS.filter((obj:{value:number, text:string, abbr:string}) => obj.text !== 'Rotating').map((option: {value:number, text:string, abbr:string}) => {
                                    return (
                                      <MenuItem 
                                        key={option.text} 
                                        value={option.text} 
                                      >
                                        {option.text} 
                                      </MenuItem>
                                    )
                                  })}
                                </CustomMuiSelect>
                              </>
                            )}
                          />

                          {
                            errors.daysAvailable?.type === "required" 
                            ? 
                            <ReqErrorMsg>{errors.daysAvailable.message}</ReqErrorMsg> 
                            :
                            <FormHelperText className='helper-text'>{t('daysAvailHelperText')}</FormHelperText>
                          }
                        </MobileFormFieldContainer>
                      </FormRow>

                      {/* intake date */}
                      <FormRow
                        height={windowDimensions.h}
                        maxHeight={'100px'}
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <Controller
                            control={control}
                            name="intakeDate"
                            rules={{ required: "When was this individual registered?" }}
                            render={({ field: { onChange, value } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  {...register("intakeDate")}
                                  label={t('dateIntake')}
                                  className="calendar-styling"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  renderInput={(params) => (
                                    <FormTextInput
                                      {...params}
                                      sx={{ width: "100%" }}
                                      error={false}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* preferred role */}
                      <FormRow
                        height='fit-content'
                        marginTop='0px'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="preferredRoles"
                            className="full-width"
                            placeholder={t('prefRoles')}
                            helperText={t('preferredRolesHelperText')}
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                    </FormPanel>
                    
                    {/* panel 4 */}
                    <FormPanel
                      className='panel-4'
                      ref={refPanel_4}
                      sx={formWidthStyling}
                    >
                      {/* pronouns */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="pronouns"
                            placeholder={t('chooseYourProNoun')}
                            className="full-width"
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* preferred comms */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="preferredComms"
                            placeholder={t('prefCommsLabel')}
                            className="full-width"
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* agency id */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="agencyId"
                            placeholder={t('agencyIdLabel')}
                            className="full-width"
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* govId */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <ControllerTextField
                            control={control}
                            register={register}
                            required={false}
                            name="govId"
                            placeholder={t('govIdLabel')}
                            className="full-width"
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* five O check */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <Controller
                            control={control}
                            name="policeCheck"
                            rules={{ required: false }}
                            render={({ field: { onChange, value } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  {...register("policeCheck")}
                                  label={t('popoCheck')}
                                  className="calendar-styling"
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  renderInput={(params) => (
                                    <FormTextInput
                                      {...params}
                                      sx={{ width: "100%" }}
                                      error={false}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* cc-check */}
                      <FormRow
                        height={windowDimensions.h}
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <Controller
                            control={control}
                            name="creditCheck"
                            render={({ field: { onChange, value } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  {...register("creditCheck")}
                                  label={t('creditCheck')}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  renderInput={(params) => (
                                    <FormTextInput
                                      {...params}
                                      sx={{ width: "100%" }}
                                      error={false}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </MobileFormFieldContainer>
                      </FormRow>
                    </FormPanel>

                    {/* panel 5 */}
                    <FormPanel
                      className='panel-5'
                      ref={refPanel_5}
                      sx={formWidthStyling}
                    >
                      {/* comments check */}
                      <FormRow
                        height='fit-content'
                        maxHeight='none'
                        width= '90%'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <Typography variant="subtitle2">{t('notesComments')}</Typography>
                          <CommentsWrapper>
                            <InlineContainer 
                              className='full-width blue-stitch'
                              marginTop='20px'
                            >
                              <Button
                              type="button"
                              variant="contained"
                              sx={{
                                background:'var(--primary-color)',
                                '&:hover': {
                                  background: 'var(--background)',
                                  color: 'var(--primary-color)',
                                  boxShadow: 'none',
                                  border: '1px solid var(--primary-color)'
                                }
                              }}
                                  onClick={() => {
                                    prependNotes({
                                      body: "",
                                      ts: dayjs().format('MM/DD/YY'),
                                      providerName: providerDetails.firstName + ' ' + providerDetails.lastName,
                                    });
                                  }}
                                >
                                  {'+' + t('notesComments')}
                              </Button>
                            </InlineContainer>
                              {fieldsNote.map(
                                (
                                  { id },
                                  index
                                ) => {
                                  return (
                                    <StackContents className='employment-record-item' key={`${id}-${index}`}>

                                      <Box className='employer-note-cont'>
                                          <Controller
                                            control={control}
                                            name={`notes.${index}.body` as const}
                                            rules={{
                                              validate: (value) => {
                                                if (value!.length > 1000) {
                                                  return `${t('notesErrorMsg')} (${value!.length}/1000)`;
                                                }
                                              }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                              <FormTextInput
                                                {...register(`notes.${index}.body`)}
                                                id="outlined-multiline-static"
                                                sx={{...formDescriptionStyling, '& .MuiOutlinedInput-input': {height: '85% !important', padding: '10px'}}}
                                                fullWidth
                                                label={t('notesComments')}
                                                multiline
                                                rows={3}
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.notes?.[index]?.body?.message}
                                                helperText={errors.notes?.[index]?.body?.type === 'validate' ? errors.notes?.[index]?.body?.message : t('thousandMaxChars')}
                                              />
                                            )}
                                        />
                                          <InlineContainer 
                                            className='disabled-note-props'
                                            justifyContent='flex-start'
                                            alignContent={'baseline'}
                                            marginTop='10px'
                                          >
                                          <Typography 
                                            variant='caption'
                                            padding='0 10px'
                                            width='80px'
                                            noWrap
                                          >
                                            Created by:
                                          </Typography>
                                          <Controller
                                            control={control}
                                            name={`notes.${index}.providerName` as const}
                                            render={({ field: { onChange, value } }) => (
                                              <FormTextInput
                                                {...register(`notes.${index}.providerName`)}
                                                id="standard-basic"
                                                disabled
                                                InputProps={{ disableUnderline: true }}
                                                variant="standard"
                                                sx={{ 
                                                  '.MuiInputBase-input':{textAlign:'left', width: 'min-content', padding: '0 10px'},
                                                  '.MuiInputBase-root' : {width: '90px'},
                                                }}
                                                value={ value || `${providerDetails?.firstName} ${providerDetails?.lastName}` }
                                                onChange={onChange}
                                              />
                                            )}
                                          />
                                          <Box width='28%'>
                                            <Controller
                                              control={control}
                                              name={`notes.${index}.ts` as const}
                                              render={({ field: { onChange, value } }) => (
                                                <FormTextInput
                                                  {...register(`notes.${index}.ts`)}
                                                  id="standard-basic"
                                                  disabled
                                                  InputProps={{ disableUnderline: true }}
                                                  variant="standard"
                                                  sx={{ '.MuiInputBase-input':{textAlign:'left', width: '100% !important'}}}
                                                  value={dayjs().format('MM/DD/YYYY')}
                                                  onChange={onChange}
                                                />
                                              )}
                                            />
                                          </Box>
                                          <Box 
                                            className="meat-balls"
                                            marginLeft='auto'
                                          >
                                            <IconButton color="error" onClick={()=>{removeNote(index)}}>
                                              {<DeleteOutlineOutlinedIcon />}
                                            </IconButton>
                                          </Box>
                                        </InlineContainer>
                                    </Box>
                                  </StackContents>
                                  );
                                }
                              )
                            }   
                          </CommentsWrapper>
                        </MobileFormFieldContainer>
                      </FormRow>
                      {/* employment record */}
                      <FormRow
                        height='fit-content'
                        maxHeight='none'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >

                              <div className="iet-header form-icon inline full-width">
                                <img src={Icon.DetailedJob} alt='icon'/>
                                <Typography variant="subtitle2">{t('currentEmploymentTitle')}</Typography>
                              </div>

                            <InlineContainer className="emloyment-record-header blue-stitch">
                              <Button
                                type="button"
                                variant="outlined"
                                sx={{
                                  minWidth: '180px', 
                                  background:'var(--primary-color)', 
                                  color: 'white',
                                  '&:hover': {
                                    color: 'var(--primary-color)',
                                    boxShadow: 'none',
                                  }
                                }}
                                onClick={() => {
                                  prepend({
                                    employerName: "",
                                    role: "",
                                    day: [""],
                                    hours: 0,
                                    isCurrent: true,
                                    isPlaceMaker: true,
                                    startDate: '',
                                  });
                                }}
                              >
                                { '+' + t('addJobBtn')}
                              </Button>
                            </InlineContainer>
                              {fields.map(
                                (
                                  { id, employerName, role, day, hours, isCurrent, isPlaceMaker, startDate },
                                  index
                                ) => {
                                  return (
                                    <StackContents 
                                      className='employment-record-item' 
                                      sx={{gridRowGap: '20px', marginTop: '20px', background: 'var(--background)', borderRadius: '15px', padding: '25px', border: '2px dashed #cacaca'}}
                                      key={`${id}-${index}`}
                                    >
                                        {/* toggle */}
                                        <Controller
                                          control={control}
                                          name={`employmentRecord.${index}.isCurrent` as const}
                                          render={({ field: {onChange, value, name}}) => (
                                            <div className="form-switch">
                                              <InlineContainer className="job-toggle"
                                                justifyContent={'space-between'}
                                              >
                                                {!value && <Typography variant="body1" className={!value ? 'active-switch':'inactive-switch'}>{t('noLongWorksHere')}</Typography> }
                                                {value && <Typography variant="body1" className={value ? 'active-switch':'inactive-switch'}>{t('roleActiveMsg')}</Typography> }
                                                <Switch
                                                  checked={value}
                                                  onChange={(e)=>onChange(e)}
                                                  inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                              </InlineContainer>
                                            </div>
                                          )} 
                                        />
                                      
                                        {/* startDate */}
                                        <div className='display-contents'>
                                          <Controller
                                            control={control}
                                            name={`employmentRecord.${index}.startDate` as const}
                                            render={({field: {onChange, value}}) => (
                                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                  <DatePicker
                                                    disableFuture
                                                    label={t('startDateLabel')}
                                                    openTo="year"
                                                    views={['year', 'month', 'day']}
                                                    value={value || null}
                                                    onChange={onChange}
                                                    renderInput={(params) => 
                                                    <FormTextInput 
                                                      sx={{
                                                        height: '50px',
                                                        '& > label' : {
                                                          marginTop: '-3px',
                                                          fontWeight: '600'
                                                        },
                                                        '& > div' : {
                                                          height: 'inherit'
                                                        }
                                                      }} 
                                                      {...params} 
                                                      background='var(--background)'
                                                    />
                                                  }
                                                  />
                                              </LocalizationProvider>
                                            )} />
                                        </div>
                                        {/* day  */}
                                        <div className='display-contents'>
                                          <Controller
                                            control={control}
                                            name={`employmentRecord.${index}.day` as const}
                                            
                                            rules={{
                                              required: t('selectDayLabel'),
                                              pattern: {
                                                value:  /^(?!default)\w*/i,
                                                message: t('selectDayReqMsg')
                                              }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                              <Box position='relative'>
                                                <InputLabel sx={{position: 'absolute', top: '-5px', left: '14px', background: 'var(--background)', zIndex: 4, fontSize:'12px !important'}}>Selected Days</InputLabel>
                                                <CustomMuiSelect
                                                  {...register(`employmentRecord.${index}.day` as const)}
                                                  name={`employmentRecord[${index}].day`}
                                                  value={ typeof value === 'string' ? [value] : value }
                                                  multiple
                                                  label={'daysWorked'}
                                                  onChange={(e)=>{
                                                    onChange(e); sortDays(e, index); getShortDays(e.target.value);
                                                  }}
                                                  sx={{ 
                                                    'minWidth':'180px',
                                                    'borderRadius': '15px',
                                                    'height': '50px',
                                                    'fontWeight': '600',
                                                    'fontFamily': 'Nunito',
                                                    overflow: 'hidden',
                                                    maxWidth: '105px'
                                                  }}
                                                >
                                                  {SCHEDULE_OPTIONS.map((option: {value:number, text:string, abbr:string}) => {
                                                    return (
                                                      <MenuItem 
                                                        key={option.text} 
                                                        value={option.text} 
                                                      >
                                                        {t(option.text)}
                                                      </MenuItem>
                                                    )
                                                  })}
                                                </CustomMuiSelect>
                                              </Box>
                                            )}
                                          />
                                          { (!!errors?.employmentRecord?.[index]?.day ) && (<ReqErrorMsg>{errors?.employmentRecord?.[index]?.day?.message}</ReqErrorMsg>)}
                                        </div>
                                        {/* hours */}
                                        <div className='display-contents'>
                                          <ControllerTextField
                                            control={control}
                                            register={register}
                                            required={true}
                                            name={`employmentRecord[${index}].hours`}
                                            rules={{
                                              required: t('hoursReqErrorMsg'),
                                            }}
                                            className=""
                                            label={t('totalHrsLabel')}
                                            placeholder={t('totalHrsLabel')}
                                            setBackgroundColor='var(--background)'
                                          />
                                          {(!!errors?.employmentRecord?.[index]?.hours ) && (<ReqErrorMsg>{errors?.employmentRecord?.[index]?.hours?.message}</ReqErrorMsg>)}
                                        </div>
                                        {/* employer name */}
                                        <div className='display-contents'>
                                          <ControllerTextField
                                            control={control}
                                            register={register}
                                            required={true}
                                            name={`employmentRecord.${index}.employerName` as const}
                                            rules={{
                                              required: t('employerNamePleaseMsg'),
                                            }}
                                            className=""
                                            label={t('employerName')}
                                            placeholder={t('employersName')}
                                            setBackgroundColor='var(--background)'
                                          />
                                          { (!!errors?.employmentRecord?.[index]?.employerName ) && (<ReqErrorMsg>{errors?.employmentRecord?.[index]?.employerName?.message}</ReqErrorMsg>)}
                                        </div>
                                          {/* role */}
                                        <div className='display-contents'>
                                          <ControllerTextField
                                            control={control}
                                            register={register}
                                            required={true}
                                            name={`employmentRecord.${index}.role` as const}
                                            rules={{
                                              required: t('roleReqMsg'),
                                            }}
                                            className=""
                                            label={t('role')}
                                            placeholder={t('reqRoleLabel')}
                                            setBackgroundColor='var(--background)'
                                          />
                                          {(!!errors?.employmentRecord?.[index]?.role ) && (<ReqErrorMsg>{errors?.employmentRecord?.[index]?.role?.message}</ReqErrorMsg>)}
                                        </div>
                                        {/* checkbox + delete */}
                                        <div className="full-width ">
      
                                            <InlineContainer className="job-checkbox">
                                              <ControllerCheckBox
                                                control={control}
                                                name={`employmentRecord.${index}.isPlaceMaker` as const}
                                                register={register}
                                                setValue={setValue}
                                              />
                                              <div className='check-box-multiline'>
                                                <Typography
                                                  fontWeight={900}
                                                  color={'#0F1177'}
                                                >
                                                  {t('individualPlacedByMJM')}
                                                </Typography>
                                      
                                              </div>
                                            </InlineContainer>

                                            <div className="meat-balls">
                                              <IconButton color="error" onClick={()=>{displayConfirmEmploymentDelete(index)}}>
                                                {<DeleteOutlineOutlinedIcon />}
                                              </IconButton>
                                            </div>
                                        </div>
                                    </StackContents>
                                  );
                                }
                              )}


                        </MobileFormFieldContainer>
                      </FormRow>

                    </FormPanel>

                    <FormPanel
                      className='panel-6'
                      ref={refPanel_6}
                      sx={formWidthStyling}
                    >
                      {/* attachments */}
                      <FormRow
                        height='fit-content'
                      >
                        <MobileFormFieldContainer
                          className='full-width'
                        >
                          <Box className="individuals-file-attachments">

                            <Box className="iet-header form-icon inline full-width">
                              <img src={Icon.AttachmentIcon} alt='icon'/>
                              <Typography variant="h3">{t('attachmentsTitle')}</Typography>
                            </Box>

                            <Box className="iet-header form-icon inline full-width" marginTop= '10px'>
                              <Typography variant="h4">{t('resumeUploadTitle')}</Typography>
                            </Box>
                            <UploadFileComponent 
                              text={t('resumeUploadLabel')} 
                              fileName={fileNames[0]} 
                              filePostition={0} 
                              controllerName='resumeUpload'
                              canDelete={(myDetails?.id === individual?.leadProviderId || myDetails?.canDelete || myDetails?.isAdmin)}
                            />

                            <Box className="iet-header form-icon inline full-width" marginTop= '30px'>
                              <Typography variant="h4">{t('coverLetterUploadTitle')}</Typography>
                            </Box>
                            <UploadFileComponent 
                              text={t('coverLetterUploadLabel')} 
                              fileName={fileNames[1]} 
                              filePostition={1} 
                              controllerName='coverLetterUpload'
                              canDelete={(myDetails?.id === individual?.leadProviderId || myDetails?.canDelete || myDetails?.isAdmin)}
                            />

                            <Box className="iet-header form-icon inline full-width" marginTop= '30px'>
                              <Typography variant="h4">{t('refTitle')}: </Typography>
                              <Typography variant="subtitle1" className='ref-helper-text'>&nbsp;{t('refHelperText')}</Typography>
                            </Box>
                            {/* <UploadMultiFileComponent text='Upload References' filesArr={refFiles} removeHandlerVal={2} fileSetState={setRefFiles} maxFiles={3} controllerName='references'/> */}
                            <UploadMultiFileComponent 
                                text={t('refLabel')} 
                                filesArr={refFiles} 
                                removeHandlerVal={2} 
                                fileSetState={setRefFiles} 
                                maxFiles={3} 
                                controllerName='references' 
                                removeFileHandler={removeFileHandler} 
                                control ={control}
                                canDelete={(myDetails?.id === individual?.leadProviderId || myDetails?.canDelete || myDetails?.isAdmin)}
                              />
                            <Box className="iet-header form-icon inline full-width" marginTop= '30px'>
                              <Typography variant="h4">{t('certificationTitle')}: </Typography>
                              <Typography variant="subtitle1" className='ref-helper-text'>&nbsp; {t('certsHelperText')}</Typography>
                            </Box>
                            <UploadMultiFileComponent 
                              text={t('certsLabel')} 
                              filesArr={certFiles} 
                              removeHandlerVal={3} 
                              fileSetState={setCertFiles} 
                              maxFiles={5} 
                              controllerName='certifications'
                              removeFileHandler={removeFileHandler} 
                              control ={control}
                              canDelete={(myDetails?.id === individual?.leadProviderId || myDetails?.canDelete! || myDetails?.isAdmin)}
                            />
                          </Box>                       
                        </MobileFormFieldContainer>
                      </FormRow>

                    </FormPanel>
                  </PanelCarousel>
                </HorizontalScrollContainer>
                
              </StepperWidget>
            }
          </FormContainer>

        {        
          hideFormOverlay 
          && 
          <HideFormOverlayComponent 
            noun={t('savingIndividual')}
          />
        }
        {
          displaySuccessModal 
          && 
          <SuccessComponent 
            noun={t('individual')} 
            onAcknowledged={navigateHandler} 
          />
        }
        </form>
      </FormComponentWrapper>

      {
        confirmEmploymentRecordDelete
        && 
        <Modal 
          className='modal' 
          onHide={cancelActionHandler} 
        >
          <DeleteOverlay
            onClick={(e)=>{e.stopPropagation()}}
            top = {windowDimensions.h}
            right = {windowDimensions.w}
          >
            <ConfirmDeleteComponent  
              msg='Are you sure you want to delete this job record?' 
              confirmAction={deleteEmploymentRecord} 
              goBack={cancelActionHandler
            }/> 

          </DeleteOverlay>
        </Modal>
      }
      {
        displayKeywordSubmit && (
        <Modal 
          onHide={() =>{setDisplayKeywordSubmit(false)}} 
        >
          <KeywordSubmitOverlay
            onClick={(e)=>{e.stopPropagation()}}
            top = {windowDimensions.h}
            right = {windowDimensions.w}
          >
            <PopupMessageComponent 
              className="keyword-submit-popup"
              icon={Icon.SwooshGif}
              message={'Your submission has been recieved, keep an eye out in the weeks to come!'} 
              closeModal={() => { setDisplayKeywordSubmit(false)}} 
              overrideButtonText={'Go Back'}
            />


          </KeywordSubmitOverlay>
        </Modal>
        )}
      </>

  );
};

interface PropsControllerTextField {
  register: UseFormRegister<IndividualFormData>;
  customFunction?: (e: string) => void;
  onClick?: ()=>void;
  control: Control<IndividualFormData, any>;
  name: any;
  rules?: {
    required?: boolean | string;
    maxLength?: number;
    pattern?: { value: RegExp; message: string };
    validate?: { 
      isLessThan?: (value:string) => boolean,
      isGreaterThan?: (value:string) => boolean,
      isFullDecimal?: (value:string) => boolean,
      isEmpty?: (value:string) => boolean,
      isZero?: (value:string) => boolean,
    }
  };
  required: boolean;
  placeholder: string;
  className: string;
  id?:string;
  label?: string;
  focused?:boolean;
  type?: string;
  helperText?:string,
  setBackgroundColor?:string,
}

const ControllerTextField = (props: PropsControllerTextField) => {
  const {
    control,
    name,
    placeholder,
    rules,
    register,
    required,
    className,
    customFunction,
    onClick,
    label,
    focused,
    type,
    id,
    helperText,
    setBackgroundColor
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FormTextInput
          {...register(name)}
          id={id ? id :"outlined-required"}
          className={className}
          type={type ? type : 'text'}
          placeholder={placeholder}
          label={label ? label : placeholder}
          value={value || ""}
          required={required}
          autoFocus={focused ? true : false}
          helperText={helperText || null}
          onChange={(e) => {
            if (customFunction) {
              onChange(customFunction(e.target.value));
            } else {
              onChange(e.target.value);
            }
          }}
          onClick={ onClick && onClick }
          background={setBackgroundColor ? setBackgroundColor : 'white'}
        />
      )}
    />
  );
};

interface PropsControllerCheckbox {
  control: Control<IndividualFormData, any>;
  name: any;
  disabled?:boolean;
  register: UseFormRegister<IndividualFormData>;
  setValue: UseFormSetValue<IndividualFormData>;
}

const ControllerCheckBox = (props: PropsControllerCheckbox) => {
  const { control, register, name, setValue, disabled } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => (
        <Checkbox
          {...register(name)}
          disabled={disabled}
          onChange={(e) => {
            onChange(e.target.checked);
            setValue(name, e.target.checked);
          }}
          checked={Boolean(value > 0)}
          sx={{
              '&.Mui-checked' : {
              color: 'var(--secondary-color)'
            }
          }}
        />
      )}
    />
  );
};

export default IndividualCreateComponent;

export const IndividualCreateHeader = () => {
  const t = useTranslate(); 
  return(
    <PageTitleComponent greeting={t('addIndividualBtn')} />
  )
}

export const IndividualEditHeader = () => {
  const t = useTranslate(); 
  return(
    <PageTitleComponent greeting={t('individualViewEditBtn')} />
  )
}