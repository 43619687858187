import { Button, IconButton, Tooltip } from '@mui/material';
import React from 'react'
import { Controller } from 'react-hook-form';
import Icon from '../../asssets/icons';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Control} from "react-hook-form";

// used for a single file (IndividualCreateComponent stores the Resume and Coverletter as an array ...why I did that I can't remember. I do apologize. Don't try to use this component when refactoring IndividualCreateComponent)
const UploadFileComponent = (props:{ text: string, fileName:string, filePostition:number, controllerName:string, setFileName:React.Dispatch<React.SetStateAction<string>>, removeFileHandler:(index:number)=>void, control: Control<any,any>, canDelete:boolean }) => {
  const {text, fileName, filePostition, controllerName, setFileName, removeFileHandler, control, canDelete} = props;

  return (
    <div className='blue-stitch inline-a file-upload'>
      <Button 
        variant="text" 
        component="label"
        disabled={!canDelete} 
      >
        {
          (fileName === 'empty' || fileName === '' || fileName === null || fileName === undefined) ? 
          <div className="inline-a">
            <img src={Icon.AttachmentIcon} alt='icon'/>
             {text}
          </div>
          : 
          <Tooltip
            title={(fileName?.startsWith('https') ? fileName.split('-').pop() : fileName) || ''}
            placement='top'
          >
            <p className='text-overflow'>
              { fileName?.startsWith('https') ? fileName.split('-').pop() : fileName}
            </p>
          </Tooltip>
        }

        <Controller
          control={control}
          name={controllerName as any}
          render={({ field}) => (
            <input
              type="file"
              name={controllerName as any}
              onChange={(e) => {
                // console.info('Resume File', e.target.files![0])
                field.onChange(e.target.files![0]);
                setFileName((currState) => currState = e.target.files![0]?.name)
                e.target.value = ''; //edge case, if user removes, then re-adds same file, onChange will not fire if you don't do this.
              }}
              multiple={false}
              disabled={!canDelete}
              hidden
          />
        )}
      />
      </Button>

      <div className="meat-balls notif-highlight">
        {
          canDelete 
          && 
          <IconButton 
            color="inherit"  
            onClick={()=>{removeFileHandler(filePostition)}}
            sx={{
              opacity: fileName !== 'empty' ? '1':'0', 
              width: fileName !== 'empty' ? '50px':'0px', 
              display: fileName !== 'empty' ? 'content':'none',
            }} 
          >
            {<DeleteOutlineOutlinedIcon />}
          </IconButton>
        }
      </div>
    </div>
  )
}

export default UploadFileComponent