import React from 'react'
import PuzzleLoader from '../UI/Animation/PuzzleLoader'

const NavigationPlaceholderComponent = () => {
  return (
    <div className='site-loading'>
      <PuzzleLoader/>
    </div>
  )
}

export default NavigationPlaceholderComponent