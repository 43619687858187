import { Box, Button, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { ReactNode } from 'react';
import useTranslate from '../../../hooks/useTranslate';
import { CloseJobIcon, PopupModalContainer } from '../StyledComponents/NotificationModalStyledComponents';

interface IPopUpProps {
  closeModal: () => void;
  message: ReactNode;
  className?: string;
  icon?: string;
  overrideButtonText?:string;
}

const cardStyle = {
  width: 500,
  margin: "auto",
  textAlign: 'center',
  padding: 1.5,
  transition: "0.3s",
  backgroundColor: 'white',
  boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  "&:hover": {
    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
  }
}

const PopupMessageComponent = (props: IPopUpProps) => {
  const { closeModal, message, className, icon, overrideButtonText } = props;
  const t = useTranslate();
  return(
      <PopupModalContainer className={className + ''}>
        <Box>
          <CloseJobIcon
            src={icon} 
            alt='icon'
          />
          <Typography variant="h4">{message}</Typography>
        </Box>
        <br/>
        <Button 
          sx={{background: 'var(--primary-color) !important', color: 'white'}}
          onClick={closeModal}
        >
          {overrideButtonText ? overrideButtonText : t('iUnderstand')}
        </Button>
      </PopupModalContainer>
  );
}

export default PopupMessageComponent;